import styled from "styled-components";

const Container = styled.main`
  min-height: 100vh;
  min-height: 100dvh;
  padding-bottom: 200px;

  & section:not(:last-child) {
    margin-bottom: 80px;
  }

  & .mapSection {
    & .map {
      position: relative;

      &:not(:last-child) {
        margin-bottom: 50px;
      }

      &:last-child .textWrap {
        background-color: rgba(34, 34, 34, 0.8);
      }

      & .textWrap {
        position: absolute;
        top: 0;
        left: 80px;
        display: flex;
        flex-direction: column;
        padding: 30px;
        width: 296px;
        height: 100%;
        color: #fff;
        background-color: rgba(1, 120, 192, 0.8);
        z-index: 2;

        & .title {
          font-size: 28px;
          font-weight: bold;
        }

        & ul {
          margin-top: auto;

          & .mapInfoList:not(:last-child) {
            margin-bottom: 10px;
          }

          & .icon {
            width: 24px;
            height: 24px;
            margin-bottom: 6px;
          }

          & p {
            font-size: 14px;
            font-weight: 500;
          }
        }
      }

      & .mapWrap {
        height: 400px;
        box-shadow: 0px 4px 36px rgba(34, 34, 34, 0.2);
      }
    }
  }

  @media (max-width: 1480px) {
    padding-bottom: calc(100vw * (200 / 1480));

    & section:not(:last-child) {
      margin-bottom: calc(100vw * (80 / 1480));
    }

    & .mapSection {
      & .map {
        &:not(:last-child) {
          margin-bottom: calc(100vw * (50 / 1480));
        }

        & .textWrap {
          left: calc(100vw * (80 / 1480));
          padding: calc(100vw * (30 / 1480));
          width: calc(100vw * (296 / 1480));

          & .title {
            font-size: calc(100vw * (28 / 1480));
          }

          & ul {
            & .mapInfoList:not(:last-child) {
              margin-bottom: calc(100vw * (10 / 1480));
            }

            & .icon {
              width: calc(100vw * (24 / 1480));
              height: calc(100vw * (24 / 1480));
              margin-bottom: calc(100vw * (6 / 1480));
            }

            & p {
              font-size: calc(100vw * (14 / 1480));
            }
          }
        }

        & .mapWrap {
          height: calc(100vw * (400 / 1480));
          box-shadow: 0px calc(100vw * (4 / 1480)) calc(100vw * (36 / 1480))
            rgba(34, 34, 34, 0.2);
        }
      }
    }
  }

  @media (max-width: 768px) {
    min-height: calc(100vh - calc(100vw * (309 / 375)));
    min-height: calc(100dvh - calc(100vw * (309 / 375)));
    padding: calc(100vw * (66 / 375)) 0 calc(100vw * (130 / 375));

    & section:not(:last-child) {
      margin-bottom: calc(100vw * (50 / 375));
    }

    & .mapSection {
      & .map {
        display: flex;
        flex-direction: column-reverse;

        &:not(:last-child) {
          margin-bottom: 30px;
        }

        &:last-child .textWrap {
          background-color: var(--black);
        }

        & .textWrap {
          position: static;
          align-items: center;
          width: 100%;
          padding: calc(100vw * (20 / 375));
          background-color: var(--blue02);

          & .title {
            margin-bottom: calc(100vw * (30 / 375));
            font-size: calc(100vw * (24 / 375));
          }

          & ul {
            & .mapInfoList:not(:last-child) {
              margin-bottom: calc(100vw * (10 / 375));
            }

            & .icon {
              width: calc(100vw * (24 / 375));
              height: calc(100vw * (24 / 375));
              margin: 0 auto calc(100vw * (6 / 375));
            }

            & p {
              font-size: calc(100vw * (14 / 375));
              text-align: center;
            }
          }
        }

        & .mapWrap {
          height: calc(100vw * (400 / 375));
          box-shadow: 0px calc(100vw * (4 / 375)) calc(100vw * (36 / 375))
            rgba(34, 34, 34, 0.2);
        }
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
