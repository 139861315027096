import styled from "styled-components";

const Container = styled.section`
  &.structureSection {
    & .sectionDescription {
      margin-bottom: 20px;
    }

    & .img {
      height: 450px;
      padding: 30px 307px;
    }
  }

  &.strategySection {
    & ul {
      display: flex;
      flex-wrap: wrap;
      gap: 14px;
      margin-bottom: 30px;

      & li {
        width: calc(calc(100% - 14px) / 2);
      }
    }

    & .img {
      height: 360px;
      padding: 30px 470px;
    }
  }

  &.definitionSection {
    & ul {
      display: flex;
      gap: 15px;
      margin-bottom: 30px;

      & li {
        flex: 1 0;
      }
    }

    & .inner > figure .img {
      height: 624px;
      padding: 30px 280px;
    }

    & .wrap {
      margin-top: 20px;

      & figure {
        & .img {
          width: 645px;
          height: 420px;
        }

        &:first-child .img {
          padding: 86px 100px;
        }

        &:last-child .img {
          padding: 113px 25px;
        }
      }
    }
  }

  &.designSection {
    & .sectionDescription {
      margin-bottom: 30px;
    }

    & .img {
      height: 452px;
      padding: 30px 287px;
    }
  }

  &.validationSection {
    & .sectionDescription {
      &:first-of-type {
        margin-bottom: 30px;
      }
      &:last-of-type {
        margin-bottom: 20px;
      }
    }

    & ul {
      display: flex;
      gap: 15px;
      margin-bottom: 30px;

      & li {
        flex: 1 0;
      }
    }

    & figure {
      & .img {
        padding: 30px 421px;
      }

      &:first-of-type .img {
        height: 456px;
        margin-bottom: 30px;
      }

      &:last-of-type .img {
        height: 434px;
        padding: 30px 421px;
      }
    }
  }

  @media (max-width: 1480px) {
    &.structureSection {
      & .sectionDescription {
        margin-bottom: calc(100vw * (20 / 1480));
      }

      & .img {
        height: calc(100vw * (450 / 1480));
        padding: calc(100vw * (30 / 1480)) calc(100vw * (307 / 1480));
      }
    }

    &.strategySection {
      & ul {
        gap: calc(100vw * (14 / 1480));
        margin-bottom: calc(100vw * (30 / 1480));

        & li {
          width: calc(calc(100% - calc(100vw * (14 / 1480))) / 2);
        }
      }

      & .img {
        height: calc(100vw * (360 / 1480));
        padding: calc(100vw * (30 / 1480)) calc(100vw * (470 / 1480));
      }
    }

    &.definitionSection {
      & ul {
        gap: calc(100vw * (15 / 1480));
        margin-bottom: calc(100vw * (30 / 1480));
      }

      & .inner > figure .img {
        height: calc(100vw * (624 / 1480));
        padding: calc(100vw * (30 / 1480)) calc(100vw * (280 / 1480));
      }

      & .wrap {
        margin-top: calc(100vw * (20 / 1480));

        & figure {
          & .img {
            width: calc(100vw * (645 / 1480));
            height: calc(100vw * (420 / 1480));
          }

          &:first-child .img {
            padding: calc(100vw * (86 / 1480)) calc(100vw * (100 / 1480));
          }

          &:last-child .img {
            padding: calc(100vw * (113 / 1480)) calc(100vw * (25 / 1480));
          }
        }
      }
    }

    &.designSection {
      & .sectionDescription {
        margin-bottom: calc(100vw * (30 / 1480));
      }

      & .img {
        height: calc(100vw * (452 / 1480));
        padding: calc(100vw * (30 / 1480)) calc(100vw * (287 / 1480));
      }
    }

    &.validationSection {
      & .sectionDescription {
        &:first-of-type {
          margin-bottom: calc(100vw * (30 / 1480));
        }
        &:last-of-type {
          margin-bottom: calc(100vw * (20 / 1480));
        }
      }

      & ul {
        gap: calc(100vw * (15 / 1480));
        margin-bottom: calc(100vw * (30 / 1480));
      }

      & figure {
        & .img {
          padding: calc(100vw * (30 / 1480)) calc(100vw * (421 / 1480));
        }

        &:first-of-type .img {
          height: calc(100vw * (456 / 1480));
          margin-bottom: calc(100vw * (30 / 1480));
        }

        &:last-of-type .img {
          height: calc(100vw * (434 / 1480));
          padding: calc(100vw * (30 / 1480)) calc(100vw * (421 / 1480));
        }
      }
    }
  }

  @media (max-width: 768px) {
    &.structureSection {
      & .sectionDescription {
        margin-bottom: calc(100vw * (20 / 375));
      }

      & .img {
        height: calc(100vw * (208 / 375));
        padding: calc(100vw * (21 / 375)) calc(100vw * (17 / 375));
      }
    }

    &.strategySection {
      & ul {
        gap: calc(100vw * (14 / 375));
        margin-bottom: calc(100vw * (20 / 375));

        & li {
          width: 100%;
        }
      }

      & .img {
        height: calc(100vw * (278 / 375));
        padding: calc(100vw * (20 / 375)) calc(100vw * (17 / 375));
      }
    }

    &.definitionSection {
      & ul {
        flex-direction: column;
        gap: calc(100vw * (14 / 375));
        margin-bottom: calc(100vw * (20 / 375));

        & li {
          flex: auto;
        }
      }

      & .inner > figure .img {
        height: calc(100vw * (270 / 375));
        padding: calc(100vw * (20 / 375)) calc(100vw * (13 / 375));
      }

      & .wrap {
        margin-top: calc(100vw * (14 / 375));

        & figure {
          & .img {
            width: 100%;
          }

          &:first-child .img {
            height: calc(100vw * (206 / 375));
            padding: calc(100vw * (20 / 375)) calc(100vw * (18 / 375));
          }

          &:last-child .img {
            height: calc(100vw * (137 / 375));
            padding: calc(100vw * (20 / 375)) calc(100vw * (19 / 375));
          }
        }
      }
    }

    &.designSection {
      & .sectionDescription {
        margin-bottom: calc(100vw * (20 / 375));
      }

      & .img {
        height: calc(100vw * (202 / 375));
        padding: calc(100vw * (20 / 375)) calc(100vw * (13 / 375));
      }
    }

    &.validationSection {
      & .sectionDescription {
        &:first-of-type {
          margin-bottom: calc(100vw * (20 / 375));
        }
        &:last-of-type {
          margin-bottom: calc(100vw * (20 / 375));
        }
      }

      & ul {
        flex-direction: column;
        gap: calc(100vw * (14 / 375));
        margin-bottom: calc(100vw * (20 / 375));
      }

      & figure {
        &:first-of-type .img {
          height: calc(100vw * (286 / 375));
          margin-bottom: calc(100vw * (30 / 375));
          padding: calc(100vw * (17 / 375)) calc(100vw * (16 / 375));
        }

        &:last-of-type .img {
          height: calc(100vw * (278 / 375));
          padding: calc(100vw * (20 / 375)) calc(100vw * (16 / 375));
        }
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
