import connector from "service/connector"
import utils from "utils";


const getStudyList = async (filter) => {
  return await connector.connectFetchController("my/study" + (filter ? "?" + utils.etc.getFilterStr(filter) : ""), "GET");
}
const getStudyDetail = async (filter) => {
  return await connector.connectFetchController("my/study/detail" + (filter ? "?" + utils.etc.getFilterStr(filter) : ""), "GET");
}
const postStudyTax = async (data) => {
  return await connector.jsonConnector("my/study/tax", "POST", data);
}

export {
  getStudyList,
  getStudyDetail,
  postStudyTax,
};

export default {
  getStudyList,
  getStudyDetail,
  postStudyTax,
}