import Styles from "./styles";

function BasicTextarea({ value, data, setInputs, onChange }) {
  const { labelText, name, placeholder, errorText } = data;

  const onChangeEvent = (e) => {
    const { name, value } = e.target;
    if (onChange) {
      onChange(e);
    } else
      setInputs((e) => {
        return { ...e, [name]: value };
      });
  };

  return (
    <Styles.Container>
      {labelText && (
        <div className="inputLabel">
          <label>{labelText}</label>
        </div>
      )}

      <textarea
        className={errorText ? "error" : ""}
        name={name}
        placeholder={placeholder}
        value={value ? value : data.value}
        onChange={onChangeEvent}
      ></textarea>
    </Styles.Container>
  );
}

export default BasicTextarea;
