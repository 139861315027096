import utils from "utils";
import Styles from "./styles";
import { api } from "service";
import { common, layout, ui } from "components";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCategory, useLanguage, useProduct } from "hooks";

// img
import BgBanner from "resources/image/education_center/bg_main.jpg";
import BgBannerMb from "resources/image/education_center/bg_main_mb.jpg";
import ImgSubBanner from "resources/image/education_center/img_sub_banner.png";
import IconCenter01 from "resources/image/icon/icon_center01.svg";
import IconCenter02 from "resources/image/icon/icon_center02.svg";
import IconCenter03 from "resources/image/icon/icon_center03.svg";
import IconCenter04 from "resources/image/icon/icon_center04.svg";

function EducationCnbisCurriculmPage() {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === ""

  const location = useLocation();
  const navigate = useNavigate();
  const [filter,setFilter] = useState();

  const categoryInfo = useCategory({
    listOption: {
      type: 1, 
      parent_info_id: 2,
    },
  });
  const productInfo = useProduct({
    listOption: filter,
  });

  useEffect(()=>{
    let param = utils.etc.getSearchParam();
    let f = {
      page: 1,
      pageSize: 20,
      recordSize: 999,
    };
    if(param.category_info_id){
      f.category_info_id = param.category_info_id;
    } else {
      f.category_info_id = 2;
    }
    setFilter({ ...f });
  },[location])

  // data
  const introductionList = [
    {
      icon: IconCenter01,
      text: (languageQuarter ?
        <>
          강사진 전원 <br />
          심사원 자격 보유
        </> : <>
          All instructors are <br />
          qualified as examiners
        </>
      ),
    },
    {
      icon: IconCenter02,
      text: (languageQuarter ?
        <>
          소규모/수준별 <br />
          맞춤 강의
        </> : <>
          small-scale/level-specific lectures
        </>
      ),
    },
    {
      icon: IconCenter03,
      text: (languageQuarter ?
        <>
          관련 분야 업무 경력 <br />
          10년 이상의
          <br className="mbOnly" /> 전문 강사진
        </> : <>
          More than 10 years of professional instructor in the relevant field
        </>
      ),
    },
    {
      icon: IconCenter04,
      text: (languageQuarter ?
        <>
          실무 적용에 <br />
          최적화된 강의
        </> : <>
          lectures optimized <br />
          for practical application
        </>
      ),
    },
  ];

  return (
    <>
      <common.Header />
      <Styles.Container>
        <section className="bannerSection">
          <layout.MainBanner
            img={BgBanner}
            mbImg={BgBannerMb}
            colorText={"C&BIS"}
            title={languageQuarter ? "교육과정" : "Curriculum"}
          />
        </section>

        <section className="introductionSection">
          <div className="inner">
            <h3>
              {
                languageQuarter ?
                <>
                  C&BIS 교육센터
                  <span>는</span>
                </> : <>
                  <span>The{" "}</span>
                  C&BIS training center
                </>

              }
            </h3>

            <p className="description">
              {languageQuarter ? <>
                자동차 전기/전자제어부품을 개발하는 개발자와 관리자에게 실무
                역량을 배양하기 위한 자체 교육과정을 운영하고 있습니다. <br />
                교육과정은 Automotive SPICE, Automotive Functional Safety, SOTIF,
                OTA, Cybersecurity를 주제로, 기본, 중급, 분석과정 등으로
                나뉘어집니다.
                <br />
                다양한 교육과정을 통한 수준별 학습이 가능하며, 최신 동향을 반영한
                교육 내용을 바탕으로 실무에서 바로 활용할 수 있는 지식과 기술을
                습득할 수 있습니다.
              </> : <>
                Is running our own curriculum to develop practical skills for developers and managers
                <br className="pcOnly"/>{" "}
                who develop automotive electrical and electronic control parts. The curriculum is divided into basic, intermediate,
                <br className="pcOnly"/>{" "}
                and analysis courses under the theme of Automotive SPICE, Automotive Functional Safety, SOTIF, OTA, Cybersecurity.
                <br /> You can learn by level through various curriculums, and you can acquire knowledge
                <br className="pcOnly"/>{" "}
                and skills that can be used in practice based on educational content that reflects the latest trends.
              </>}
            </p>

            <ul>
              {introductionList.map((item, index) => {
                return (
                  <li
                    className="introductionList"
                    key={"introductionList" + index}
                  >
                    <div className="icon">
                      <img src={item.icon} alt="introductionIcon" />
                    </div>

                    <p className="text">{item.text}</p>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>

        <section className="subBannerSection">
          <div className="inner">
            <div className="textWrap">
              <small>C&BIS Education Center</small>

              <h3>
                {languageQuarter ? <>
                  C&BIS 교육센터는 <br />
                  실무 경험이 충분한 강사진을
                  <br />
                  바탕으로 수준 높은 교육을
                  <br className="mbOnly" /> 제공합니다.
                </> : <>
                  The C&BIS Education Center <br />
                  provides high-quality education
                  <br className="pcOnly"/>{" "}
                  based on a staff of instructors
                  <br className="pcOnly"/>{" "}
                  with sufficient practical experience.
                </>}
              </h3>
            </div>

            <div className="img">
              <img src={ImgSubBanner} alt="ImgSubBanner" />
            </div>
          </div>
        </section>

        {languageQuarter &&
          <section className="curriculmSection">
            <div className="inner">
              <h3>{languageQuarter ? "C&BIS 교육과정" : "C&BIS curriculum"}</h3>

              <div className="tab">
                <ui.button.EducationTab
                  list={[{ title: "All", id: 2 }].concat(categoryInfo.list)}
                  tabIndex={filter?.category_info_id}
                  setTabIndex={v => { setFilter(e => { return { ...e, category_info_id: v } }) }}
                  onClick={(e) => {
                    navigate(utils.etc.getQueryParamUrl({ category_info_id: e.id, disableScrollTop: true }));
                  }}
                />
              </div>

              <layout.EducationSlider
                id={"cnbis"}
                // link={"/education/curriculum-detail/cnbis"}
                data={productInfo?.listQuery?.data?.list || []}
                isIntro={true}
              />
            </div>
          </section>
        }
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default EducationCnbisCurriculmPage;
