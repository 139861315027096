import styled from "styled-components";

const Container = styled.div`
  & .titleContainer {
    margin-bottom: 20px;
    padding: 50px 30px;
    font-weight: 500;
    text-align: center;
    background-color: var(--blue04);
    border-radius: 20px;

    & .title {
      margin-bottom: 20px;
      font-size: 28px;
    }

    & .date {
      color: var(--gray01);
    }
  }

  & .fileWrap {
    margin-top: 20px;
    padding: 30px 60px;
    background-color: #fafafa;
    border-radius: 20px;

    & .fileList {
      &:not(:last-child) {
        margin-bottom: 20px;
      }

      & a {
        display: flex;
        align-items: center;

        & .fileName {
          max-width: calc(100% - 38px);
          font-size: 18px;
          font-weight: 500;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        & .icon {
          width: 24px;
          height: 24px;
          margin-left: 14px;
          filter: var(--blue02Filter);
        }
      }
    }
  }

  & .listButton {
    width: 260px;
    margin: 80px auto 0;
  }

  @media (max-width: 1480px) {
    & .titleContainer {
      margin-bottom: calc(100vw * (20 / 1480));
      padding: calc(100vw * (50 / 1480)) calc(100vw * (30 / 1480));
      border-radius: calc(100vw * (20 / 1480));

      & .title {
        margin-bottom: calc(100vw * (20 / 1480));
        font-size: calc(100vw * (28 / 1480));
      }
    }

    & .fileWrap {
      margin-top: calc(100vw * (20 / 1480));
      padding: calc(100vw * (30 / 1480)) calc(100vw * (60 / 1480));
      border-radius: calc(100vw * (20 / 1480));

      & .fileList {
        &:not(:last-child) {
          margin-bottom: calc(100vw * (20 / 1480));
        }

        & a {
          & .fileName {
            max-width: calc(100% - calc(100vw * (38 / 1480)));
            font-size: calc(100vw * (18 / 1480));
          }

          & .icon {
            width: calc(100vw * (24 / 1480));
            height: calc(100vw * (24 / 1480));
            margin-left: calc(100vw * (14 / 1480));
          }
        }
      }
    }

    & .listButton {
      width: calc(100vw * (260 / 1480));
      margin: calc(100vw * (80 / 1480)) auto 0;
    }
  }

  @media (max-width: 768px) {
    & .titleContainer {
      margin-bottom: calc(100vw * (20 / 375));
      padding: calc(100vw * (20 / 375));
      border-radius: calc(100vw * (12 / 375));

      & .title {
        margin-bottom: calc(100vw * (16 / 375));
        font-size: calc(100vw * (16 / 375));
      }
    }

    & .fileWrap {
      margin-top: calc(100vw * (20 / 375));
      padding: calc(100vw * (20 / 375)) calc(100vw * (25 / 375));
      border-radius: calc(100vw * (10 / 375));

      & .fileList {
        &:not(:last-child) {
          margin-bottom: calc(100vw * (14 / 375));
        }

        & a {
          & .fileName {
            max-width: calc(100% - calc(100vw * (32 / 375)));
            font-size: calc(100vw * (14 / 375));
          }

          & .icon {
            width: calc(100vw * (18 / 375));
            height: calc(100vw * (18 / 375));
            margin-left: calc(100vw * (14 / 375));
          }
        }
      }
    }

    & .listButton {
      width: calc(100vw * (180 / 375));
      margin: calc(100vw * (50 / 375)) auto 0;
    }
  }
`;

export { Container };

export default {
  Container,
};
