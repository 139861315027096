import Styles from "./styles";

function BasicInput({ value, data, setInputs, onChange, onKeyup }) {
  const {
    id,
    labelText,
    essential,
    type,
    name,
    placeholder,
    errorText,
    confirmText,
    disabled,
  } = data;

  const onChangeEvent = (e) => {
    const { name, value } = e.target;
    if (onChange) onChange(e);
    else setInputs((e) => (
      { ...e, [name]: value }
    ));
  };

  const onKeyupEvent = (e) => {
    if (onKeyup) onKeyup(e);
  }

  return (
    <Styles.Container>
      {labelText && (
        <div className="inputLabel">
          <label
            htmlFor={id || name}
            className={essential ? "essential" : ""}
          >
            {labelText}
          </label>

          {errorText && <p className="errorText">{errorText}</p>}
          {confirmText && <p className="confirmText">{confirmText}</p>}
        </div>
      )}

      <input
        className={errorText ? "error" : ""}
        type={type || "text"}
        id={id || name}
        name={name}
        placeholder={placeholder}
        value={value || data.value}
        onChange={onChangeEvent}
        onKeyUp={onKeyupEvent}
        disabled={disabled}
      />
    </Styles.Container>
  );
}

export default BasicInput;
