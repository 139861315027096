import styled from "styled-components";

// img
import ImgAsk from "resources/image/customer/img_ask.png";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 11;

  & .modalInner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 694px;
    background-color: #fff;

    & .modalTitle {
      padding: 91px 40px 40px;
      font-size: 36px;
      font-weight: bold;
      color: #fff;
      background: url(${ImgAsk}) no-repeat calc(100% - 40px) center / 219px
        203px var(--blue02);

      & p {
        margin-top: 12px;
        font-size: 14px;
        font-weight: 500;

        & button {
          font-size: 14px;
          font-weight: 800;
          color: #fff;
          border-bottom: 2px solid #fff;
        }
      }
    }

    & .wrap {
      padding: 30px 80px 40px;
      max-height: 45vh;
      max-height: 45dvh;
      overflow: auto;

      & .formTitle {
        margin-bottom: 20px;
        font-size: 22px;
        font-weight: 600;
      }

      & .info {
        & .inputWrap {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          margin-bottom: 30px;

          & .input {
            width: 100%;
          }

          & .input:nth-child(-n + 2) {
            width: calc(calc(100% - 20px) / 2);
          }
        }
      }

      & .contents {
        & .radioWrap {
          display: flex;
          gap: 20px;
          margin-bottom: 20px;
        }

        & .input {
          margin-bottom: 20px;
        }

        & .textarea {
          margin-bottom: 30px;

          & textarea {
            height: 250px;
          }
        }
      }

      & .terms {
        margin-bottom: 80px;

        & .check {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-bottom: 10px;
          font-size: 18px;
        }

        & .termsDetail {
          padding: 16px 20px;
          font-size: 14px;
          color: var(--gray01);
          line-height: 1.43;
          background-color: #fafafa;
        }
      }

      & .buttonWrap {
        position: relative;
        display: flex;
        gap: 14px;

        & .errorText {
          position: absolute;
          bottom: calc(100% + 20px);
          left: 50%;
          transform: translateX(-50%);
          width: max-content;
          font-size: 18px;
          font-weight: 500;
          color: var(--red);
        }
      }
    }
  }

  & .overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(34, 34, 34, 0.7);
  }

  @media (max-width: 1480px) {
    & .modalInner {
      width: calc(100vw * (694 / 1480));

      & .modalTitle {
        padding: calc(100vw * (91 / 1480)) calc(100vw * (40 / 1480))
          calc(100vw * (40 / 1480));
        font-size: calc(100vw * (36 / 1480));
        background: url(${ImgAsk}) no-repeat
          calc(100% - calc(100vw * (40 / 1480))) center /
          calc(100vw * (219 / 1480)) calc(100vw * (203 / 1480)) var(--blue02);

        & p {
          margin-top: calc(100vw * (12 / 1480));
          font-size: calc(100vw * (14 / 1480));

          & button {
            font-size: calc(100vw * (14 / 1480));
            border-bottom: calc(100vw * (2 / 1480)) solid #fff;
          }
        }
      }

      & .wrap {
        padding: calc(100vw * (30 / 1480)) calc(100vw * (80 / 1480))
          calc(100vw * (40 / 1480));

        & .formTitle {
          margin-bottom: calc(100vw * (20 / 1480));
          font-size: calc(100vw * (22 / 1480));
        }

        & .info {
          & .inputWrap {
            gap: calc(100vw * (20 / 1480));
            margin-bottom: calc(100vw * (30 / 1480));

            & .input:nth-child(-n + 2) {
              width: calc(calc(100% - calc(100vw * (20 / 1480))) / 2);
            }
          }
        }

        & .contents {
          & .radioWrap {
            gap: calc(100vw * (20 / 1480));
            margin-bottom: calc(100vw * (20 / 1480));
          }

          & .input {
            margin-bottom: calc(100vw * (20 / 1480));
          }

          & .textarea {
            margin-bottom: calc(100vw * (30 / 1480));

            & textarea {
              height: calc(100vw * (250 / 1480));
            }
          }
        }

        & .terms {
          margin-bottom: calc(100vw * (80 / 1480));

          & .check {
            gap: calc(100vw * (10 / 1480));
            margin-bottom: calc(100vw * (10 / 1480));
            font-size: calc(100vw * (18 / 1480));
          }

          & .termsDetail {
            padding: calc(100vw * (16 / 1480)) calc(100vw * (20 / 1480));
            font-size: calc(100vw * (14 / 1480));
          }
        }

        & .buttonWrap {
          gap: calc(100vw * (14 / 1480));

          & .errorText {
            bottom: calc(100% + calc(100vw * (20 / 1480)));
            font-size: calc(100vw * (18 / 1480));
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    & .modalInner {
      width: calc(100vw * (335 / 375));

      & .modalTitle {
        padding: calc(100vw * (96 / 375)) calc(100vw * (20 / 375))
          calc(100vw * (20 / 375));
        font-size: calc(100vw * (26 / 375));
        background: url(${ImgAsk}) no-repeat
          calc(100% - calc(100vw * (20 / 375))) bottom /
          calc(100vw * (150 / 375)) calc(100vw * (140 / 375)) var(--blue02);

        & p {
          margin-top: calc(100vw * (8 / 375));
          font-size: calc(100vw * (14 / 375));

          & button {
            font-size: calc(100vw * (14 / 375));
            border-bottom: calc(100vw * (2 / 375)) solid #fff;
          }
        }
      }

      & .wrap {
        padding: calc(100vw * (20 / 375)) calc(100vw * (30 / 375))
          calc(100vw * (30 / 375));

        & .formTitle {
          margin-bottom: calc(100vw * (14 / 375));
          font-size: calc(100vw * (16 / 375));
        }

        & .info {
          & .inputWrap {
            flex-direction: column;
            gap: calc(100vw * (10 / 375));
            margin-bottom: calc(100vw * (20 / 375));

            & .input:nth-child(-n + 2) {
              width: 100%;
            }
          }
        }

        & .contents {
          & .radioWrap {
            flex-direction: column;
            gap: calc(100vw * (14 / 375));
            margin-bottom: calc(100vw * (14 / 375));
          }

          & .input {
            margin-bottom: calc(100vw * (14 / 375));
          }

          & .textarea {
            margin-bottom: calc(100vw * (25 / 375));

            & textarea {
              height: calc(100vw * (275 / 375));
            }
          }
        }

        & .terms {
          margin-bottom: calc(100vw * (50 / 375));

          & .check {
            gap: calc(100vw * (14 / 375));
            margin-bottom: calc(100vw * (6 / 375));
            font-size: calc(100vw * (14 / 375));
          }

          & .termsDetail {
            padding: calc(100vw * (15 / 375)) calc(100vw * (14 / 375));
            font-size: calc(100vw * (12 / 375));
            word-break: keep-all;
          }
        }

        & .buttonWrap {
          flex-direction: column;
          gap: calc(100vw * (15 / 375));
          width: calc(100vw * (180 / 375));
          margin: 0 auto;

          & .errorText {
            bottom: calc(100% + calc(100vw * (10 / 375)));
            font-size: calc(100vw * (14 / 375));
          }
        }
      }
    }
  }
`;

export { Container };

export default { Container };
