import styled from "styled-components";

const Container = styled.section`
  &.subjectSection {
    & .wrap {
      margin: 30px 0;

      & ul {
        display: flex;
        gap: 15px;

        & li {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 260px;
          height: 60px;
          font-weight: bold;
          color: #fff;
          background-color: var(--blue02);
        }
      }

      & .moreButton {
        position: relative;
        width: 260px;

        &::before {
          position: absolute;
          top: 50%;
          right: calc(100% + 15px);
          transform: translateY(-50%);
          width: 218px;
          border-bottom: 1px dashed var(--black);
          content: "";
        }
      }
    }
  }

  &.procedureSection {
    & .sectionDescription {
      margin-bottom: 30px;
    }

    & .img {
      height: 402px;
      padding: 30px 224px;
    }
  }

  &.holdingsSection {
    & .sectionDescription {
      margin-bottom: 30px;
    }

    & .img {
      height: 422px;
      padding: 30px 215px;
    }
  }

  &.historySection {
    & .historyWrap li {
      display: flex;
      height: 78px;
      border-radius: 10px;
      overflow: hidden;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      & .title,
      .text {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      & .title {
        width: 116px;
        font-weight: bold;
        color: #fff;
        background-color: var(--blue02);
      }

      & .text {
        width: 497px;
        font-weight: 500;
        background-color: var(--blue04);
      }
    }

    & .logoWrap {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      gap: 20px 19px;
      font-size: 14px;
      font-weight: 500;
      text-align: center;

      & li {
        width: 204px;

        & .img {
          height: 82px;
          margin-bottom: 10px;
          border-radius: 10px;
          box-shadow: 3px 5px 15px 0px rgba(63, 93, 111, 0.14);
          overflow: hidden;
        }
      }
    }
  }

  @media (max-width: 1480px) {
    &.subjectSection {
      & .wrap {
        margin: calc(100vw * (30 / 1480)) 0;

        & ul {
          gap: calc(100vw * (15 / 1480));

          & li {
            width: calc(100vw * (260 / 1480));
            height: calc(100vw * (60 / 1480));
          }
        }

        & .moreButton {
          width: calc(100vw * (260 / 1480));

          &::before {
            right: calc(100% + calc(100vw * (15 / 1480)));
            width: calc(100vw * (218 / 1480));
          }
        }
      }
    }

    &.procedureSection {
      & .sectionDescription {
        margin-bottom: calc(100vw * (30 / 1480));
      }

      & .img {
        height: calc(100vw * (402 / 1480));
        padding: calc(100vw * (30 / 1480)) calc(100vw * (224 / 1480));
      }
    }

    &.holdingsSection {
      & .sectionDescription {
        margin-bottom: calc(100vw * (30 / 1480));
      }

      & .img {
        height: calc(100vw * (422 / 1480));
        padding: calc(100vw * (30 / 1480)) calc(100vw * (215 / 1480));
      }
    }

    &.historySection {
      & .historyWrap li {
        height: calc(100vw * (78 / 1480));
        border-radius: calc(100vw * (10 / 1480));

        &:not(:last-child) {
          margin-bottom: calc(100vw * (20 / 1480));
        }

        & .title {
          width: calc(100vw * (116 / 1480));
        }

        & .text {
          width: calc(100vw * (497 / 1480));
        }
      }

      & .logoWrap {
        gap: calc(100vw * (20 / 1480)) calc(100vw * (19 / 1480));
        font-size: calc(100vw * (14 / 1480));

        & li {
          width: calc(100vw * (204 / 1480));

          & .img {
            height: calc(100vw * (82 / 1480));
            margin-bottom: calc(100vw * (10 / 1480));
            border-radius: calc(100vw * (10 / 1480));
            box-shadow: calc(100vw * (3 / 1480)) calc(100vw * (5 / 1480))
              calc(100vw * (15 / 1480)) 0 rgba(63, 93, 111, 0.14);
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    &.subjectSection {
      & .wrap {
        align-items: center;
        gap: calc(100vw * (172 / 375));
        margin: calc(100vw * (30 / 375)) 0;

        & ul {
          flex-direction: column;
          gap: calc(100vw * (20 / 375));

          & li {
            width: calc(100vw * (180 / 375));
            height: calc(100vw * (41 / 375));
          }
        }

        & .moreButton {
          width: calc(100vw * (180 / 375));

          &::before {
            top: auto;
            bottom: calc(100% + calc(100vw * (16 / 375)));
            right: 50%;
            transform: translateX(-50%);
            width: 0;
            height: calc(100vw * (140 / 375));
            border-right: 1px dashed var(--black);
          }
        }
      }
    }

    &.procedureSection {
      & .sectionDescription {
        margin-bottom: calc(100vw * (20 / 375));
      }

      & .img {
        height: calc(100vw * (158 / 375));
        padding: calc(100vw * (20 / 375)) calc(100vw * (16 / 375));
      }
    }

    &.holdingsSection {
      & .sectionDescription {
        margin-bottom: calc(100vw * (20 / 375));
      }

      & .img {
        height: calc(100vw * (162 / 375));
        padding: calc(100vw * (20 / 375)) calc(100vw * (18 / 375));
      }
    }

    &.historySection {
      & .wrap {
        gap: calc(100vw * (30 / 375));
      }
      & .historyWrap li {
        height: calc(100vw * (62 / 375));
        border-radius: calc(100vw * (10 / 375));

        &:not(:last-child) {
          margin-bottom: calc(100vw * (14 / 375));
        }

        & .title {
          width: calc(100vw * (116 / 375));
        }

        & .text {
          width: calc(100vw * (219 / 375));
        }
      }

      & .logoWrap {
        justify-content: flex-start;
        gap: calc(100vw * (14 / 375)) calc(100vw * (7 / 375));
        font-size: calc(100vw * (14 / 375));

        & li {
          width: calc(100vw * (164 / 375));

          & .img {
            height: calc(100vw * (66 / 375));
            margin-bottom: calc(100vw * (14 / 375));
            border-radius: calc(100vw * (10 / 375));
            box-shadow: calc(100vw * (3 / 375)) calc(100vw * (5 / 375))
              calc(100vw * (15 / 375)) 0 rgba(63, 93, 111, 0.14);
          }
        }
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
