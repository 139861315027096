import styled from "styled-components";

// img
import IconEssential from "resources/image/icon/icon_essential.svg";

const Container = styled.div`
  & h3 .errorText {
    margin-left: 20px;
    font-size: 18px;
    font-weight: 500;
    color: var(--red);
  }

  & .licenseInfo {
    & .licenseUpload {
      display: flex;
      align-items: center;

      & .title {
        position: relative;
        margin-right: 40px;
        font-size: 20px;
        font-weight: bold;

        &::after {
          position: absolute;
          top: 0;
          right: -10px;
          width: 7px;
          height: 7px;
          background: url(${IconEssential}) no-repeat center / cover;
          content: "";
        }
      }

      & input {
        display: none;
      }

      & label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 260px;
        height: 60px;
        padding: 0 20px;
        font-weight: bold;
        border: 1px solid var(--black);
        cursor: pointer;

        & .icon {
          width: 19px;
          height: 19px;
        }
      }
    }

    & .licenseFile {
      display: flex;
      align-items: center;
      gap: 10px;
      height: 64px;
      margin-top: 30px;
      padding: 0 20px;
      font-size: 18px;
      font-weight: 500;
      background-color: #fafafa;

      & .deleteButton {
        flex-shrink: 0;
        display: block;
        width: 24px;
        height: 24px;
      }
    }
  }

  & .inputListWrap {
    display: flex;
    flex-wrap: wrap;
    gap: 30px 40px;

    & .inputList {
      position: relative;
      width: 640px;

      &:last-child {
        margin-right: calc(100% - 640px);
      }

      & textarea {
        height: 204px;
      }

      & .guide {
        position: absolute;
        top: 62px;
        right: 20px;

        & .icon {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }

        & .guideListWrap {
          position: absolute;
          right: 0;
          bottom: calc(100% + 4px);
          display: none;
          width: max-content;
          padding: 15px;
          font-size: 14px;
          font-weight: 500;
          background-color: var(--blue04);
          border-radius: 4px;

          & .guideList {
            position: relative;
            padding-left: 10px;

            &:not(:last-child) {
              margin-bottom: 9px;
            }

            &::before {
              position: absolute;
              top: -1px;
              left: 0;
              content: "•";
            }
          }
        }

        &:hover .guideListWrap {
          display: block;
        }
      }
    }
  }

  & .depositInfo {
    & .depositListWrap {
      margin-top: 30px;
      color: var(--black);

      & .list {
        &:not(:last-child) {
          margin-bottom: 14px;
        }

        & .listTitle {
          width: 128px;
        }
      }
    }
  }

  & .buttonWrap {
    display: flex;
    justify-content: center;
    gap: 30px;

    & .prevButton,
    .cancelButton,
    .saveButton {
      width: 260px;
    }
  }

  @media (max-width: 1480px) {
    & h3 .errorText {
      margin-left: calc(100vw * (20 / 1480));
      font-size: calc(100vw * (18 / 1480));
    }

    & .licenseInfo {
      & .licenseUpload {
        & .title {
          margin-right: calc(100vw * (40 / 1480));
          font-size: calc(100vw * (20 / 1480));

          &::after {
            right: calc(100vw * (-10 / 1480));
            width: calc(100vw * (7 / 1480));
            height: calc(100vw * (7 / 1480));
          }
        }

        & label {
          width: calc(100vw * (260 / 1480));
          height: calc(100vw * (60 / 1480));
          padding: 0 calc(100vw * (20 / 1480));

          & .icon {
            width: calc(100vw * (19 / 1480));
            height: calc(100vw * (19 / 1480));
          }
        }
      }

      & .licenseFile {
        gap: calc(100vw * (10 / 1480));
        height: calc(100vw * (64 / 1480));
        margin-top: calc(100vw * (30 / 1480));
        padding: 0 calc(100vw * (20 / 1480));
        font-size: calc(100vw * (18 / 1480));

        & .deleteButton {
          width: calc(100vw * (24 / 1480));
          height: calc(100vw * (24 / 1480));
        }
      }
    }

    & .inputListWrap {
      gap: calc(100vw * (30 / 1480)) calc(100vw * (40 / 1480));

      & .inputList {
        width: calc(100vw * (640 / 1480));

        &:last-child {
          margin-right: calc(100% - calc(100vw * (640 / 1480)));
        }

        & textarea {
          height: calc(100vw * (204 / 1480));
        }

        & .guide {
          top: calc(100vw * (62 / 1480));
          right: calc(100vw * (20 / 1480));

          & .icon {
            width: calc(100vw * (24 / 1480));
            height: calc(100vw * (24 / 1480));
          }

          & .guideListWrap {
            bottom: calc(100% + calc(100vw * (4 / 1480)));
            padding: calc(100vw * (15 / 1480));
            font-size: calc(100vw * (14 / 1480));
            border-radius: calc(100vw * (4 / 1480));

            & .guideList {
              padding-left: calc(100vw * (10 / 1480));

              &:not(:last-child) {
                margin-bottom: calc(100vw * (9 / 1480));
              }
            }
          }
        }
      }
    }

    & .depositInfo {
      & .depositListWrap {
        margin-top: calc(100vw * (30 / 1480));

        & .list {
          &:not(:last-child) {
            margin-bottom: calc(100vw * (14 / 1480));
          }

          & .listTitle {
            width: calc(100vw * (128 / 1480));
          }
        }
      }
    }

    & .buttonWrap {
      gap: calc(100vw * (30 / 1480));

      & .prevButton,
      .cancelButton,
      .saveButton {
        width: calc(100vw * (260 / 1480));
      }
    }
  }

  @media (max-width: 768px) {
    & h3 .errorText {
      margin-left: calc(100vw * (14 / 375));
      font-size: calc(100vw * (12 / 375));
    }

    & .licenseInfo {
      & .licenseUpload {
        & .title {
          margin-right: calc(100vw * (20 / 375));
          font-size: calc(100vw * (14 / 375));

          &::after {
            right: calc(100vw * (-8 / 375));
            width: calc(100vw * (5 / 375));
            height: calc(100vw * (5 / 375));
          }
        }

        & label {
          width: calc(100vw * (180 / 375));
          height: calc(100vw * (41 / 375));
          padding: 0 calc(100vw * (10 / 375));

          & .icon {
            width: calc(100vw * (16 / 375));
            height: calc(100vw * (16 / 375));
          }
        }
      }

      & .licenseFile {
        gap: calc(100vw * (8 / 375));
        height: calc(100vw * (45 / 375));
        margin-top: calc(100vw * (20 / 375));
        padding: 0 calc(100vw * (14 / 375));
        font-size: calc(100vw * (14 / 375));

        & .deleteButton {
          width: calc(100vw * (16 / 375));
          height: calc(100vw * (16 / 375));
        }
      }
    }

    & .inputListWrap {
      gap: calc(100vw * (20 / 375));

      & .inputList {
        width: 100%;

        &:last-child {
          margin-right: 0;
        }

        & textarea {
          height: calc(100vw * (164 / 375));
        }

        & .guide {
          top: calc(100vw * (43 / 375));
          right: calc(100vw * (11 / 375));

          & .icon {
            width: calc(100vw * (18 / 375));
            height: calc(100vw * (18 / 375));
          }

          & .guideListWrap {
            bottom: calc(100% + calc(100vw * (2 / 375)));
            max-width: calc(100vw * (315 / 375));
            padding: calc(100vw * (10 / 375));
            font-size: calc(100vw * (12 / 375));
            border-radius: calc(100vw * (4 / 375));
            word-break: keep-all;

            & .guideList {
              padding-left: calc(100vw * (8 / 375));

              &:not(:last-child) {
                margin-bottom: calc(100vw * (6 / 375));
              }
            }
          }
        }
      }
    }

    & .depositInfo {
      & .depositListWrap {
        margin-top: calc(100vw * (20 / 375));

        & .list {
          &:not(:last-child) {
            margin-bottom: calc(100vw * (10 / 375));
          }

          & .listTitle {
            width: calc(100vw * (84 / 375));
          }
        }
      }
    }

    & .buttonWrap {
      flex-direction: column;
      align-items: center;
      gap: calc(100vw * (15 / 375));

      & .prevButton,
      .cancelButton,
      .saveButton {
        width: calc(100vw * (180 / 375));
      }
    }
  }
`;

export { Container };

export default { Container };
