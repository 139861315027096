import Styles from "./styles";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useFileIntroduce, useLanguage } from "hooks";

// img
import LogoWhite from "resources/image/icon/logo_white.svg";
import Logo from "resources/image/icon/logo.svg";
import IconDownload from "resources/image/icon/icon_download.svg";
import IconGlobal from "resources/image/icon/icon_global.svg";
import IconMenu from "resources/image/icon/icon_menu.svg";
import IconClose from "resources/image/icon/icon_close.svg";
import ImgLnb01 from "resources/image/common/img_lnb01.png";
import ImgLnb02 from "resources/image/common/img_lnb02.png";
import ImgLnb03 from "resources/image/common/img_lnb03.png";
import ImgLnb04 from "resources/image/common/img_lnb04.png";

function Header({ main }) {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";
  const fileIntroduceInfo = useFileIntroduce({ studyFile: true });
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });

  const [isScroll, setIsScroll] = useState(0);
  const [isHover, setIsHover] = useState("");
  const [isGlobalMenuOpen, setIsGlobalMenuOpen] = useState(false);
  const [language, setLanguage] = useState(
    languageInfo.suffix === "" ? "KOR" : "ENG"
  );
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSubDepthHover, setIsSubDepthHover] = useState("");

  // 스크롤 시 헤더 스타일 변경
  const updateScroll = () => {
    setIsScroll(window.pageYOffset || document.documentElement.scrollTop);
  };

  useEffect(() => {
    window.addEventListener("scroll", updateScroll);
    return () => {
      window.addEventListener("scroll", updateScroll);
    };
  });

  // 메뉴 오픈 시 스크롤 막기
  useEffect(() => {
    const body = document.body;
    const html = document.querySelector("html");
    if (isMenuOpen) {
      body.className = "hidden";
      html.className = "hidden";
    } else {
      body.classList.remove("hidden");
      html.classList.remove("hidden");
    }
  }, [isMenuOpen]);

  // data
  const gnbList = [
    {
      text: languageQuarter ? (
        "회사소개"
      ) : isMenuOpen ? (
        <>
          About <br />
          C&BIS
        </>
      ) : (
        "About C&BIS"
      ),
      url: "/introduction/ceo-greeting",
      nav: {
        navTitle: "About C&BIS",
        lnbList: [
          {
            text: languageQuarter ? "CEO 인사말" : "CEO Message",
            url: "/introduction/ceo-greeting",
          },
          {
            text: languageQuarter ? "연혁" : "History",
            url: "/introduction/history",
          },
          {
            text: languageQuarter ? "조직도" : "Organization",
            url: "/introduction/organize-chart",
          },
          {
            text: languageQuarter ? "사업실적" : "Business Performance",
            url: "/introduction/performance",
          },
          {
            text: languageQuarter ? "오시는 길" : "Directions",
            url: "/introduction/direction",
          },
        ],
        navImg: ImgLnb01,
      },
    },
    {
      text: languageQuarter ? "사업영역" : "Technical Service",
      url: `/business/depth/menu${
        window.location.pathname.includes("business")
          ? "?disableScrollTop=true&focus=bis_menu"
          : ""
      }`,
      nav: {
        navTitle: "For a safer world",
        lnbList: [
          {
            text: "Process & Engineering Service",
            url: "/business/depth/service?disableScrollTop=true&focus=bis_menu_0"
          },
          {
            text: "Assessment",
            url: "/business/depth/assessment?disableScrollTop=true&focus=bis_menu_1"
          },
          {
            text: "Automated Driving R&D",
            url: "/business/depth/r&d?disableScrollTop=true&focus=bis_menu_2"
          },
          {
            text: "Solution",
            url: "/business/depth/solution?disableScrollTop=true&focus=bis_menu_3"
          },
        ],
        navImg: ImgLnb02,
      },
    },
    {
      text: languageQuarter ? "교육" : "Training",
      url: "/education/center-introduction",
      nav: {
        navTitle: "Training",
        lnbList: [
          {
            text: languageQuarter ? "교육센터 소개" : "Education Center",
            url: "/education/center-introduction",
          },
          {
            text: languageQuarter ? "C&BIS 교육과정" : "CEC Education",
            url: "/education/cnbis-curriculum",
          },
          {
            text: languageQuarter ? "intacs™️ 공식 교육과정" : "intacs™️",
            url: "/education/intacs-curriculum",
          },
          languageQuarter && {
            text: "교육신청",
            url: "/education/apply",
          },
          languageQuarter && {
            text: "교육일정",
            url: "/schedule",
          },
        ],
        navImg: ImgLnb03,
      },
    },
    {
      text: languageQuarter ? "고객지원" : "Support",
      url: "/customer-support/notice",
      nav: {
        navTitle: "Support",
        lnbList: [
          {
            text: languageQuarter ? "공지사항" : "Notice",
            url: "/customer-support/notice",
          },
          {
            text: languageQuarter ? "문의사항" : "Contact Us",
            url: "/customer-support/ask",
          },
          {
            text: languageQuarter ? "채용정보" : "Recruitment",
            url: "/customer-support/recruitment",
          },
        ],
        navImg: ImgLnb04,
      },
    },
  ];
  const globalList = [
    {
      text: "KOR",
      onClick: () => {
        languageInfo.setLanguage("KOR");
      },
    },
    {
      text: "ENG",
      onClick: () => {
        languageInfo.setLanguage("ENG");
      },
    },
  ];

  return (
    <>
      <Styles.Container
        onMouseLeave={() => {
          setIsHover("");
        }}
        className={
          !isScroll && !isMobile && isHover === "" && main ? " main" : ""
        }
      >
        <div className="headerWrap">
          <div className="inner">
            <h1>
              <Link to={"/"}>
                <img
                  src={
                    !isScroll && !isMobile && isHover === "" && main
                      ? LogoWhite
                      : Logo
                  }
                  alt="logo"
                />
              </Link>
            </h1>

            <ul className="gnb">
              {gnbList.map((item, index) => {
                return (
                  <li
                    className="gnbList"
                    key={"gnbList" + index}
                    onMouseEnter={() => setIsHover(index)}
                  >
                    <Link to={item.url}>{item.text}</Link>
                  </li>
                );
              })}
            </ul>

            <ul className="util">
              <li className="utilList">
                <a
                  href={fileIntroduceInfo.getStudyFile.data?.file_url}
                  download
                  className="downloadButton"
                >
                  {languageQuarter
                    ? "교육과정 소개서"
                    : "Introduction to the curriculum"}
                  <div className="icon">
                    <img src={IconDownload} alt="IconDownload" />
                  </div>
                </a>
              </li>

              <li className="utilList">
                <button
                  type="button"
                  className="globalButton"
                  onClick={() => {
                    setIsGlobalMenuOpen(!isGlobalMenuOpen);
                  }}
                >
                  <img src={IconGlobal} alt="IconGlobal" />
                </button>

                {isGlobalMenuOpen && (
                  <ul className="global">
                    {globalList.map((item, index) => {
                      return (
                        <li key={"globalList" + index}>
                          <button
                            type="button"
                            className={language === item.text ? "on" : ""}
                            onClick={() => {
                              setLanguage(item.text);
                              setIsGlobalMenuOpen(false);
                              item.onClick();
                              window.location.reload();
                            }}
                          >
                            {item.text}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </li>

              <li className="utilList">
                <button
                  type="button"
                  className="menuButton"
                  onClick={() => {
                    setIsMenuOpen(true);
                  }}
                >
                  <img src={IconMenu} alt="IconMenu" />
                </button>
              </li>
            </ul>
          </div>
        </div>

        {isHover !== "" && (
          <nav>
            <div className="inner">
              <p className="navTitle">{gnbList[isHover].nav.navTitle}</p>

              <ul className="lnb">
                {gnbList[isHover].nav.lnbList.map((item, index) => {
                  return (
                    <li
                      className="lnbList"
                      key={"lnbList" + index}
                      onClick={() => {
                        setIsHover("");
                      }}
                    >
                      <Link to={item.url}>{item.text}</Link>
                    </li>
                  );
                })}
              </ul>

              <div className="img">
                <img src={gnbList[isHover].nav.navImg} alt="navImg" />
              </div>
            </div>
          </nav>
        )}
      </Styles.Container>

      {isMenuOpen && (
        <Styles.Menu>
          <div className="inner">
            <a
              href={fileIntroduceInfo.getStudyFile.data?.file_url}
              download
              className="downloadButton"
            >
              {languageQuarter
                ? "교육과정 소개서"
                : "Introduction to the curriculum"}
              <div className="icon">
                <img src={IconDownload} alt="IconDownload" />
              </div>
            </a>

            <button
              type="button"
              className="closeButton"
              onClick={() => {
                setIsMenuOpen(false);
              }}
            >
              <img src={IconClose} alt="IconClose" />
            </button>

            <ul className="menu">
              {gnbList.map((item, index) => {
                return (
                  <li className="menuList" key={"menuList" + index}>
                    <p
                      className={
                        "depth01" +
                        (!isMobile && isSubDepthHover === index ? " on" : "")
                      }
                    >
                      {item.text}
                    </p>

                    <ul
                      className="depth02"
                      onMouseLeave={() => {
                        setIsSubDepthHover("");
                      }}
                    >
                      {item.nav.lnbList.map((current, idx) => {
                        return (
                          <li
                            className="depth02List"
                            key={"depth02List" + idx}
                            onMouseEnter={() => {
                              setIsSubDepthHover(index);
                            }}
                          >
                            <Link
                              to={current.url}
                              onClick={() => {
                                setIsMenuOpen(false);
                              }}
                            >
                              <span>{current.text}</span>
                              <span>{current.text}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              })}
            </ul>
          </div>
        </Styles.Menu>
      )}
    </>
  );
}

export default Header;
