import Styles from "./styles";
import ScrollContainer from "react-indiana-drag-scroll";
import { layout, ui } from "components";
import { useLanguage } from "hooks";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

// img
import ImgSotif01 from "resources/image/business/service/img_sotif01.png";
import ImgSotif02 from "resources/image/business/service/img_sotif02.png";
import ImgSotif03 from "resources/image/business/service/img_sotif03.jpg";
import ImgSotif03En from "resources/image/business/service/img_sotif03_en.jpg";
import ImgSotif03Mb from "resources/image/business/service/img_sotif03_mb.jpg";
import ImgSotif03MbEn from "resources/image/business/service/img_sotif03_mb_en.jpg";
import ImgSotif04 from "resources/image/business/service/img_sotif04.png";
import ImgSotif04Mb from "resources/image/business/service/img_sotif04_mb.png";
import ImgSotif05 from "resources/image/business/service/img_sotif05.png";
import ImgSotif06 from "resources/image/business/service/img_sotif06.jpg";
import ImgSotif06En from "resources/image/business/service/img_sotif06_en.jpg";
import ImgSotif06Mb from "resources/image/business/service/img_sotif06_mb.jpg";
import ImgSotif06MbEn from "resources/image/business/service/img_sotif06_mb_en.jpg";
import ImgSotif07 from "resources/image/business/service/img_sotif07.png";

function Sotif() {
  const languageInfo = useLanguage()
  const languageQuarter = languageInfo.suffix === ""
  const navigate = useNavigate();

  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });

  const introductionList = {
    iso26262: languageQuarter ? [
      <>
        E/E 시스템의 오작동 행위에 의해 <br />
        유발되는 위험원으로 인한 불합리한 위험(Risk)의 부재
      </>,
      <>
        차량 수준 위험원 및 관련 안전 목표를 결정하기 위해 <br />
        위험 분석 및 위험 평가(HARA)를 수행하는 방법
      </>,
      <>
        안전목표를 위반할 수 있는 랜덤 하드웨어 고장 및 <br />
        시스템적 고장을 방지하고 제어하기 위한 요구사항과 권고사항
      </>,
    ] : [
      <>
        Absence of unreasonable risks due to hazards caused by
        malfunctioning behavior of the E/E system
      </>,
      <>
        How to perform a hazard analysis and risk assessment (HARA)
        to determine vehicle-level hazards and associated safety goals
      </>,
      <>
        Requirements and recommendations to avoid and control random hardware
        failures and systematic failures that could violate safety goals
      </>,
    ],
    iso21448: languageQuarter ? [
      "환경을 올바르게 인식할 기능의 불능",
      <>
        센서 입력 변형, 융합에 사용된 휴리스틱스 또는 다양한 환경 <br />
        조건과 관련된 기능, 시스템 또는 알고리즘의 강건성의 부족
      </>,
      <>
        의사결정 알고리즘 및/또는 다른 인간의 <br />
        기대치로 인한 예상치 못한 행동
      </>,
    ] : [
      "Inability to properly perceive the environment",
      <>
        Lack of robustness of functions, systems or algorithms related to
        sensor input variations, heuristics used for fusion, or various environmental conditions.
      </>,
      <>
        Unexpected behavior due to decision-making algorithms and/or other human expectations.
      </>,
    ],
  };

  const overviewList = languageQuarter ? [
    <>
      Area 2는 의도된 기능의 분석을 기반으로 <br className="pcOnly" />
      위험 수용 <br className="mbOnly" />
      평가(Risk Acceptance Evaluation)을 수행
    </>,
    <>
      기능 수정을 통해 Area 2에서 위험한 행동
      <br />
      (Hazardous Behavior)을 유발하는 알려진 위험한 <br />
      시나리오의 가능성을 허용 가능한 기준(Acceptable <br />
      Criterion)로 줄이는 활동 수행
    </>,
    <>
      적절한 Verification 및 Validation 전략을 통해 <br className="mbOnly" />
      Area 3에서 <br className="pcOnly" />
      잠재적인 위험한 행동(Hazardous <br className="mbOnly" />
      Behavior)을 유발하는 <br className="pcOnly" />
      알려지지 않은 시나리오의 <br className="mbOnly" />
      가능성을 수용 가능한 기준
      <br className="pcOnly" />
      (Acceptable Criterion)로 <br className="mbOnly" />
      줄이는 활동 수행
    </>,
  ] : [
    <>
      Risk acceptance evaluation of area2 based on analysis of intended functionality.
    </>,
    <>
      Reduction the probability of known dangerous scenarios causing hazardous behavior
      in area 2 to acceptable criteria through functional modifications
    </>,
    <>
      Reduction the probability of unknown scenarios causing potentially hazardous B-behavior
      in area 3 to acceptable criteria through an adequate verification and validation strategies.
    </>,
  ];

  const coverageList = {
    first: languageQuarter ? [
      "차량 수준에서 의도된 기능의 규격의 불충분",
      "시스템에서 전기 및/또는 전자(E/E) 엘리먼트들의 구현 시 규격의 불충분 또는 성능 불충분",
    ] : [
      "The insufficiencies of specification of intended functions at vehicle level",
      "The insufficiencies of specification or performance insufficiencies in the implementation of electrical and/or electronic (E/E) elements in the system",
    ],
    second: languageQuarter ? [
      <>
        적절한 상황 인식이 안전에 필수적이며 상황 인식이{" "}
        <br className="mbOnly" />
        복잡한 센서 <br className="pcOnly" />
        및 처리 알고리즘, 특히 비상 개입 시스템 <br className="mbOnly" />및
        1에서 5까지의 운전 <br className="pcOnly" />
        자동화 수준을 가진 시스템에서 <br className="mbOnly" />
        파생된 의도된 기능
      </>,
      <>
        모페드(Moped)를 제외한 양산 도로 차량에 설치된 <br className="mbOnly" />
        하나 이상의 <br className="pcOnly" />
        E/E 시스템을 포함하는 의도된 기능
      </>,
      <>
        원격 사용자가 차량을 조작 또는 지원하거나 차량 의사{" "}
        <br className="mbOnly" />
        결정에 <br className="pcOnly" />
        영향을 줄 수 있는 백오피스와의 통신이 안전 <br className="mbOnly" />
        위험원 <br className="pcOnly" />
        (Safety Hazard)으로 이어질 수 있는 경우
      </>,
    ] : [
      <>
        The proper situational awareness is essential to safety and the intended function
        for which situational awareness is derived from complex sensors and processing algorithms,
        especially emergency intervention systems and systems with driving automation levels from 1 to 5.
      </>,
      <>
        The intended functionalities are included in one or more E/E systems
        installed on production road vehicles, excluding Moped.
      </>,
      <>
        The operation or assistance of a vehicle by a remote user or communication with
        a back office that can affect vehicle decision making can lead to safety hazards.
      </>,
    ],
    third: languageQuarter ? [
      "ISO 26262 시리즈에서 다루는 결함",
      "사이버 보안 위협",
      "시스템 기술에 의해 직접적으로 유발되는 위험원",
      <>
        E/E 시스템의 의도된 기능에 의해 직접적으로 유발되지{" "}
        <br className="mbOnly" />
        않는 한 <br className="pcOnly" />
        감전, 화재, 연기, 열, 방사선, 독성, 가연성, <br className="mbOnly" />
        반응성,
        <br className="pcOnly" />
        에너지 방출과 관련된 위험원
      </>,
      <>
        기능 남용으로 간주되는 시스템의 의도된 사용을 <br />
        명백하게 위반하는 고의적인 행동
      </>,
    ] : [
      "Faults covered by ISO 26262 series",
      "Cybersecurity Threats",
      "Hazards directly caused by system technology",
      <>
        Hazards related to electric shock, fire, smoke, heat, radiation, toxicity,
        combustibility, reactivity, energy release unless directly caused by the intended function of the E/E system
      </>,
      <>
        Deliberate actions that clearly violate the intended use of the system considered to be a feature abuse.
      </>,

    ],
  };

  return (
    <>
      <Styles.Container className="titleSection">
        <div className="inner" id="focus">
          <layout.DescriptionBox
            title={
              <>
                ISO 21448 – Road vehicle <br className="mbOnly" />- Safety of
                the intended functionality
              </>
            }
            left={"ISO 21448" + (languageQuarter ? "이란" : "")}
            right={languageQuarter ?
              <>
                자동차 시스템의 자율성 증가는 새로운 안전 문제를 제기
                <br className="mbOnly" />
                합니다. 시스템의 의도된 기능이 위험한 상황을 초래할 수 있는
                방식으로 동작하는 것은 ISO26262에서 다루지 않습니다. ISO21448
                표준은 기능안전과 달리 오작동, 고장, 결함에 관련된 것을 다루는
                것이 아니라 의도된 설계 자체가 안전을 확보하기에 불충분/부적절한
                경우를 다룹니다.
              </> : <>
                Increasing autonomy in automotive systems poses new safety concerns.
                The way the intended functions of the system operate in a way that can
                lead to dangerous situations is not covered by ISO26262. Unlike functional
                safety, the ISO21448 standard does not address malfunctions, failures, and defects,
                but rather deals with cases where the intended design itself is insufficient/inadequate to ensure safety.
              </>
            }
          />
        </div>
      </Styles.Container>

      <Styles.Container className="introductionSection">
        <div className="inner">
          <h3>{languageQuarter ? "ISO 21448 소개" : "ISO 21448 Introduction"}</h3>

          <p className="sectionDescription">
            {languageQuarter ?
              <>
                도로 차량의 안전은 도로 차량 산업에서 매우 중요한 관심사
                <br className="mbOnly" />
                입니다. 차량에 포함된 자율주행 기능의 수가 증가하고 있습니다.
                자율주행 기능은 전기 및/또는 전자(E/E) 시스템에 의해 구현되는 감지,
                복잡한 알고리즘 처리 그리고 작동에 의존
                <br className="mbOnly" />
                하고 있습니다. <br />
                도로 차량에 대한 안전의 허용 가능한 수준은 규격의
                불충분(Insufficiency Of Specification) 또는 성능 불충분(Performance
                Insufficiency)에 의한 두가지 위험원(Hazard)을 포함하여 의도된 기능
                및 구현과 관련된 모든 위험원(Hazard)으로 인한 불합리한 위험(Risk)의
                부재를 요구합니다.
                <br />
                <br />
                기능 안전의 달성을 위한 ISO 26262은 아래와 같은 내용을 다룹니다.
              </> : <>
                The safety of road vehicles is a very important concern in the road vehicle industry.
                The number of self-driving features embedded in vehicles is increasing and they depend
                on the detection, complex algorithmic processing and operation implemented by electrical
                and/or electronic (E/E) systems. The acceptable level of safety for road vehicles requires
                the absence of unreasonable risk due to all hazards associated with the intended function
                and implementation, including two hazards caused by Insufficiencies of Specification or Performance Insufficiencies.
                <br />
                <br />
                ISO 26262 for the achievement of functional safety covers the following.
              </>}
          </p>

          <ul>
            {introductionList.iso26262.map((item, index) => {
              return (
                <li
                  className="introductionList"
                  key={"introductionList" + index}
                >
                  <layout.BusinessTable
                    title={`0${index + 1}`}
                    description={item}
                  />
                </li>
              );
            })}
          </ul>

          <p className="sectionDescription">
            {languageQuarter ?
              <>
                도로의 상황을 인식하는 E/E 시스템(외부 또는 내부 차량 환경을 감지)은
                ISO 26262 시리즈에서 다루는 결함이 없는 시스템임에도 불구하고 위험한
                행동(Hazardous behavior)을 유발할 수 있습니다. <br />
                <br />
                ISO 21448에서 다루는 위험한 행동의 예는 다음과 같습니다.
              </> : <>
                An E/E system (detecting the exterior or interior vehicle environment) that
                recognizes road conditions can cause hazardous behavior, despite the fact that
                it is a flawless system covered by ISO 26262 series.
                <br />
                <br />
                Examples of dangerous behaviors covered in ISO 21448 include.
              </>}
          </p>

          <ul>
            {introductionList.iso21448.map((item, index) => {
              return (
                <li
                  className="introductionList"
                  key={"introductionList" + index}
                >
                  <layout.BusinessTable
                    title={`0${index + 1}`}
                    description={item}
                  />
                </li>
              );
            })}
          </ul>

          <p className="sectionDescription">
            {languageQuarter ?
              <>
                특히 이러한 요인(Factor)들은 기계 학습(Machine Learning)을 사용하는
                기능, 시스템 또는 알고리즘과 관련이 있습니다. <br />
                따라서, ISO 26262와 ISO 21448은 안전의 보완적인 측면이 있으며, ISO
                21448은 SOTIF을 위한 위험원(Hazard)을 제거하거나 위험(Risk)을 줄이기
                위한 활동들과 수단을 제공하고 있습니다.
              </> : <>
                In particular, these factors are related to functions, systems,or algorithms that use Machine Learning.
                Thus, ISO 26262 and ISO 21448 have complementary aspects of safety,
                and ISO 21448 provides activities and means to eliminate hazards or reduce risks for SOTIF
              </>}
          </p>
        </div>
      </Styles.Container>

      <Styles.Container className="overviewSection">
        <div className="inner">
          <h3>{languageQuarter ? "ISO 21448 개요" : "ISO 21448 Overview"}</h3>

          <div className="grayBox">
            <p className="title">
              {languageQuarter ?
                <>
                  SOTIF 관련 <span>위험한 사건 모델</span>
                  <br className="mbOnly" />
                  (SOTIF-related Hazardous event model)
                </> : <>
                  SOTIF-related <span>Hazardous event model</span>
                </>}
            </p>

            <div className="text">
              <p>
                {languageQuarter ?
                  <>
                    SOTIF의 목적은 SOTIF 관련 위험한 사건 모델과 연관된 위험 수준이
                    충분히 낮다는 것을 보증하기 위해 사용된 활동들과 근거를 설명하는
                    것입니다. <br />
                    <br />
                    아래의 그림 1은 SOTIF 관련 위험한 사건 모델을 보여준
                    순서도입니다.
                  </> : <>
                    The purpose of the SOTIF is to describe the activities and rationale used
                    to ensure that the risk level associated with the SOTIF-related hazardous event model is sufficiently low.
                    <br />
                    <br />
                    The figure below is a flowchart showing the SOTIF-related hazardous event model.


                  </>}
              </p>

              <figure>
                <div className="img">
                  <img src={ImgSotif01} alt="ImgSotif01" />
                </div>
              </figure>

              <ul>
                {languageQuarter ?
                  <>
                    <li>
                      트리거 조건(Triggering Conditions)는 합리적으로 예상할 수 있는
                      직접 오용(Reasonably Foreseeable Direct Misuse)를 포함합니다.
                    </li>
                    <li>
                      위험한 사건을 통제할 수 없는 것은 합리적으로 예상할 수 있는
                      간접 오용(Reasonably Foreseeable Indirect Misuse)의 결과일
                      수도 있습니다.
                    </li>
                  </> : <>
                    <li>
                      Trigger Conditions include Reasonably Foreseeable Direct Misuse, which is reasonably predictable.
                    </li>
                    <li>
                      The inability to control dangerous events may also be a result of reasonably foreseeable indirect misuse.
                    </li>
                  </>}
              </ul>
            </div>
          </div>

          <div className="grayBox">
            <p className="title">
              {languageQuarter ?
                <>
                  SOTIF의 <span>4가지 시나리오 영역</span>
                  <br className="mbOnly" />
                  (Four scenario areas)
                </> : <>
                  <span>Four Scenario Areas</span> in SOTIF
                </>}
            </p>

            <div className="text">
              <p>
                {languageQuarter ?
                  <>
                    위험한 시나리오(Hazardous Scenarios)는 위험한 행위(Hazardous
                    Behavior)의 원인이 되는 시나리오이고, 관련된 Use-case들의
                    시나리오는 아래 그림과 같이 4가지 영역으로 분류됩니다.
                  </> : <>
                    Hazardous Scenario is a scenario that causes Hazardous Behavior,
                    and the scenarios for the use-case involved are classified into four areas, as shown in the figure below.
                  </>}
              </p>

              <div className="wrap">
                <figure>
                  <div className="img">
                    <img src={ImgSotif02} alt="ImgSotif02" />
                  </div>
                </figure>

                <ScrollContainer className="imgWrap">
                  <div className="img">
                    <img
                      src={
                        languageQuarter
                          ? isMobile ? ImgSotif03Mb : ImgSotif03
                          : isMobile ? ImgSotif03MbEn : ImgSotif03En
                        }
                      alt="ImgSotif03"
                    />
                  </div>
                </ScrollContainer>

                <layout.ScrollGuide />
              </div>

              <p>
                {languageQuarter ?
                  <>
                    SOTIF 활동의 최종 목표는 Area 2와 3에 존재하는 잠재적 위험
                    행동을 평가하고 이러한 시나리오에 의해 유발된 잔류 위험(Residual
                    Risk)이 허용 기준(Acceptance Criteria) 또는 그 이하에서 충분히
                    낮다는 주장을 제공하는 것입니다. SOTIF 활동의 최종 목표를
                    달성하기 위해 다음과 같은 활동들을 수행합니다.
                  </> : <>
                    The ultimate goal of the SOTIF activities is to evaluate the potentially
                    hazardous behavior present in Areas 2 and 3 and to provide an argument that
                    the residual risk caused by these scenarios is sufficiently low at the acceptance
                    criteria or below. To achieve the ultimate goal of SOTIF activities, the following activities are carried out.
                  </>}
              </p>

              <ul>
                {overviewList.map((item, index) => {
                  return (
                    <li className="overviewList" key={"overviewList" + index}>
                      <layout.BusinessTable
                        title={`0${index + 1}`}
                        description={item}
                      />
                    </li>
                  );
                })}
              </ul>

              <p>
                {languageQuarter ?
                  <>
                    아래의 그림은 SOTIF 활동으로 인한 시나리오 범위의 변화의 목표를
                    보여줍니다.
                  </> : <>
                    The figure below shows alternative evolution of the scenario
                    categories resulting from the ISO 21448 activities.
                  </>}
              </p>

              <figure>
                <div className="img">
                  <img
                    src={isMobile ? ImgSotif04Mb : ImgSotif04}
                    alt="ImgSotif04"
                  />
                </div>
              </figure>
            </div>
          </div>

          <div className="grayBox">
            <p className="title">
              {languageQuarter ?
                <>
                  <span>감지-계획-행동 모델</span>(Sense-plan-act model)
                </> : <>
                  <span>Sense-plan-act model</span>
                </>}
            </p>

            <div className="text">
              <p>
                {languageQuarter ?
                  <>
                    SOTIF에서 고려되는 위험한 행동(Hazardous Behavior)의 가능한
                    원인은 충분히 정확한 환경 모델을 생성하고, 올바른 결정을 내리고,
                    환경 모델을 기반으로 올바른 제어 수단을 도출하고, 제어 수단을{" "}
                    <br />
                    실행하는 시스템의 능력과 밀접한 관련이 있습니다.
                    <br />
                    "Sense" Element는 차량의 외부 및 내부 환경은 물론 차량 및 시스템
                    상태를 감지하여 수신한 정보를 기반으로 환경 모델을 생성하는
                    부분을 실행합니다.
                    <br />
                    "Plan" Element는 Sense Element가 제공하는 환경 모델에 대한
                    목표와 정책을 적용하여 제어 수단을 도출하고, "Act" Element는
                    제어 작업을 실행합니다.
                    <br />
                    <br />
                    아래 그림은 감지-계획-행동 모델을 도식한 것입니다.
                  </> : <>
                    The possible causes of Hazardous Behavior considered in the SOTIF
                    are closely related to the system's ability to create a sufficiently
                    accurate environmental model, make the right decisions and derive the correct
                    control actions based on the environmental model, and execute the control actions.
                    The “Sense” element executes the perception part creating an environmental model
                    based on the information received from sensing both the vehicle's external and
                    internal environment as well as the vehicle and system states. The "Plan" element
                    applies the goals and policies on the environmental model provided by the Sense element
                    to derive control actions, and the "Act" element executes control tasks.
                    <br />
                    <br />
                    The figure below is a visualization of the Sense-Plan-Act model.
                  </>}
              </p>

              <figure>
                <div className="img">
                  <img src={ImgSotif05} alt="ImgSotif05" />
                </div>
              </figure>
            </div>
          </div>

          <div className="grayBox">
            <p className="title">
              {languageQuarter ?
                <>
                  <span>SOTIF</span> 구조
                </> : <>
                  <span>SOTIF</span> structure
                </>}
            </p>

            <div className="text">
              <p>{languageQuarter
                ? "ISO 21448 표준의 구조와 활동은 다음과 같습니다."
                : "The structure and activities of the ISO 21448 standard are as follows."
              }</p>

              <b>{languageQuarter
                ? "1. ISO 26262 표준의 구조"
                : "1. Structural structure of ISO 26262 standard"
              }</b>

              <ScrollContainer className="imgWrap">
                <div className="img">
                  <img
                    src={
                      languageQuarter
                        ? isMobile ? ImgSotif06Mb : ImgSotif06
                        : isMobile ? ImgSotif06MbEn : ImgSotif06En
                    }
                    alt="ImgSotif06"
                  />
                </div>
              </ScrollContainer>

              <layout.ScrollGuide />

              <b>{languageQuarter
                ? "2. ISO 21448 활동들의 의존 관계"
                : "2. The dependency of ISO 21448 activities"
              }</b>

              <figure>
                <div className="img">
                  <img src={ImgSotif07} alt="ImgSotif07" />
                </div>
              </figure>
            </div>
          </div>
        </div>
      </Styles.Container>

      <Styles.Container className="coverageSection">
        <div className="inner">
          <h3>{languageQuarter ? "적용 범위" : "Coverage"}</h3>

          <p className="sectionDescription">
            {languageQuarter ?
              <>
                ISO 21448은 기능적 불충분으로 발생한 위험원에 인한 불합리한 위험이
                없는 의도된 기능의 안전성(Safety Of The Intended Functionality,
                SOTIF)을 보장하기 위한 조치에 대한 일반적인 논거 프레임워크와 지침을
                제공합니다. <br />
                기능적 불충분(Functional Insufficiency)는 다음의 두 가지로 구분할 수
                있습니다.
              </> : <>
                ISO 21448 provides a general argument framework and guidance on measures to
                ensure safety of the intended functionality (SOTIF) being absent of unreasonable
                risk due to a hazard caused by functional insufficiencies.
                Functional Insufficiency can be divided into two categories.
              </>}
          </p>

          <ul>
            {coverageList.first.map((item, index) => {
              return (
                <li className="firstList" key={"firstList" + index}>
                  <layout.BusinessTable
                    title={`0${index + 1}`}
                    description={item}
                  />
                </li>
              );
            })}
          </ul>

          <p className="sectionDescription">
            {languageQuarter ?
              <>
                ISO 21448은 SOTIF를 달성하고 유지하는 데 필요한 적용 가능한 설계,
                Verification 및 Validation의 조치뿐만 아니라 운영 단계 중 활동에
                대한 지침을 제공합니다. <br />
                ISO 21448은 다음의 경우 적용할 수 있습니다.
              </> : <>
                ISO 21448 provides guidance on activities during the operational phase as well
                as the applicable design, verification, and validation measures required to achieve and maintain the SOTIF.
                ISO 21448 is applicable if.
              </>}
          </p>

          <ul>
            {coverageList.second.map((item, index) => {
              return (
                <li className="secondList" key={"secondList" + index}>
                  <layout.BusinessTable
                    title={`0${index + 1}`}
                    description={item}
                  />
                </li>
              );
            })}
          </ul>

          <p className="sectionDescription">
            {languageQuarter ?
              <>
                ISO 21448은 다음의 경우 적용되지 않습니다.
              </> : <>
                ISO 21448 does not apply to.
              </>}
          </p>

          <ul>
            {coverageList.third.map((item, index) => {
              return (
                <li className="thirdList" key={"thirdList" + index}>
                  <layout.BusinessTable
                    title={`0${index + 1}`}
                    description={item}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </Styles.Container>

      <div className="listButton">
        <ui.button.BasicButton
          buttonText={languageQuarter ? "목록으로" : "List"}
          buttonType={"blue"}
          onClick={() => {
            navigate("/business/depth/service?disableScrollTop=true&focus=bis_menu_0");
          }}
        />
      </div>
    </>
  );
}

export default Sotif;
