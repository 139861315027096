import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;

  & .iconArrow {
    width: 12px;
    height: 12px;
  }

  & .paginationList {
    display: flex;
    gap: 30px;
  }

  & .paginationList button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    font-size: 18px;
    color: var(--gray01);
  }

  & .paginationList button.on {
    font-weight: bold;
    color: var(--black);
  }

  @media (max-width: 1480px) {
    gap: calc(100vw * (30 / 1480));

    & .iconArrow {
      width: calc(100vw * (12 / 1480));
      height: calc(100vw * (12 / 1480));
    }

    & .paginationList {
      gap: calc(100vw * (30 / 1480));
    }

    & .paginationList button {
      width: calc(100vw * (24 / 1480));
      height: calc(100vw * (24 / 1480));
      font-size: calc(100vw * (18 / 1480));
    }
  }

  @media (max-width: 768px) {
    gap: calc(100vw * (16 / 375));

    & .iconArrow {
      width: calc(100vw * (12 / 375));
      height: calc(100vw * (12 / 375));
    }

    & .paginationList {
      gap: calc(100vw * (16 / 375));
    }

    & .paginationList button {
      width: calc(100vw * (24 / 375));
      height: calc(100vw * (24 / 375));
      font-size: calc(100vw * (16 / 375));
    }
  }
`;

export { Container };

export default { Container };
