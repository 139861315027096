import Styles from "./styles";
import { useEffect } from "react";

function KakaoMap({ location }) {
  const { kakao } = window;
  const address = {
    "headOffice": { lat: 37.4116143, lng: 127.0960481 },
    "branchOffice": { lat: 35.8741627, lng: 128.6248868 },
  }

  useEffect(() => {
    if (!kakao?.maps) return;

    const container = document.getElementById(`map_${location}`);
    const option = {
      center: new kakao.maps.LatLng(address[location]["lat"], address[location]["lng"]),
      level: 3,
    };
    const map = new kakao.maps.Map(container, option);

    let markerPosition = new kakao.maps.LatLng(
      address[location]["lat"], address[location]["lng"]
    );

    // 마커를 생성
    let marker = new kakao.maps.Marker({
      position: markerPosition,
    });
    // 마커를 지도 위에 표시
    marker.setMap(map);
  }, []);

  return <Styles.Container id={`map_${location}`}></Styles.Container>;
}

export default KakaoMap;
