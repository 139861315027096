import { useAtom } from "jotai";
import { modalAtom } from "store/atoms";

export default function useModals() {
  const [defaultModal, setDefaultModal] = useAtom(modalAtom.defaultModalAtom);
  const [mainModal, setMainModal] = useAtom(modalAtom.mainModalAtom);
  const [registerModal, setRegisterModal] = useAtom(
    modalAtom.registerModalAtom
  );
  const [scheduleModal, setScheduleModal] = useAtom(
    modalAtom.schedulerModalAtom
  );
  const [askModal, setAskModal] = useAtom(modalAtom.askModalAtom);
  const [cancelModal, setCancelModal] = useAtom(modalAtom.cancelModalAtom);
  const [toastModal, setToastModal] = useAtom(modalAtom.toastModalAtom);

  const clearModals = () => {
    setDefaultModal({ show: false });
    setMainModal({ show: false });
    setRegisterModal({ show: false });
    setScheduleModal({ show: false });
    setAskModal({ show: false });
    setCancelModal({ show: false });
    setToastModal({ show: false });
  };

  const clearSubModals = (callback) => {
    setDefaultModal((e) => !e);
    setTimeout(() => {
      callback();
    }, [0]);
  };

  return {
    clearModals,
    defaultModal,
    setDefaultModal,
    mainModal,
    setMainModal,
    registerModal,
    setRegisterModal,
    scheduleModal,
    setScheduleModal,
    askModal,
    setAskModal,
    cancelModal,
    setCancelModal,
    toastModal,
  };
}
