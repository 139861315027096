import styled from "styled-components";

const Container = styled.main`
  padding: 180px 0 200px;

  & .title {
    display: flex;
    align-items: center;
    gap: 50px;
    margin-bottom: 80px;

    & h2 {
      font-size: 62px;
      font-weight: 800;
    }

    & p {
      font-weight: 500;
      line-height: 1.5;
    }
  }

  & h3 {
    margin-bottom: 30px;
    padding-bottom: 30px;
    font-size: 28px;
    font-weight: bold;
    border-bottom: 1px solid var(--gray02);
  }

  & .wrap {
    margin-bottom: 80px;
  }

  & .list {
    display: flex;
    font-size: 20px;

    &:not(:last-child) {
      margin-bottom: 30px;
    }

    & .listTitle {
      flex-shrink: 0;
      width: 148px;
      font-weight: bold;
    }

    & p {
      font-weight: 500;
    }
  }

  & .educationInfo {
    & .listTitle {
      color: var(--blue02);
    }
  }

  & .payInfo {
    & .payDetail {
      display: flex;
      align-items: center;
      gap: 20px;
      font-size: 20px;
      font-weight: 500;

      & a {
        font-size: 18px;
        color: var(--blue02);
        text-decoration: underline;
        text-underline-offset: 3px;
      }
    }
  }

  & .termsDetail {
    padding: 20px;
    color: var(--gray01);
    line-height: 1.62;
    background-color: #fafafa;

    & li {
      position: relative;
      padding-left: 10px;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
      }
    }
  }

  & .buttonWrap {
    display: flex;
    justify-content: center;
    gap: 30px;

    & .prevButton,
    .nextButton {
      width: 260px;
    }
  }

  @media (max-width: 1480px) {
    padding: calc(100vw * (180 / 1480)) 0 calc(100vw * (200 / 1480));

    & .title {
      gap: calc(100vw * (50 / 1480));
      margin-bottom: calc(100vw * (80 / 1480));

      & h2 {
        font-size: calc(100vw * (62 / 1480));
      }
    }

    & h3 {
      margin-bottom: calc(100vw * (30 / 1480));
      padding-bottom: calc(100vw * (30 / 1480));
      font-size: calc(100vw * (28 / 1480));
    }

    & .wrap {
      margin-bottom: calc(100vw * (80 / 1480));
    }

    & .list {
      font-size: calc(100vw * (20 / 1480));

      &:not(:last-child) {
        margin-bottom: calc(100vw * (30 / 1480));
      }

      & .listTitle {
        width: calc(100vw * (148 / 1480));
      }
    }

    & .payInfo {
      & .payDetail {
        gap: calc(100vw * (20 / 1480));
        font-size: calc(100vw * (20 / 1480));

        & a {
          font-size: calc(100vw * (18 / 1480));
          text-underline-offset: calc(100vw * (3 / 1480));
        }
      }
    }

    & .termsDetail {
      padding: calc(100vw * (20 / 1480));

      & li {
        padding-left: calc(100vw * (10 / 1480));
      }
    }

    & .buttonWrap {
      gap: calc(100vw * (30 / 1480));

      & .prevButton,
      .nextButton {
        width: calc(100vw * (260 / 1480));
      }
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (116 / 375)) 0 calc(100vw * (130 / 375));

    & .title {
      flex-direction: column;
      align-items: flex-start;
      gap: calc(100vw * (20 / 375));
      margin-bottom: calc(100vw * (50 / 375));

      & h2 {
        font-size: calc(100vw * (32 / 375));
      }
    }

    & h3 {
      margin-bottom: calc(100vw * (20 / 375));
      padding-bottom: calc(100vw * (20 / 375));
      font-size: calc(100vw * (20 / 375));
    }

    & .wrap {
      margin-bottom: calc(100vw * (50 / 375));
    }

    & .list {
      font-size: calc(100vw * (14 / 375));

      &:not(:last-child) {
        margin-bottom: calc(100vw * (20 / 375));
      }

      & .listTitle {
        width: calc(100vw * (98 / 375));
      }
    }

    & .payInfo {
      & .payDetail {
        gap: calc(100vw * (14 / 375));
        font-size: calc(100vw * (14 / 375));

        & a {
          font-size: calc(100vw * (14 / 375));
          text-underline-offset: calc(100vw * (2 / 375));
        }
      }
    }

    & .termsDetail {
      padding: calc(100vw * (14 / 375));

      & li {
        padding-left: calc(100vw * (10 / 375));
        word-break: keep-all;
      }
    }

    & .buttonWrap {
      flex-direction: column;
      align-items: center;
      gap: calc(100vw * (15 / 375));

      & .prevButton,
      .nextButton {
        width: calc(100vw * (180 / 375));
      }
    }
  }
`;

export { Container };

export default { Container };
