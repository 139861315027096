import utils from "utils";
import Styles from "./styles";
import NoticeList from "../NoticeList";
import { ui } from "components";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLanguage, useSupport } from "hooks";

//img
import IconEmpty from "resources/image/icon/icon_empty.svg";

function Notice() {
  const location = useLocation();
  const [filter, setFilter] = useState({});

  const languageInfo = useLanguage()
  const supportInfo = useSupport({ noticeOption: filter.recordSize && filter });
  const data = supportInfo.getNoticeList.data || []
  const languageQuarter = languageInfo.suffix === ""

  useEffect(() => {
    let param = utils.etc.getSearchParam();
    let f = {
      page: param.page ? param.page : 1,
      recordSize: 10,
    };
    setFilter({ ...f });
  }, [location]);

  return (
    <Styles.Container>
      {data.list?.length > 0 ? (
        <>
          <ul className="noticeWrap">
            {data.list?.map((item, index) => {
              return (
                <li className="noticeList" key={"noticeList" + index}>
                  <Link to={"detail/" + item.id}>
                    <NoticeList
                      data={item}
                      language={languageInfo.suffix}  
                    />
                  </Link>
                </li>
              );
            })}
          </ul>

          <div className="pagination">
            <ui.Pagination
              page={data.pagination?.page}
              list={data.pagination?.page_list}
              maxPage={data.pagination?.totalPageCount}
            />
          </div>
        </>
      ) : (
        <div className="emptyText">
          <div className="icon">
            <img src={IconEmpty} alt="IconEmpty" />
          </div>

          <p>{languageQuarter ? "등록된 게시물이 없습니다." : "There are no registered posts."}</p>
        </div>
      )}
    </Styles.Container>
  );
}

export default Notice;
