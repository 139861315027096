import Styles from "./styles";
import ui from "components/ui";
import { useState } from "react";
import { useLanguage, useSupport } from "hooks";

function AskModal({ modalClose }) {
  const supportInfo = useSupport({});
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";

  const [errorText, setErrorText] = useState({ text: "", state: 0 });
  const [isBlocked, setIsBlocked] = useState(false);
  const [contentsCheck, setContentsCheck] = useState(null);
  const [check, setCheck] = useState(false);
  const [inputs, setInputs] = useState({
    company_title: "",
    name: "",
    email: "",
    phone: "",
    title: "",
    description: "",
  });

  const inputList = [
    {
      name: "company_title",
      value: inputs.company_title,
      placeholder: languageQuarter
        ? "회사명을 입력하세요."
        : "Please enter a company name.",
      errorText: errorText.state === 1
    },
    {
      name: "name",
      value: inputs.name,
      placeholder: languageQuarter
        ? "이름을 입력하세요."
        : "Please enter your name.",
      errorText: errorText.state === 2
    },
    {
      name: "email",
      value: inputs.email,
      placeholder: languageQuarter
        ? "이메일을 입력하세요."
        : "Please enter your email.",
      errorText: errorText.state === 3
    },
    {
      value: inputs.phone,
      name: "phone",
      type: "tel",
      placeholder: languageQuarter
        ? "연락처를 입력하세요."
        : "Please enter your contact information.",
    },
  ];

  const radioList = [
    {
      id: "컨설팅 문의",
      labelText: languageQuarter ? "컨설팅 문의" : "Consulting Inquiries",
    },
    {
      id: "교육 문의",
      labelText: languageQuarter ? "교육 문의" : "Education Inquiries",
    },
    {
      id: "기타",
      labelText: languageQuarter ? "기타" : "Etc.",
    },
  ];

  const validate = () => {
    const emailRegEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (inputs.company_title === "") {
      setErrorText({
        state: 1,
        text: languageQuarter
          ? "회사명을 입력해주세요."
          : "Please enter a company name.",
      })
      return false;
    }
    if (inputs.name === "") {
      setErrorText({
        state: 2,
        text: languageQuarter
          ? "이름을 입력해주세요."
          : "Please enter your name.",
      })
      return false;
    }
    if (inputs.email === "") {
      setErrorText({
        state: 3,
        text: languageQuarter
          ? "이메일을 입력해주세요."
          : "Please enter your email.",
      })
      return false;
    }
    if (emailRegEx.test(inputs.email) === false) {
      setErrorText({
        state: 3,
        text: languageQuarter
          ? "이메일 형식을 확인해주세요."
          : "Please check the email format."
      })
      return false;
    }
    if (contentsCheck === null) {
      setErrorText({
        text: languageQuarter
          ? "문의 분류를 선택해주세요."
          : "Please Ok to the terms and conditions.",
      })
      return false;
    }
    if (inputs.title === "") {
      setErrorText({
        state: 4,
        text: languageQuarter
          ? "제목을 입력해주세요."
          : "Please enter the title.",
      })
      return false;
    }
    if (inputs.description === "") {
      setErrorText({
        state: 5,
        text: languageQuarter
          ? "내용을 입력해주세요."
          : "Please enter the content.",
      })
      return false;
    }
    if (!check) {
      setErrorText({
        text: languageQuarter
          ? "약관에 동의해주세요."
          : "Please Ok to the terms and conditions.",
      })
      return false;
    }
    return true;
  }

  const onNext = () => {
    if (validate()) {
      setIsBlocked(true);
      supportInfo.postInquiry({
        ...inputs,
        type: (radioList.map(item => item.id).indexOf(contentsCheck)) + 1,
        personal_policy_yn: check ? "Y" : "N"
      },
        modalClose,
        languageQuarter,
      );
    }
  }

  return (
    <Styles.Container>
      <div className="modalInner">
        <div className="modalTitle">
          {languageQuarter ? "1:1 문의" : "1:1 Inquiry"}
          <p>
            {languageQuarter ? (
              <>
                문의사항을 남기기 전 <br className="mbOnly" />
                <button type="button" onClick={modalClose}>
                  FAQ
                </button>
                를 확인해주세요.
              </>
            ) : (
              <>
                Please check the{" "}
                <button type="button" onClick={modalClose}>
                  FAQ
                </button> <br className="mbOnly" />
                before leaving any questions.
              </>
            )}
          </p>
        </div>

        <div className="wrap">
          <form>
            <div className="info">
              <p className="formTitle">
                {languageQuarter ? "문의자 정보" : "Contact Information"}
              </p>

              <ul className="inputWrap">
                {inputList.map((item, index) => {
                  return (
                    <li className="input" key={"input" + index}>
                      <ui.input.BasicInput data={item} setInputs={setInputs} />
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="contents">
              <p className="formTitle">
                {languageQuarter ? "문의 내용" : "Inquiries"}
              </p>

              <ul className="radioWrap">
                {radioList.map((item, index) => {
                  return (
                    <li className="radioList" key={"radioList" + index}>
                      <ui.input.BasicRadio
                        data={item}
                        name={"contentCheck"}
                        check={contentsCheck}
                        setCheck={setContentsCheck}
                      />
                    </li>
                  );
                })}
              </ul>

              <div className="input">
                <ui.input.BasicInput
                  data={{
                    name: "title",
                    value: inputs.title,
                    placeholder: languageQuarter
                      ? "제목을 입력하세요."
                      : "Please enter a title.",
                    errorText: errorText.state === 4
                  }}
                  setInputs={setInputs}
                />
              </div>

              <div className="textarea">
                <ui.input.BasicTextarea
                  data={{
                    name: "description",
                    value: inputs.description,
                    placeholder: languageQuarter
                      ? "문의 내용을 입력하세요."
                      : "Please enter your inquiry.",
                    errorText: errorText.state === 5
                  }}
                  setInputs={setInputs}
                />
              </div>
            </div>

            <div className="terms">
              <div className="check">
                {languageQuarter
                  ? "개인정보 수집 및 이용에 동의합니다."
                  : "I Ok to collect and use personal information."}
                <div className="checkbox">
                  <ui.input.BasicCheckbox
                    checked={check}
                    id={"privacyTerms"}
                    checkText={languageQuarter ? "동의" : "Ok"}
                    onChange={(e) => {
                      setCheck(e.target.checked);
                    }}
                  />
                </div>
              </div>

              <div className="termsDetail">
                {languageQuarter ? (
                  <>
                    제공하신 성함, 이메일 등 모든 제출 정보는 문의에{" "}
                    <br className="mbOnly" />
                    대한 답변 및 응대 품질 향상을 위해 <br className="pcOnly" />
                    C&BIS에서 3년 동안 보관 후 영구 삭제 처리합니다.
                  </>
                ) : (
                  <>
                    All submission information such as your name and e-mail will
                    be permanently deleted after 3 years of storage by C&BIS to
                    improve the quality of response and response to inquiries.
                  </>
                )}
              </div>
            </div>

            <div className="buttonWrap">
              <div className="errorText">
                {errorText.text}
              </div>

              <ui.button.BasicButton
                buttonText={languageQuarter ? "취소" : "Cancellation"}
                buttonType={"white"}
                onClick={modalClose}
              />
              <ui.button.BasicButton
                buttonText={
                  isBlocked
                    ? languageQuarter ? "처리 중" : "Processing"
                    : languageQuarter ? "문의하기" : "Contact Us"
                }
                buttonType={"blue"}
                onClick={!isBlocked ? onNext : ()=>{}}
              />
            </div>
          </form>
        </div>
      </div>

      <div className="overlay"></div>
    </Styles.Container>
  );
}

export { AskModal };

export default AskModal;
