import styled from "styled-components";

const Container = styled.div`
  position: relative;

  & .slider {
    padding-top: 20px;
  }

  & .slideList {
    width: 314px;

    & a {
      transition: 0.3s;
    }

    & .imgWrap {
      position: relative;
      width: 100%;
      height: 202px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      overflow: hidden;

      & .img {
        height: 100%;
      }

      & .state {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
      }

      & .progress,
      .scheduled {
        bottom: 12px;
        left: 10px;
        width: 60px;
        height: 29px;
        font-size: 14px;
        font-weight: 500;
        border-radius: 5px;
      }

      & .progress {
        background-color: #abcd03;
      }

      & .scheduled {
        background-color: var(--blue02);
      }

      & .closed {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-size: 16px;
        font-weight: bold;
        background-color: rgba(54, 57, 70, 0.95);
      }
    }

    & .title {
      height: 68px;
      padding: 15px;
      font-weight: bold;
      background-color: var(--blue04);
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      & p {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  & .prevButton,
  .nextButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.2));
    z-index: 2;
  }

  & .prevButton {
    left: -20px;
  }

  & .nextButton {
    right: -20px;
  }

  & .swiper-button-disabled {
    display: none;
  }

  @media (max-width: 1480px) {
    & .slider {
      padding-top: calc(100vw * (20 / 1480));
    }

    & .slideList {
      width: calc(100vw * (314 / 1480));

      & .imgWrap {
        height: calc(100vw * (202 / 1480));
        border-top-left-radius: calc(100vw * (5 / 1480));
        border-top-right-radius: calc(100vw * (5 / 1480));

        & .progress,
        .scheduled {
          bottom: calc(100vw * (12 / 1480));
          left: calc(100vw * (10 / 1480));
          width: calc(100vw * (60 / 1480));
          height: calc(100vw * (29 / 1480));
          font-size: calc(100vw * (14 / 1480));
          border-radius: calc(100vw * (5 / 1480));
        }

        & .closed {
          font-size: calc(100vw * (16 / 1480));
        }
      }

      & .title {
        height: calc(100vw * (68 / 1480));
        padding: calc(100vw * (15 / 1480));
        border-bottom-left-radius: calc(100vw * (5 / 1480));
        border-bottom-right-radius: calc(100vw * (5 / 1480));
      }
    }

    & .prevButton,
    .nextButton {
      width: calc(100vw * (40 / 1480));
      height: calc(100vw * (40 / 1480));
      filter: drop-shadow(0 0 calc(100vw * (20 / 1480)) rgba(0, 0, 0, 0.2));
    }

    & .prevButton {
      left: calc(100vw * (-20 / 1480));
    }

    & .nextButton {
      right: calc(100vw * (-20 / 1480));
    }
  }

  @media (max-width: 768px) {
    & .slider {
      padding-top: 0;
    }

    & .slideList {
      width: calc(100vw * (240 / 375));

      & .imgWrap {
        height: calc(100vw * (154 / 375));
        border-top-left-radius: calc(100vw * (3 / 375));
        border-top-right-radius: calc(100vw * (3 / 375));

        & .progress,
        .scheduled {
          bottom: calc(100vw * (10 / 375));
          left: calc(100vw * (10 / 375));
          width: calc(100vw * (60 / 375));
          height: calc(100vw * (29 / 375));
          font-size: calc(100vw * (14 / 375));
          border-radius: calc(100vw * (5 / 375));
        }

        & .closed {
          font-size: calc(100vw * (14 / 375));
        }
      }

      & .title {
        height: calc(100vw * (52 / 375));
        padding: calc(100vw * (9 / 375));
        border-bottom-left-radius: calc(100vw * (3 / 375));
        border-bottom-right-radius: calc(100vw * (3 / 375));
      }
    }

    & .prevButton,
    .nextButton {
      width: calc(100vw * (28 / 375));
      height: calc(100vw * (28 / 375));
      filter: drop-shadow(0 0 calc(100vw * (20 / 375)) rgba(0, 0, 0, 0.2));
    }

    & .prevButton {
      left: calc(100vw * (-14 / 375));
    }

    & .nextButton {
      right: calc(100vw * (-14 / 375));
    }
  }

  @media (hover: hover) {
    & .slideList:hover a {
      transform: translateY(-20px);
    }

    @media (max-width: 1480px) {
      & .slideList:hover a {
        transform: translateY(-20px);
      }
    }

    @media (max-width: 768px) {
      & .slideList:hover a {
        transform: translateY(0);
      }
    }
  }
`;

export { Container };

export default { Container };
