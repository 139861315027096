import Styles from "./styles";
import ui from "components/ui";
import { useState } from "react";
import { useModals, useTransaction } from "hooks";

function CancelModal({ modalClose }) {
  const [check, setCheck] = useState(false);
  const [errorText, setErrorText] = useState();
  
  const modalOption = useModals();
  const transactionInfo = useTransaction({});

  return (
    <Styles.Container>
      <div className="modalInner">
        <div className="modalTitle">
          교육신청 <br />
          취소
        </div>

        <div className="wrap">
          <p className="title">교육신청을 취소하시겠습니까?</p>

          <div className="guideText">
            교육신청을 취소할 경우, 신청 시 선택한 결제수단으로 취소됩니다.
            <br className="pcOnly" />
            결제수단에 따라 취소 처리 과정이 상이
            <br className="mbOnly" />
            하오니 아래 내용 참고 바랍니다.
            <br />
            <br />
            1. 카드 결제: 취소 후 3~5 영업일 이내 카드 승인 취소 (결제 당일 취소
            시 당일 승인 취소) <br />
            2. 세금계산서: 담당자 확인 후 개별 연락 (세금계산서 수정발행 후
            환불)
            <br />
            3. 현장 결제: 별도 환불 처리 없이 즉시 취소
          </div>

          <div className="check">
            안내를 확인했습니다.{" "}
            <div className="checkbox">
              <ui.input.BasicCheckbox
                checked={check}
                id={"privacyTerms"}
                checkText={"동의"}
                onChange={(e) => {
                  setCheck(e.target.checked);
                }}
              />
            </div>
          </div>

          <div className="buttonWrap">
            <div className="errorText">{errorText}</div>

            <ui.button.BasicButton
              buttonText={"닫기"}
              buttonType={"white"}
              onClick={() => {
                modalClose();
              }}
            />
            <ui.button.BasicButton
              buttonText={"신청 취소"}
              buttonType={"blue"}
              onClick={ async () => {
                if (check) {
                  const result = await transactionInfo.cancelTransaction({
                    id: modalOption.cancelModal.id,
                    token: sessionStorage.getItem("token"),
                  })

                  if (result.success) {
                    modalClose();
                    modalOption.setDefaultModal((e) => {
                      e.show = true;
                      e.title = "교육 신청이 취소되었습니다.";
                      e.confirmText = "확인";
                      e.confirmClick = () => {
                        window.location.reload();
                      };
                      e.subText = false;
                      e.closeText = false;
    
                      return { ...e };
                    });
                  }
                } else {
                  setErrorText("안내에 동의해주세요.");
                }
              }}
            />
          </div>
        </div>
      </div>

      <div className="overlay"></div>
    </Styles.Container>
  );
}

export { CancelModal };

export default CancelModal;
