import BasicButton from "./BasicButton";
import EducationTab from "./EducationTab";
import Tab from "./Tab";

export { BasicButton, EducationTab, Tab };

export default {
  BasicButton,
  EducationTab,
  Tab,
};
