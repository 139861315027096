import styled from "styled-components";

// img
import ImgLine from "resources/image/education_center/img_line02.png";

const Container = styled.main`
  padding: 150px 0 200px;
  min-height: 100vh;
  min-height: 100dvh;

  & .inner {
    display: flex;
    justify-content: space-between;

    & .category {
      width: fit-content;
      padding: 6px 12px;
      font-size: 18px;
      font-weight: 500;
      color: #fff;
      background-color: var(--blue02);
      border-radius: 5px;
    }

    & .title {
      margin-bottom: 20px;
      font-size: 22px;
      font-weight: bold;
    }

    & .detailList {
      display: flex;
      align-items: center;
      gap: 12px;
      font-size: 14px;
      font-weight: 500;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      & .icon {
        width: 20px;
        height: 20px;
      }
    }

    & .left {
      width: 853px;

      & .infoContainer {
        display: flex;
        gap: 30px;
        margin-bottom: 100px;

        & .img {
          flex-shrink: 0;
          width: 349px;
          height: 225px;
          border-radius: 5px;
          overflow: hidden;
        }

        & .infoWrap {
          flex-grow: 1;

          & .category {
            margin-bottom: 14px;
          }

          & .title {
            line-height: 1.5;
          }
        }
      }

      & .goListButton {
        width: 260px;
        margin: 80px auto 0;
      }
    }

    & .right {
      position: relative;
      width: 427px;

      & .sticky {
        position: sticky;
        top: 150px;
        right: 0;
        width: 100%;
        padding: 26px 26px 30px;
        background-color: #fff;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);

        & .info {
          position: relative;
          margin-bottom: 30px;
          padding-bottom: 30px;

          &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background: url(${ImgLine}) no-repeat center / cover;
            content: "";
          }

          & .category {
            margin-bottom: 20px;
          }
        }

        & .applyList:not(:last-child) {
          margin-bottom: 14px;
        }

        & .applyButton {
          width: 260px;
          margin: 40px auto 0;
        }
      }
    }
  }

  @media (max-width: 1480px) {
    padding: calc(100vw * (150 / 1480)) 0 calc(100vw * (200 / 1480));

    & .inner {
      & .category {
        padding: calc(100vw * (6 / 1480)) calc(100vw * (12 / 1480));
        font-size: calc(100vw * (18 / 1480));
        border-radius: calc(100vw * (5 / 1480));
      }

      & .title {
        margin-bottom: calc(100vw * (20 / 1480));
        font-size: calc(100vw * (22 / 1480));
      }

      & .detailList {
        gap: calc(100vw * (12 / 1480));
        font-size: calc(100vw * (14 / 1480));

        &:not(:last-child) {
          margin-bottom: calc(100vw * (16 / 1480));
        }

        & .icon {
          width: calc(100vw * (20 / 1480));
          height: calc(100vw * (20 / 1480));
        }
      }

      & .left {
        width: calc(100vw * (853 / 1480));

        & .infoContainer {
          gap: calc(100vw * (30 / 1480));
          margin-bottom: calc(100vw * (100 / 1480));

          & .img {
            width: calc(100vw * (349 / 1480));
            height: calc(100vw * (225 / 1480));
            border-radius: calc(100vw * (5 / 1480));
          }

          & .infoWrap {
            & .category {
              margin-bottom: calc(100vw * (14 / 1480));
            }
          }
        }

        & .goListButton {
          width: calc(100vw * (260 / 1480));
          margin: calc(100vw * (80 / 1480)) auto 0;
        }
      }

      & .right {
        width: calc(100vw * (427 / 1480));

        & .sticky {
          top: calc(100vw * (150 / 1480));
          padding: calc(100vw * (26 / 1480)) calc(100vw * (26 / 1480))
            calc(100vw * (30 / 1480));
          box-shadow: 0 calc(100vw * (10 / 1480)) calc(100vw * (20 / 1480)) 0
            rgba(0, 0, 0, 0.2);

          & .info {
            margin-bottom: calc(100vw * (30 / 1480));
            padding-bottom: calc(100vw * (30 / 1480));

            &::after {
              height: calc(100vw * (2 / 1480));
            }

            & .category {
              margin-bottom: calc(100vw * (20 / 1480));
            }
          }

          & .applyList:not(:last-child) {
            margin-bottom: calc(100vw * (14 / 1480));
          }

          & .applyButton {
            width: calc(100vw * (260 / 1480));
            margin: calc(100vw * (40 / 1480)) auto 0;
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    padding: calc(100vw * (96 / 375)) 0 calc(100vw * (130 / 375));

    & .inner {
      & .category {
        padding: calc(100vw * (6 / 375)) calc(100vw * (12 / 375));
        font-size: calc(100vw * (14 / 375));
        border-radius: calc(100vw * (5 / 375));
      }

      & .title {
        margin-bottom: calc(100vw * (14 / 375));
        font-size: calc(100vw * (16 / 375));
      }

      & .detailList {
        gap: calc(100vw * (12 / 375));
        font-size: calc(100vw * (14 / 375));

        &:not(:last-child) {
          margin-bottom: calc(100vw * (10 / 375));
        }

        & .icon {
          width: calc(100vw * (20 / 375));
          height: calc(100vw * (20 / 375));
        }
      }

      & .left {
        width: 100%;

        & .infoContainer {
          flex-direction: column;
          gap: calc(100vw * (30 / 375));
          margin-bottom: calc(100vw * (30 / 375));

          & .img {
            width: 100%;
            height: calc(100vw * (216 / 375));
            border-radius: calc(100vw * (5 / 375));
          }

          & .infoWrap {
            & .category {
              margin-bottom: calc(100vw * (10 / 375));
            }

            & .title {
              line-height: normal;
            }
          }
        }

        & .goListButton {
          width: calc(100vw * (180 / 375));
          margin: calc(100vw * (50 / 375)) auto 0;
        }
      }

      & .right {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;

        & .sticky {
          padding: calc(100vw * (20 / 375)) calc(100vw * (20 / 375));
          box-shadow: 0 calc(100vw * (-2 / 375)) calc(100vw * (20 / 375)) 0
            rgba(0, 0, 0, 0.06);

          & .info {
            margin-bottom: calc(100vw * (20 / 375));
            padding-bottom: 0;

            &::after {
              display: none;
            }

            & .category {
              margin-bottom: calc(100vw * (10 / 375));
            }
          }

          & .apply {
            max-height: 40vh;
            max-height: 40dvh;
            margin-bottom: calc(100vw * (30 / 375));
            overflow: auto;
          }

          & .applyList:not(:last-child) {
            margin-bottom: calc(100vw * (10 / 375));
          }

          & .applyButton {
            width: calc(100vw * (180 / 375));
            margin: 0 auto;
          }
        }
      }
    }
  }
`;

export { Container };

export default { Container };
