import Styles from "./styles";
import { ui } from "components";
import { api } from "service";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useModals } from "hooks";

function AfterForming() {
  const navigate = useNavigate();
  const modalOption = useModals();
  const { data, ableCancel } = useOutletContext();

  // const data = api.dummy;
  // const renderState = (state) => {
  //   switch (String(state)) {
  //     case "-1":
  //       return "결제 취소";
  //     case "10":
  //       return "결제 대기";
  //     case "80":
  //       return "환불 신청";
  //     case "83":
  //       return "환불 완료";
  //     case "81":
  //       return "환불 반려";
  //     default:
  //       return "결제 완료";
  //   }
  // };

  // data
  const termsList = {
    vbank: [
      "무통장 입금의 경우 세금계산서를 발행하여 결제합니다.",
      "신청 완료 후 ‘교육 신청 조회’ 페이지에서 세금계산서 발행 정보를 작성해주셔야 결제가 완료됩니다.",
      "세금계산서 발행 정보는 교육 신청 마감일까지 작성 가능합니다.",
    ],
    deposit: [
      "씨엔비스 ‘입금계좌/예금주명’을 확인해주세요. (통장사본이 필요할 경우 ghyoo@cnbis.co.kr로 요청해주세요.)",
    ],
    refund: [
      "C&BIS 교육과정은 교육일 기준 일주일 전까지 취소 가능하며, 이후 취소 시 교재 비용을 제외한 금액만 환불됩니다.",
      "intacs™ 교육과정은 신청 마감일까지 취소 가능하며, 이후 취소시 라이선스 비용(100만원)을 제외한 금액만 환불됩니다.",
      "카드 결제의 경우, 신청 취소 시 카드사 영업일 기준 3~5일 이내 환불됩니다.",
      "세금계산서 발행의 경우, 신청 취소 시 C&BIS 담당자 확인 후 환불 관련 개별 연락드립니다.",
      "현장 결제의 경우, 당일 취소 및 No-show 발생 시 C&BIS에서 진행하는 모든 교육에 대해 6개월~1년간 수강이 불가합니다.",
    ],
  };
  const taxInvoiceList = [
    {
      title: "담당자 이메일",
      text: data?.manager_email,
    },
    {
      title: "발행 사이트",
      text: data?.publish_site,
    },
    {
      title: "발행 일자",
      text: data?.publish_date_format,
    },
    {
      title: "입금 예정일",
      text: data?.deposit_date_format,
    },
    {
      title: "메모(요청사항)",
      text: data?.request_memo,
    },
  ];
  const depositList = [
    {
      title: "입금계좌",
      text: "신한은행 140-009-880504",
    },
    {
      title: "예금주명",
      text: "씨엔비스(주)",
    },
  ];

  return (
    <Styles.Container>
      {data?.method === "VBANK" && (
        <>
          <div className="wrap">
            <h3>세금계산서 발행 안내</h3>
            <div className="termsDetail">
              <ul>
                {termsList.vbank.map((item, index) => {
                  return (
                    <li
                      className="vbankList termsList"
                      key={"vbankList" + index}
                    >
                      {item}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          <div className="licenseInfo wrap">
            <h3>사업자등록증 정보</h3>

            <p className="title">사업자등록증 첨부</p>

            <div className="licenseFile">
              <a
                href={
                  "https://cnbis.co.kr/api/" +
                  data?.file_uri +
                  "?token=" +
                  encodeURI(sessionStorage.getItem("token"))
                }
              >
                <p>{data?.file_name}</p>
              </a>
            </div>
          </div>

          <div className="wrap">
            <h3>세금계산서 업무 담당자 및 발행 정보</h3>

            <ul>
              {taxInvoiceList.map((item, index) => {
                return (
                  <li className="list" key={"taxInvoiceList" + index}>
                    <div className="listTitle">{item.title}</div>
                    <p>{item.text ? item.text : "-"}</p>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="depositInfo wrap">
            <h3>씨엔비스 입금 정보</h3>
            <div className="termsDetail">
              <ul>
                {termsList.deposit.map((item, index) => {
                  return (
                    <li
                      className="depositTermsList termsList"
                      key={"depositTermsList" + index}
                    >
                      {item}
                    </li>
                  );
                })}
              </ul>

              <ul className="depositListWrap">
                {depositList.map((item, index) => {
                  return (
                    <li className="list" key={"depositList" + index}>
                      <div className="listTitle">{item.title}</div>
                      <p>{item.text}</p>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </>
      )}

      <div className="wrap">
        <h3>환불 규정</h3>
        <div className="termsDetail">
          <ul>
            {termsList.refund.map((item, index) => {
              return (
                <li className="refundList termsList" key={"refundList" + index}>
                  {item}
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      <div className="buttonWrap">
        <div className="prevButton">
          <ui.button.BasicButton
            buttonText={"목록으로"}
            buttonType={"white"}
            onClick={() => {
              navigate("/inquiry/result");
            }}
          />
        </div>
        {ableCancel && (
          <div className="nextButton">
            <ui.button.BasicButton
              buttonText={"신청 취소"}
              buttonType={"blue"}
              onClick={() => {
                modalOption.setCancelModal((e) => {
                  e.show = true;
                  e.id = data?.id;

                  return { ...e };
                });
              }}
            />
          </div>
        )}
      </div>
    </Styles.Container>
  );
}
export default AfterForming;
