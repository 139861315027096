import styled from "styled-components";

const Container = styled.main`
  padding-bottom: 200px;

  & section:not(:last-child) {
    margin-bottom: 80px;
  }

  & h3 {
    font-size: 36px;
    font-weight: bold;
  }

  & .introductionSection {
    & h3 {
      margin-bottom: 30px;
      color: var(--blue02);

      & span {
        font-weight: 500;
        color: var(--black);
      }
    }

    & .description {
      margin-bottom: 135px;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.44;
    }

    & ul {
      display: flex;
      gap: 27px;

      & .introductionList {
        position: relative;
        width: calc(calc(100% - 81px) / 4);
        height: 203px;
        padding-top: 95px;
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.12);

        & .icon {
          position: absolute;
          top: -55px;
          left: 50%;
          transform: translateX(-50%);
          width: 100px;
          height: 100px;
          filter: drop-shadow(4px 4px 20px rgba(0, 0, 0, 0.12));
        }
      }
    }
  }

  & .subBannerSection {
    height: 440px;
    background-color: var(--blue01);

    & .inner {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & .textWrap {
        color: #fff;

        & small {
          display: inline-block;
          margin-bottom: 10px;
          font-size: 18px;
          font-weight: 500;
        }

        & h3 {
          line-height: 1.47;
        }
      }

      & .img {
        width: 580px;
        height: 440px;
      }
    }
  }

  & .curriculmSection {
    & h3 {
      margin-bottom: 20px;
    }

    & .tab {
      margin-bottom: 30px;
    }
  }

  @media (max-width: 1480px) {
    padding-bottom: calc(100vw * (200 / 1480));

    & section:not(:last-child) {
      margin-bottom: calc(100vw * (80 / 1480));
    }

    & h3 {
      font-size: calc(100vw * (36 / 1480));
    }

    & .introductionSection {
      & h3 {
        margin-bottom: calc(100vw * (30 / 1480));
      }

      & .description {
        margin-bottom: calc(100vw * (135 / 1480));
        font-size: calc(100vw * (18 / 1480));
      }

      & ul {
        gap: calc(100vw * (27 / 1480));

        & .introductionList {
          width: calc(calc(100% - calc(100vw * (81 / 1480))) / 4);
          height: calc(100vw * (203 / 1480));
          padding-top: calc(100vw * (95 / 1480));
          font-size: calc(100vw * (24 / 1480));
          border-radius: calc(100vw * (10 / 1480));
          box-shadow: calc(100vw * (4 / 1480)) calc(100vw * (4 / 1480))
            calc(100vw * (20 / 1480)) 0 rgba(0, 0, 0, 0.12);

          & .icon {
            top: calc(100vw * (-55 / 1480));
            width: calc(100vw * (100 / 1480));
            height: calc(100vw * (100 / 1480));
            filter: drop-shadow(
              calc(100vw * (4 / 1480)) calc(100vw * (4 / 1480))
                calc(100vw * (20 / 1480)) rgba(0, 0, 0, 0.12)
            );
          }
        }
      }
    }

    & .subBannerSection {
      height: calc(100vw * (440 / 1480));

      & .inner {
        & .textWrap {
          & small {
            margin-bottom: calc(100vw * (10 / 1480));
            font-size: calc(100vw * (18 / 1480));
          }
        }

        & .img {
          width: calc(100vw * (580 / 1480));
          height: calc(100vw * (440 / 1480));
        }
      }
    }

    & .curriculmSection {
      & h3 {
        margin-bottom: calc(100vw * (20 / 1480));
      }

      & .tab {
        margin-bottom: calc(100vw * (30 / 1480));
      }
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (66 / 375)) 0 calc(100vw * (130 / 375));

    & section:not(:last-child) {
      margin-bottom: calc(100vw * (50 / 375));
    }

    & h3 {
      font-size: calc(100vw * (24 / 375));
    }

    & .introductionSection {
      & h3 {
        margin-bottom: calc(100vw * (30 / 375));
      }

      & .description {
        margin-bottom: calc(100vw * (105 / 375));
        font-size: calc(100vw * (14 / 375));
        word-break: keep-all;
        line-height: 1.42;
      }

      & ul {
        flex-wrap: wrap;
        gap: calc(100vw * (66 / 375)) calc(100vw * (11 / 375));

        & .introductionList {
          width: calc(calc(100% - calc(100vw * (11 / 375))) / 2);
          height: calc(100vw * (137 / 375));
          padding-top: calc(100vw * (57 / 375));
          font-size: calc(100vw * (18 / 375));
          border-radius: calc(100vw * (10 / 375));
          box-shadow: calc(100vw * (4 / 375)) calc(100vw * (4 / 375))
            calc(100vw * (20 / 375)) 0 rgba(0, 0, 0, 0.12);

          & .icon {
            top: calc(100vw * (-55 / 375));
            width: calc(100vw * (74 / 375));
            height: calc(100vw * (74 / 375));
            filter: drop-shadow(
              calc(100vw * (4 / 375)) calc(100vw * (4 / 375))
                calc(100vw * (20 / 375)) rgba(0, 0, 0, 0.12)
            );
          }
        }
      }
    }

    & .subBannerSection {
      height: auto;
      padding: calc(100vw * (50 / 375)) 0;

      & .inner {
        flex-direction: column;
        gap: calc(100vw * (50 / 375));

        & .textWrap {
          width: 100%;
          line-height: 1.41;

          & small {
            margin-bottom: calc(100vw * (10 / 375));
            font-size: calc(100vw * (14 / 375));
          }
        }

        & .img {
          width: calc(100vw * (335 / 375));
          height: calc(100vw * (254 / 375));
        }
      }
    }

    & .curriculmSection {
      & h3 {
        margin-bottom: calc(100vw * (17 / 375));
      }

      & .tab {
        margin-bottom: calc(100vw * (32 / 375));
      }
    }
  }
`;

export { Container };

export default { Container };
