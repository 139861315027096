import Styles from "./styles";
function AskList({ data, isOpen, setIsOpen, suffix }) {

  return (
    <Styles.Container
      className={isOpen[data.id] ? "on" : ""}
      onClick={() => {
        setIsOpen({ [data.id]: !isOpen[data.id] });
      }}
    >
      <div className="questionWrap">
        <div className="icon">Q</div>

        <div className="question">
          <p>{data?.["title" + suffix]}</p>
        </div>
      </div>

      {isOpen[data.id] && (
        <div className="askWrap">
          <div className="icon">A</div>
          <p
            className="ask"
            dangerouslySetInnerHTML={{__html: data?.["description" + suffix]}}  
          >
          </p>
        </div>
      )}
    </Styles.Container>
  );
}

export default AskList;
