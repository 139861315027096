import styled from "styled-components";

const Container = styled.div`
  & .dateTopContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 33px;
    margin-bottom: 30px;
    border-bottom: 1px solid var(--black);

    & .dateWrap {
      font-size: 32px;
      font-weight: bold;

      & .month {
        margin-right: 14px;
        color: var(--blue02);
      }
    }

    & .buttonContainer {
      display: flex;
      gap: 20px;

      & button {
        width: 32px;
        height: 32px;
      }
    }
  }

  & .dateBottomContainer {
    & .dayContainer {
      display: flex;
      justify-content: center;
      gap: 34px;
      margin-bottom: 38px;

      & .day {
        width: 35px;
        font-size: 16px;
        font-weight: bold;
        color: var(--black01);
        text-align: center;

        &.sunday {
          color: #ff4329;
        }
      }
    }

    & .row {
      display: flex;
      justify-content: center;
      gap: 34px;

      &:not(:last-child) {
        margin-bottom: 34px;
      }
    }

    & .date {
      position: relative;
      width: 35px;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      cursor: pointer;

      &.active::after {
        position: absolute;
        top: calc(100% + 5px);
        left: 50%;
        transform: translateX(-50%);
        width: 6px;
        height: 6px;
        background-color: var(--blue03);
        border-radius: 50%;
        content: "";
      }

      &.sunday {
        color: #ff4329;
      }

      & .emptyDate {
        opacity: 0.15;
      }
    }
  }

  @media (max-width: 1480px) {
    & .dateTopContainer {
      padding-bottom: calc(100vw * (33 / 1480));
      margin-bottom: calc(100vw * (30 / 1480));

      & .dateWrap {
        font-size: calc(100vw * (32 / 1480));

        & .month {
          margin-right: calc(100vw * (14 / 1480));
        }
      }

      & .buttonContainer {
        gap: calc(100vw * (20 / 1480));

        & button {
          width: calc(100vw * (32 / 1480));
          height: calc(100vw * (32 / 1480));
        }
      }
    }

    & .dateBottomContainer {
      & .dayContainer {
        gap: calc(100vw * (34 / 1480));
        margin-bottom: calc(100vw * (38 / 1480));

        & .day {
          width: calc(100vw * (35 / 1480));
          font-size: calc(100vw * (16 / 1480));
        }
      }

      & .row {
        gap: calc(100vw * (34 / 1480));

        &:not(:last-child) {
          margin-bottom: calc(100vw * (34 / 1480));
        }
      }

      & .date {
        width: calc(100vw * (35 / 1480));
        font-size: calc(100vw * (16 / 1480));

        &.active::after {
          top: calc(100% + calc(100vw * (5 / 1480)));
          width: calc(100vw * (6 / 1480));
          height: calc(100vw * (6 / 1480));
        }
      }
    }
  }

  @media (max-width: 768px) {
    & .dateTopContainer {
      padding-bottom: calc(100vw * (20 / 375));
      margin-bottom: calc(100vw * (20 / 375));

      & .dateWrap {
        font-size: calc(100vw * (20 / 375));

        & .month {
          margin-right: calc(100vw * (10 / 375));
        }
      }

      & .buttonContainer {
        gap: calc(100vw * (14 / 375));

        & button {
          width: calc(100vw * (24 / 375));
          height: calc(100vw * (24 / 375));
        }
      }
    }

    & .dateBottomContainer {
      & .dayContainer {
        gap: calc(100vw * (14 / 375));
        margin-bottom: calc(100vw * (30 / 375));

        & .day {
          width: calc(100vw * (31 / 375));
          font-size: calc(100vw * (14 / 375));
        }
      }

      & .row {
        gap: calc(100vw * (27 / 375));

        &:not(:last-child) {
          margin-bottom: calc(100vw * (26 / 375));
        }
      }

      & .date {
        width: calc(100vw * (18 / 375));
        font-size: calc(100vw * (14 / 375));

        &.active::after {
          top: calc(100% + calc(100vw * (7 / 375)));
          width: calc(100vw * (6 / 375));
          height: calc(100vw * (6 / 375));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
