import styled from "styled-components";

// img
import BgBusiness from "resources/image/business/bg_business.png";
import BgBusinessMb from "resources/image/business/bg_business_mb.png";
import IconCarOn from "resources/image/icon/icon_car_on.svg";
import IconCarOff from "resources/image/icon/icon_car_off.svg";
import IconArrowRight from "resources/image/icon/icon_arrow_right02.svg";

const Container = styled.main`
  min-height: 100vh;
  min-height: 100dvh;
  padding-bottom: 200px;

  & section:not(:last-child) {
    margin-bottom: 80px;
  }

  & .textSection {
    padding: 0 50px;

    & .textWrap {
      padding: 80px 0;
      color: #fff;
      text-align: center;
      background: url(${BgBusiness}) no-repeat center / cover;
      border-radius: 500px;
      overflow: hidden;

      & p:first-child {
        margin-bottom: 50px;
        font-size: 52px;
        font-weight: bold;
      }

      & p:last-child {
        font-size: 28px;
        font-weight: 500;
        line-height: 1.42;
      }
    }
  }

  & .menuSection {
    & .menu {
      display: flex;
      flex-wrap: wrap;
      gap: 50px 60px;
      font-weight: bold;

      & .meuList {
        width: calc(calc(100% - 60px) / 2);

        &.on {
          & .title {
            color: #fff;
            background: url(${IconCarOn}) no-repeat calc(100% - 20px) center /
              60px var(--blue02);
          }
        }

        & .title {
          padding: 20px;
          font-size: 28px;
          border-bottom: 2px solid var(--blue02);
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          background: url(${IconCarOff}) no-repeat calc(100% - 20px) center /
            60px;
        }

        & ul {
          display: flex;
          flex-wrap: wrap;
          gap: 0 30px;

          & .depthList a {
            display: flex;
            align-items: center;
            width: 300px;
            height: 80px;
            padding: 0 20px;
            font-size: 20px;
            line-height: 1.4;
            background: url(${IconArrowRight}) no-repeat calc(100% - 20px)
              center / 19px;
            border-bottom: 2px solid var(--gray02);
          }
        }
      }
    }
  }

  @media (max-width: 1480px) {
    padding-bottom: calc(100vw * (200 / 1480));

    & section:not(:last-child) {
      margin-bottom: calc(100vw * (80 / 1480));
    }

    & .textSection {
      padding: 0 calc(100vw * (50 / 1480));

      & .textWrap {
        padding: calc(100vw * (80 / 1480)) 0;
        border-radius: calc(100vw * (500 / 1480));

        & p:first-child {
          margin-bottom: calc(100vw * (50 / 1480));
          font-size: calc(100vw * (52 / 1480));
        }

        & p:last-child {
          font-size: calc(100vw * (28 / 1480));
        }
      }
    }

    & .menuSection {
      & .menu {
        gap: calc(100vw * (50 / 1480)) calc(100vw * (60 / 1480));

        & .meuList {
          width: calc(calc(100% - calc(100vw * (60 / 1480))) / 2);

          &.on {
            & .title {
              background: url(${IconCarOn}) no-repeat
                calc(100% - calc(100vw * (20 / 1480))) center /
                calc(100vw * (60 / 1480)) var(--blue02);
            }
          }

          & .title {
            padding: calc(100vw * (20 / 1480));
            font-size: calc(100vw * (28 / 1480));
            border-bottom: calc(100vw * (2 / 1480)) solid var(--blue02);
            border-top-left-radius: calc(100vw * (10 / 1480));
            border-top-right-radius: calc(100vw * (10 / 1480));
            background: url(${IconCarOff}) no-repeat
              calc(100% - calc(100vw * (20 / 1480))) center /
              calc(100vw * (60 / 1480));
          }

          & ul {
            gap: 0 calc(100vw * (30 / 1480));

            & .depthList a {
              width: calc(100vw * (300 / 1480));
              height: calc(100vw * (80 / 1480));
              padding: 0 calc(100vw * (20 / 1480));
              font-size: calc(100vw * (20 / 1480));
              background: url(${IconArrowRight}) no-repeat
                calc(100% - calc(100vw * (20 / 1480))) center /
                calc(100vw * (19 / 1480));
              border-bottom: calc(100vw * (2 / 1480)) solid var(--gray02);
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    min-height: calc(100vh - calc(100vw * (309 / 375)));
    min-height: calc(100dvh - calc(100vw * (309 / 375)));
    padding: calc(100vw * (66 / 375)) 0 calc(100vw * (130 / 375));

    & section:not(:last-child) {
      margin-bottom: calc(100vw * (50 / 375));
    }

    & .textSection {
      padding: 0 calc(100vw * (20 / 375));

      & .textWrap {
        padding: calc(100vw * (100 / 375)) calc(100vw * (40 / 375));
        word-break: keep-all;
        background-image: url(${BgBusinessMb});
        border-radius: calc(100vw * (200 / 375));

        & p:first-child {
          margin-bottom: calc(100vw * (50 / 375));
          font-size: calc(100vw * (32 / 375));
        }

        & p:last-child {
          font-size: calc(100vw * (20 / 375));
          line-height: 1.5;
        }
      }
    }

    & .menuSection {
      & .menu {
        gap: calc(100vw * (50 / 375));

        & .meuList {
          width: 100%;

          &.on {
            & .title {
              background: url(${IconCarOn}) no-repeat
                calc(100% - calc(100vw * (20 / 375))) center /
                calc(100vw * (42 / 375)) var(--blue02);
            }
          }

          & .title {
            padding: calc(100vw * (10 / 375)) calc(100vw * (20 / 375));
            font-size: calc(100vw * (20 / 375));
            border-bottom: calc(100vw * (2 / 375)) solid var(--blue02);
            border-top-left-radius: calc(100vw * (10 / 375));
            border-top-right-radius: calc(100vw * (10 / 375));
            background: url(${IconCarOff}) no-repeat
              calc(100% - calc(100vw * (20 / 375))) center /
              calc(100vw * (42 / 375));
          }

          & ul {
            gap: 0;

            & .depthList {
              width: 100%;
            }

            & .depthList a {
              width: 100%;
              height: calc(100vw * (60 / 375));
              padding: 0 calc(100vw * (20 / 375));
              font-size: calc(100vw * (16 / 375));
              background: url(${IconArrowRight}) no-repeat
                calc(100% - calc(100vw * (20 / 375))) center /
                calc(100vw * (16 / 375));
              border-bottom: calc(100vw * (2 / 375)) solid var(--gray02);
            }
          }
        }
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
