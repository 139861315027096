import Styles from "./styles";
import { common, layout } from "components";
import { useLanguage, useProduct } from "hooks";

// img
import BgBanner from "resources/image/education_center/bg_main.jpg";
import BgBannerMb from "resources/image/education_center/bg_main_mb.jpg";
import ImgLogo01 from "resources/image/education_center/img_logo01.png";
import ImgLogo02 from "resources/image/education_center/img_logo02.png";
import ImgLogo03 from "resources/image/education_center/img_logo03.png";

function EducationIntacsCurriculmPage() {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === ""

  const logoList = [ImgLogo01, ImgLogo02, ImgLogo03];

  const productIntacsInfo = useProduct({
    listOption: {
      page: 1,
      pageSize: 20,
      recordSize: 999,
      category_info_id: 3,
    },
  });

  return (
    <>
      <common.Header />
      <Styles.Container>
        <section className="bannerSection">
          <layout.MainBanner
            img={BgBanner}
            mbImg={BgBannerMb}
            colorText={"intacs™"}
            title={languageQuarter ? "공식 교육과정" : <>An official<br />Curriculum</>}
          />
        </section>

        <section className="introductionSection">
          <div className="inner">
            <h3>
              {languageQuarter ?
                <>
                  C&BIS<span>는 독일 VDA QMC로부터 인정받은</span> 국내 공식
                  교육기관<span>으로, <br />
                  intacs™에서 주관하는 교육과정을 운영하고 있습니다.</span>
                </> : <>
                  C&BIS<span> is an</span> official domestic educational institution <span>recognized by
                  <br className="pcOnly"/> VDA QMC in Germany and operates a curriculum organized by intacs™.</span>
                </>
              }
            </h3>

            <p className="description">
              {languageQuarter ?
                <>
                  intacs™ 주관 교육은 Automotive SPICE Process Assessment
                  Model(ASPICE PAM) 기반의 심사에 참여, 진행할 수 있는 심사원을
                  양성하기 위한 교육입니다. <br />
                  Automotive SPICE PAM은 자동차 부품 개발 과정이 Model의 요구사항을
                  기준으로 적합하게 개발되었는지, 혹은 개발되고 있는지를 심사하기
                  위해 사용됩니다. <br />
                  이러한 심사는 intacs™에서 공식적으로 인정된 심사원에 의해
                  <br className="mbOnly" />
                  서만 수행할 수 있으며, 단계별 교육 수료 후 시험을 통과하면 공식
                  심사원이 될 수 있습니다.
                </> : <>
                  intacs™ supervised training is designed to train judges who can participate in and proceed with
                  the Automatic SPICE Process Assignment Model (ASPICE PAM)-based screening. <br className="mbOnly"/>
                  Automotive SPICE PAM is used to assess whether the automotive parts development process
                  has been developed or is being developed in accordance with the requirements of the model. <br className="mbOnly"/>
                  These examinations can only be conducted by an officially recognized examiner in intacs™,
                  and if you pass the examination after completing the step-by-step training, you can become an official examiner.
                </>
              }
            </p>

            <ul>
              {logoList.map((item, index) => {
                return (
                  <li className="logoList" key={"logoList" + index}>
                    <div className="img">
                      <img src={item} alt="logo" />
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>

        {languageQuarter &&
          <section className="curriculmSection">
            <div className="inner">
              <h3>{languageQuarter ? "intacs™ 공식 교육과정" : "intacTM official curriculum"}</h3>

              <layout.EducationSlider
                id={"intacs"}
                // link={"/education/curriculum-detail/intacs"}
                data={productIntacsInfo?.listQuery?.data?.list || []}
                isIntro={true}
              />
            </div>
          </section>
        }
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default EducationIntacsCurriculmPage;
