import connector from "service/connector"


const getTermPersonal = async () => {
  return await connector.connectFetchController("term/personal", "GET");
}
const getTermService = async () => {
  return await connector.connectFetchController("term/service", "GET");
}
export {
  getTermPersonal,
  getTermService,
};


export default {
  getTermPersonal,
  getTermService,
}