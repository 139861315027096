import ImgDummyMainBanner from "resources/image/dummy/img_banner_dummy.jpg";
import ImgDummyMainBannerMb from "resources/image/dummy/img_banner_mb_dummy.jpg";
import ImgDummySlide01 from "resources/image/dummy/img_dummy_slide01.png";
import ImgDummySlide02 from "resources/image/dummy/img_dummy_slide02.png";
import ImgDummyHistory from "resources/image/dummy/img_dummy_history.jpg";

const mainBannerList = [
  {
    pc: ImgDummyMainBanner,
    mobile: ImgDummyMainBannerMb,
  },
  {
    pc: ImgDummyMainBanner,
    mobile: ImgDummyMainBannerMb,
  },
  {
    pc: ImgDummyMainBanner,
    mobile: ImgDummyMainBannerMb,
  },
  {
    pc: ImgDummyMainBanner,
    mobile: ImgDummyMainBannerMb,
  },
];

const slideList = [
  // type 0: 개설예정 / 1: 신청중 / 2: 신청 마감
  {
    img: ImgDummySlide01,
    type: 0,
    title: "ASPICE Functional Safety 과정명 제목 ASPICE Functional Safe",
  },
  {
    img: ImgDummySlide01,
    type: 1,
    title: "ASPICE Functional Safety 과정명 제목 ASPICE Functional Safe",
  },
  {
    img: ImgDummySlide02,
    type: 1,
    title: "ASPICE Functional Safety 과정명 제목 ASPICE Functional Safe",
  },
  {
    img: ImgDummySlide01,
    type: 2,
    title: "ASPICE Functional Safety 과정명 제목 ASPICE Functional Safe",
  },
  {
    img: ImgDummySlide02,
    type: 1,
    title: "ASPICE Functional Safety 과정명 제목 ASPICE Functional Safe",
  },
];

const educationData = {
  // type: 0 - 신청 가능 / 1 - 신청 불가능
  type: 0,
  img: ImgDummySlide01,
  category: "ASPICE Functional Safety",
  title: "ASPICE Functional Safety 과정명 제목 A SPICE Functional Safe",
  date: 1,
  applyDate: "2024.01.01",
  time: 3,
  people: 20,
  price: 123456,
  method: "VBANK",
  applyList: [
    {
      period: 0,
      // 날짜는 년/월/일/요일이 데이터로 들어갑니다
      startDate: "0000.00.00(월)",
      endDate: "0000.00.00(월)",
      id: "a",
      disabled: false,
    },
    {
      period: 1,
      startDate: new Date(),
      endDate: new Date(),
      id: "b",
      disabled: false,
    },
    {
      period: 3,
      startDate: "0000.00.00(월)",
      endDate: "0000.00.00(월)",
      id: "c",
      disabled: true,
    },
  ],
};

const userInfo = {
  koreanName: "국문이름",
  englishName: "English Name",
  birth: "0000.00.00",
  koreanDepartment: "국문소속",
  englishDepartment: "English Team",
  tel: "010-0000-0000",
  email: "test@test.com",
  judgeId: "intacs-KR00-0000-00000-00",
  license: "파일명파일명파일명파일명파일명파일명.확장자",
};

const scheduleList = [
  // type 0: 개설예정 / 1: 신청중 / 2: 신청 마감
  {
    type: 0,
    period: "2024.01.01 ~ 2024.03.01",
    title:
      "과정명 title 과정명 title 과정명 title 과정명 title 과정명 title 과정명 title ",
  },
  {
    type: 1,
    period: "2024.01.01 ~ 2024.03.01",
    title:
      "과정명 title 과정명 title 과정명 title 과정명 title 과정명 title 과정명 title ",
  },
  {
    type: 2,
    period: "2024.01.01 ~ 2024.03.01",
    title:
      "과정명 title 과정명 title 과정명 title 과정명 title 과정명 title 과정명 title ",
  },
  {
    type: 1,
    period: "2024.01.01 ~ 2024.03.01",
    title:
      "과정명 title 과정명 title 과정명 title 과정명 title 과정명 title 과정명 title ",
  },
  {
    type: 2,
    period: "2024.01.01 ~ 2024.03.01",
    title:
      "과정명 title 과정명 title 과정명 title 과정명 title 과정명 title 과정명 title ",
  },
];

const historyList = [
  {
    year: 2018,
    img: ImgDummyHistory,
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Error earum esse iusto dignissimos, iste maiores rem quasi quibusdam magnam, pariatur laudantium id nihil laborum assumenda obcaecati commodi, quod sapiente nam?",
  },
  {
    year: 2017,
    img: ImgDummySlide02,
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Error earum esse iusto dignissimos, iste maiores rem quasi quibusdam magnam, pariatur laudantium id nihil laborum assumenda obcaecati commodi, quod sapiente nam?",
  },
  {
    year: 2016,
    img: ImgDummyHistory,
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Error earum esse iusto dignissimos, iste maiores rem quasi quibusdam magnam, pariatur laudantium id nihil laborum assumenda obcaecati commodi, quod sapiente nam?",
  },
  {
    year: 2015,
    img: ImgDummySlide01,
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Error earum esse iusto dignissimos, iste maiores rem quasi quibusdam magnam, pariatur laudantium id nihil laborum assumenda obcaecati commodi, quod sapiente nam?",
  },
  {
    year: 2014,
    img: ImgDummyHistory,
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Error earum esse iusto dignissimos, iste maiores rem quasi quibusdam magnam, pariatur laudantium id nihil laborum assumenda obcaecati commodi, quod sapiente nam?",
  },
];

const noticeList = [
  {
    pin: true,
    title:
      "제목이 들어가는 곳입니다 제목이 들어가는 곳입니다 제목이 들어가는 곳",
    fileList: [
      {
        fileName: "파일명파일명파일명.확장자",
      },
      {
        fileName:
          "파일명파일명파일명파일명파일명파일명파일명파일명파일명파일명.확장자",
      },
      ,
    ],
    date: "2024.01.01",
  },
  {
    number: "01",
    title:
      "제목이 들어가는 곳입니다 제목이 들어가는 곳입니다 제목이 들어가는 곳",
    fileList: [""],
    date: "2024.01.01",
  },
  {
    number: "01",
    title:
      "제목이 들어가는 곳입니다 제목이 들어가는 곳입니다 제목이 들어가는 곳",
    fileList: [],
    date: "2024.01.01",
  },
  {
    number: "01",
    title:
      "제목이 들어가는 곳입니다 제목이 들어가는 곳입니다 제목이 들어가는 곳",
    fileList: [""],
    date: "2024.01.01",
  },
];

const askList = [
  {
    question:
      "자주 묻는 질문의 제목입니다 자주 묻는 질문의 제목입니다 자주 묻는 질문의 제목입니다 ",
    ask: "자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 ",
  },
  {
    question:
      "자주 묻는 질문의 제목입니다 자주 묻는 질문의 제목입니다 자주 묻는 질문의 제목입니다 ",
    ask: "자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 ",
  },
  {
    question:
      "자주 묻는 질문의 제목입니다 자주 묻는 질문의 제목입니다 자주 묻는 질문의 제목입니다 ",
    ask: "자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 ",
  },
  {
    question:
      "자주 묻는 질문의 제목입니다 자주 묻는 질문의 제목입니다 자주 묻는 질문의 제목입니다 ",
    ask: "자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 ",
  },
  {
    question:
      "자주 묻는 질문의 제목입니다 자주 묻는 질문의 제목입니다 자주 묻는 질문의 제목입니다 ",
    ask: "자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 자주 묻는 질문에 대한 답변입니다 ",
  },
];

const taxInvoiceInfo = {
  contactEmail: "이메일",
  site: "발행 사이트",
  date: "YYYY.MM.DD",
  depositDate: "YYYY.MM.DD",
  memo: "요청사항 요청사항 요청사항 요청사항 요청사항 요청사항 요청사항 요청사항 요청사항 ",
};

export {
  mainBannerList,
  slideList,
  educationData,
  userInfo,
  scheduleList,
  historyList,
  noticeList,
  askList,
  taxInvoiceInfo,
};

export default {
  mainBannerList,
  slideList,
  educationData,
  userInfo,
  scheduleList,
  historyList,
  noticeList,
  askList,
  taxInvoiceInfo,
};
