import { useLanguage } from "hooks";
import Styles from "./styles";

//img
import IconRecruitment01 from "resources/image/icon/icon_recruitment01.svg";
import IconRecruitment02 from "resources/image/icon/icon_recruitment02.svg";
import IconRecruitment03 from "resources/image/icon/icon_recruitment03.svg";
import IconRecruitment04 from "resources/image/icon/icon_recruitment04.svg";

function Recruitment() {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === ""

  const stepList = [
    {
      icon: IconRecruitment01,
      title: languageQuarter ? "서류전형" : "Document screening",
      list: [languageQuarter
        ? "모집분야별 능력 및 자격요건 서류심사"
        : "Examination of competency and qualification documents by recruitment field"
      ],
    },
    {
      icon: IconRecruitment02,
      title: languageQuarter ? "1차 면접" : <>1st <br /> interviews</>,
      list: languageQuarter ? [
        "직무별 직업기초능력 중심의 면접평가",
        "직무수행에 필요한 역량중심의 면접평가",
      ] : [
        "Evaluation of basic job competency by job and competency required for job performance"
      ],
    },
    {
      icon: IconRecruitment03,
      title: languageQuarter ? "임원 면접" : "An executive interview",
      list: [languageQuarter
        ? "인성중심으로 지원분야의 전문성, 기본 자질 및 태도, 가치관 등으로 종합적으로 평가"
        : "Comprehensive evaluation of expertise, qualities, attitudes, values, etc. in the field of support",
      ],
    },
    {
      icon: IconRecruitment04,
      title: languageQuarter ? "최종 합격" : "The final pass",
      list: [languageQuarter
        ? "최종 합격한 입사지원자에게 유선이나 이메일로 개별 통보"
        : "Notify the final successful applicants by wire or e-mail"],
    },
  ];

  const welfareList = [
    {
      title: (languageQuarter ?
        <>
          Work-life <br />
          balance 보장
        </> : <>
          Ensure work-life balance
        </>
      ),
      description: (languageQuarter ?
        <>
          업무와 삶의 균형을 위하여 <br className="mbOnly" />
          자유로운 연차 사용을 권장하며, <br />
          회식과 야근을 강요하지 않습니다.
        </> : <>
          Free annual leave is recommended for work-life balance, and it does not force dinners and overtime.
        </>
      ),
    },
    {
      title: (languageQuarter ?
        <>
          포상 & <br />
          인센티브 제공
        </> : <>
          Award & Incentive Offer
        </>
      ),
      description: (languageQuarter ?
        <>
          근속연수에 따른 장기근속사원에게 <br className="mbOnly" />
          상패와 포상금을 지급하고 있습니다. <br />
          또한, 회사의 경영성과 달성에 따른 <br className="mbOnly" />
          인센티브 제도를 운영하며 성장을 지원하고 있습니다.
        </> : <>
          We provide plaques and rewards to long-term employees based on their service training.
          In addition, we support growth by operating an incentive system to achieve the company's management performance.
        </>
      ),
    },
    {
      title: languageQuarter ? "자기개발 지원" : "Self-development support",
      description: (languageQuarter ?
        <>
          업무능력 향상을 위하여 <br className="mbOnly" />
          자기개발이 필요한 임직원들에게 <br />
          교육훈련비를 지원하고 있습니다.
        </> : <>
          We provide education and training expenses to executives and employees who need self-development to improve their work skills.
        </>
      ),
    },
    {
      title: languageQuarter ? "건강관리 지원" : "Health care support",
      description: (languageQuarter ?
        <>
          임직원의 건강관리를 위해 <br />
          종합건강검진을 제공하고 있습니다.
        </> : <>
          Comprehensive health check-ups are provided for the health care of executives and employees.
        </>
      ),
    },
    {
      title: languageQuarter ? "경조사 지원" : "Support for congratulatory events",
      description: (languageQuarter ?
        <>
          결혼, 출산 등의 경조금 및 <br />
          경조휴가를 제공하고 있습니다.
        </> : <>
          We provide condolence money such as marriage and childbirth and condolence leave.
        </>
      ),
    },
    {
      title: languageQuarter ? "기타" : "Etc.",
      description: (languageQuarter ?
        <>
          자율복장 및 존칭 경어를 사용하여 서로를 존중하고{" "}
          <br className="mbOnly" />
          자유로운 분위기를 지향합니다.
          <br className="pcOnly" />
          워크샵 및 가족초청
          <br className="mbOnly" />
          행사를 통해 구성원간의 친목도모 뿐만 아니라
          <br />
          가족과 함께하는 시간을 제공하고 있습니다.
          <br />
          주차지원 및 통근버스를 통하여 임직원의 편안한{" "}
          <br className="mbOnly" />
          출퇴근을 돕고 있습니다.
        </> : <>
          We respect each other and aim for a free atmosphere by using self-dressing and honorific honorifics.
          Through workshops and family invitation events, we provide not only socializing among members but also time with family.
          We help executives and employees commute comfortably through parking support and commuter buses.
        </>
      ),
    },
  ];

  return (
    <Styles.Container>
      <div className="step">
        <h3>{languageQuarter ? "채용절차" : "Recruitment process"}</h3>

        <ul className="stepWrap">
          {stepList.map((item, index) => {
            return (
              <li className="stepList" key={"stepList" + index}>
                <div className="top">
                  <div className="textWrap">
                    <p className="stepText">STEP{index + 1}</p>
                    <p className="title">{item.title}</p>
                  </div>

                  <div className="icon">
                    <img src={item.icon} alt="IconRecruitment" />
                  </div>
                </div>

                <ul className="bottom">
                  {item.list.map((current, idx) => {
                    return (
                      <li className="list" key={"list" + idx}>
                        {current}
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
        </ul>
      </div>

      <div className="welfare">
        <h3>{languageQuarter ? "복지제도" : "The welfare system"}</h3>
        <p className="subText">
          {languageQuarter ? "씨엔비스㈜는 다양한 복리후생 제도를 운영하고 있습니다." : "C&BIS operates a variety of benefits programs."}
        </p>

        <ul>
          {welfareList.map((item, index) => {
            return (
              <li className="welfareList" key={"welfareList" + index}>
                <p className="title">{item.title}</p>
                <p className="description">{item.description}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </Styles.Container>
  );
}

export default Recruitment;
