import styled from "styled-components";

const Container = styled.section`
  & h3 {
    & sup {
      font-weight: 400;
    }
    & span {
      font-size: 28px;
    }
  }

  &.systemSection {
    & .sectionDescription {
      margin-bottom: 20px;
    }

    & .img {
      height: 381px;
      padding: 30px 322px;
    }
  }

  &.intacsSection {
    & ul {
      display: flex;
      gap: 15px;
      margin-bottom: 30px;

      & .intacsList {
        flex: 1 0;
      }
    }

    & .img {
      height: 384px;
      padding: 30px 360px;
    }
  }

  &.certificationSection {
    & ul {
      display: flex;
      flex-wrap: wrap;
      gap: 20px 15px;

      & .certificationList {
        flex: 1 0;

        &:nth-last-child(-n + 2) {
          flex: auto;
        }

        & > div {
          height: 100%;
        }

        & .img {
          width: 1260px;
        }

        &:nth-child(4) .img {
          height: 246px;
        }

        &:last-child .img {
          height: 370px;
        }
      }
    }
  }

  &.providerSection {
    & .list {
      &:not(:last-child) {
        margin-bottom: 30px;
      }

      & p {
        margin: 10px;
        line-height: 1.87;
      }

      & .providerListWrap {
        display: flex;
        gap: 97px;
        padding-left: 30px;

        & ol:first-child li:nth-child(2) {
          font-weight: bold;
        }

        & li {
          line-height: 1.87;
          list-style: upper-roman inside;

          &:not(:last-child) {
            margin-bottom: 4px;
          }
        }
      }
    }
  }

  @media (max-width: 1480px) {
    & h3 {
      & span {
        font-size: calc(100vw * (28 / 1480));
      }
    }

    &.systemSection {
      & .sectionDescription {
        margin-bottom: calc(100vw * (20 / 1480));
      }

      & .img {
        height: calc(100vw * (381 / 1480));
        padding: calc(100vw * (30 / 1480)) calc(100vw * (322 / 1480));
      }
    }

    &.intacsSection {
      & ul {
        gap: calc(100vw * (15 / 1480));
        margin-bottom: calc(100vw * (30 / 1480));
      }

      & .img {
        height: calc(100vw * (384 / 1480));
        padding: calc(100vw * (30 / 1480)) calc(100vw * (360 / 1480));
      }
    }

    &.certificationSection {
      & ul {
        gap: calc(100vw * (20 / 1480)) calc(100vw * (15 / 1480));

        & .certificationList {
          & .img {
            width: calc(100vw * (1260 / 1480));
          }

          &:nth-child(4) .img {
            height: calc(100vw * (246 / 1480));
          }

          &:last-child .img {
            height: calc(100vw * (370 / 1480));
          }
        }
      }
    }

    &.providerSection {
      & .list {
        &:not(:last-child) {
          margin-bottom: calc(100vw * (30 / 1480));
        }

        & p {
          margin: calc(100vw * (10 / 1480));
        }

        & .providerListWrap {
          gap: calc(100vw * (97 / 1480));
          padding-left: calc(100vw * (30 / 1480));

          & li {
            &:not(:last-child) {
              margin-bottom: calc(100vw * (4 / 1480));
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    & h3 {
      & span {
        font-size: calc(100vw * (24 / 375));
      }
    }

    &.systemSection {
      & .sectionDescription {
        margin-bottom: calc(100vw * (20 / 375));
      }

      & .img {
        height: calc(100vw * (180 / 375));
        padding: calc(100vw * (16 / 375)) calc(100vw * (12 / 375));
      }
    }

    &.intacsSection {
      & ul {
        flex-direction: column;
        gap: calc(100vw * (14 / 375));
        margin-bottom: calc(100vw * (20 / 375));
      }

      & .img {
        height: calc(100vw * (196 / 375));
        padding: calc(100vw * (16 / 375)) calc(100vw * (15 / 375));
      }
    }

    &.certificationSection {
      & ul {
        flex-direction: column;
        gap: calc(100vw * (14 / 375));

        & .certificationList {
          width: 100%;

          & .img {
            width: calc(100vw * (476 / 375));
          }

          & .scrollGuide {
            padding-top: calc(100vw * (20 / 375));
          }

          &:nth-child(4) .bottom,
          &:last-child .bottom {
            padding: calc(100vw * (12 / 375)) calc(100vw * (20 / 375));
          }

          &:nth-child(4) {
            & .img {
              height: calc(100vw * (434 / 375));
            }

            & .scrollGuide {
              padding-bottom: calc(100vw * (6 / 375));
            }
          }

          &:last-child .img {
            height: calc(100vw * (694 / 375));
          }
        }
      }
    }

    &.providerSection {
      & .list {
        &:not(:last-child) {
          margin-bottom: calc(100vw * (20 / 375));
        }

        & p {
          display: flex;
          gap: calc(100vw * (3 / 375));
          margin: calc(100vw * (10 / 375));
          line-height: 1.57;
        }

        & .providerListWrap {
          flex-direction: column;
          gap: calc(100vw * (10 / 375));
          padding-left: calc(100vw * (20 / 375));

          & li {
            line-height: normal;

            &:not(:last-child) {
              margin-bottom: calc(100vw * (10 / 375));
            }
          }
        }
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
