import styled from "styled-components";

const Container = styled.main`
  padding-bottom: 200px;

  & section:not(:last-child) {
    margin-bottom: 80px;
  }

  & h3 {
    font-size: 36px;
    font-weight: bold;
  }

  & .introductionSection {
    & h3 {
      margin-bottom: 30px;
      color: var(--blue02);
      line-height: 1.33;

      & span {
        font-weight: 500;
        color: var(--black);
      }
    }

    & .description {
      margin-bottom: 50px;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.44;
    }

    & ul {
      display: flex;
      gap: 30px;

      & .logoList {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 420px;
        height: 131px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.12);

        & .img {
          width: fit-content;
          height: 58px;

          & img {
            width: auto;
          }
        }
      }
    }
  }

  & .curriculmSection {
    & h3 {
      margin-bottom: 30px;
    }
  }

  @media (max-width: 1480px) {
    padding-bottom: calc(100vw * (200 / 1480));

    & section:not(:last-child) {
      margin-bottom: calc(100vw * (80 / 1480));
    }

    & h3 {
      font-size: calc(100vw * (36 / 1480));
    }

    & .introductionSection {
      & h3 {
        margin-bottom: calc(100vw * (30 / 1480));
      }

      & .description {
        margin-bottom: calc(100vw * (50 / 1480));
        font-size: calc(100vw * (18 / 1480));
      }

      & ul {
        gap: calc(100vw * (30 / 1480));

        & .logoList {
          width: calc(100vw * (420 / 1480));
          height: calc(100vw * (131 / 1480));
          border-radius: calc(100vw * (10 / 1480));
          box-shadow: calc(100vw * (4 / 1480)) calc(100vw * (4 / 1480))
            calc(100vw * (20 / 1480)) 0 rgba(0, 0, 0, 0.12);

          & .img {
            height: calc(100vw * (58 / 1480));
          }
        }
      }
    }

    & .curriculmSection {
      & h3 {
        margin-bottom: calc(100vw * (30 / 1480));
      }
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (66 / 375)) 0 calc(100vw * (130 / 375));

    & section:not(:last-child) {
      margin-bottom: calc(100vw * (50 / 375));
    }

    & h3 {
      font-size: calc(100vw * (24 / 375));
    }

    & .introductionSection {
      & h3 {
        margin-bottom: calc(100vw * (50 / 375));
        line-height: normal;
      }

      & .description {
        margin-bottom: calc(100vw * (30 / 375));
        font-size: calc(100vw * (14 / 375));
        line-height: 1.42;
        word-break: keep-all;
      }

      & ul {
        flex-direction: column;
        gap: calc(100vw * (14 / 375));

        & .logoList {
          width: 100%;
          height: calc(100vw * (104 / 375));
          border-radius: calc(100vw * (10 / 375));
          box-shadow: calc(100vw * (4 / 375)) calc(100vw * (4 / 375))
            calc(100vw * (20 / 375)) 0 rgba(0, 0, 0, 0.12);

          & .img {
            height: calc(100vw * (46 / 375));
          }
        }
      }
    }

    & .curriculmSection {
      & h3 {
        margin-bottom: calc(100vw * (44 / 375));
      }
    }
  }
`;

export { Container };

export default { Container };
