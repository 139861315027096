import Styles from "./styles";
import utils, { etc } from "utils";
import { common, ui } from "components";
import { useMediaQuery } from "react-responsive";
import { useState, useEffect } from "react";
import { useProduct, useTransaction } from "hooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// img
import IconTime from "resources/image/icon/icon_time.svg";
import IconPeople from "resources/image/icon/icon_people.svg";
import IconPrice from "resources/image/icon/icon_price.svg";

function EducationApplyDetailPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const productInfo = useProduct({
    detailOption : {
      id : utils.etc.getSearchParam().id
    },
    transactionDetailOption:{
      id : utils.etc.getSearchParam().id
    },
    optionListOption:{
      id : utils.etc.getSearchParam().id
    }
  });

  const detail = productInfo.detailQuery?.data?.data || [];
  
  const transactionInfo = useTransaction({
    transactionList : [
      {
        optionList : productInfo?.optionGroupList,
        detail : productInfo?.transactionDetailQuery?.data?.data,
        amount : 1,
        date : null,
      }
    ]
  });

  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });

  const [isApplyOpen, setIsApplyOpen] = useState(!isMobile);

  useEffect(() => {
    setIsApplyOpen(!isMobile);
  }, [isMobile]);

  return (
    <>
      <common.Header />
      <Styles.Container>
        <div className="inner" >
          <div className="left">
            <div className="infoContainer">
              <div className="img">
                <img src={detail?.image} alt="thumbnail" />
              </div>

              <div className="infoWrap">
                <div className="category">{detail?.category_title}</div>
                <p className="title">{detail?.title}</p>

                <ul className="detail">
                  <li className="detailList">
                    <div className="icon">
                      <img src={IconTime} alt="IconTime" />
                    </div>
                    {/* {data.date}일 / {data.time}시간 */}
                    {detail?.class_title}
                  </li>

                  <li className="detailList">
                    <div className="icon">
                      <img src={IconPeople} alt="IconPeople" />
                    </div>
                    {detail?.member_title}명
                  </li>

                  <li className="detailList">
                    <div className="icon">
                      <img src={IconPrice} alt="IconPrice" />
                    </div>
                    {etc.numberWithCommas(detail?.price)}원
                  </li>
                </ul>
              </div>
            </div>

            <div
              className="editor ck-content"
              dangerouslySetInnerHTML={{ __html: detail?.description }}
            ></div>

            <div className="goListButton">
              <ui.button.BasicButton
                buttonText={"목록으로"}
                buttonType={"blue"}
                onClick={() => {
                  navigate(location.state ? -2 : -1)
                }}
              />
            </div>
          </div>

          <div className="right">
            {isMobile && isApplyOpen && (
              <div
                style={{ width: "100%", height: "100vh", backgroundColor: "transparent" }}
                onClick={() => { isMobile && setIsApplyOpen(false) }}
              >
              </div>
            )}
            <div className="sticky">
              {isApplyOpen && (
                <>
                  <div className="info">
                    <div className="category">{detail?.category_title}</div>
                    <p className="title">{detail?.title}</p>

                    <ul className="detail">
                      <li className="detailList">
                        <div className="icon">
                          <img src={IconTime} alt="IconTime" />
                        </div>
                        {detail?.class_title}
                      </li>

                      <li className="detailList">
                        <div className="icon">
                          <img src={IconPeople} alt="IconPeople" />
                        </div>
                        {detail?.member_title}명
                      </li>

                      <li className="detailList">
                        <div className="icon">
                          <img src={IconPrice} alt="IconPrice" />
                        </div>
                        {etc.numberWithCommas(detail?.price)}원
                      </li>
                    </ul>
                  </div>

                  <ul className="apply">
                    {productInfo.optionGroupList[0]?.list?.map((item, index) => {
                      return (
                        <li className="applyList" key={"applyList" + index}>
                          <ui.input.ApplyRadio
                            data={item}
                            detail={detail}
                            onChangeOption={productInfo.onChangeOption}
                          />
                        </li>
                      );
                    })}
                  </ul>
                </>
              )}

              <div className="applyButton"
              >
                <ui.button.BasicButton
                  buttonText={"신청하기"}
                  buttonType={"blue"}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    if (isMobile && !isApplyOpen) {
                      setIsApplyOpen(true);
                    } else {
                      if(!transactionInfo.isValidate() || detail?.price == 0){
                        return;
                      }
                      if (id === "cnbis" || id === "intacs") {
                        navigate("/apply/" + id + "?id=" + detail?.id, {
                          state : {
                            transactionList : transactionInfo.getGenerateTransactionList(),
                            productList : transactionInfo.transactionList,
                          }
                        })
                      }
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Styles.Container>

      <common.Footer noQuickMenu />
    </>
  );
}

export default EducationApplyDetailPage;
