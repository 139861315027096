import styled from "styled-components";

// img
import BgGreeting from "resources/image/introduction/img_greeting_01.png";
import BgGreetingMb from "resources/image/introduction/img_greeting_01_mb.png";

const Container = styled.main`
  min-height: 100vh;
  min-height: 100dvh;
  padding-bottom: 200px;

  & section:not(:last-child) {
    margin-bottom: 80px;
  }

  & .textSection {
    margin: 0 50px;
    padding-bottom: 375px;
    background: url(${BgGreeting}) no-repeat center bottom / 100% 500px;

    & .inner {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      & .left {
        & p {
          font-size: 62px;
          font-weight: 800;

          &:last-of-type {
            margin: -10px 0 30px;
            color: var(--blue02);
          }
        }

        & .downloadButton {
          width: 260px;
        }
      }

      & .right p {
        line-height: 1.87;
      }
    }
  }

  & .marqueeSection {
    padding: 38px 0;

    & p {
      font-size: 140px;
      font-weight: bold;
      color: var(--blue04);
    }
  }

  & .youtubeSection .video {
    height: 743px;

    & iframe {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 1480px) {
    padding-bottom: calc(100vw * (200 / 1480));

    & section:not(:last-child) {
      margin-bottom: calc(100vw * (80 / 1480));
    }

    & .textSection {
      margin: 0 calc(100vw * (50 / 1480));
      padding-bottom: calc(100vw * (255 / 1480));
      background: url(${BgGreeting}) no-repeat center bottom / 100%
        calc(100vw * (379 / 1480));

      & .inner {
        & .left {
          & p {
            font-size: calc(100vw * (62 / 1480));

            &:last-of-type {
              margin: calc(100vw * (-10 / 1480)) 0 calc(100vw * (30 / 1480));
            }
          }

          & .downloadButton {
            width: calc(100vw * (260 / 1480));
          }
        }
      }
    }

    & .marqueeSection {
      padding: calc(100vw * (38 / 1480)) 0;

      & p {
        font-size: calc(100vw * (140 / 1480));
      }
    }

    & .youtubeSection .video {
      height: calc(100vw * (743 / 1480));
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (66 / 375)) 0 calc(100vw * (130 / 375));

    & section:not(:last-child) {
      margin-bottom: calc(100vw * (50 / 375));
    }

    & .textSection {
      margin: 0 calc(100vw * (10 / 375));
      padding-bottom: calc(100vw * (108 / 375));
      background: url(${BgGreetingMb}) no-repeat center bottom / 100%
        calc(100vw * (149 / 375));

      & .inner {
        flex-direction: column;
        align-items: flex-start;
        gap: calc(100vw * (30 / 375));

        & .left {
          & p {
            font-size: calc(100vw * (32 / 375));

            &:last-of-type {
              margin: calc(100vw * (-2 / 375)) 0 calc(100vw * (16 / 375));
            }
          }

          & .downloadButton {
            width: calc(100vw * (180 / 375));
          }
        }

        & .right p {
          line-height: 1.42;
          word-break: keep-all;
        }
      }
    }

    & .marqueeSection {
      padding: calc(100vw * (26 / 375)) 0;

      & p {
        font-size: calc(100vw * (60 / 375));
      }
    }

    & .youtubeSection .video {
      height: calc(100vw * (189 / 375));
    }
  }
`;

export { Container };

export default {
  Container,
};
