import styled from "styled-components";

const Container = styled.main`
  min-height: 100vh;
  min-height: 100dvh;
  padding: 180px 0 200px;

  & h2 {
    margin-bottom: 80px;
    font-size: 62px;
    font-weight: 800;
    text-align: center;
  }

  & .formInner {
    padding: 80px;
    border: 2px solid var(--gray02);

    & h3 {
      margin-bottom: 30px;
      padding-bottom: 30px;
      font-size: 28px;
      font-weight: bold;
      border-bottom: 1px solid var(--gray02);
    }

    & .inputList {
      position: relative;

      &:not(:last-child) {
        margin-bottom: 30px;
      }

      &:nth-child(2) input {
        padding-right: 136px;
      }

      &:nth-child(3) input {
        padding-right: 171px;
      }

      & .time,
      button {
        position: absolute;
        bottom: 20px;
        font-size: 18px;
        font-weight: 500;
      }

      & .time {
        right: 102px;
        color: var(--red);
      }

      & button {
        right: 20px;
        color: var(--blue02);
        text-decoration: underline;
        text-underline-offset: 3px;
      }
    }

    & .inquiryButton {
      width: 260px;
      margin: 80px auto 0;
    }
  }

  @media (max-width: 1480px) {
    padding: calc(100vw * (180 / 1480)) 0 calc(100vw * (200 / 1480));

    & h2 {
      margin-bottom: calc(100vw * (80 / 1480));
      font-size: calc(100vw * (62 / 1480));
    }

    & .formInner {
      padding: calc(100vw * (80 / 1480));
      border: calc(100vw * (2 / 1480)) solid var(--gray02);

      & h3 {
        margin-bottom: calc(100vw * (30 / 1480));
        padding-bottom: calc(100vw * (30 / 1480));
        font-size: calc(100vw * (28 / 1480));
      }

      & .inputList {
        &:not(:last-child) {
          margin-bottom: calc(100vw * (30 / 1480));
        }

        &:nth-child(2) input {
          padding-right: calc(100vw * (136 / 1480));
        }

        &:nth-child(3) input {
          padding-right: calc(100vw * (171 / 1480));
        }

        & .time,
        button {
          bottom: calc(100vw * (20 / 1480));
          font-size: calc(100vw * (18 / 1480));
        }

        & .time {
          right: calc(100vw * (102 / 1480));
        }

        & button {
          right: calc(100vw * (20 / 1480));
          text-underline-offset: calc(100vw * (3 / 1480));
        }
      }

      & .inquiryButton {
        width: calc(100vw * (260 / 1480));
        margin: calc(100vw * (80 / 1480)) auto 0;
      }
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (116 / 375)) 0 calc(100vw * (130 / 375));

    & h2 {
      margin-bottom: calc(100vw * (50 / 375));
      font-size: calc(100vw * (32 / 375));
    }

    & .formInner {
      padding: calc(100vw * (30 / 375)) calc(100vw * (30 / 375))
        calc(100vw * (50 / 375));
      border: calc(100vw * (2 / 375)) solid var(--gray02);

      & h3 {
        margin-bottom: calc(100vw * (20 / 375));
        padding-bottom: calc(100vw * (20 / 375));
        font-size: calc(100vw * (20 / 375));
      }

      & .inputList {
        &:not(:last-child) {
          margin-bottom: calc(100vw * (20 / 375));
        }

        &:nth-child(2) input {
          padding-right: calc(100vw * (92 / 375));
        }

        &:nth-child(3) input {
          padding-right: calc(100vw * (110 / 375));
        }

        & .time,
        button {
          bottom: calc(100vw * (13 / 375));
          font-size: calc(100vw * (12 / 375));
        }

        & .time {
          right: calc(100vw * (63 / 375));
        }

        & button {
          right: calc(100vw * (14 / 375));
          text-underline-offset: calc(100vw * (3 / 375));
        }
      }

      & .inquiryButton {
        width: calc(100vw * (180 / 375));
        margin: calc(100vw * (50 / 375)) auto 0;
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
