import connector from "service/connector"


const uploadFile = async (data) => {
  return await connector.connectFetchController("upload/file", "POST", data);
}
const uploadImage = async (data) => {
  return await connector.connectFetchController("upload/image", "POST", data);
}


export {
  uploadFile,
  uploadImage,
};

export default {
  uploadFile,
  uploadImage,
}