import styled, { css } from "styled-components";

const buttonTypes = {
  white: {
    color: "var(--black)",
    border: "1px solid var(--black)",
    backgroundColor: "#fff",
  },
  blue: {
    color: "#fff",
    border: "1px solid var(--blue02)",
    backgroundColor: "var(--blue02)",
  },
  whiteHover: {
    color: "var(--black)",
    border: "1px solid var(--black)",
    backgroundColor: "#fff",
    hoverColor: "#fff",
    hoverBorder: "1px solid var(--blue02)",
    hoverBackgroundColor: "var(--blue02)",
  },
  blueHover: {
    color: "#fff",
    border: "1px solid var(--blue02)",
    backgroundColor: "var(--blue02)",
    hoverColor: "var(--black)",
    hoverBorder: "1px solid var(--black)",
    hoverBackgroundColor: "#fff",
  },
};

//변수를 size 형태로 받아서 분기 처리가 가능합니다.
const buttonTypeStyles = css`
  ${({ buttonType }) => css`
    color: ${buttonTypes[buttonType] && buttonTypes[buttonType].color};
    border: ${buttonTypes[buttonType] && buttonTypes[buttonType].border};
    background-color: ${buttonTypes[buttonType] &&
    buttonTypes[buttonType].backgroundColor};

    &:hover {
      color: ${buttonTypes[buttonType] && buttonTypes[buttonType].hoverColor};
      border: ${buttonTypes[buttonType] && buttonTypes[buttonType].hoverBorder};
      background-color: ${buttonTypes[buttonType] &&
      buttonTypes[buttonType].hoverBackgroundColor};
    }
  `}
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  font-size: 16px;
  font-weight: bold;

  ${buttonTypeStyles}

  & .icon {
    display: inline-block;
    width: 19px;
    height: 19px;
    margin-left: auto;
    filter: var(--blackFilter);
  }

  &.whiteHover:hover {
    & .icon {
      filter: var(--whiteFilter);
    }
  }

  @media (max-width: 1480px) {
    height: calc(100vw * (60 / 1480));
    padding: 0 calc(100vw * (20 / 1480));
    font-size: calc(100vw * (16 / 1480));

    ${buttonTypeStyles}

    & .icon {
      width: calc(100vw * (19 / 1480));
      height: calc(100vw * (19 / 1480));
    }
  }

  @media (max-width: 768px) {
    height: calc(100vw * (41 / 375));
    padding: 0 calc(100vw * (10 / 375));
    font-size: calc(100vw * (14 / 375));

    ${buttonTypeStyles}

    & .icon {
      width: calc(100vw * (16 / 375));
      height: calc(100vw * (16 / 375));
    }
  }
`;

export { Button };

export default {
  Button,
};
