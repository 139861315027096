import Styles from "./styles";
import { layout, ui } from "components";
import { useLanguage } from "hooks";
import { useNavigate } from "react-router-dom";

// img
import ImgCybersecurity01 from "resources/image/business/service/img_cybersecurity01.jpg";
import ImgCybersecurity02 from "resources/image/business/service/img_cybersecurity02.jpg";
import ImgCybersecurity03 from "resources/image/business/service/img_cybersecurity03.jpg";
import ImgCybersecurity04 from "resources/image/business/service/img_cybersecurity04.jpg";
import ImgCybersecurity05 from "resources/image/business/service/img_cybersecurity05.jpg";
import ImgCybersecurity06 from "resources/image/business/service/img_cybersecurity06.jpg";
import IconWarning from "resources/image/icon/icon_warning.svg";

function Cybersecurity() {
  const languageInfo = useLanguage()
  const languageQuarter = languageInfo.suffix === ""
  const navigate = useNavigate();

  const standardList = [
    <>
      General Consideration <br />
      {languageQuarter && "(일반 고려사항)"}
    </>,
    <>
      Overall Cybersecurity Management <br />
      {languageQuarter && "(조직의 전반적인 사이버보안 관리 요구사항)"}
    </>,
    <>
      Project Dependent Cybersecurity Management <br />
      {languageQuarter && "(프로젝트별 사이버보안 관리 요구사항)"}
    </>,
    <>
      Distributed Cybersecurity Activities <br />
      {languageQuarter && "(분산 개발 활동 요구사항)"}
    </>,
    <>
      Continual Cybersecurity Activities <br />
      {languageQuarter && "(지속적인 사이버보안 활동 요구사항)"}
    </>,
    <>
      Concept <br />
      {languageQuarter && "(개념 요구사항)"}
    </>,
    <>
      Product Development <br />
      {languageQuarter && "(제품 개발 요구사항)"}
    </>,
    <>
      Cybersecurity Validation <br />
      {languageQuarter && "(타당성 검증 요구사항)"}
    </>,
    <>
      Production <br />
      {languageQuarter && "(생산 요구사항)"}
    </>,
    <>
      Operation and Maintenance <br />
      {languageQuarter && "(운영 및 유지보수 요구사항)"}
    </>,
    <>
      End of Cybersecurity Support and Decommissioning <br />
      {languageQuarter && "(사이버보안 지원 종료 및 폐기 요구사항)"}
    </>,
    <>
      Threat Analysis and Risk Assessment Methods <br />
      {languageQuarter && "(위협 분석 및 위험 평가 방법 요구사항)"}
    </>,
  ];

  return (
    <>
      <Styles.Container className="titleSection">
        <div className="inner" id="focus">
          <layout.DescriptionBox
            title={"ISO 21434 - Road vehicles – Cybersecurity engineering"}
            left={languageQuarter ?
              <>
                자동차 사이버보안 <br />
                (Cybersecurity)
                <br className="pcOnly" />
                이란
              </> : <>
                Automotive Cybersecurity
              </>
            }
            right={languageQuarter ?
              <>
                2021년 8월에 제정된 ISO 21434표준에서 자동차 사이버보안에 대한
                설명은 Clause 3.1에 다음과 같이 정의되어 있습니다.
                <br />
                "Condition in which assets are sufficiently protected against
                threat scenarios to items of road vehicles, their functions and
                their electrical or electronic components"{" "}
                <br className="mbOnly" />
                즉, 자동차의 사이버보안은 “도로 차량의 아이템, 기능,{" "}
                <br className="mbOnly" />
                전기·전자 컴포넌트에 대해 위협 시나리오로
                <br className="pcOnly" />
                부터 자산(Asset)이 충분히 보호되어 있는 상태”를 의미합니다.
              </> : <>
                In the ISO 21434 standard established in August 2021, the description of automotive cybersecurity is defined in Clause 3.1 as follows.
                "Condition in which assets are sufficiently protected against threat scenarios to items of road vehicles, their functions and their electrical or electronic components"
              </>
            }
          />
        </div>
      </Styles.Container>

      <Styles.Container className="propertySection">
        <div className="inner">
          <h3>{languageQuarter ? "자동차 사이버보안 자산" : "Automotive Cybersecurity Assets"}</h3>

          <div className="wrap">
            <div className="textContainer">
              <p className="sectionDescription">
                {languageQuarter ?
                  <>
                    여기서 자산은 하기와 같이 도로 차량의 운행에 기여하는 가치를
                    갖는 것들을 의미합니다.
                  </> : <>
                    Assets here refer to things that have a value that contributes to the operation of the road vehicle as follows.
                  </>
                }
                <br />
                <br />
                1. Functionality (correct working of functions)
                <br />
                2. The Item itself (e.g., an ECU, component)
                <br />
                3. Software
                <br />
                4. Cryptographic keys
                <br />
                5. Communication channels (interfaces: internal/external)
                <br />
                6. Data (e.g., personal data)
                <br />
                <br />

                {languageQuarter ?
                  <>
                    다시 말해서, 자동차의 사이버보안은 자동차의 기능 및 성능,
                    자동차에서 제공되는 서비스를
                    <br className="pcOnly" />
                    위해 필요한 자산들을 사이버보안 위협으로부터 보호하는 것입니다
                  </> : <>
                    In other words, the automotive cybersecurity is that its functions, performance,
                    and assets for services provided by the car are protected from cybersecurity threats.
                  </>}
                <br />
                <br />
              </p>
            </div>

            <div className="imgContainer">
              <div className="source">
                <div className="icon">
                  <img src={IconWarning} alt="IconWarning" />
                </div>

                <p>
                  [{languageQuarter ? "출처" : "source"}] http://www.autoelectronics.co.kr/
                  <br className="mbOnly" />
                  article/articleView.asp?idx=1152
                </p>
              </div>
              <figure>
                <div className="img">
                  <img src={ImgCybersecurity01} alt="ImgCybersecurity01" />
                </div>
              </figure>
            </div>
          </div>
        </div>
      </Styles.Container>

      <Styles.Container className="backgroundSection">
        <div className="inner">
          <h3>{languageQuarter ? "자동차 사이버보안 등장 배경" : "Background of Automotive Cybersecurity"}</h3>

          <p className="sectionDescription">
            {languageQuarter ?
              <>
                오늘날의 자동차는 통신기술의 발달로 차량과 차량 간의 통신, 차량과
                백엔드 시스템 간 통신이 늘어나면서 자동차 안에서 처리 및 관리하는
                정보가 많아지고 있으며 이를 통해 운전자에게 다양한{" "}
                <br className="pcOnly" />
                IT 서비스를 제공하고 있습니다. 예를 들어, 이더넷 통신을 통해 모바일
                앱에서 자동차의 시동을 원격으로 제어하는 기능이나, 차량이 교통
                사고가 났을 때 위치 정보를 받아 신속하게 구조를 할 수 있는 Emergency
                Call 기능을 제공받을 수 있습니다. <br />
                <br />
                또한, USB 및 블루투스 통신을 통해 모바일에서 구동되는 네비게이션
                정보를 차량의 AVN 시스템과 연결하여 제공할 수도 있게 되었습니다.{" "}
                <br />
                이러한 통신 기술의 발달이 차량의 연결성(Connectivity) 증가로
                이어졌고 이는 또 하나의 사이버 공간을 만들게 되었습니다.
              </> : <>
                With the development of communication technology today, vehicle-to-vehicle communication and vehicle-to-backend
                system communication are increasing, and more information is processed and managed in the vehicle, providing
                drivers with a variety of IT services. For example, there is a function to remotely control the start of a car
                in a mobile app via Ethernet communication, and there is an Emergency Call function that can quickly rescue you by
                receiving location information in case of a traffic accident.
                <br />
                <br />
                In addition, USB and Bluetooth communication has enabled us to provide mobile-operated navigation information by
                connecting it to the vehicle's AVN system. The development of these communication technologies has led to an increase
                in vehicle connectivity, which has created another cyberspace.
              </>}
          </p>

          <div className="imgContainer">
            <div className="source">
              <div className="icon">
                <img src={IconWarning} alt="IconWarning" />
              </div>

              <p>[{languageQuarter ? "출처" : "source"}] ISO/SAE 21434 CD</p>
            </div>
            <figure>
              <div className="img">
                <img src={ImgCybersecurity02} alt="ImgCybersecurity02" />
              </div>
            </figure>
          </div>

          <p className="sectionDescription">
            {languageQuarter ?
              <>
                IT와 마찬가지로 확장된 자동차 사이버 공간에서도 악의적인 의도를 가진
                공격자가 침투하여 운전자의 개인 정보를 탈취하거나, 의도된 자동차의
                기능에 나쁜 영향을 줘서 주행 중 엔진을 갑자기 멈추도록 하거나,
                야간에 전조등을 끄는 사이버보안 공격이 가능하게 되었습니다.
              </> : <>
                Like IT, extended car cyberspace can allow malicious attackers to infiltrate and steal the driver's personal information.
                It also adversely affects the intended functionality of the car, enabling cybersecurity attacks that can cause the engine
                to suddenly stop while driving or turn off headlights at night.
              </>}
          </p>

          <div className="imgContainer">
            <figure>
              <div className="img">
                <img src={ImgCybersecurity03} alt="ImgCybersecurity03" />
              </div>
            </figure>
          </div>
        </div>
      </Styles.Container>

      <Styles.Container className="standardSection">
        <div className="inner">
          <h3>
            {languageQuarter ?
              <>
                UNECE WP.29 CSMS 규제 및 <br />
                ISO 21434 표준
              </> : <>
                UNECE WP.29 CSMS Regulation and ISO 21434 Standard
              </>}
          </h3>

          <p className="sectionDescription">
            {languageQuarter ?
              <>
                갈수록 지능적으로 심각해지는 자동차 사이버보안의 문제에 대응하기
                위해서 유엔유럽경제위원회(UNECE)에서는 자동차 사이버보안관련 법규를
                제정하게 되었고, 2022년 7월 이후 생산되는 <br className="pcOnly" />
                신규 차량은 법규에 따라 사이버보안 관리 시스템(CSMS, Cyber Security
                Management System) 인증을 받아야 UNECE협약국에 차량을 출시할 수 있게
                되었습니다.<br/>
                또한, 2024년 7월부터는 자동차 업체의 모든 생산 차량에 대한 인증이 의무화되었습니다.
                <br />
                <br />
                국제표준화기구(ISO, International Standardization
                Organization)에서는 자동차 사이버보안 엔지니어링을 위해서 ISO 21434,
                차량용 사이버보안 표준을 출시하였고 <br className="pcOnly" />
                글로벌 OEM들은 협력업체에게 이러한 표준에서 제시하는 사이버보안
                요구사항들을 필수적으로 만족하도록 요구하고 있습니다.
                <br />
                하기는 ISO 21434 표준의 보안 요구사항입니다.
              </> : <>
                In order to address the increasingly serious problems of automotive cybersecurity,
                the United Nations Economic Commission of Europe (UNECE) has enacted regulations on automotive cybersecurity,
                and new vehicles produced after July 2022 must be certified as Cyber Security Management System (CSMS) in
                accordance with the regulations to launch vehicles to the UNECE countries.<br/>
                In addition, from July 2024, certification of all manufactured vehicles by automobile companies has been mandatory.
                <br />
                <br />
                In addition, the International Standardization Organization (ISO) has released ISO 21434(automotive cybersecurity engineering),
                and global OEMs require their suppliers to meet the cybersecurity requirements set out by these standards.
                <br />
                The following are the security requirements of the ISO 21434 standard
              </>}
          </p>

          <ul>
            {standardList?.map((item, index) => {
              return (
                <li className="standardList" key={"standardList" + index}>
                  <layout.BusinessTable
                    title={`Clause ${index + 4}`}
                    description={item}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </Styles.Container>

      <Styles.Container className="processSection">
        <div className="inner">
          <h3>
            {languageQuarter ?
              <>
                ISO 21434 사이버보안 대응을 위한 <br />
                표준 프로세스 및 관리 체계 구축
              </> : <>
                Establish a process and management framework for <br />
                ISO 21434 cybersecurity standards
              </>}
          </h3>

          <p className="sectionDescription">
            {languageQuarter ?
              <>
                ISO 21434 자동차 사이버보안을 적용하기 위해서는 조직의 사이버보안
                관리, 프로젝트 별 사이버보안 관리, 분산 개발 관리, 제품 개발 관리 및
                검증, 생산, 운영, 유지보수, 사이버보안 서비스 종료 및 폐기에
                이르기까지 하기와 같이 자동차 사이버보안 라이프 사이클을 고려하여
                표준 프로세스가 구축이 되어야 합니다.
              </> : <>
                ISO 21434 standard must be established to take into account the following automotive cybersecurity life cycles:
                organization cybersecurity management, project-specific cybersecurity management, distributed development management,
                product development, verification, production, operation, maintenance, decommissioning and end of cybersecurity support.
              </>}
          </p>

          <figure>
            <div className="img">
              <img src={ImgCybersecurity04} alt="ImgCybersecurity04" />
            </div>
          </figure>

          <p className="sectionDescription">
            {languageQuarter ?
              <>
                구축된 표준 프로세스를 수행하기 위해 각 영역별로 필요한 활동들이
                무엇인지 식별하고 각 활동을 수행할 R&R 정의 및 수행에 필요한
                가이드라인 및 템플릿이 필요합니다.
              </> : <>
                The established standard process identifies what activities are needed in each area and requires guidelines
                and templates to define and implement R&R to perform each activity.
              </>}
          </p>

          <figure>
            <div className="img">
              <img src={ImgCybersecurity05} alt="ImgCybersecurity05" />
            </div>
          </figure>

          <p className="sectionDescription">
            {languageQuarter ?
              <>
                ISO 21434에서는 조직 관점의 표준 프로세스가 사이버보안을 달성하는데
                적절한지 독립적으로 판단하는 Cybersecurity Audit을 수행하도록
                요구하고 있으며, 프로젝트 관점에서 사이버보안 활동들이 계획에 의해서
                수행되고 수행된 관련 산출물들을 통해서 아이템 또는 컴포넌트의
                사이버보안을 만족하는지 Cybersecurity Assessment를 요구하고
                있습니다.
              </> : <>
                ISO 21434 requires Cybersecurity Audits to independently determine whether standard
                processes from an organizational perspective are appropriate to achieve cybersecurity,
                and Cybersecurity Assessments are required from a project perspective to ensure that
                cybersecurity activities satisfy the cybersecurity of items or components through the
                relevant outputs carried out by the plan.
              </>}
          </p>

          <div className="imgContainer">
            <div className="source">
              <div className="icon">
                <img src={IconWarning} alt="IconWarning" />
              </div>

              <p>
                [{languageQuarter ? "출처" : "source"}] ISO/SAE 21434, Cybersecurity assessment{" "}
                <br className="mbOnly" /> in relation to other{" "}
                <br className="pcOnly" />
                cybersecurity activities
              </p>
            </div>
            <figure>
              <div className="img">
                <img src={ImgCybersecurity06} alt="ImgCybersecurity06" />
              </div>
            </figure>
          </div>
        </div>
      </Styles.Container>

      <Styles.Container className="solutionSection">
        <div className="inner">
          <h3>
            {languageQuarter ?
              <>
                ISO 21434 사이버보안 <br />
                대응을 위한 솔루션 제공
              </> : <>
                C&BIS Services
              </>}
          </h3>

          <p className="sectionDescription">
            {languageQuarter ?
              <>
                저희 씨엔비스㈜에서는 ASPICE 및 ISO26262 기능안전 프로세스에 대한
                엔지니어링 컨설팅 능력을 바탕으로 자동차 사이버보안에서 화두가 되고
                있는 ISO 21434 표준의 Working Group (WG11, 사이버보안) 한국 대표로써
                표준 제정 활동을 수행하면서 빠르게 변화하고 있는 자동차 사이버보안에
                대한 첨단 기술들을 이해하고 있으며 이를 통해 다양한 컨설팅 솔루션을
                제공하고 있습니다. 제공되는 솔루션에는 ISO 21434 사이버보안 대응을
                위해서 필요한 사이버보안 통합 프로세스 및 관련 프로세스 자산 구축,
                사이버보안 전문 인력 양성 및 교육, 사이버보안 심사(Audit) 및
                평가(Assessment) 서비스 등을 제공하고 있습니다.
              </> : <>
                C&BIS Co., Ltd. has a number of engineering consulting capabilities on ASPICE and ISO26262 functional safety,
                and is carrying out standard activities as the representative of Korea of ISO 21434 standards. Based on this,
                we understand rapidly changing technologies for automotive cybersecurity and provide various consulting solutions.
                The provided solutions include integrated cybersecurity processes and related process assets for ISO 21434
                cybersecurity response, as well as training cybersecurity professionals, cybersecurity audit and assessment services.
              </>}
          </p>
        </div>
      </Styles.Container>

      <div className="listButton">
        <ui.button.BasicButton
          buttonText={languageQuarter ? "목록으로" : "List"}
          buttonType={"blue"}
          onClick={() => {
            navigate("/business/depth/service?disableScrollTop=true&focus=bis_menu_0");
          }}
        />
      </div>
    </>
  );
}

export default Cybersecurity;
