import Styles from "./styles";
import { useLanguage } from "hooks";
import { common, layout } from "components";

// img
import BgBanner from "resources/image/introduction/bg_main.jpg";
import BgBannerMb from "resources/image/introduction/bg_main_mb.jpg";
import ImgLogo01 from "resources/image/introduction/img_performance_logo01.png";
import ImgLogo02 from "resources/image/introduction/img_performance_logo02.png";
import ImgLogo03 from "resources/image/introduction/img_performance_logo03.png";
import ImgLogo04 from "resources/image/introduction/img_performance_logo04.png";
import ImgLogo05 from "resources/image/introduction/img_performance_logo05.png";
import ImgLogo06 from "resources/image/introduction/img_performance_logo06.png";
import ImgLogo07 from "resources/image/introduction/img_performance_logo07.png";
import ImgLogo08 from "resources/image/introduction/img_performance_logo08.png";
import ImgLogo09 from "resources/image/introduction/img_performance_logo09.png";
import ImgLogo10 from "resources/image/introduction/img_performance_logo10.png";
import ImgLogo11 from "resources/image/introduction/img_performance_logo11.png";
import ImgLogo12 from "resources/image/introduction/img_performance_logo12.png";
import ImgLogo13 from "resources/image/introduction/img_performance_logo13.png";
import ImgLogo14 from "resources/image/introduction/img_performance_logo14.png";
import ImgLogo15 from "resources/image/introduction/img_performance_logo15.png";

function PerformancePage() {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === ""

  const list = [
    {
      text: "Automotive SPICE",
      number: 47,
    },
    {
      text: "ISO 26262",
      number: 37,
    },
    {
      text: languageQuarter ? "Automotive SPICE / ISO 26262 통합" : "Integrate Automotive SPICE / ISO 26262",
      number: 27,
    },
    {
      text: "Cybersecurity",
      number: 6,
    },
    {
      text: "Assessment",
      number: 90,
    },
    {
      text: "Cybersecurity Assessment",
      number: 3,
    },
  ];

  const clientList = [
    {
      logo: ImgLogo01,
      text: languageQuarter ? "삼성전자" : "Samsung Electronics",
    },
    {
      logo: ImgLogo02,
      text: languageQuarter ? "삼성전기" : "Samsung Electro-Mechanics",
    },
    {
      logo: ImgLogo03,
      text: languageQuarter ? "SK하이닉스" : "SK Hynix",
    },
    {
      logo: ImgLogo04,
      text: languageQuarter ? "SK이노베이션" : "SK Innovation",
    },
    {
      logo: ImgLogo05,
      text: languageQuarter ? "현대자동차" : "Hyundai Motors",
    },
    {
      logo: ImgLogo06,
      text: languageQuarter ? "현대모비스" : "Hyundai Mobis",
    },
    {
      logo: ImgLogo07,
      text: languageQuarter ? "현대오토에버" : "Hyundai Autoever",
    },
    {
      logo: ImgLogo08,
      text: languageQuarter ? "현대트랜시스" : "Hyundai Transys",
    },
    {
      logo: ImgLogo09,
      text: languageQuarter ? "HL만도" : "HL Mando",
    },
    {
      logo: ImgLogo10,
      text: languageQuarter ? "HL클레무브" : "HL Klemove",
    },
    {
      logo: ImgLogo11,
      text: languageQuarter ? "LG전자" : "LG Electronics",
    },
    {
      logo: ImgLogo12,
      text: languageQuarter ? "LG이노텍" : "LG Innotek",
    },
    {
      logo: ImgLogo13,
      text: languageQuarter ? "SL" : "SL",
    },
    {
      logo: ImgLogo14,
      text: languageQuarter ? "덴소코리아" : "Denso Korea",
    },
    {
      logo: ImgLogo15,
      text: (
        languageQuarter ?
          <>
            KG모빌리티<br />
            (구 쌍용자동차)
          </> : <>
            KG Mobility <br />
            (formerly Ssangyong Motor)
          </>
      ),
    },
  ];

  return (
    <>
      <common.Header />
      <Styles.Container>
        <section className="bannerSection">
          <layout.MainBanner
            img={BgBanner}
            mbImg={BgBannerMb}
            colorText={languageQuarter ? "회사소개" : "About C&BIS"}
            title={languageQuarter ? "사업실적" : "Business Performance"}
          />
        </section>

        <section className="performanceSection">
          <div className="wrap">
            <div className="inner">
              <div className="textWrap">
                <h3>{languageQuarter ? "사업 실적" : "Business Performance"}</h3>
                <p>
                  {languageQuarter ?
                    <>
                      2023년 12월 기준 프로젝트 수행 실적 <br className="mbOnly" />
                      누적 합계입니다.
                    </> : <>
                      This is the cumulative total of project performance <br className="pcOnly" />
                      as of December 2023.
                    </>
                  }
                </p>
              </div>

              <ul className="listWrap">
                {list.map((item, index) => {
                  return (
                    <li className="list" key={"list" + index}>
                      <p className="text">{item.text}</p>
                      <p className="number">
                        <span>{item.number}</span>{languageQuarter ? "건" : "cs."}
                      </p>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </section>

        <section className="clientSection">
          <div className="inner">
            <div className="textWrap">
              <h3>{languageQuarter ? "주요 고객사" : "Major Client"}</h3>
              <p>
                {languageQuarter ?
                  <>
                    삼성, 현대, SK 등 대기업 고객이 전체 비중의 <br />
                    80% 이상을 차지하고 있습니다.
                  </> : <>
                    Customers of large companies such as <br />
                    Samsung, Hyundai, and SK account for <br />
                    more than 80% of the total.
                  </>
                }
              </p>
            </div>

            <ul className="listWrap">
              {clientList.map((item, index) => {
                return (
                  <li className="list" key={"list" + index}>
                    <div className="img">
                      <img src={item.logo} alt="clientLogo" />
                    </div>

                    <p className="text">{item.text}</p>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default PerformancePage;
