import connector from "service/connector"


const getCompanyFile = async () => {
  return await connector.connectFetchController("introduce/company", "GET");
}
const getStudyFile = async () => {
  return await connector.connectFetchController("introduce/study", "GET");
}


export {
  getCompanyFile,
  getStudyFile,
};

export default {
  getCompanyFile,
  getStudyFile,
}