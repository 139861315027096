import useModals from "hooks/useModals";
import { api } from "service";
import { useQuery } from "react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";

export default function useSupport({
  options = {
    policy: false,
    noticeDetail: false
  },
  noticeOption,
  noticeDetail,
  faqOption,
}) {
  const { id } = useParams();
  const [type, setType] = useState();
  const modalOption = useModals();

  const getPolicy = useQuery(["policy", type], () => {
    return api.support.getPolicy(type);
  }, {
    refetchOnWindowFocus: false, 
    enabled: !!options.policy
  });
  const getNoticeList = useQuery(["notice_list", noticeOption], () => {
    return api.support.getNoticeList(noticeOption);
  }, {
    refetchOnWindowFocus: false,
    enabled: !!noticeOption
  });
  const getNoticeDetail = useQuery(["notice_detail", id], () => {
    return api.support.getNoticeDetail(id);
  }, {
    refetchOnWindowFocus: false, 
    enabled: !!noticeDetail
  });
  const getFaqList = useQuery(["faq_list", faqOption], () => {
    return api.support.getFaqList(faqOption);
  }, {
    refetchOnWindowFocus: false, 
    enabled: !!faqOption
  });
  const postInquiry = async (data, modalClose, languageQuarter) => {
    const result = await api.support.postInquiry(JSON.stringify(data));
    if (result.success) {
      modalClose();
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.title = languageQuarter
          ? "문의가 접수되었습니다."
          : "Your inquiry has been received.";
        e.subText = languageQuarter ? (
          <>
            영업일 기준 3~5일 이내{" "}
            <br className="mbOnly" />
            메일로 답변드립니다.
          </>
        ) : (
          <>We will respond by email within 3-5 business days.</>
        );
        e.confirmText = languageQuarter ? "확인" : "Check";
        e.closeText = false;
        e.confirmClick = false;

        return { ...e };
      });
    } 
  }


  
  return {
    type, setType,

    getNoticeList,
    getNoticeDetail,
    getFaqList,
    getPolicy,
    postInquiry,
  }
}