import { api } from "service";

export default function useAuth() {

  const sendSms = async (data) => {
    return await api.sms.sendSms(JSON.stringify(data));
  };

  const checkSms = async (check) => {
    return await api.sms.checkSms(JSON.stringify(check));
  };

  return {
    sendSms,
    checkSms,
  };
}
