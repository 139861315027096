import Styles from "./styles";
import utils, { etc } from "utils";
import { api } from "service";
import { common, ui } from "components";
import { useState, useEffect } from "react";
import { useProduct, useTransaction, useKg, useToss } from "hooks";
import { useNavigate, useLocation, useParams } from "react-router-dom";

// img
import IconWarning from "resources/image/icon/icon_warning.svg";

function ApplyPage() {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const kgInfo = useKg(true);
  const tossInfo = useToss(true);
  const productInfo = useProduct({
    transactionDetailOption: {
      id: utils.etc.getSearchParam().id,
    },
  });

  const detail = productInfo?.transactionDetailQuery?.data?.data;
  const id =
    detail?.category_parent_title == "히든 교육" ? "hidden" : params.id;

  const transactionInfo = useTransaction({
    transactionList: location.state?.productList,
  });

  const [inputs, setInputs] = useState({
    koreanName: "",
    englishName: "",
    birth: null,
    koreanDepartment: "",
    englishDepartment: "",
    tel: "",
    email: "",
    judgeId: "",
  });

  const [errorText, setErrorText] = useState(false);
  const [pay, setPay] = useState(null);
  const [gender, setGender] = useState(null);
  const [privacyCheck, setPrivacyCheck] = useState(false);
  const [refund, setRefund] = useState(false);

  const dateSame =
    location.state?.transactionList[0]?.option_list[0]?.date.split("~")[0].trim() ===
    location.state?.transactionList[0]?.option_list[0]?.date.split("~")[1].trim()

  // data
  const educationInfo = [
    { title: "교육과정", text: detail?.title },
    {
      title: "교육기간",
      text: dateSame
        ? location.state?.transactionList[0]?.option_list[0]?.date.split("~")[0].trim()
        : location.state?.transactionList[0]?.option_list[0]?.date,
    },
    { title: "교육비용", text: `${etc.numberWithCommas(detail?.price)}원` },
  ];

  const [userInputList, setUserInputList] = useState({
    cnbis: [
      {
        labelText: "이름(국문)",
        value: inputs.koreanName,
        name: "koreanName",
        placeholder: "이름(국문)을 입력하세요.",
        essential: true,
      },
      {
        labelText: "이름(영문)",
        value: inputs.englishName,
        name: "englishName",
        placeholder: "이름(영문)을 입력하세요.",
      },
      {
        labelText: "생년월일",
        startDate: inputs.birth,
        setStartDate: (e) => inputValidate(userInputList[id][2], e),
        maxDate: new Date(),
        name: "birth",
        placeholder: "YYYY.MM.DD",
      },
      {
        labelText: "소속(국문)",
        value: inputs.koreanDepartment,
        name: "koreanDepartment",
        placeholder: "소속(국문)을 입력하세요.",
        essential: true,
      },
      {
        labelText: "소속(영문)",
        value: inputs.englishDepartment,
        name: "englishDepartment",
        placeholder: "소속(영문)을 입력하세요.",
      },
      {
        labelText: "연락처",
        value: inputs.tel,
        name: "tel",
        placeholder: "연락처를 입력하세요.",
        essential: true,
      },
      {
        labelText: "이메일",
        value: inputs.email,
        name: "email",
        placeholder: "이메일을 입력하세요.",
        essential: true,
      },
      {
        labelText: "심사원 ID",
        value: inputs.judgeId,
        name: "judgeId",
        placeholder: "심사원 ID를 입력하세요.",
        guide: true,
      },
      {
        labelText: "성별",
        value: inputs.gender,
        name: "gender",
      },
    ],
    intacs: [
      {
        labelText: "이름(국문)",
        value: inputs.koreanName,
        name: "koreanName",
        placeholder: "이름(국문)을 입력하세요.",
        essential: true,
      },
      {
        labelText: "이름(영문)",
        value: inputs.englishName,
        name: "englishName",
        placeholder: "이름(영문)을 입력하세요.",
        essential: true,
      },
      {
        labelText: "생년월일",
        startDate: inputs.birth,
        setStartDate: (e) => inputValidate(userInputList[id][2], e),
        maxDate: new Date(),
        name: "birth",
        placeholder: "YYYY.MM.DD",
        essential: true,
      },
      {
        labelText: "소속(국문)",
        value: inputs.koreanDepartment,
        name: "koreanDepartment",
        placeholder: "소속(국문)을 입력하세요.",
        essential: true,
      },
      {
        labelText: "소속(영문)",
        value: inputs.englishDepartment,
        name: "englishDepartment",
        placeholder: "소속(영문)을 입력하세요.",
        essential: true,
      },
      {
        labelText: "연락처",
        value: inputs.tel,
        name: "tel",
        placeholder: "연락처를 입력하세요.",
        essential: true,
      },
      {
        labelText: "이메일",
        value: inputs.email,
        name: "email",
        placeholder: "이메일을 입력하세요.",
        essential: true,
      },
      {
        labelText: "심사원 ID",
        value: inputs.judgeId,
        name: "judgeId",
        placeholder: "심사원 ID를 입력하세요.",
        guide: true,
        essential: true,
      },
      {
        labelText: "성별",
        value: inputs.gender,
        name: "gender",
      },
    ],
    hidden: [
      {
        labelText: "이름(국문)",
        value: inputs.koreanName,
        name: "koreanName",
        placeholder: "이름(국문)을 입력하세요.",
        essential: true,
      },
      {
        labelText: "이름(영문)",
        value: inputs.englishName,
        name: "englishName",
        placeholder: "이름(영문)을 입력하세요.",
      },
      {
        labelText: "생년월일",
        startDate: inputs.birth,
        setStartDate: (e) => inputValidate(userInputList[id][2], e),
        maxDate: new Date(),
        name: "birth",
        placeholder: "YYYY.MM.DD",
      },
      {
        labelText: "소속(국문)",
        value: inputs.koreanDepartment,
        name: "koreanDepartment",
        placeholder: "소속(국문)을 입력하세요.",
        essential: true,
      },
      {
        labelText: "소속(영문)",
        value: inputs.englishDepartment,
        name: "englishDepartment",
        placeholder: "소속(영문)을 입력하세요.",
      },
      {
        labelText: "연락처",
        value: inputs.tel,
        name: "tel",
        placeholder: "연락처를 입력하세요.",
        essential: true,
      },
      {
        labelText: "이메일",
        value: inputs.email,
        name: "email",
        placeholder: "이메일을 입력하세요.",
        essential: true,
      },
      {
        labelText: "심사원 ID",
        value: inputs.judgeId,
        name: "judgeId",
        placeholder: "심사원 ID를 입력하세요.",
        guide: true,
      },
      {
        labelText: "성별",
        value: inputs.gender,
        name: "gender",
      },
    ],
  });

  const payList = {
    cnbis: [
      {
        id: "CARD",
        labelText: "카드 결제",
      },
      {
        id: "VBANK",
        labelText: "세금계산서(무통장 입금)",
      },
      {
        id: "OFFLINE",
        labelText: "현장 결제",
      },
    ],
    intacs: [
      {
        id: "CARD",
        labelText: "카드 결제",
      },
      {
        id: "VBANK",
        labelText: "세금계산서(무통장 입금)",
      },
    ],
    hidden: [
      {
        id: "CARD",
        labelText: "카드 결제",
      },
    ],
  };

  const genderList = [
    {
      id: "M",
      labelText: "남성",
    },
    {
      id: "F",
      labelText: "여성",
    },
    // {
    //   id: "N",
    //   labelText: "선택안함",
    // },
  ];

  const inputValidate = (item, e) => {
    let text = e.target?.value;
    setInputs((ins) => ({ ...ins, [item.name]: text }));
  };

  const inputKeyupValidate = (item, e) => {
    let text = e.target?.value;

    // if (item.name == "birth") {
    //   text = text?.replace(/\./gi, "");
    //   text =
    //     text?.slice(0, 4) +
    //     (text?.length > 4
    //       ? "." +
    //         text?.slice(4, 6) +
    //         (text?.length > 6 ? "." + text?.slice(6, 8) : "")
    //       : "");
    // } else

    if (
      String(item.name).includes("korea") &&
      text.match(/[^ㄱ-ㅎ|ㅏ-ㅣ|가-힣|ㆍᆞᆢ\s]/)
    ) {
      text = text.replace(/[^ㄱ-ㅎ|ㅏ-ㅣ|가-힣|ㆍᆞᆢ\s]/g, "");
    }
    if (String(item.name).includes("english") && text.match(/[^A-Za-z.\s]/)) {
      text = text.replace(/[^A-Za-z.\s]/gi, "");
    }
    if (String(item.name).includes("email") && text.match(/[^A-Za-z\-.@0-9]/)) {
      text = text.replace(/[^A-Za-z\-.@0-9]/gi, "");
    }

    if (item.name == "tel") {
      text = text.replace(/[^0-9]/gi, "");
      text = text.replace(/-/gi, "");
      text =
        text?.substr(0, 3) +
        (text?.length > 3
          ? "-" +
            text?.substr(3, 4) +
            (text?.length > 7 ? "-" + text?.substr(7, 4) : "")
          : "");
    } else if (item.name == "judgeId") {
      // "intacs-KR00-0000-00000-00".
    }

    setInputs((ins) => ({ ...ins, [item.name]: text }));
  };

  const validate = () => {
    let isError = false;
    const keyObject = {};
    userInputList[id].map((value) => {
      keyObject[value.name] = value;
    });

    if (keyObject.tel && inputs.tel.length != "13") {
      keyObject.tel.errorText = "연락처를 입력하세요.";
      isError = true;
    } else keyObject.tel.errorText = "";

    if (keyObject.koreanName && inputs.koreanName.length == 0) {
      keyObject.koreanName.errorText = "이름(국문)을 입력하세요.";
      isError = true;
    } else keyObject.koreanName.errorText = "";

    if (id == "intacs") {
      if (keyObject.englishName && inputs.englishName.length == 0) {
        keyObject.englishName.errorText = "이름(영문)을 입력하세요.";
        isError = true;
      } else keyObject.englishName.errorText = "";

      if (keyObject.englishDepartment && inputs.englishDepartment.length == 0) {
        keyObject.englishDepartment.errorText = "소속(영문)을 입력하세요.";
        isError = true;
      } else keyObject.englishDepartment.errorText = "";

      if (keyObject.birth && inputs.birth === null) {
        // if (keyObject.birth && (inputs.birth.length != 10 && utils.date.getDateType(inputs.birth).length != 10)) {
        keyObject.birth.errorText = "생년월일을 입력해주세요.";
        isError = true;
      } else keyObject.birth.errorText = "";

      if (
        keyObject.judgeId &&
        inputs.judgeId != "없음" &&
        inputs.judgeId.length != "intacs-KR00-0000-00000-00".length
      ) {
        keyObject.judgeId.errorText = "심사원 ID를  입력하세요.";
        isError = true;
      } else keyObject.judgeId.errorText = "";
    }

    if (keyObject.koreanDepartment && inputs.koreanDepartment.length == 0) {
      keyObject.koreanDepartment.errorText = "소속(국문)을 입력하세요.";
      isError = true;
    } else keyObject.koreanDepartment.errorText = "";

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (keyObject.email && emailRegex.test(inputs.email) === false) {
      keyObject.email.errorText = "이메일을 입력하세요.";
      isError = true;
    } else keyObject.email.errorText = "";

    if (pay === null) {
      setErrorText((e) => {
        return { ...e, pay: "결제수단을 선택하세요." };
      });
    } else
      setErrorText((e) => {
        return { ...e, pay: "" };
      });

    if (privacyCheck === false) {
      setErrorText((e) => {
        return { ...e, privacyCheck: "개인정보 수집에 동의해주세요." };
      });
    } else
      setErrorText((e) => {
        return { ...e, privacyCheck: "" };
      });

    if (refund === false) {
      setErrorText((e) => {
        return { ...e, refund: "환불 규정에 동의해주세요." };
      });
    } else
      setErrorText((e) => {
        return { ...e, refund: "" };
      });

    setUserInputList({ ...userInputList });
    if (isError || privacyCheck == false || refund == false || pay == null) {
      if (isError) {
        window.scrollTo(0, 500);
      } else if (pay == null) {
        window.scrollTo(0, 1000);
      } else if (privacyCheck == false || refund == false) {
        window.scrollTo(0, 1300);
      } else {
        window.scrollTo(0, 500);
      }
      return false;
    }

    return true;
  };

  const onTransaction = async () => {
    if (validate() == false) return;

    let result = await api.transaction.doTransaction(
      JSON.stringify({
        gender: gender,
        productTitle: transactionInfo.transactionList[0]?.detail?.title,
        type: transactionInfo.transactionList[0]?.detail?.type || 3,
        merchant_uid: tossInfo.data?.orderNumber, // 주문번호
        method: pay,
        total_point: 0,
        total_price: transactionInfo.getTotalPrice(),
        transaction_group_list: transactionInfo.getGenerateTransactionList(),
        transaction_member_list: [],
        name: inputs.koreanName,
        name_en: inputs.englishName,
        birthday: inputs.birth ? utils.date.getDateType(inputs.birth, "-") : "",
        department: inputs.koreanDepartment,
        department_en: inputs.englishDepartment,
        phone: inputs.tel,
        email: inputs.email,
        code: inputs.judgeId,
      })
    );
    if (result.success == false) {
      return result;
    }
    if (result.success && pay != "CARD") {
      navigate("/apply/complete/" + result?.data?.id, { state: true });
      return true;
    }
    if (result.success) {
      sessionStorage.setItem(
        "check",
        result?.data?.id + result?.data?.merchant_uid
      );
      // kgInfo.doTransaction();
      tossInfo.doTransaction({
        name: inputs.koreanName ? inputs.koreanName : inputs.englishName,
        title: transactionInfo.transactionList[0]?.detail?.title,
        orderId: tossInfo.data?.orderNumber,
        price: transactionInfo.getTotalPrice(),
      });
    }
  };

  useEffect(() => {
    tossInfo.updatePrice(detail?.price);
    // kgInfo.updatePrice(detail?.price);
  }, [detail]);

  useEffect(() => {
    !location.state && navigate(-1);
  }, [location.state]);

  return (
    location.state && (
      <>
        <common.Header />
        <Styles.Container>
          <section>
            <div className="inner">
              <h2>교육 신청하기</h2>

              <form>
                <div className="educationInfo wrap">
                  <h3>교육정보</h3>

                  <ul>
                    {educationInfo.map((item, index) => {
                      return (
                        <li
                          className="educationInfoList"
                          key={"educationInfoList" + index}
                        >
                          <div className="listTitle">{item.title}</div>

                          <p>{item.text}</p>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div className="userInfo wrap">
                  <h3>신청자 정보</h3>

                  <ul className="inputListWrap">
                    <li className="inputList">
                      <ui.input.BasicInput
                        data={userInputList[id][0]}
                        value={inputs[userInputList[id][0].name]}
                        onKeyup={(e) =>
                          inputKeyupValidate(userInputList[id][0], e)
                        }
                        onChange={(e) => inputValidate(userInputList[id][0], e)}
                      />
                    </li>

                    <li className="inputList">
                      <ui.input.BasicInput
                        data={userInputList[id][1]}
                        value={inputs[userInputList[id][1].name]}
                        onKeyup={(e) =>
                          inputKeyupValidate(userInputList[id][1], e)
                        }
                        onChange={(e) => inputValidate(userInputList[id][1], e)}
                      />
                    </li>

                    <li className="inputList">
                      <ui.input.DateInput
                        data={userInputList[id][2]}
                        value={inputs[userInputList[id][2].name]}
                        onChange={(e) => {
                          inputValidate(userInputList[id][2], e);
                        }}
                        setValue={(e) => {
                          inputValidate(userInputList[id][2], {
                            target: { value: e },
                          });
                        }}
                      />
                    </li>

                    <li className="inputList">
                      <p className="label">성별</p>

                      <ul className="radioListWrap">
                        {genderList?.map((item, index) => {
                          return (
                            <li
                              className="radioList"
                              key={"genderList" + index}
                            >
                              <ui.input.BasicRadio
                                data={item}
                                name={"gender"}
                                check={gender}
                                setCheck={setGender}
                              />
                            </li>
                          );
                        })}
                      </ul>
                    </li>

                    <li className="inputList">
                      <ui.input.BasicInput
                        data={userInputList[id][3]}
                        value={inputs[userInputList[id][3].name]}
                        onKeyup={(e) =>
                          inputKeyupValidate(userInputList[id][3], e)
                        }
                        onChange={(e) => inputValidate(userInputList[id][3], e)}
                      />
                    </li>

                    <li className="inputList">
                      <ui.input.BasicInput
                        data={userInputList[id][4]}
                        value={inputs[userInputList[id][4].name]}
                        onKeyup={(e) =>
                          inputKeyupValidate(userInputList[id][4], e)
                        }
                        onChange={(e) => inputValidate(userInputList[id][4], e)}
                      />
                    </li>

                    <li className="inputList">
                      <ui.input.BasicInput
                        data={userInputList[id][5]}
                        value={inputs[userInputList[id][5].name]}
                        onKeyup={(e) =>
                          inputKeyupValidate(userInputList[id][5], e)
                        }
                        onChange={(e) => inputValidate(userInputList[id][5], e)}
                      />
                    </li>

                    <li className="inputList">
                      <ui.input.BasicInput
                        data={userInputList[id][6]}
                        value={inputs[userInputList[id][6].name]}
                        onKeyup={(e) =>
                          inputKeyupValidate(userInputList[id][6], e)
                        }
                        onChange={(e) => inputValidate(userInputList[id][6], e)}
                      />
                    </li>

                    <li className="inputList">
                      <ui.input.BasicInput
                        data={userInputList[id][7]}
                        value={inputs[userInputList[id][7].name]}
                        onKeyup={(e) =>
                          inputKeyupValidate(userInputList[id][7], e)
                        }
                        onChange={(e) => inputValidate(userInputList[id][7], e)}
                      />

                      <div className="guide">
                        <div className="icon">
                          <img src={IconWarning} alt="IconWarning" />
                        </div>

                        <ul className="guideListWrap">
                          <li className="guideList">
                            • <b>intacs-KR00-0000-00000-00</b> 형식으로 작성
                            바랍니다.
                          </li>
                          <li className="guideList">
                            • 심사원 ID가 없을 경우 ‘없음’으로 작성 바랍니다.
                            <br className="mbOnly" />{" "}
                            <span className="mbOnly"></span> (심사원 ID 보유자
                            대상 교육 신청 시 필수 작성)
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="payInfo wrap">
                  <h3>
                    결제수단 <span className="errorText">{errorText.pay}</span>
                  </h3>
                  {/* pay, privacyCheck, refund */}
                  <ul className="inputListWrap">
                    {payList[id]?.map((item, index) => {
                      return (
                        <li className="inputList" key={"payList" + index}>
                          <ui.input.BasicRadio
                            data={item}
                            name={"pay"}
                            check={pay}
                            setCheck={setPay}
                          />
                        </li>
                      );
                    })}
                  </ul>

                  {pay === "VBANK" && (
                    <div className="termsDetail">
                      <ul>
                        <li>
                          세금계산서 발행으로 결제할 경우 발행 후 당사 계좌로
                          교육비를 입금해주셔야 합니다.
                        </li>
                        <li>
                          신청 완료 후 ‘교육신청 조회’ 페이지에서 세금계산서
                          발행 정보를 작성해주셔야 결제가 완료됩니다.
                        </li>
                        <li>
                          세금계산서 발행 정보는 교육신청 마감일까지 작성
                          가능합니다.
                          <br />- 세금계산서 발행 정보 : 세금계산서 담당자 및
                          사업자 등록증
                        </li>
                      </ul>
                    </div>
                  )}
                  {pay === "OFFLINE" && (
                    <div className="termsDetail">
                      <ul>
                        <li>
                          현장 결제 No-show 발생 시 씨엔비스에서 진행하는 모든
                          교육에 대해 1년간 수강이 불가합니다.
                        </li>
                        <li>
                          신청 완료 후 교육 당일 취소 요청 시 6개월간 씨엔비스
                          전 교육 수강 불가하며, 교재 비용이 청구됩니다.
                        </li>
                      </ul>
                    </div>
                  )}
                </div>

                <div className="privacyTerms wrap">
                  <h3>
                    개인정보 수집 안내
                    <span className="errorText">{errorText.privacyCheck}</span>
                  </h3>

                  <div className="check">
                    개인정보 수집 및 이용에 동의합니다.{" "}
                    <div className="checkbox">
                      <ui.input.BasicCheckbox
                        checked={privacyCheck}
                        id={"privacyTerms"}
                        checkText={"동의"}
                        onChange={(e) => {
                          setPrivacyCheck(e.target.checked);
                        }}
                      />
                    </div>
                  </div>

                  <div className="termsDetail">
                    교육 신청을 위해 아래와 같이 개인정보를 수집・이용 합니다.
                    <br />
                    <br />
                    <ul>
                      <li>1. 개인정보 수집 목적 : 교육 신청 및 결제</li>
                      <li>
                        2. 개인정보 수집 항목 : 이름(국문), 이름(영문),
                        생년월일, 소속(국문), 소속(영문), 연락처, 이메일, 심사원
                        ID
                      </li>
                      <li>3. 보유 및 이용기간 : 수집일부터 3년까지</li>
                    </ul>
                  </div>
                </div>

                <div className="refundTerms wrap">
                  <h3>
                    환불 규정
                    <span className="errorText">{errorText.refund}</span>
                  </h3>

                  <div className="check">
                    환불 규정을 확인했습니다.
                    <div className="checkbox">
                      <ui.input.BasicCheckbox
                        checked={refund}
                        id={"refundTerms"}
                        checkText={"확인"}
                        onChange={(e) => {
                          setRefund(e.target.checked);
                        }}
                      />
                    </div>
                  </div>

                  <div className="termsDetail">
                    <ul>
                      <li>
                        C&BIS 교육과정은 교육일 기준 일주일 전까지 취소
                        가능하며, 이후 취소 시 교재 비용을 제외한 금액만
                        환불됩니다.
                      </li>
                      <li>
                        intacs™ 교육과정은 신청 마감일까지 취소 가능하며, 이후
                        취소 시 라이선스 비용(100만원)을 제외한 금액만
                        환불됩니다.
                      </li>
                      <li>
                        카드 결제의 경우, 신청 취소 시 카드사 영업일 기준 3~5일
                        이내 환불됩니다.
                      </li>
                      <li>
                        세금계산서 발행의 경우, 신청 취소 시 C&BIS 담당자 확인
                        후 환불 관련 개별 연락드립니다.
                      </li>
                      <li>
                        현장 결제의 경우, 당일 취소 및 No-show 발생 시 C&BIS에서
                        진행하는 모든 교육에 대해 6개월~1년간 수강이 불가합니다.
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="buttonWrap">
                  <div className="prevButton">
                    <ui.button.BasicButton
                      buttonText={"이전으로"}
                      buttonType={"white"}
                      onClick={() => {
                        navigate(-1);
                      }}
                    />
                  </div>

                  {/* pay 값 마다 완료 페이지가 다릅니다. */}
                  <div className="nextButton">
                    <ui.button.BasicButton
                      buttonText={"결제하기"}
                      buttonType={"blue"}
                      onClick={() => {
                        onTransaction();
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
          </section>
          <form
            name="mobileweb"
            id=""
            method="post"
            className="mt-5"
            accept-charset="euc-kr"
            style={{ display: "none" }}
          >
            <input type="text" name="P_INI_PAYMENT" value="CARD" />
            <input type="text" name="P_MID" value={kgInfo.data?.mid} />
            <input type="text" name="P_OID" value={kgInfo.data?.orderNumber} />
            <input type="text" name="P_AMT" value={kgInfo.data?.price} />
            <input type="text" name="P_GOODS" value={detail?.title} />
            <input type="text" name="P_UNAME" value={"name"} />
            <input type="text" name="P_MOBILE" value="010-0000-0000"></input>
            <input type="text" name="P_EMAIL" value={"test@test.com"} />
            <input
              type="hidden"
              name="P_NEXT_URL"
              value="https://cnbis.co.kr/api/pg/kg/mo/success"
            />
            <input type="hidden" name="P_CHARSET" value="utf8" />
            <input
              type="text"
              name="P_RESERVED"
              value="below1000=Y&vbank_receipt=Y&centerCd=Y"
            />
          </form>

          <form
            id="SendPayForm_id"
            name=""
            method="Post"
            style={{ display: "none" }}
          >
            <input type="hidden" name="version" value="1.0"></input>
            <input
              type="text"
              name="gopaymethod"
              value="Card:Directbank:vbank"
            ></input>
            <input type="text" name="mid" value={kgInfo.data?.mid}></input>
            <input
              type="text"
              name="oid"
              value={kgInfo.data?.orderNumber}
            ></input>

            <input type="text" name="price" value={kgInfo.data?.price}></input>
            <input
              type="text"
              name="timestamp"
              value={kgInfo.data?.timestamp}
            ></input>
            <input type="hidden" name="use_chkfake" value="N"></input>
            <input
              type="hidden"
              name="signature"
              value={kgInfo.data?.signature}
            ></input>
            <input
              type="hidden"
              name="verification"
              value={kgInfo.data?.verification}
            ></input>
            <input type="hidden" name="mKey" value={kgInfo.data?.mKey}></input>
            <input type="hidden" name="currency" value="WON"></input>
            <input type="text" name="goodname" value={detail?.title}></input>
            <input type="text" name="buyername" value={"test"}></input>
            {true ? (
              <input
                type="text"
                name="buyertel"
                value={"010-0000-0000"}
              ></input>
            ) : (
              <input type="text" name="buyertel" value="010-0000-0000"></input>
            )}
            <input type="text" name="byeremail" value={"test"}></input>
            <input
              type="hidden"
              name="returnUrl"
              // value="https://cnbis.co.kr/api/pg/kg/success"
              value="https://cnbis.org/api/pg/kg/success"
            ></input>
            <input
              type="hidden"
              name="closeUrl"
              // value="https://cnbis.co.kr/close"
              value="https://cnbis.org/close"
            ></input>
            <input
              type="text"
              name="acceptmethod"
              value="HPP(1):below1000:centerCd(Y)"
            ></input>
          </form>
        </Styles.Container>
        <common.Footer />
      </>
    )
  );
}

export default ApplyPage;
