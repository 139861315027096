import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;

  & .top,
  .bottom {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .top {
    height: 43px;
    font-weight: bold;
    color: #fff;
    line-height: 1.25;
    background-color: var(--blue02);
  }

  & .bottom {
    flex-grow: 1;
    min-height: 78px;
    padding: 12px 16px;
    font-weight: 500;
    text-align: center;
    line-height: 1.37;
    word-break: keep-all;
    background-color: var(--blue04);
  }

  @media (max-width: 1480px) {
    border-radius: calc(100vw * (10 / 1480));

    & .top {
      height: calc(100vw * (43 / 1480));
    }

    & .bottom {
      min-height: calc(100vw * (78 / 1480));
      padding: calc(100vw * (12 / 1480)) calc(100vw * (16 / 1480));
    }
  }

  @media (max-width: 768px) {
    border-radius: calc(100vw * (10 / 375));

    & .top {
      height: calc(100vw * (43 / 375));
    }

    & .bottom {
      min-height: calc(100vw * (64 / 375));
      padding: calc(100vw * (12 / 375)) 0;
      line-height: 1.57;
    }
  }
`;

export { Container };

export default { Container };
