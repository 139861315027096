import connector from "service/connector";

// 인증번호 전송
const sendSms = async (data) => {
  return await connector.jsonConnector("identify", "POST", data);
};

// 인증번호 확인
const checkSms = async (data) => {
  return await connector.jsonConnector("identify/check", "POST", data);
};

export { sendSms, checkSms };
export default { sendSms, checkSms };
