import Styles from "./styles";
import { useState } from "react";
import { common, ui } from "components";
import { useNavigate } from "react-router-dom";
import { useAuth, useTimer } from "hooks";

function InquiryPage() {
  const navigate = useNavigate();
  const authInfo = useAuth();
  const timer = useTimer();

  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    confirmCode: "",
  });

  const [isSend, setIsSend] = useState(false);
  const [errorText, setErrorText] = useState({ errorName: "", errorEmail: "", errorCode: "", confirmCode: "" });
  const [checkCode, setCheckCode] = useState(false);

  const inputList = [
    {
      labelText: "이름",
      value: inputs.name,
      name: "name",
      placeholder: "이름(국문)을 입력하세요.",
      essential: true,
      errorText: errorText.errorName,
    },
    {
      labelText: "이메일",
      value: inputs.email,
      name: "email",
      placeholder: "이메일을 입력하세요.",
      essential: true,
      errorText: errorText.errorEmail,
    },
    {
      labelText: "인증번호",
      value: inputs.confirmCode,
      name: "confirmCode",
      placeholder: "인증번호를 입력하세요.",
      essential: true,
      confirmText: errorText.confirmCode,
      errorText: errorText.errorCode,
    },
  ];

  const sendIdentify = async () => {
    if (inputs.email !== "") {
      authInfo.sendSms({ email: inputs.email, name: inputs.name })
      setErrorText({})
      setIsSend(true);
      timer.clickTimer();
    } else {
      setErrorText({ errorEmail: "이메일을 입력해주세요." })
    }
  };

  const identifyCode = async () => {
    setErrorText({})
    if (timer.min == "00" && timer.sec == "00") {
      setErrorText({ errorCode: "시간이 초과되었습니다. 재인증을 해주세요." })
      setCheckCode(false);
      return;
    }
    if (inputs.confirmCode === "") {
      setErrorText({ errorCode: "인증번호를 입력하세요." })
      setCheckCode(false);
      return;
    }
    let resultData = {
      code: inputs.confirmCode,
      email: inputs.email,
      name: inputs.name,
    };
    const result = await authInfo.checkSms(resultData);
    if (result.success) {
      setErrorText({ confirmCode: "인증번호가 일치합니다." })
      sessionStorage.setItem("token",result.data?.token);
      setCheckCode(true);
    } else {
      setErrorText({ errorCode: "인증번호가 일치하지 않습니다." })
      setCheckCode(false);
    }
  };

  const validate = () => {
    setErrorText({});
    const emailRegEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (inputs.name === "") {
      setErrorText({ errorName: "이름을 입력해주세요." })
      return false;
    }
    if (inputs.email === "") {
      setErrorText({ errorEmail: "이메일을 입력해주세요." })
      return false;
    }
    if (emailRegEx.test(inputs.email) === false) {
      setErrorText({ errorEmail: "이메일 형식을 확인해주세요." })
      return false;
    }
    if (isSend === false) {
      setErrorText({ errorEmail: "이메일 인증을 해주세요." })
      return false;
    }
    if (inputs.confirmCode === "") {
      setErrorText({ errorCode: "인증번호를 입력하세요." })
      return false;
    }
    if (!checkCode) {
      setErrorText({ errorCode: "이메일 인증을 진행해 주세요." })
      return false;
    }
    return true;
  }

  const onNext = () => {
    if (validate()) {
      navigate("/inquiry/result");
    }
  }

  return (
    <>
      <common.Header />
      <Styles.Container>
        <section>
          <h2>교육신청 조회</h2>

          <div className="formInner">
            <form>
              <h3>수강신청자 정보를 입력하세요.</h3>

              <ul className="inputListWrap">
                <li className="inputList">
                  <ui.input.BasicInput
                    data={inputList[0]}
                    setInputs={setInputs}
                  />
                </li>

                <li className="inputList">
                  <ui.input.BasicInput
                    data={inputList[1]}
                    setInputs={setInputs}
                  />

                  <button
                    type="button"
                    onClick={sendIdentify}
                  >
                    {isSend ? "재발송" : "인증번호 발송"}
                  </button>
                </li>

                {isSend && (
                  <li className="inputList">
                    <ui.input.BasicInput
                      data={inputList[2]}
                      setInputs={setInputs}
                    />

                    {!errorText.confirmCode &&
                      <span className="time">
                        {timer.min}:{timer.sec}
                      </span>
                    }

                    <button
                      type="button"
                      onClick={identifyCode}
                    >
                      인증확인
                    </button>
                  </li>
                )}
              </ul>

              <div className="inquiryButton">
                <ui.button.BasicButton
                  buttonText={"조회하기"}
                  buttonType={"blue"}
                  onClick={onNext}
                />
              </div>
            </form>
          </div>
        </section>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default InquiryPage;
