import styled from "styled-components";

const Container = styled.div`
  & .noticeList:not(:last-child) {
    margin-bottom: 10px;
  }

  & .pagination {
    margin-top: 80px;
  }

  & .emptyText {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 400px;
    background-color: #f6f6f6;
    border-radius: 20px;

    & .icon {
      width: 30px;
      height: 30px;
      margin-bottom: 20px;
    }

    & p {
      font-size: 18px;
      font-weight: 500;
    }
  }

  @media (max-width: 1480px) {
    & .noticeList:not(:last-child) {
      margin-bottom: calc(100vw * (10 / 1480));
    }

    & .pagination {
      margin-top: calc(100vw * (80 / 1480));
    }

    & .emptyText {
      height: calc(100vw * (400 / 1480));
      border-radius: calc(100vw * (20 / 1480));

      & .icon {
        width: calc(100vw * (30 / 1480));
        height: calc(100vw * (30 / 1480));
        margin-bottom: calc(100vw * (20 / 1480));
      }

      & p {
        font-size: calc(100vw * (18 / 1480));
      }
    }
  }

  @media (max-width: 768px) {
    & .noticeList:not(:last-child) {
      margin-bottom: calc(100vw * (8 / 375));
    }

    & .pagination {
      margin-top: calc(100vw * (50 / 375));
    }

    & .emptyText {
      height: calc(100vw * (381 / 375));
      border-radius: calc(100vw * (12 / 375));

      & .icon {
        width: calc(100vw * (24 / 375));
        height: calc(100vw * (24 / 375));
        margin-bottom: calc(100vw * (16 / 375));
      }

      & p {
        font-size: calc(100vw * (14 / 375));
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
