import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export { Container };

export default { Container };
