import Styles from "./styles";
import { layout, ui } from "components";
import { useLanguage } from "hooks";
import { useNavigate } from "react-router-dom";

// img
import IconArrowRight from "resources/image/icon/icon_arrow_right02.svg";
import ImgAssessment01 from "resources/image/business/assessment/img_assessment01.jpg";
import ImgAssessment01En from "resources/image/business/assessment/img_assessment01_en.jpg";
import ImgAssessment02 from "resources/image/business/assessment/img_assessment02.jpg";
import ImgAssessment02En from "resources/image/business/assessment/img_assessment02_en.jpg";
import ImgLogo01 from "resources/image/introduction/img_performance_logo01.png";
import ImgLogo03 from "resources/image/introduction/img_performance_logo03.png";
import ImgLogo04 from "resources/image/introduction/img_performance_logo04.png";
import ImgLogo06 from "resources/image/introduction/img_performance_logo06.png";
import ImgLogo07 from "resources/image/introduction/img_performance_logo07.png";
import ImgLogo09 from "resources/image/introduction/img_performance_logo09.png";
import ImgLogo12 from "resources/image/introduction/img_performance_logo12.png";
import ImgLogo14 from "resources/image/introduction/img_performance_logo14.png";
import ImgLogo15 from "resources/image/introduction/img_performance_logo15.png";

function Assessment() {
  const languageInfo = useLanguage()
  const languageQuarter = languageInfo.suffix === ""
  const navigate = useNavigate();

  const logoList = [
    {
      logo: ImgLogo01,
      text: languageQuarter ? "삼성전기" : "Samsung Electro-Mechanics",
    },
    {
      logo: ImgLogo03,
      text: languageQuarter ? "SK하이닉스" : "SK Hynix",
    },
    {
      logo: ImgLogo04,
      text: languageQuarter ? "SK이노베이션" : "SK Innovation",
    },
    {
      logo: ImgLogo06,
      text: languageQuarter ? "현대모비스" : "Hyundai Mobis",
    },
    {
      logo: ImgLogo07,
      text: languageQuarter ? "현대오토에버" : "Hyundai Autoever",
    },
    {
      logo: ImgLogo12,
      text: languageQuarter ? "LG이노텍" : "LG Innotek",
    },
    {
      logo: ImgLogo15,
      text: (languageQuarter ?
        <>
          KG모빌리티 <br />
          (구 쌍용자동차)
        </> : <>
          KG Mobility <br />
          (formerly Ssangyong Motor)
        </>
      ),
    },
    {
      logo: ImgLogo14,
      text: languageQuarter ? "덴소코리아" : "Denso Korea",
    },
    {
      logo: ImgLogo09,
      text: languageQuarter ? "HL만도" : "HL Mando",
    },
  ];

  return (
    <>
      <Styles.Container className="titleSection">
        <div className="inner">
          <layout.DescriptionBox title={"Assessment"} />
        </div>
      </Styles.Container>

      <div id="focus"></div>

      <Styles.Container className="subjectSection">
        <div className="inner">
          <h3>{languageQuarter ? "심사 대상" : "Subject to assessment"}</h3>
          <p className="sectionDescription">
            {languageQuarter ?
              <>
                Automotive SPICE / Cybersecurity / Functional{" "}
                <br className="mbOnly" />
                Safety의 심사를 수행할 수 있으며, 각 심사에 대하여 심사
                <br className="mbOnly" />
                주체가 달라집니다.
              </> : <>
                Automotive SPICE/Cybersecurity/Functional Safety can be reviewed, and the subject of assessment varies for each assessment.
              </>}
          </p>

          <div className="wrap">
            <ul>
              <li>Automotive SPICE</li>
              <li>Cybersecurity</li>
              <li>Functional Safety</li>
            </ul>

            <div className="moreButton">
              <ui.button.BasicButton
                buttonText={languageQuarter ? "자세히 보기" : "Read more"}
                buttonType={"white"}
                icon={IconArrowRight}
                onClick={() => {
                  navigate("detail?disableScrollTop=true&focus=focus");
                }}
              />
            </div>
          </div>

          <p className="sectionDescription">
            {languageQuarter ?
              <>
                씨엔비스는 intacs™️의 공인심사원과 UTAC의 공식심사원을 보유하고
                있으며, Automotive SPICE / Cybersecurity / Functional Safety
                심사수행에 필요한 리소스를 제공해
                <br className="mbOnly" />
                드립니다.
              </> : <>
                C&BIS has an accredited assessor from intacsTM and an official assessor from UTAC,
                and provides the resources needed to conduct the Automotive SPICE/Cybersecurity/Functional Safety assessment.
              </>}
          </p>
        </div>
      </Styles.Container>

      <Styles.Container className="procedureSection">
        <div className="inner">
          <h3>{languageQuarter ? "Automotive SPICE® 심사절차" : "Automotive SPICE® assessment Procedure"}</h3>

          <p className="sectionDescription">
            {languageQuarter ?
              <>
                Automotive SPICE® 심사 절차는 크게 계획 단계, 실행 단계, 보고 단계로
                구성되며, 심사팀(Assessment Team)은 일반적으로 계획에서 보고까지 총
                8개의 심사 활동을 수행하게 됩니다.
              </> : <>
                The Automotive SPICE® assessment process consists largely of planning,
                execution and reporting stages, and the Ascension Team typically performs
                a total of eight screening activities from planning to reporting.
              </>}
          </p>

          <figure>
            <div className="img">
              <img src={languageQuarter ? ImgAssessment01 : ImgAssessment01En} alt="ImgAssessment01" />
            </div>
          </figure>
        </div>
      </Styles.Container>

      <Styles.Container className="holdingsSection">
        <div className="inner">
          <h3>{languageQuarter ? "심사원 보유 현황" : "Auditors' holding status"}</h3>

          <p className="sectionDescription">
            {languageQuarter ?
              <>
                씨엔비스㈜는 intacs™에서 인정받은 다수의 A-SPICE 심사원과 국내 최고
                수준의 안전성, 신뢰성 기술을 전문으로 하는 검증된 석·박사급 전문
                인력을 보유하고 있습니다.
              </> : <>
                C&BIS has a number of A-SPICE assessor recognized by intacs™ and proven master's
                and doctoral-level professionals specializing in the nation's highest level of safety and reliability technology.
              </>}
          </p>

          <figure>
            <div className="img">
              <img src={languageQuarter ? ImgAssessment02 : ImgAssessment02En} alt="ImgAssessment02" />
            </div>
          </figure>
        </div>
      </Styles.Container>

      <Styles.Container className="historySection">
        <div className="inner">
          <h3>{languageQuarter ? "심사 수행 이력" : "Assement performance history"}</h3>

          <div className="wrap">
            <ul className="historyWrap">
              <li>
                <div className="title">01</div>
                <div className="text">{"ASPICE Assessment " + (languageQuarter ? "90회" : "90 times")}</div>
              </li>

              <li>
                <div className="title">02</div>
                <div className="text">{"ASPICE Cybersecurity " + (languageQuarter ? "3회" : "3 times")}</div>
              </li>
            </ul>

            <ul className="logoWrap">
              {logoList.map((item, index) => {
                return (
                  <li className="list" key={"list" + index}>
                    <div className="img">
                      <img src={item.logo} alt="clientLogo" />
                    </div>

                    <p className="text">{item.text}</p>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </Styles.Container>

      <div className="listButton">
        <ui.button.BasicButton
          buttonText={languageQuarter ? "목록으로" : "List"}
          buttonType={"blue"}
          onClick={() => {
            navigate("/business/depth/assessment?disableScrollTop=true&focus=bis_menu_1");
          }}
        />
      </div>
    </>
  );
}

export default Assessment;
