import styled from "styled-components";

const Container = styled.main`
  padding: 180px 0 200px;

  & h2 {
    margin-bottom: 80px;
    font-size: 62px;
    font-weight: 800;
  }

  & h3 {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    padding-bottom: 30px;
    font-size: 28px;
    font-weight: bold;
    border-bottom: 1px solid var(--gray02);

    & .errorText {
      margin-left: 20px;
      font-size: 18px;
      font-weight: 500;
      color: var(--red);
    }
  }

  & .wrap {
    margin-bottom: 80px;
  }

  & .check {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 500;
  }

  & .termsDetail {
    padding: 20px;
    color: var(--gray01);
    line-height: 1.62;
    background-color: #fafafa;
  }

  & .educationInfo {
    & .educationInfoList {
      display: flex;
      gap: 60px;
      font-size: 20px;

      &:not(:last-child) {
        margin-bottom: 30px;
      }

      & .listTitle {
        flex-shrink: 0;
        font-weight: bold;
        color: var(--blue02);
      }

      & p {
        font-weight: 500;
      }
    }
  }

  & .userInfo {
    & .inputListWrap {
      display: flex;
      flex-wrap: wrap;
      gap: 30px 40px;

      & .inputList {
        position: relative;
        width: 640px;

        &:last-child {
          margin-right: calc(100% - 640px);
        }

        & .label {
          margin-bottom: 41px;
          font-size: 20px;
          font-weight: bold;
        }

        & .radioListWrap {
          display: flex;
          gap: 30px;
        }

        & .guide {
          position: absolute;
          bottom: 18px;
          right: 20px;

          & .icon {
            width: 24px;
            height: 24px;
            cursor: pointer;
          }

          & .guideListWrap {
            position: absolute;
            right: 0;
            bottom: calc(100% + 4px);
            display: none;
            width: max-content;
            padding: 15px;
            font-size: 14px;
            font-weight: 500;
            background-color: var(--blue04);
            border-radius: 4px;

            & .guideList:not(:last-child) {
              margin-bottom: 9px;
            }
          }

          &:hover .guideListWrap {
            display: block;
          }
        }
      }
    }
  }

  & .payInfo {
    & .inputListWrap {
      display: flex;
      gap: 30px;
      margin-bottom: 20px;
    }
    & .termsDetail li {
      position: relative;
      padding-left: 10px;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
      }
    }
  }

  & .refundTerms {
    & li {
      position: relative;
      padding-left: 10px;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
      }
    }
  }

  & .buttonWrap {
    display: flex;
    justify-content: center;
    gap: 30px;

    & .prevButton,
    .nextButton {
      width: 260px;
    }
  }

  @media (max-width: 1480px) {
    padding: calc(100vw * (180 / 1480)) 0 calc(100vw * (200 / 1480));

    & h2 {
      margin-bottom: calc(100vw * (80 / 1480));
      font-size: calc(100vw * (62 / 1480));
    }

    & h3 {
      margin-bottom: calc(100vw * (30 / 1480));
      padding-bottom: calc(100vw * (30 / 1480));
      font-size: calc(100vw * (28 / 1480));

      & .errorText {
        margin-left: calc(100vw * (20 / 1480));
        font-size: calc(100vw * (18 / 1480));
      }
    }

    & .wrap {
      margin-bottom: calc(100vw * (80 / 1480));
    }

    & .check {
      gap: calc(100vw * (10 / 1480));
      margin-bottom: calc(100vw * (20 / 1480));
      font-size: calc(100vw * (18 / 1480));
    }

    & .termsDetail {
      padding: calc(100vw * (20 / 1480));
    }

    & .educationInfo {
      & .educationInfoList {
        gap: calc(100vw * (60 / 1480));
        font-size: calc(100vw * (20 / 1480));

        &:not(:last-child) {
          margin-bottom: calc(100vw * (30 / 1480));
        }
      }
    }

    & .userInfo {
      & .inputListWrap {
        gap: calc(100vw * (30 / 1480)) calc(100vw * (40 / 1480));

        & .inputList {
          width: calc(100vw * (640 / 1480));

          &:last-child {
            margin-right: calc(100% - calc(100vw * (640 / 1480)));
          }

          & .label {
            margin-bottom: calc(100vw * (41 / 1480));
            font-size: calc(100vw * (20 / 1480));
          }

          & .radioListWrap {
            gap: calc(100vw * (30 / 1480));
          }

          & .guide {
            bottom: calc(100vw * (18 / 1480));
            right: calc(100vw * (20 / 1480));

            & .icon {
              width: calc(100vw * (24 / 1480));
              height: calc(100vw * (24 / 1480));
            }

            & .guideListWrap {
              bottom: calc(100% + calc(100vw * (4 / 1480)));
              padding: calc(100vw * (15 / 1480));
              font-size: calc(100vw * (14 / 1480));
              border-radius: calc(100vw * (4 / 1480));

              & .guideList:not(:last-child) {
                margin-bottom: calc(100vw * (9 / 1480));
              }
            }
          }
        }
      }
    }

    & .payInfo {
      & .inputListWrap {
        gap: calc(100vw * (30 / 1480));
      }
    }

    & .refundTerms {
      & li {
        padding-left: calc(100vw * (10 / 1480));
      }
    }

    & .buttonWrap {
      gap: calc(100vw * (30 / 1480));

      & .prevButton,
      .nextButton {
        width: calc(100vw * (260 / 1480));
      }
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (116 / 375)) 0 calc(100vw * (130 / 375));

    & h2 {
      margin-bottom: calc(100vw * (50 / 375));
      font-size: calc(100vw * (32 / 375));
    }

    & h3 {
      margin-bottom: calc(100vw * (20 / 375));
      padding-bottom: calc(100vw * (20 / 375));
      font-size: calc(100vw * (20 / 375));

      & .errorText {
        margin-left: calc(100vw * (14 / 375));
        font-size: calc(100vw * (12 / 375));
      }
    }

    & .wrap {
      margin-bottom: calc(100vw * (50 / 375));
    }

    & .check {
      gap: calc(100vw * (6 / 375));
      margin-bottom: calc(100vw * (14 / 375));
      font-size: calc(100vw * (14 / 375));
    }

    & .termsDetail {
      padding: calc(100vw * (14 / 375));
      word-break: keep-all;
    }

    & .educationInfo {
      & .educationInfoList {
        gap: calc(100vw * (30 / 375));
        font-size: calc(100vw * (14 / 375));

        &:not(:last-child) {
          margin-bottom: calc(100vw * (20 / 375));
        }
      }
    }

    & .userInfo {
      & .inputListWrap {
        gap: calc(100vw * (14 / 375));

        & .inputList {
          width: 100%;

          &:last-child {
            margin-right: 0;
          }

          & .label {
            margin-bottom: calc(100vw * (25 / 375));
            font-size: calc(100vw * (14 / 375));
          }

          & .radioListWrap {
            gap: calc(100vw * (14 / 375));
            margin-bottom: calc(100vw * (10 / 375));
          }

          & .guide {
            bottom: calc(100vw * (11 / 375));
            right: calc(100vw * (14 / 375));

            & .icon {
              width: calc(100vw * (18 / 375));
              height: calc(100vw * (18 / 375));
            }

            & .guideListWrap {
              bottom: calc(100% + calc(100vw * (2 / 375)));
              padding: calc(100vw * (10 / 375));
              font-size: calc(100vw * (12 / 375));
              border-radius: calc(100vw * (4 / 375));

              & .guideList:not(:last-child) {
                margin-bottom: calc(100vw * (6 / 375));
              }

              & span.mbOnly {
                display: inline-block;
                margin-right: calc(100vw * (3 / 375));
              }
            }
          }
        }
      }
    }

    & .payInfo {
      & .inputListWrap {
        flex-direction: column;
        gap: calc(100vw * (14 / 375));
      }
    }

    & .refundTerms {
      & li {
        padding-left: calc(100vw * (10 / 375));
      }
    }

    & .buttonWrap {
      flex-direction: column;
      align-items: center;
      gap: calc(100vw * (15 / 375));

      & .prevButton,
      .nextButton {
        width: calc(100vw * (180 / 375));
      }
    }
  }
`;

export { Container };

export default { Container };
