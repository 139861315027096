import connector from "service/connector"


const postSeminar = async (data) => {
  return await connector.jsonConnector("seminar", "POST", data);
}


export {
  postSeminar,
};

export default {
  postSeminar,
}