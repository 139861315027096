import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 11;

  & .modalInner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 44px 16px;
    background-color: var(--blue02);

    & .img {
      width: 484px;
      height: 601px;
    }

    & .closeButton,
    .todayCloseButton {
      position: absolute;
      right: 10px;
    }

    & .closeButton {
      top: 10px;
      width: 24px;
      height: 24px;
    }

    & .todayCloseButton {
      bottom: 10px;
      gap: 8px;
      display: flex;
      align-items: center;
      font-weight: 500;
      color: #fff;

      & .icon {
        display: inline-block;
        width: 24px;
        height: 24px;
      }
    }
  }

  & .overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(34, 34, 34, 0.7);
  }

  @media (max-width: 1480px) {
    & .modalInner {
      padding: calc(100vw * (44 / 1480)) calc(100vw * (16 / 1480));

      & .img {
        width: calc(100vw * (484 / 1480));
        height: calc(100vw * (601 / 1480));
      }

      & .closeButton,
      .todayCloseButton {
        right: calc(100vw * (10 / 1480));
      }

      & .closeButton {
        top: calc(100vw * (10 / 1480));
        width: calc(100vw * (24 / 1480));
        height: calc(100vw * (24 / 1480));
      }

      & .todayCloseButton {
        bottom: calc(100vw * (10 / 1480));
        gap: calc(100vw * (8 / 1480));

        & .icon {
          width: calc(100vw * (24 / 1480));
          height: calc(100vw * (24 / 1480));
        }
      }
    }
  }

  @media (max-width: 768px) {
    & .modalInner {
      padding: calc(100vw * (30 / 375)) calc(100vw * (12 / 375));

      & .img {
        width: calc(100vw * (296 / 375));
        height: calc(100vw * (367 / 375));
      }

      & .closeButton,
      .todayCloseButton {
        right: calc(100vw * (10 / 375));
      }

      & .closeButton {
        top: calc(100vw * (8 / 375));
        width: calc(100vw * (14 / 375));
        height: calc(100vw * (14 / 375));
      }

      & .todayCloseButton {
        bottom: calc(100vw * (8 / 375));
        gap: calc(100vw * (8 / 375));

        & .icon {
          width: calc(100vw * (14 / 375));
          height: calc(100vw * (14 / 375));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
