import styled from "styled-components";

// img
import BgHistory from "resources/image/introduction/bg_history.png";
import BgHistoryMb from "resources/image/introduction/bg_history_mb.png";
import ImgCar from "resources/image/introduction/img_car.png";

const Container = styled.main`
  min-height: 100vh;
  min-height: 100dvh;
  padding-bottom: 200px;
  background: url(${BgHistory}) no-repeat center bottom / 100% 520px;

  & section:not(:last-child) {
    margin-bottom: 80px;
  }

  & .historySection {
    & .inner {
      position: relative;
      display: flex;
      justify-content: space-between;
    }

    & .yearSliderContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 44px;

      & button {
        width: 50px;
        height: 50px;
      }

      & .yearSlider {
        width: 154px;
        height: 250px;
      }

      & .swiper-slide {
        height: 88px;
        padding: 6px 0 20px;
        font-size: 52px;
        font-weight: bold;
        color: var(--gray02);
        text-align: center;
        cursor: pointer;

        &.swiper-slide-active {
          padding: 0 0 14px;
          font-size: 62px;
          color: var(--blue02);
        }
      }
    }

    & .progressBar {
      position: relative;
      width: 3px;
      height: 650px;
      background-color: var(--blue02);

      & .guage {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        width: 100%;
        height: 0;
        background-color: var(--gray02);
        transition: 0.5s;

        & .car {
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 75px;
          height: 124px;
          background: url(${ImgCar}) no-repeat center / cover;
          transition: 0.5s;
          content: "";
        }
      }
    }

    & .historySlider {
      width: 842px;
      height: 650px;
      font-size: 20px;
      font-weight: 500;

      & .img {
        height: 291px;
        margin-bottom: 80px;
        border-radius: 20px;
        overflow: hidden;
      }

      & .text {
        white-space: pre-wrap;
      }

      & .swiper-slide:not(.swiper-slide-active) {
        opacity: 0 !important;
      }
    }
  }

  @media (max-width: 1480px) {
    padding-bottom: calc(100vw * (200 / 1480));
    background: url(${BgHistory}) no-repeat center bottom / 100%
      calc(100vw * (520 / 1480));

    & section:not(:last-child) {
      margin-bottom: calc(100vw * (80 / 1480));
    }

    & .historySection {
      & .yearSliderContainer {
        gap: calc(100vw * (44 / 1480));

        & button {
          width: calc(100vw * (50 / 1480));
          height: calc(100vw * (50 / 1480));
        }

        & .yearSlider {
          width: calc(100vw * (154 / 1480));
          height: calc(100vw * (250 / 1480));
        }

        & .swiper-slide {
          height: calc(100vw * (88 / 1480));
          padding: calc(100vw * (6 / 1480)) 0 calc(100vw * (20 / 1480));
          font-size: calc(100vw * (52 / 1480));

          &.swiper-slide-active {
            padding: 0 0 calc(100vw * (14 / 1480));
            font-size: calc(100vw * (62 / 1480));
          }
        }
      }

      & .progressBar {
        width: calc(100vw * (3 / 1480));
        height: calc(100vw * (650 / 1480));

        & .guage {
          & .car {
            width: calc(100vw * (75 / 1480));
            height: calc(100vw * (124 / 1480));
          }
        }
      }

      & .historySlider {
        width: calc(100vw * (842 / 1480));
        height: calc(100vw * (650 / 1480));
        font-size: calc(100vw * (20 / 1480));

        & .img {
          height: calc(100vw * (291 / 1480));
          margin-bottom: calc(100vw * (80 / 1480));
          border-radius: calc(100vw * (20 / 1480));
        }
      }
    }
  }

  @media (max-width: 768px) {
    min-height: calc(100vh - calc(100vw * (309 / 375)));
    min-height: calc(100dvh - calc(100vw * (309 / 375)));
    padding: calc(100vw * (66 / 375)) 0 calc(100vw * (130 / 375));
    background: url(${BgHistoryMb}) no-repeat center bottom / 100%
      calc(100vw * (195 / 375));

    & section:not(:last-child) {
      margin-bottom: calc(100vw * (50 / 375));
    }

    & .historySection {
      & .inner {
        flex-wrap: wrap;
        justify-content: center;
        gap: calc(100vw * (50 / 375)) calc(100vw * (115 / 375));
      }

      & .yearSliderContainer {
        gap: calc(100vw * (24 / 375));

        & button {
          width: calc(100vw * (30 / 375));
          height: calc(100vw * (30 / 375));
        }

        & .yearSlider {
          width: calc(100vw * (80 / 375));
          height: calc(100vw * (130 / 375));
        }

        & .swiper-slide {
          height: calc(100vw * (46 / 375));
          padding: calc(100vw * (6 / 375)) 0 calc(100vw * (14 / 375));
          font-size: calc(100vw * (22 / 375));

          &.swiper-slide-active {
            padding: 0 0 calc(100vw * (8 / 375));
            font-size: calc(100vw * (32 / 375));
          }
        }
      }

      & .progressBar {
        width: calc(100vw * (3 / 375));
        height: calc(100vw * (442 / 375));

        & .guage {
          & .car {
            width: calc(100vw * (54 / 375));
            height: calc(100vw * (89 / 375));
          }
        }
      }

      & .historySliderContainer {
        width: 100%;

        & .historySlider {
          width: 100%;
          height: auto;
          font-size: calc(100vw * (14 / 375));

          & .img {
            height: calc(100vw * (115 / 375));
            margin-bottom: calc(100vw * (30 / 375));
            border-radius: calc(100vw * (10 / 375));
          }
        }
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
