import Styles from "./styles";
import ScrollContainer from "react-indiana-drag-scroll";
import { layout, ui } from "components";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "hooks";
import { useMediaQuery } from "react-responsive";

// img
import ImgSpice01 from "resources/image/business/service/img_spice01.jpg";
import ImgSpice01En from "resources/image/business/service/img_spice01_en.jpg";
import ImgSpice02 from "resources/image/business/service/img_spice02.jpg";
import ImgSpice02En from "resources/image/business/service/img_spice02_en.jpg";
import ImgSpice03 from "resources/image/business/service/img_spice03.jpg";
import ImgSpice03En from "resources/image/business/service/img_spice03_en.jpg";
import ImgSpice03Mb from "resources/image/business/service/img_spice03_mb.jpg";
import ImgSpice03MbEn from "resources/image/business/service/img_spice03_mb_en.jpg";
import ImgSpice04 from "resources/image/business/service/img_spice04.jpg";
import ImgSpice04En from "resources/image/business/service/img_spice04_en.jpg";
import ImgSpice04Mb from "resources/image/business/service/img_spice04_mb.jpg";
import ImgSpice04MbEn from "resources/image/business/service/img_spice04_mb_en.jpg";
import ImgSpice05 from "resources/image/business/service/img_spice05.jpg";
import ImgSpice05En from "resources/image/business/service/img_spice05_en.jpg";
import ImgSpice05Mb from "resources/image/business/service/img_spice05_mb.jpg";
import ImgSpice05MbEn from "resources/image/business/service/img_spice05_mb_en.jpg";
import ImgSpice06 from "resources/image/business/service/img_spice06.jpg";
import ImgSpice06En from "resources/image/business/service/img_spice06_en.jpg";
import ImgSpice07 from "resources/image/business/service/img_spice07.jpg";
import ImgSpice07En from "resources/image/business/service/img_spice07_en.jpg";

function Spice() {
  const languageInfo = useLanguage()
  const languageQuarter = languageInfo.suffix === ""
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });

  const navigate = useNavigate();


  const referenceList = [
    {
      color: languageQuarter ? "주요" : "Primary",
      title: languageQuarter ? "수명주기 프로세스" : "life cycle processes category",
      text: languageQuarter
        ? "주요 수명주기 프로세스 범주는 공급업체로부터 제품을 납품 받을 때 고객에 적용될 수 있는 프로세스 또는 이해관계자의 요구에 응답하고 산출물을 전달할 때 제품 개발에 적용될 수 있는 프로세스로 구성되며, 명세, 설계, 구현, 통합 및 검증에 필요한 공학 프로세스를 포함합니다."
        : "The primary life cycle processes category consists of processes that may apply for an acquirer of products from a supplier or may apply for product development when responding to stakeholder needs and delivering products including the engineering processes needed for specification, design, implementation, integration and verification.",
    },
    {
      color: languageQuarter ? "지원" : "Supporting",
      title: languageQuarter ? "수명주기 프로세스" : "life cycle Processes category",
      text: languageQuarter
        ? "지원 수명주기 프로세스의 범주는 수명주기의 여러 시점에서 다른 프로세스에 의해 사용될 수 있는 프로세스를 포함합니다."
        : "The supporting life cycle processes category consists of processes that may be employed by any of the other processes at various points in the life cycle.",
    },
    {
      color: languageQuarter ? "조직" : "Organizational",
      title: languageQuarter ? "수명주기 프로세스" : "life cycle Processes category",
      text: languageQuarter
        ? "조직 수명주기 프로세스 범주는 프로세스, 제품, 자원 자산을 개발하는 프로세스로 구성되며, 이러한 프로세스는 조직 내 여러 프로젝트에서 사용될 때 조직이 비즈니스 목표를 달성하는 데 도움이 될 수 있습니다."
        : "The organizational life cycle processes category consists of processes that develop process, product, and resource assets which, when used by projects in the organization, may help the organization achieve its business goals.",
    },
  ];

  return (
    <>
      <Styles.Container className="titleSection">
        <div className="inner" id="focus">
          <layout.DescriptionBox
            title={"Automotive SPICE"}
            left={languageQuarter ?
              <>
                ASPICE 프로세스 <br />
                평가 모델이란
              </> : <>
                Automotive Software Process Improvement and Capability dEtermination
              </>
            }
            right={languageQuarter ?
              <>
                Automotive Software Process Improvement and Capability
                dEtermination <br className="pcOnly" />
                프로세스 심사는 프로세스 평가 모델에 기반한 규범화된 평가입니다.
                <br />
                ASPICE 프로세스 평가 모델은 임베디드 자동차 시스템의 개발에 대한
                프로세스의 능력의 적합성을 평가할 때 사용할 수 있도록
                개발되었습니다.
                <br />
                ASPICE 평가 모델은 ISO/IEC 33004의 요구사항에 따라
                개발되었습니다.
              </> : <>
                Automotive Software Process Improvement and Capability dEtermination
                Process assessment is a disciplined evaluation based on a process assessment model.
                The ASPICE process assessment model (PAM) is developed for use when performing conformant assessments of the process capability
                on the development of embedded automotive systems. It was developed in accordance with the requirements of ISO/IEC 33004.
              </>
            }
          />
        </div>
      </Styles.Container>

      <Styles.Container className="abilitySection">
        <div className="inner">
          <h3>{languageQuarter ? "프로세스 결정 능력" : "Process capability determination"}</h3>

          <div className="wrap">
            <div className="textContainer">
              <p className="sectionDescription">
                {languageQuarter ?
                  <>
                    프로세스 평가 모델을 사용한 프로세스 능력 결정의 개념은 2차원
                    프레임워크를 기반으로 합니다.
                  </> : <>
                    The concept of process capability determination by using a process assessment model is based on a two-dimensional framework.
                  </>
                }
              </p>

              <div className="tableWrap">
                <div className="table">
                  <layout.BusinessTable
                    title={languageQuarter ? "프로세스 차원 (process dimension)" : "Process dimension"}
                    description={
                      languageQuarter ? "프로세스 참조 모델에 정의된 프로세스에 의해 정의" : "Provided by processes defined in a process reference model"
                    }
                  />
                </div>

                <div className="table">
                  <layout.BusinessTable
                    title={languageQuarter ? "능력 차원 (capability dimension)" : "Capability dimension"}
                    description={languageQuarter ?
                      <>
                        프로세스 속성(process attribute)으로{" "}
                        <br className="mbOnly" />
                        세분된 능력 수준으로 정의
                      </> : <>
                        Consists of capability levels that are further subdivided into process attributes
                      </>
                    }
                  />
                </div>
              </div>

              <p className="sectionDescription">
                {languageQuarter ?
                  <>
                    프로세스 평가 모델과 측정 프레임워크, 프로세스 참조 모델과의
                    관계는 그림 1과 같습니다.
                  </> : <>
                    The relationship among the process assessment model, the measurement framework
                    and the process reference model is shown in Figure 1.
                  </>

                }
              </p>
            </div>

            <div className="imgContainer">
              <figure>
                <figcaption>
                  {languageQuarter ?
                    <>
                      그림 1. 프로세스 평가 모델 관계
                    </> : <>
                      Figure 1. Process Evaluation Model Relationships
                    </>
                  }
                </figcaption>

                <div className="img">
                  <img
                    src={languageQuarter ? ImgSpice01 : ImgSpice01En}
                    alt="ImgSpice01" />
                </div>
              </figure>
            </div>
          </div>
        </div>
      </Styles.Container>

      <Styles.Container className="referenceSection">
        <div className="inner">
          <h3>{languageQuarter ? "프로세스 참조 모델" : "Process Reference Model"}</h3>

          <div className="wrap">
            <div className="textContainer">
              <p className="sectionDescription">
                {languageQuarter ?
                  <>
                    프로세스는 프로세스가 다루는 활동 영역에 따라 3개의 프로세스
                    범주로 구분됩니다.
                  </> : <>
                    Processes are organized into 3 process categories based on the domain of activities they address.
                  </>
                }
              </p>

              <ul>
                {referenceList.map((item, index) => {
                  return (
                    <li
                      className="referenceList grayBox"
                      key={"referenceList" + index}
                    >
                      <p className="title">
                        <span>{item.color}</span> {item.title}
                      </p>

                      <p className="text">{item.text}</p>
                    </li>
                  );
                })}
              </ul>

              <p className="sectionDescription">
                {languageQuarter ?
                  <>
                    프로세스 차원에 대해 ASPICE 프로세스 참조 모델은{" "}
                    <br className="mbOnly" />
                    그림 2와 같습니다.
                  </> : <>
                    For the process dimension, the ASPICE process reference model is{" "}
                    <br className="mbOnly" />
                    shown in Figure 2.
                  </>
                }
              </p>
            </div>

            <div className="imgContainer">
              <figure>
                <figcaption>{languageQuarter ? "그림 2. ASPICE 프로세스 참조 모델" : "Figure 2. ASPICE Process Reference Model"}</figcaption>

                <div className="img">
                  <img src={languageQuarter ? ImgSpice02 : ImgSpice02En} alt="ImgSpice02" />
                </div>
              </figure>
            </div>
          </div>
        </div>
      </Styles.Container>

      <Styles.Container className="frameworkSection">
        <div className="inner">
          <h3>{languageQuarter ? "측정 프레임워크" : "Measurement frameworks"}</h3>

          <p className="sectionDescription">
            {languageQuarter ?
              <>
                측정 프레임워크는 능력 차원에 대해 필요한 요구사항과 규칙을
                제공합니다. 측정 프레임워크는 심사자가 대상 프로세스의 능력 수준을
                결정할 수 있도록 하는 틀을 정의합니다.
              </> : <>
                The measurement framework provides the necessary requirements and rules for the capability dimension.
                It defines a schema which enables an assessor to determine the capability level of a given process.
              </>}
          </p>

          <div className="grayBox">
            <p className="title">
              {languageQuarter ?
                <>
                  프로세스 <span>능력 수준</span>과 프로세스 <span>속성</span>
                </> : <>
                  Process capability levels and process attributes
                </>}
            </p>

            <p className="text">
              {languageQuarter ?
                <>
                  프로세스의 속성은 프로세스의 능력에 대한 측정 수단을 제공함으로써,
                  능력 달성에 대해 평가할 수 있는 프로세스 특징을 말합니다. 프로세스
                  능력 수준은 6개의 단계가 존재하고, 9개의 프로세스 속성으로
                  구성되어 있습니다. 그림 3은 능력 수준과 각 능력 수준별 프로세스
                  속성을 나타냅니다.
                </> : <>
                  Process attributes are features of a process that can be evaluated on a scale of achievement,
                  providing a measurement of the capability of the process.
                  The process capability level consists of nine process properties, with six stages.
                  Figure 3 shows the capability level and process attributes for each capability level.
                </>}
            </p>
          </div>

          <figure>
            <figcaption>
              {languageQuarter ?
                <>
                  그림 3. 능력 수준과 각 능력 수준별 프로세스 속성
                </> : <>
                  Figure 3. Capability level and process attributes for each capability level
                </>}
            </figcaption>

            <div className="img">
              <img
                src={
                  languageQuarter
                    ? isMobile ? ImgSpice03Mb : ImgSpice03
                    : isMobile ? ImgSpice03MbEn : ImgSpice03En
                }
                alt="ImgSpice03"
              />
            </div>
          </figure>

          <div className="grayBox">
            <p className="title">
              {languageQuarter ?
                <>
                  프로세스 속성 <span>등급 평가</span>
                </> : <>
                  Process attribute rating
                </>}
            </p>

            <div className="text">
              <p>
                {languageQuarter ?
                  <>
                    프로세스 속성의 등급 평가를 위해, 측정 프레임워크는 등급 척도와
                    평가 형태에 따른 등급 산출 방법 및 여러 집계 방법을 제공합니다.
                  </> : <>
                    For the rating of process attributes, the measurement framework provides a rating scale and a method of calculating the rating,
                    as well as several aggregation methods. Depending on the class of the assessment
                  </>}
              </p>

              <div>
                <b>
                  {languageQuarter ?
                    <>
                      1. 등급 척도
                    </> : <>
                      1. Rating scale
                    </>}
                </b>

                <ul className="listWrap">
                  <li className="list">
                    <p>
                      {languageQuarter ?
                        <>
                          a. 프로세스 속성 등급은 평가된 프로세스에 대한 프로세스
                          속성의 달성 정도에 관한 판정입니다. N, P, L, F는 순서
                          척도의 관점이며 각 등급에 대한 비율이 정의됩니다.
                        </> : <>
                          a. A process attribute rating is a judgement of the degree to which a process attribute is achieved for an assessed process.
                          N, P, L, and F are the viewpoints of an ordinal scale and a ratio is defined for each rating.
                        </>}
                    </p>

                    <ScrollContainer className="imgWrap">
                      <div className="img">
                        <img
                          src={
                            languageQuarter
                              ? isMobile ? ImgSpice04Mb : ImgSpice04
                              : isMobile ? ImgSpice04MbEn : ImgSpice04En
                          }
                          alt="ImgSpice04"
                        />
                      </div>
                    </ScrollContainer>

                    <layout.ScrollGuide />
                  </li>
                </ul>
              </div>

              <div>
                <b>
                  {languageQuarter ?
                    <>
                      2. 등급 산출 방법과 집계 방법
                    </> : <>
                      2. Rating and aggregation method
                    </>}

                </b>
                <ul className="listWrap">
                  <li className="list">
                    <p>
                      {languageQuarter ?
                        <>
                          a. 등급 산출 방법과 집계 방법은 ISO/IEC 33020:
                          <br className="mbOnly" />
                          2019에서 찾을 수 있으며, 다음과 같은 정의를 제공합니다.
                        </> : <>
                          a. Rating and aggregation methods are taken from ISO/IEC 33020:2019, which provides the following definitions.
                        </>}
                    </p>

                    <ul className="subListWrap">
                      <li className="subList">
                        {languageQuarter ?
                          <>
                            i. 프로세스 성과 : 프로세스 목적이 성공적으로
                            달성되었는지 관측할 수 있는 결과
                          </> : <>
                            i. Process outcome:  the observable result of successful achievement of the process purpose
                          </>}
                      </li>
                      <li className="subList">
                        {languageQuarter ?
                          <>
                            ii. 프로세스 속성 성과 : 해당 프로세스 속성이
                            달성되었는지 관측할 수 있는 결과
                          </> : <>
                            ii. Process attribute outcome:  the observable result of achievement of a specified process attribute
                          </>}
                      </li>
                    </ul>
                  </li>

                  <li className="list">
                    <p>
                      {languageQuarter ?
                        <>
                          b. ISO/IEC 33020:2019은 다음과 같은 3 개의 등급 산출
                          방법을 제공합니다.
                        </> : <>
                          b. ISO/IEC 33020:2019 provides the following three methods of rating calculation.
                        </>}
                    </p>

                    <ul className="subListWrap">
                      <li className="subList">
                        {languageQuarter ?
                          <>
                            i. 등급 산출 방법 R1: 인스턴스에 대한 프로세스 속성 성과
                            및 프로세스 속성 기반으로 집계 및 평가
                          </> : <>
                            i. Rating Method R1: Aggregate and evaluate based on process attribute outcomes and process attributes for instances
                          </>}
                      </li>
                      <li className="subList">
                        {languageQuarter ?
                          <>
                            ii. 등급 산출 방법 R2: 인스턴스에 대한 프로세스 속성
                            기반으로 집계 및 평가
                          </> : <>
                            ii. Rating Method R2: Aggregate and evaluate based on process attributes for instances
                          </>}
                      </li>
                      <li>
                        {languageQuarter ?
                          <>
                            iii. 등급 산출 방법 R3: 인스턴스에 대한 프로세스 속성이
                            집계 없이 평가
                          </> : <>
                            iii. Rated method R3: Evaluate the process attributes for an instance without aggregation
                          </>}
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="grayBox">
            <p className="title">
              {languageQuarter ?
                <>
                  프로세스 <span>능력 수준 모델</span>
                </> : <>
                  Process capability level model
                </>}
            </p>

            <div className="text">
              <p>
                {languageQuarter ?
                  <>
                    일반적으로 주어진 능력 수준의 달성은 해당 프로세스 속성의 대부분
                    달성과 하위 프로세스 속성 완전한 달성을 요구합니다. <br />
                    일반적으로 OEM으로부터 요구되는 ASPICE CL3에 대한 수준 평가
                    규칙은 다음과 같습니다.
                  </> : <>
                    In general, the achievement of a given level requires a largely or fully achievement of the corresponding process attributes and a full achievement of any lower lying process attribute.
                    Typically, the rules for ASPICE CL3 required by OEMs are as follows.
                  </>}
              </p>

              <ScrollContainer className="imgWrap">
                <div className="img">
                  <img
                    src={languageQuarter
                      ? isMobile ? ImgSpice05Mb : ImgSpice05
                      : isMobile ? ImgSpice05MbEn : ImgSpice05En}
                    alt="ImgSpice05"
                  />
                </div>
              </ScrollContainer>
            </div>
          </div>
        </div>
      </Styles.Container>

      <Styles.Container className="evaluationSection">
        <div className="inner">
          <h3>{languageQuarter ? "프로세스 평가 모델" : "Process assessment model"}</h3>

          <p className="sectionDescription">
            {languageQuarter ?
              <>
                프로세스 평가 모델은 프로세스 성과와 프로세스 속성 성과(달성)가
                프로젝트와 조직 단위의 인스턴스화된 프로세스에 존재하는지를 식별하기
                위해 지표를 제공합니다.
              </> : <>
                The process assessment model offers indicators in order to identify whether
                the process outcomes and the process attribute outcomes (achievements) are present or absent
                in the instantiated processesof projects and organizational units.
              </>}
          </p>

          <div className="grayBox">
            <p className="title">
              <span>{languageQuarter ? "평가 지표" : "Assessment indicators"}</span>
            </p>

            <div className="text">
              {languageQuarter ?
                <>
                  ASPICE 평가 모델에서는 ISO 33004에 정의된 지표 중 사례와 정보
                  아이템만을 사용합니다.
                </> : <>
                  The ASPICE evaluation model uses only case and information items among the indicators defined in ISO 33004.
                </>}
            </div>

            <div className="wrap">
              <div className="textContainer">
                <div className="table">
                  <layout.BusinessTable
                    title={languageQuarter ? "사례 (Practices)" : "Practices"}
                    description={
                      languageQuarter ? "프로세스 목적 또는 특정 프로세스 속성의 달성을 지원" : "support achievement of either the process purpose or the specific process attribute"
                    }
                  />
                </div>

                <div className="table">
                  <layout.BusinessTable
                    title={languageQuarter ? "정보 아이템 (information items)" : "Information items"}
                    description={languageQuarter ? "대응되는 달성을 증명" : "demonstrate the respective achievements"}
                  />
                </div>
              </div>

              <div className="imgContainer">
                <figure>
                  <figcaption>
                    {languageQuarter ?
                      <>
                        그림 4. 평가 지표 및 능력 간의 관계
                      </> : <>
                        Figure 4. Relationship between metrics and capabilities
                      </>}
                  </figcaption>

                  <div className="img">
                    <img src={languageQuarter ? ImgSpice06 : ImgSpice06En} alt="ImgSpice06" />
                  </div>
                </figure>
              </div>
            </div>
          </div>

          <div className="grayBox">
            <p className="title">
              {languageQuarter ?
                <>
                  <span>PAM의 추상적 수준 이해</span>
                </> : <>
                  <span>Understanding the Abstract Level of PAM</span>
                </>}
            </p>

            <div className="text">
              {languageQuarter ?
                <>
                  “프로세스”라는 용어는 세 가지 추상적 수준에서 이해할 수 있습니다.
                  그림 5는 프로세스 평가 모델, 방법, 실행 간의 사용할 수 있는
                  “프로세스”의 추상화 수준을 설명합니다.
                </> : <>
                  The term "process" can be understood at three abstract levels.
                  Figure 5 describes the level of abstraction of the available "process" between process assessment models, “how”, and “Doing”.
                </>}
            </div>

            <figure>
              <figcaption>
                {languageQuarter ?
                  <>
                    그림 5. "프로세스"라는 용어에 대한 가능한 추상적 수준
                  </> : <>
                    Figure 5. Possible levels of abstraction
                  </>
                }
              </figcaption>

              <div className="img">
                <img src={languageQuarter ? ImgSpice07 : ImgSpice07En} alt="ImgSpice07" />
              </div>
            </figure>
          </div>
        </div>
      </Styles.Container>

      <Styles.Container className="serviceSection">
        <div className="inner">
          {languageQuarter ?
            <>
              <h3>씨엔비스의 서비스</h3>
            </> : <>
              <h3>C&BIS Services</h3>
            </>}
          <p className="sectionDescription">
            {languageQuarter ?
              <>
                자동차 시장은 신뢰할 수 있는 기간 내에 복잡성이 증가하고 있는 제품에
                대응할 수 있는 프로세스를 요구하고 있습니다. 이에 부품 공급업체는
                고객 및 이해관계자의 요구에 부합하는 제품을 개발하여 안정적으로
                납품할 수 있는 능력을 증명해야 합니다. Automotive SPICE는 이러한
                자동차 시장의 요구에 부합할 수 있고, 공급업체의 능력을 증명할 수
                있는 주요 수단으로의 역할을 합니다.
                <br />
                <br />
                씨엔비스(주)는 ASPICE 참조/심사 모델에 대한 국제 공인 인증을 받은
                선임/수석 심사원 이상의 자격을 보유한 인력을 다수 보유하고 있으며,
                자동차 시장에서 요구하고 있는 자동차 제조사의 Automotive SPICE 요구
                수준에 부합할 수 있는 컨설팅 서비스를 제공합니다. 이를 통해 고객사는
                부품업체로부터 안정적인 부품의 납품을 기대할 수 있는 근거를
                확보하고, 부품업체는 고객사로부터 개발된 제품의 프로세스 품질을
                증명할 수 있게 됩니다. 컨설팅 서비스는 기본적으로 시스템 개발,
                하드웨어, 소프트웨어 개발을 다루며, ASPICE 확장 영역인 기계,
                사이버보안, 데이터 관리 등의 범위를 포함할 수 있습니다.
              </> : <>
                The automotive market demands processes that can respond to products of increasing complexity within a reliable time frame. Accordingly,
                parts suppliers have to demonstrate their ability to develop products that meet customer and stakeholder needs and deliver them reliably.
                Automotive SPICE can meet the needs of these automotive markets and serves as a primary tool to demonstrate the competence of vendors.
                <br />
                <br />
                C&BIS Co., Ltd. has a large number of qualified competent / principal assessors who are internationally certified for
                the ASPICE reference / assessment model and provides consulting services to meet the Automotive SPICE requirements of
                OEMs in the automotive market. This provides the customer with the basis to expect a stable delivery of parts from the parts manufacturer,
                which can demonstrate the quality of the products developed from the customer. Consulting services basically cover systems development,
                hardware, and software development, and can range from ASPICE extensions such as mechanical, cybersecurity, and data management.
              </>}
          </p>
        </div>
      </Styles.Container>

      <div className="listButton">
        <ui.button.BasicButton
          buttonText={languageQuarter ? "목록으로" : "List"}
          buttonType={"blue"}
          onClick={() => {
            navigate("/business/depth/service?disableScrollTop=true&focus=bis_menu_0");
          }}
        />
      </div>
    </>
  );
}

export default Spice;
