import Styles from "./styles";
import { common, ui, layout } from "components";
import { useLanguage, useModals } from "hooks";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";

// img
import BgBanner from "resources/image/education_center/bg_main.jpg";
import BgBannerMb from "resources/image/education_center/bg_main_mb.jpg";
import ImgPlace01 from "resources/image/education_center/img_place01.png";
import ImgPlace02 from "resources/image/education_center/img_place02.png";
import ImgPlace03 from "resources/image/education_center/img_place03.png";
import ImgPlace04 from "resources/image/education_center/img_place04.png";
import IconArrowRight from "resources/image/icon/icon_arrow_right02.svg";
import IconStep01 from "resources/image/icon/icon_step01.svg";
import IconStep02 from "resources/image/icon/icon_step02.svg";
import IconStep03 from "resources/image/icon/icon_step03.svg";
import IconStep04 from "resources/image/icon/icon_step04.svg";
import IconStep05 from "resources/image/icon/icon_step05.svg";
import IconPrev from "resources/image/icon/icon_prev.svg";
import IconNext from "resources/image/icon/icon_next.svg";
import IconPlace from "resources/image/icon/icon_place.svg";
import IconCall from "resources/image/icon/icon_call.svg";
import IconFax from "resources/image/icon/icon_fax.svg";
import IconSend from "resources/image/icon/icon_send.svg";

function EducationCenterPage() {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";

  const modalOption = useModals();

  // data
  const mainGuideList = languageQuarter
    ? [
        "교육 신청은 선착순 모집으로 진행합니다.",
        "강의 정원 초과 시 조기 마감하며, 정원 외 인원은 대기 인원으로 등록되어 결원이 발생할 경우 순차적으로 연락드립니다.",
        "강의 정원 미달 시, 교육은 취소 및 폐강될 수 있습니다. (교육 일주일 전 최종 안내)",
        "씨엔비스는 교육비 공제 대상 기관이 아니므로 당사의 모든 교육은 세금 환급이 되지 않으며, 연말정산 교육비에 반영되지 않습니다.",
        "안내된 교육 비용은 모두 부가세 별도입니다.",
        "교육비 결제는 카드/세금계산서 발행으로 진행합니다.",
        "온라인 교육은 별도 문의 바랍니다.",
      ]
    : [
        "The application for training will be made on a first-come, first-served basis.",
        "If the number of students exceeds the number of students, it will be closed early, and people other than the number of students will be registered as waiting people, and we will contact you in order if a vacancy occurs.",
        "If the number of lecture capacity is insufficient, the training may be canceled or closed. (Final guide a week before the training)",
        "Since CNBIS is not an institution subject to education expense deduction, all of our education will not be tax refundable and will not be reflected in the year-end tax adjustment training expenses.",
        "All guided training costs are subject to VAT.",
        "Education fees are paid by card/tax invoice issuance.",
        "For online education, please contact us separately.",
      ];
  const subGuideList = [
    {
      title: languageQuarter ? "C&BIS 교육과정" : "C&BIS Curriculum",
      list: languageQuarter
        ? [
            "교육비 카드 결제의 경우 홈페이지 내 결제 및 교육 당일 현장 결제 가능합니다.",
            "세금계산서 발행 시 발행일자는 교육일 이전까지 발행 가능합니다.",
            <b>
              교육 신청 취소는 교육일 기준 1주 전까지 가능하며 이후 취소할 경우{" "}
              <br className="pcOnly" />
              교재 비용이 청구
              <br className="mbOnly" />
              됩니다.
            </b>,
            <>
              교육 당일 수강 취소 요청 시 이후 6개월간 씨엔비스에서 진행하는
              모든 교육에 대해 수강 불가하며, <br className="pcOnly" />
              No-show 발생 시 이후 1년간 수강 불가합니다.
            </>,
          ]
        : [
            "In the case of payment by card for education expenses, payment on the website and on-site payment on the day of education can be made.",
            "The issuance date of tax payment, the issuance date can be issued until before the education day.",
            <b>
              The application for education can be canceled up to one week
              before the training date, and if it is canceled after that You
              will be charged for the textbook
            </b>,
            <>
              If you request cancellation of the course on the day of the
              training, you will not be able to take all the training conducted
              by C&BIS for the next six months, and if no-show occurs, you will
              not be able to take the course for one year after that.
            </>,
          ],
    },
    {
      title: languageQuarter
        ? "intacs™ 공식 교육과정"
        : "intacs™ official curriculum",
      list: languageQuarter
        ? [
            "교육비 결제는 교육 3주 전(신청 마감일)까지 비용 납부가 모두 완료되어야 합니다.",
            "교육 당일 현장결제 불가합니다.",
            <b>
              3주 미만 시점에서 교육을 취소하는 경우, 라이선스 비용(100만원)을
              제외한 <br className="pcOnly" />
              차액만 환불됩니다.
            </b>,
            <>
              시험 합격 후 등록비용은 별도이며,
              <br className="mbOnly" /> 합격자에게 개별적으로 발송되는 메일 내
              결제
              <br className="mbOnly" />
              링크를 통해 <br className="pcOnly" />
              직접 VDA에 납부해야 합니다.
            </>,
          ]
        : [
            "The payment of the training fee must be completed by three weeks before the training (application deadline).",
            "On-site payment is not possible on the day of training.",
            <b>
              If you cancel the training less than three weeks ago, only the
              difference will be refunded except for the license fee
              <br className="pcOnly" /> (KRW 1 million).
            </b>,
            <>
              After passing the exam, the registration fee is additional and
              must be paid directly to the VDA through the payment link in the
              mail sent to the successful candidate individually.
            </>,
          ],
    },
    {
      title: languageQuarter ? "인하우스 교육" : "in-house education",
      list: languageQuarter
        ? [
            <>
              모든 교육과정은 인하우스 교육이 가능하며, 고객 요청에 따른
              커스터마이징 <br className="pcOnly" />
              과정 개설도 가능합니다.
            </>,
            <>
              인하우스 교육은 Class 단위로 개설되며,
              <br className="mbOnly" /> 최대 20명으로 제한합니다. (최소 인원
              제한 없음)
            </>,
            <>
              교육 희망 일정을 전달해주시면 강사 일정 <br className="mbOnly" />
              확인 후 교육 일정을 조율합니다.
            </>,
            <>
              자세한 내용은 담당자 이메일(syjang@cnbis.co.kr) 또는 대표번호
              <br className="mbOnly" />
              (031-502-4900, 2번 교육문의)로 <br className="pcOnly" />
              문의 바랍니다.
            </>,
          ]
        : [
            <>
              All curriculums are available for in-house training, and
              customization courses can also be opened according to customer
              requests.
            </>,
            <>
              In-house training is open by class and is limited to a maximum of
              20 people.
              <br /> (No minimum number of people)
            </>,
            <>
              If you deliver the desired training schedule, check the
              instructor's schedule and coordinate the training schedule.
            </>,
            <>
              For more information, please contact the person in charge at
              syjang@cnbis.co.kr or the representative number (031-502-4900,
              please contact Training No. 2.)
            </>,
          ],
    },
  ];
  const stepList = [
    {
      icon: IconStep01,
      title: "STEP 01",
      description: languageQuarter ? (
        <>
          교육 4~5주 전 <br className="pcOnly" />
          안내문 이메일 발송
        </>
      ) : (
        <>
          E-mail notification 4-5 weeks <br className="mbOnly" />
          before training
        </>
      ),
    },
    {
      icon: IconStep02,
      title: "STEP 02",
      description: languageQuarter ? (
        <>
          홈페이지를 통해 <br className="pcOnly" />
          교육 신청
        </>
      ) : (
        <>Apply for training through the website</>
      ),
    },
    {
      icon: IconStep03,
      title: "STEP 03",
      description: languageQuarter
        ? "교육비 납부"
        : "Payment of educational expenses",
    },
    {
      icon: IconStep04,
      title: "STEP 04",
      description: languageQuarter
        ? "교육 확정 안내"
        : "A guide to the finalization of education",
    },
    {
      icon: IconStep05,
      title: "STEP 05",
      description: languageQuarter ? "교육 수강" : "Take a course in education",
    },
  ];
  const placeImgList = [ImgPlace01, ImgPlace02, ImgPlace03, ImgPlace04];
  const mapInfoList = [
    {
      icon: IconPlace,
      text: languageQuarter ? (
        <>
          경기도 성남시 수정구 창업로 43, <br />
          판교글로벌비즈센터 A동 907~910호
        </>
      ) : (
        <>
          Rooms 907-910, Building A, <br />
          Pangyo Global Biz Center, 43 <br />
          changeop-ro, Sujeong-gu, <br />
          Seongnam-si, Gyeonggi-do
        </>
      ),
    },
    {
      icon: IconCall,
      text: "031-502-4900",
    },
    {
      icon: IconFax,
      text: "031-502-4902",
    },
    {
      icon: IconSend,
      text: "syjang@cnbis.co.kr",
    },
  ];

  return (
    <>
      <common.Header />
      <Styles.Container>
        <section className="bannerSection">
          <layout.MainBanner
            img={BgBanner}
            mbImg={BgBannerMb}
            colorText={"C&BIS"}
            title={
              languageQuarter ? (
                "교육센터 소개"
              ) : (
                <>
                  Introduction <br className="mbOnly" />
                  to <br className="pcOnly" />
                  Education <br className="mbOnly" />
                  Center
                </>
              )
            }
          />
        </section>

        <section className="informationSection">
          <div className="inner">
            <h3>{languageQuarter ? "수강안내" : "Course guide"}</h3>

            <div className="mainGuide">
              <ul>
                {mainGuideList.map((item, index) => {
                  return (
                    <li className="mainGuideList" key={"mainGuideList" + index}>
                      {item}
                    </li>
                  );
                })}
                <li></li>
              </ul>
            </div>

            <div className="subGuide">
              <ul className="subGuideWrap">
                {subGuideList.map((item, index) => {
                  return (
                    <li className="subGuideList" key={"subGuideList" + index}>
                      <div className="title">{item.title}</div>

                      <ul className="listWrap">
                        {item.list.map((current, idx) => {
                          return (
                            <li className="list" key={"list" + idx}>
                              {current}
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </section>

        <section className="stepSection">
          <div className="inner">
            <h3>
              {languageQuarter
                ? "교육신청 절차"
                : "Training application procedures"}
            </h3>

            <ul className="step">
              {stepList.map((item, index) => {
                return (
                  <li className="stepList" key={"stepList" + index}>
                    <div className="circle"></div>

                    <p className="title">{item.title}</p>

                    <div className="stepIcon">
                      <img src={item.icon} alt="stepIcon" />
                    </div>

                    <p className="description">{item.description}</p>

                    {index === 0 && (
                      <div className="registerButton">
                        <ui.button.BasicButton
                          buttonText={
                            languageQuarter ? (
                              "교육 안내문 수신자 등록"
                            ) : (
                              <>
                                Registration of recipients
                                <br />
                                of training notices
                              </>
                            )
                          }
                          buttonType={"whiteHover"}
                          icon={IconArrowRight}
                          onClick={() => {
                            modalOption.setRegisterModal((e) => {
                              e.show = true;

                              return { ...e };
                            });
                          }}
                        />
                      </div>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </section>

        <section className="imgSection">
          <div className="inner">
            <h3>
              {languageQuarter
                ? "교육장 소개"
                : "Introduction to the Director of Education"}
            </h3>

            <Swiper
              pagination={{
                el: ".progressBar",
                type: "progressbar",
              }}
              loop={true}
              spaceBetween={10}
              slidesPerView={"auto"}
              navigation={{
                prevEl: ".prevButton",
                nextEl: ".nextButton",
              }}
              modules={[Pagination, Navigation]}
              className="placeSlider"
            >
              {placeImgList.map((item, index) => {
                return (
                  <SwiperSlide
                    className="placeSlide"
                    key={"placeSlide" + index}
                  >
                    <img src={item} alt="placeImg" />
                  </SwiperSlide>
                );
              })}
            </Swiper>

            <div className="slideControl">
              <div className="navigation">
                <button type="button" className="prevButton">
                  <img src={IconPrev} alt="IconPrev" />
                </button>

                <button type="button" className="nextButton">
                  <img src={IconNext} alt="IconNext" />
                </button>
              </div>

              <div className="progressBar">
                <span></span>
              </div>
            </div>
          </div>
        </section>

        <section className="mapSection">
          <div className="inner">
            <h3>
              {languageQuarter
                ? "교육장 약도"
                : "A map of the head of education"}
            </h3>

            <div className="map">
              <div className="textWrap">
                <p className="title">
                  {languageQuarter ? (
                    "판교 본사"
                  ) : (
                    <>
                      Pangyo Head
                      <br />
                      Office
                    </>
                  )}
                </p>

                <ul>
                  {mapInfoList.map((item, index) => {
                    return (
                      <li className="mapInfoList" key={"mapInfoList" + index}>
                        <div className="icon">
                          <img src={item.icon} alt="mapIcon" />
                        </div>

                        <p>{item.text}</p>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className="mapWrap">
                <layout.KakaoMap location={"headOffice"} />
              </div>
            </div>
          </div>
        </section>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default EducationCenterPage;
