import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 11;

  & .modalInner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 360px;
    padding: 60px 0;
    background-color: #fff;

    & .icon {
      width: 34px;
      height: 34px;
      margin-bottom: 20px;
    }

    & .title {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
    }

    & .subText {
      margin-top: 16px;
      font-size: 14px;

      & li {
        position: relative;
        padding-left: 10px;
        text-align: start;

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        &::before {
          position: absolute;
          top: -1px;
          left: 0;
          font-weight: 400;
          content: "•";
        }
      }
    }

    & .buttonContainer {
      width: 260px;
      margin-top: 40px;

      & button:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  & .overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(34, 34, 34, 0.7);
  }

  @media (max-width: 1480px) {
    & .modalInner {
      width: calc(100vw * (360 / 1480));
      padding: calc(100vw * (60 / 1480)) 0;

      & .icon {
        width: calc(100vw * (34 / 1480));
        height: calc(100vw * (34 / 1480));
        margin-bottom: calc(100vw * (20 / 1480));
      }

      & .title {
        font-size: calc(100vw * (20 / 1480));
      }

      & .subText {
        margin-top: calc(100vw * (16 / 1480));
        font-size: calc(100vw * (14 / 1480));

        & li {
          padding-left: calc(100vw * (10 / 1480));

          &:not(:last-child) {
            margin-bottom: calc(100vw * (10 / 1480));
          }
        }
      }

      & .buttonContainer {
        width: calc(100vw * (260 / 1480));
        margin-top: calc(100vw * (40 / 1480));

        & button:not(:last-child) {
          margin-bottom: calc(100vw * (10 / 1480));
        }
      }
    }
  }

  @media (max-width: 768px) {
    & .modalInner {
      width: calc(100vw * (282 / 375));
      padding: calc(100vw * (50 / 375)) 0;

      & .icon {
        width: calc(100vw * (34 / 375));
        height: calc(100vw * (34 / 375));
        margin-bottom: calc(100vw * (20 / 375));
      }

      & .title {
        font-size: calc(100vw * (18 / 375));
      }

      & .subText {
        margin-top: calc(100vw * (12 / 375));
        font-size: calc(100vw * (14 / 375));
        text-align: center;

        & li {
          padding-left: calc(100vw * (10 / 375));

          &:not(:last-child) {
            margin-bottom: calc(100vw * (10 / 375));
          }
        }
      }

      & .buttonContainer {
        width: calc(100vw * (180 / 375));
        margin-top: calc(100vw * (40 / 375));

        & button:not(:last-child) {
          margin-bottom: calc(100vw * (6 / 375));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
