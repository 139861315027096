import styled from "styled-components";

// img
import ImgLine from "resources/image/education_center/img_line.png";
import ImgLineMb from "resources/image/education_center/img_line_mb.png";

const Container = styled.main`
  padding-bottom: 200px;

  & section:not(:last-child) {
    margin-bottom: 80px;
  }

  & h3 {
    margin-bottom: 50px;
    font-size: 36px;
    font-weight: bold;
  }

  & .informationSection {
    & .mainGuide {
      padding: 40px 40px 96px;
      font-size: 20px;
      font-weight: 600;
      color: #fff;
      line-height: 1.5;
      border-radius: 12px;
      background-color: var(--blue02);

      & .mainGuideList {
        position: relative;
        padding-left: 15px;

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        &::before {
          position: absolute;
          top: -2px;
          left: 0;
          content: "•";
        }
      }
    }

    & .subGuide {
      width: 1240px;
      margin: -56px auto 0;

      & .subGuideList {
        padding: 30px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.12);

        &:not(:last-child) {
          margin-bottom: 30px;
        }

        & .title {
          margin-bottom: 20px;
          padding-bottom: 30px;
          font-size: 22px;
          font-weight: bold;
          color: var(--blue02);
          border-bottom: 1px solid var(--gray02);
        }

        & .listWrap {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 16px 0;

          & .list {
            position: relative;
            padding-left: 10px;
            font-weight: 500;
            line-height: 1.75;

            &:nth-of-type(odd) {
              width: 520px;
            }

            &:nth-of-type(even) {
              width: 651px;
            }

            &::before {
              position: absolute;
              top: -2px;
              left: 0;
              content: "•";
            }

            & b {
              font-weight: 800;
            }
          }
        }
      }
    }
  }

  & .stepSection {
    overflow: hidden;

    & .step {
      position: relative;
      display: flex;
      justify-content: center;
      gap: 166px;
      margin-bottom: 76px;

      &::before {
        position: absolute;
        top: 6px;
        left: 50%;
        transform: translateX(-50%);
        width: 100vw;
        height: 5px;
        background-color: var(--blue04);
        content: "";
      }

      & .stepList {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        & .circle {
          position: relative;
          width: 16px;
          height: 16px;
          margin-bottom: 71px;
          border-radius: 50%;
          background-color: var(--blue02);

          &::after {
            position: absolute;
            top: calc(100% + 6px);
            left: 50%;
            transform: translateX(-50%);
            width: 1px;
            height: 49px;
            background: url(${ImgLine}) no-repeat center / cover;
            content: "";
          }
        }

        & .title {
          margin-bottom: 26px;
          font-size: 24px;
          font-weight: bold;
        }

        & .stepIcon {
          width: 34px;
          height: 34px;
          margin-bottom: 20px;
        }

        & .description {
          font-size: 18px;
          font-weight: 500;
          line-height: 1.44;
          text-align: center;
        }

        & .registerButton {
          position: absolute;
          top: calc(100% + 16px);
          width: 260px;
        }
      }
    }
  }

  & .imgSection {
    overflow: hidden;

    & .placeSlider {
      width: 1620px;
      margin-bottom: 52px;

      & .placeSlide {
        width: 478px;
        height: 318px;
        cursor: pointer;
      }
    }

    & .slideControl {
      display: flex;
      align-items: center;
      gap: 80px;

      & .navigation {
        display: flex;
        gap: 40px;

        & button {
          display: block;
          width: 50px;
          height: 50px;
        }
      }

      & .progressBar {
        position: relative;
        width: 1100px;
        height: 8px;
        background-color: #f6f6f6;

        & .swiper-pagination-progressbar-fill {
          background: var(--blue02);
        }
      }
    }
  }

  & .mapSection {
    & .map {
      position: relative;

      & .textWrap {
        position: absolute;
        top: 0;
        left: 80px;
        display: flex;
        flex-direction: column;
        padding: 30px;
        height: 100%;
        color: #fff;
        background-color: rgba(1, 120, 192, 0.8);
        z-index: 2;

        & .title {
          font-size: 28px;
          font-weight: bold;
        }

        & ul {
          margin-top: auto;

          & .mapInfoList:not(:last-child) {
            margin-bottom: 10px;
          }

          & .icon {
            width: 24px;
            height: 24px;
            margin-bottom: 6px;
          }

          & p {
            font-size: 14px;
            font-weight: 500;
          }
        }
      }

      & .mapWrap {
        height: 400px;
        box-shadow: 0px 4px 36px rgba(34, 34, 34, 0.2);
      }
    }
  }

  @media (max-width: 1480px) {
    padding-bottom: calc(100vw * (200 / 1480));

    & section:not(:last-child) {
      margin-bottom: calc(100vw * (80 / 1480));
    }

    & h3 {
      margin-bottom: calc(100vw * (50 / 1480));
      font-size: calc(100vw * (36 / 1480));
    }

    & .informationSection {
      & .mainGuide {
        padding: calc(100vw * (40 / 1480)) calc(100vw * (40 / 1480))
          calc(100vw * (96 / 1480));
        font-size: calc(100vw * (20 / 1480));
        border-radius: calc(100vw * (12 / 1480));

        & .mainGuideList {
          padding-left: calc(100vw * (15 / 1480));

          &:not(:last-child) {
            margin-bottom: calc(100vw * (10 / 1480));
          }

          &::before {
            top: calc(100vw * (-2 / 1480));
          }
        }
      }

      & .subGuide {
        width: calc(100vw * (1240 / 1480));
        margin: calc(100vw * (-56 / 1480)) auto 0;

        & .subGuideList {
          padding: calc(100vw * (30 / 1480));
          border-radius: calc(100vw * (10 / 1480));
          box-shadow: calc(100vw * (4 / 1480)) calc(100vw * (4 / 1480))
            calc(100vw * (20 / 1480)) 0 rgba(0, 0, 0, 0.12);

          &:not(:last-child) {
            margin-bottom: calc(100vw * (30 / 1480));
          }

          & .title {
            margin-bottom: calc(100vw * (20 / 1480));
            padding-bottom: calc(100vw * (30 / 1480));
            font-size: calc(100vw * (22 / 1480));
          }

          & .listWrap {
            gap: calc(100vw * (16 / 1480)) 0;

            & .list {
              padding-left: calc(100vw * (10 / 1480));

              &:nth-of-type(odd) {
                width: calc(100vw * (520 / 1480));
              }

              &:nth-of-type(even) {
                width: calc(100vw * (651 / 1480));
              }

              &::before {
                top: calc(100vw * (-2 / 1480));
              }
            }
          }
        }
      }
    }

    & .stepSection {
      & .step {
        gap: calc(100vw * (166 / 1480));
        margin-bottom: calc(100vw * (76 / 1480));

        &::before {
          top: calc(100vw * (6 / 1480));
          height: calc(100vw * (5 / 1480));
        }

        & .stepList {
          & .circle {
            width: calc(100vw * (16 / 1480));
            height: calc(100vw * (16 / 1480));
            margin-bottom: calc(100vw * (71 / 1480));

            &::after {
              top: calc(100% + calc(100vw * (6 / 1480)));
              height: calc(100vw * (49 / 1480));
            }
          }

          & .title {
            margin-bottom: calc(100vw * (26 / 1480));
            font-size: calc(100vw * (24 / 1480));
          }

          & .stepIcon {
            width: calc(100vw * (34 / 1480));
            height: calc(100vw * (34 / 1480));
            margin-bottom: calc(100vw * (20 / 1480));
          }

          & .description {
            font-size: calc(100vw * (18 / 1480));
          }

          & .registerButton {
            top: calc(100% + calc(100vw * (16 / 1480)));
            width: calc(100vw * (260 / 1480));
          }
        }
      }
    }

    & .imgSection {
      & .placeSlider {
        width: calc(100vw * (1400 / 1480));
        margin-bottom: calc(100vw * (52 / 1480));

        & .placeSlide {
          width: calc(100vw * (478 / 1480));
          height: calc(100vw * (318 / 1480));
        }
      }

      & .slideControl {
        gap: calc(100vw * (80 / 1480));

        & .navigation {
          gap: calc(100vw * (40 / 1480));

          & button {
            width: calc(100vw * (50 / 1480));
            height: calc(100vw * (50 / 1480));
          }
        }

        & .progressBar {
          width: calc(100vw * (1100 / 1480));
          height: calc(100vw * (8 / 1480));
        }
      }
    }

    & .mapSection {
      & .map {
        & .textWrap {
          left: calc(100vw * (80 / 1480));
          padding: calc(100vw * (30 / 1480));

          & .title {
            font-size: calc(100vw * (28 / 1480));
          }

          & ul {
            & .mapInfoList:not(:last-child) {
              margin-bottom: calc(100vw * (10 / 1480));
            }

            & .icon {
              width: calc(100vw * (24 / 1480));
              height: calc(100vw * (24 / 1480));
              margin-bottom: calc(100vw * (6 / 1480));
            }

            & p {
              font-size: calc(100vw * (14 / 1480));
            }
          }
        }

        & .mapWrap {
          height: calc(100vw * (400 / 1480));
          box-shadow: 0px calc(100vw * (4 / 1480)) calc(100vw * (36 / 1480))
            rgba(34, 34, 34, 0.2);
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (66 / 375)) 0 calc(100vw * (130 / 375));

    & section:not(:last-child) {
      margin-bottom: calc(100vw * (50 / 375));
    }

    & h3 {
      margin-bottom: calc(100vw * (30 / 375));
      font-size: calc(100vw * (24 / 375));
    }

    & .informationSection {
      & .mainGuide {
        padding: calc(100vw * (20 / 375)) calc(100vw * (20 / 375))
          calc(100vw * (97 / 375));
        font-size: calc(100vw * (14 / 375));
        border-radius: calc(100vw * (6 / 375));

        & .mainGuideList {
          padding-left: calc(100vw * (10 / 375));
          word-break: keep-all;
          line-height: 1.42;

          &:not(:last-child) {
            margin-bottom: calc(100vw * (10 / 375));
          }

          &::before {
            top: calc(100vw * (-2 / 375));
          }
        }
      }

      & .subGuide {
        width: calc(100vw * (295 / 375));
        margin: calc(100vw * (-77 / 375)) auto 0;

        & .subGuideList {
          padding: calc(100vw * (20 / 375));
          border-radius: calc(100vw * (10 / 375));
          box-shadow: calc(100vw * (4 / 375)) calc(100vw * (4 / 375))
            calc(100vw * (20 / 375)) 0 rgba(0, 0, 0, 0.12);

          &:not(:last-child) {
            margin-bottom: calc(100vw * (20 / 375));
          }

          & .title {
            margin-bottom: calc(100vw * (20 / 375));
            padding-bottom: calc(100vw * (20 / 375));
            font-size: calc(100vw * (16 / 375));
          }

          & .listWrap {
            gap: calc(100vw * (10 / 375));

            & .list {
              padding-left: calc(100vw * (10 / 375));
              word-break: keep-all;
              line-height: 1.42;

              &:nth-of-type(odd),
              &:nth-of-type(even) {
                width: 100%;
              }

              &::before {
                top: calc(100vw * (-2 / 375));
              }
            }
          }
        }
      }
    }

    & .stepSection {
      & .step {
        flex-direction: column;
        gap: calc(100vw * (50 / 375));
        width: max-content;
        margin: 0 0 0 calc(100vw * (57 / 375));
        padding: calc(100vw * (45 / 375)) 0;

        &::before {
          top: 0;
          left: 0;
          width: calc(100vw * (2 / 375));
          height: 100%;
        }

        & .stepList {
          padding-left: calc(100vw * (41 / 375));

          & .circle {
            position: absolute;
            top: calc(100vw * (5 / 375));
            left: calc(100vw * (-6 / 375));
            width: calc(100vw * (12 / 375));
            height: calc(100vw * (12 / 375));
            margin-bottom: 0;

            &::after {
              top: 50%;
              left: calc(100% + calc(100vw * (4 / 375)));
              transform: translate(0, -50%);
              width: calc(100vw * (84 / 375));
              height: calc(100vw * (1 / 375));
              background-image: url(${ImgLineMb});
            }
          }

          &:first-child {
            margin-bottom: calc(100vw * (51 / 375));
          }

          & .title {
            margin-bottom: calc(100vw * (10 / 375));
            font-size: calc(100vw * (18 / 375));
          }

          & .stepIcon {
            width: calc(100vw * (24 / 375));
            height: calc(100vw * (24 / 375));
            margin-bottom: calc(100vw * (10 / 375));
          }

          & .description {
            font-size: calc(100vw * (14 / 375));
          }

          & .registerButton {
            top: calc(100% + calc(100vw * (10 / 375)));
            width: calc(100vw * (180 / 375));
          }
        }
      }
    }

    & .imgSection {
      & .placeSlider {
        width: calc(100vw * (355 / 375));
        margin-bottom: calc(100vw * (21 / 375));

        & .placeSlide {
          width: calc(100vw * (251 / 375));
          height: calc(100vw * (167 / 375));
        }
      }

      & .slideControl {
        gap: calc(100vw * (20 / 375));

        & .navigation {
          gap: calc(100vw * (20 / 375));

          & button {
            width: calc(100vw * (24 / 375));
            height: calc(100vw * (24 / 375));
          }
        }

        & .progressBar {
          width: calc(100vw * (247 / 375));
          height: calc(100vw * (4 / 375));
        }
      }
    }

    & .mapSection {
      & .map {
        display: flex;
        flex-direction: column-reverse;

        & .textWrap {
          position: static;
          align-items: center;
          padding: calc(100vw * (20 / 375));
          background-color: var(--blue02);

          & .title {
            margin-bottom: calc(100vw * (30 / 375));
            font-size: calc(100vw * (24 / 375));
          }

          & ul {
            & .mapInfoList:not(:last-child) {
              margin-bottom: calc(100vw * (10 / 375));
            }

            & .icon {
              width: calc(100vw * (24 / 375));
              height: calc(100vw * (24 / 375));
              margin: 0 auto calc(100vw * (6 / 375));
            }

            & p {
              font-size: calc(100vw * (14 / 375));
              text-align: center;
            }
          }
        }

        & .mapWrap {
          height: calc(100vw * (400 / 375));
          box-shadow: 0px calc(100vw * (4 / 375)) calc(100vw * (36 / 375))
            rgba(34, 34, 34, 0.2);
        }
      }
    }
  }
`;

export { Container };

export default { Container };
