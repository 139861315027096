import Styles from "./styles";
import ScrollContainer from "react-indiana-drag-scroll";

function EducationTab({ list, tabIndex, setTabIndex, onClick }) {
  return (
    <ScrollContainer>
      <Styles.Container>
        {list?.map((item, index) => {
          return (
            <li
              className={"tabList" + (tabIndex == item.id ? " on" : "")}
              key={"tabList" + index}
              onClick={() => {
                if (onClick) {
                  onClick(item);
                }
                setTabIndex(item.id);
              }}
            >
              {item.title}
            </li>
          );
        })}
      </Styles.Container>
    </ScrollContainer>
  );
}

export default EducationTab;
