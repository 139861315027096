import styled from "styled-components";

const Container = styled.section`
  &.abilitySection {
    & .tableWrap {
      margin: 30px 0;

      & .table:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    & figure .img {
      height: 344px;
      padding: 28px 42px;
    }
  }

  &.referenceSection {
    & ul {
      margin: 30px 0;
      width: 653px;

      & li:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    & figure .img {
      height: 520px;
      padding: 30px 19px;
    }
  }

  &.frameworkSection {
    & .sectionDescription {
      margin-bottom: 30px;
    }

    & figure {
      margin: 30px 0 50px;

      & .img {
        height: 1082px;
        padding: 0 99px 0 95px;
      }
    }

    & .grayBox {
      &:nth-of-type(2) {
        margin-bottom: 50px;

        & .img {
          height: 677px;
        }
      }

      &:last-of-type .img {
        height: 425px;
      }

      & b {
        display: inline-block;
        margin: 30px 0 10px;
      }

      & .listWrap,
      .subListWrap {
        padding-left: 20px;
      }

      & .list {
        line-height: 1.87;

        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }

      & .imgWrap {
        margin-top: 20px;

        & .img {
          width: 100%;
          height: 100%;
        }
      }

      & .subListWrap {
        margin-top: 10px;

        & .subList:not(:last-child) {
          margin-bottom: 4px;
        }
      }
    }
  }

  &.evaluationSection {
    & .sectionDescription {
      margin-bottom: 30px;
    }

    & .grayBox .text {
      margin-bottom: 30px;
    }

    & .table:not(:last-child) {
      margin-bottom: 20px;
    }

    & .grayBox:first-of-type {
      margin-bottom: 50px;

      & figure .img {
        height: 344px;
        padding: 28px 41px;
      }
    }

    & .grayBox:last-of-type {
      & figure .img {
        height: 420px;
        padding: 44px 237px;
      }
    }
  }

  &.serviceSection .sectionDescription {
    line-height: 1.44;
  }

  @media (max-width: 1480px) {
    &.abilitySection {
      & .tableWrap {
        margin: calc(100vw * (30 / 1480)) 0;

        & .table:not(:last-child) {
          margin-bottom: calc(100vw * (20 / 1480));
        }
      }

      & figure .img {
        height: calc(100vw * (344 / 1480));
        padding: calc(100vw * (28 / 1480)) calc(100vw * (42 / 1480));
      }
    }

    &.referenceSection {
      & ul {
        margin: calc(100vw * (30 / 1480)) 0;
        width: calc(100vw * (653 / 1480));

        & li:not(:last-child) {
          margin-bottom: calc(100vw * (20 / 1480));
        }
      }

      & figure .img {
        height: calc(100vw * (520 / 1480));
        padding: calc(100vw * (30 / 1480)) calc(100vw * (19 / 1480));
      }
    }

    &.frameworkSection {
      & .sectionDescription {
        margin-bottom: calc(100vw * (30 / 1480));
      }

      & figure {
        margin: calc(100vw * (30 / 1480)) 0 calc(100vw * (50 / 1480));

        & .img {
          height: calc(100vw * (1082 / 1480));
          padding: 0 calc(100vw * (99 / 1480)) 0 calc(100vw * (95 / 1480));
        }
      }

      & .grayBox {
        &:nth-of-type(2) {
          margin-bottom: calc(100vw * (50 / 1480));

          & .img {
            height: calc(100vw * (677 / 1480));
          }
        }

        &:last-of-type .img {
          height: calc(100vw * (425 / 1480));
        }

        & b {
          margin: calc(100vw * (30 / 1480)) 0 calc(100vw * (10 / 1480));
        }

        & .listWrap,
        .subListWrap {
          padding-left: calc(100vw * (20 / 1480));
        }

        & .list {
          &:not(:last-child) {
            margin-bottom: calc(100vw * (16 / 1480));
          }
        }

        & .imgWrap {
          margin-top: calc(100vw * (20 / 1480));

          & .img {
            width: 100%;
            height: 100%;
          }
        }

        & .subListWrap {
          margin-top: calc(100vw * (10 / 1480));

          & .subList:not(:last-child) {
            margin-bottom: calc(100vw * (4 / 1480));
          }
        }
      }
    }

    &.evaluationSection {
      & .sectionDescription {
        margin-bottom: calc(100vw * (30 / 1480));
      }

      & .grayBox .text {
        margin-bottom: calc(100vw * (30 / 1480));
      }

      & .table:not(:last-child) {
        margin-bottom: calc(100vw * (20 / 1480));
      }

      & .grayBox:first-of-type {
        margin-bottom: calc(100vw * (50 / 1480));

        & figure .img {
          height: calc(100vw * (344 / 1480));
          padding: calc(100vw * (28 / 1480)) calc(100vw * (41 / 1480));
        }
      }

      & .grayBox:last-of-type {
        & figure .img {
          height: calc(100vw * (420 / 1480));
          padding: calc(100vw * (44 / 1480)) calc(100vw * (237 / 1480));
        }
      }
    }
  }

  @media (max-width: 768px) {
    &.abilitySection {
      & .tableWrap {
        margin: calc(100vw * (20 / 375)) 0;

        & .table:not(:last-child) {
          margin-bottom: calc(100vw * (14 / 375));
        }
      }

      & figure .img {
        height: calc(100vw * (197 / 375));
        padding: calc(100vw * (15 / 375));
      }
    }

    &.referenceSection {
      & ul {
        margin: calc(100vw * (20 / 375)) 0;
        width: 100%;

        & li:not(:last-child) {
          margin-bottom: calc(100vw * (14 / 375));
        }
      }

      & figure .img {
        height: calc(100vw * (276 / 375));
        padding: calc(100vw * (23 / 375)) calc(100vw * (15 / 375))
          calc(100vw * (7 / 375));
      }
    }

    &.frameworkSection {
      & .sectionDescription {
        margin-bottom: calc(100vw * (20 / 375));
      }

      & figure {
        margin: calc(100vw * (30 / 375)) 0;

        & .img {
          height: calc(100vw * (1082 / 375));
          padding: 0 calc(100vw * (11 / 375)) 0 calc(100vw * (20 / 375));
        }
      }

      & .grayBox {
        &:nth-of-type(2) {
          margin-bottom: calc(100vw * (30 / 375));

          & .img {
            width: calc(100vw * (956 / 375));
            height: calc(100vw * (597 / 375));
          }
        }

        &:last-of-type .img {
          width: calc(100vw * (510 / 375));
          height: calc(100vw * (395 / 375));
        }

        & b {
          margin: calc(100vw * (20 / 375)) 0 calc(100vw * (6 / 375));
        }

        & .listWrap,
        .subListWrap {
          padding-left: calc(100vw * (10 / 375));
        }

        & .list {
          line-height: 1.42;

          &:not(:last-child) {
            margin-bottom: calc(100vw * (18 / 375));
          }
        }

        & .imgWrap {
          margin: calc(100vw * (14 / 375)) 0 calc(100vw * (20 / 375));
        }

        & .subListWrap {
          margin-top: calc(100vw * (10 / 375));
          line-height: normal;

          & .subList:not(:last-child) {
            margin-bottom: calc(100vw * (10 / 375));
          }
        }
      }
    }

    &.evaluationSection {
      & .sectionDescription {
        margin-bottom: calc(100vw * (30 / 375));
      }

      & .grayBox .text {
        margin-bottom: calc(100vw * (20 / 375));
      }

      & .table:not(:last-child) {
        margin-bottom: calc(100vw * (14 / 375));
      }

      & .grayBox:first-of-type {
        margin-bottom: calc(100vw * (30 / 375));

        & figure .img {
          height: calc(100vw * (198 / 375));
          padding: calc(100vw * (15 / 375));
        }
      }

      & .grayBox:last-of-type {
        & figure .img {
          height: calc(100vw * (151 / 375));
          padding: calc(100vw * (23 / 375)) calc(100vw * (14 / 375))
            calc(100vw * (7 / 375));
        }
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
