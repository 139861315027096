import styled from "styled-components";

const Container = styled.main`
  min-height: 100vh;
  min-height: 100dvh;
  padding: 180px 0 200px;

  & h2 {
    margin-bottom: 69px;
    font-size: 62px;
    font-weight: 800;
  }

  & .scheduleWrap {
    display: flex;
    gap: 51px;

    & .calendar {
      width: 501px;
      padding-top: 11px;
    }

    & .detail {
      width: 768px;

      & .detailTitle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 19px;
        font-size: 32px;
        font-weight: bold;

        & .scheduleButton {
          width: 260px;
        }
      }

      & .listContainer {
        height: 329px;
        padding: 30px;
        background-color: #fafafa;
        border-radius: 10px;

        & .scroll-container {
          height: 100%;
        }

        & .state {
          width: 72px;
        }

        & .period {
          width: 170px;
        }

        & .title {
          width: 422px;
        }

        & .thead {
          display: flex;
          gap: 30px;
          margin-bottom: 20px;
          padding-bottom: 20px;
          font-weight: bold;
          border-bottom: 1px solid var(--black);
        }

        & .tbody {
          height: 210px;
          overflow: auto;

          & .tr {
            display: flex;
            align-items: center;
            gap: 30px;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;

            &:not(:last-child) {
              margin-bottom: 20px;
            }
          }

          & .state {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 29px;
            color: #fff;
            border-radius: 5px;

            &.progress {
              background-color: #abcd03;
            }

            &.scheduled {
              background-color: var(--blue02);
            }

            &.closed {
              background-color: #363946;
            }
          }

          & .title {
            font-size: 15px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        & .empty {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100%;
          font-size: 18px;
          font-weight: 500;

          & .icon {
            width: 30px;
            height: 30px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  @media (max-width: 1480px) {
    padding: calc(100vw * (180 / 1480)) 0 calc(100vw * (200 / 1480));

    & h2 {
      margin-bottom: calc(100vw * (69 / 1480));
      font-size: calc(100vw * (62 / 1480));
    }

    & .scheduleWrap {
      gap: calc(100vw * (51 / 1480));

      & .calendar {
        width: calc(100vw * (501 / 1480));
        padding-top: calc(100vw * (11 / 1480));
      }

      & .detail {
        width: calc(100vw * (768 / 1480));

        & .detailTitle {
          margin-bottom: calc(100vw * (19 / 1480));
          font-size: calc(100vw * (32 / 1480));

          & .scheduleButton {
            width: calc(100vw * (260 / 1480));
          }
        }

        & .listContainer {
          height: calc(100vw * (329 / 1480));
          padding: calc(100vw * (30 / 1480));
          border-radius: calc(100vw * (10 / 1480));

          & .state {
            width: calc(100vw * (72 / 1480));
          }

          & .period {
            width: calc(100vw * (165 / 1480));
          }

          & .title {
            width: calc(100vw * (422 / 1480));
          }

          & .thead {
            gap: calc(100vw * (30 / 1480));
            margin-bottom: calc(100vw * (20 / 1480));
            padding-bottom: calc(100vw * (20 / 1480));
          }

          & .tbody {
            height: calc(100vw * (210 / 1480));

            & .tr {
              gap: calc(100vw * (30 / 1480));
              font-size: calc(100vw * (14 / 1480));

              &:not(:last-child) {
                margin-bottom: calc(100vw * (20 / 1480));
              }
            }

            & .state {
              height: calc(100vw * (29 / 1480));
              border-radius: calc(100vw * (5 / 1480));
            }

            & .title {
              font-size: calc(100vw * (15 / 1480));
            }
          }

          & .empty {
            font-size: calc(100vw * (18 / 1480));

            & .icon {
              width: calc(100vw * (30 / 1480));
              height: calc(100vw * (30 / 1480));
              margin-bottom: calc(100vw * (20 / 1480));
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (116 / 375)) 0 calc(100vw * (130 / 375));

    & h2 {
      margin-bottom: calc(100vw * (50 / 375));
      font-size: calc(100vw * (32 / 375));
    }

    & .scheduleWrap {
      flex-direction: column;
      gap: calc(100vw * (41 / 375));

      & .calendar {
        width: 100%;
        padding-top: 0;
      }

      & .detail {
        width: 100%;

        & .detailTitle {
          margin-bottom: calc(100vw * (12 / 375));
          font-size: calc(100vw * (20 / 375));

          & .scheduleButton {
            width: calc(100vw * (180 / 375));
          }
        }

        & .listContainer {
          height: calc(100vw * (329 / 375));
          margin-bottom: calc(100vw * (20 / 375));
          padding: calc(100vw * (20 / 375));
          border-radius: calc(100vw * (6 / 375));

          & .th,
          .td {
            flex-shrink: 0;
          }

          & .state {
            width: calc(100vw * (72 / 375));
          }

          & .period {
            width: calc(100vw * (165 / 375));
          }

          & .title {
            width: calc(100vw * (422 / 375));
          }

          & .thead {
            width: fit-content;
            gap: calc(100vw * (20 / 375));
            margin-bottom: calc(100vw * (14 / 375));
            padding-bottom: calc(100vw * (14 / 375));
          }

          & .tbody {
            width: fit-content;
            height: calc(100vw * (242 / 375));

            & .tr {
              gap: calc(100vw * (20 / 375));
              font-size: calc(100vw * (14 / 375));

              &:not(:last-child) {
                margin-bottom: calc(100vw * (20 / 375));
              }
            }

            & .state {
              height: calc(100vw * (29 / 375));
              border-radius: calc(100vw * (5 / 375));
            }

            & .title {
              font-size: calc(100vw * (14 / 375));
            }
          }

          & .empty {
            font-size: calc(100vw * (14 / 375));

            & .icon {
              width: calc(100vw * (24 / 375));
              height: calc(100vw * (24 / 375));
              margin-bottom: calc(100vw * (16 / 375));
            }
          }
        }
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
