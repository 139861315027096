import styled from "styled-components";

const Container = styled.main`
  min-height: 100vh;
  min-height: 100dvh;
  padding-bottom: 200px;

  & section:not(:last-of-type) {
    margin-bottom: 80px;

    & .sectionDescription {
      margin-bottom: 20px;
    }
  }

  & h3 {
    margin-bottom: 50px;
    padding-bottom: 30px;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: -0.02em;
    border-bottom: 1px solid var(--gray02);
  }

  & .sectionDescription {
    font-size: 18px;
    line-height: 1.44;
    word-break: keep-all;
  }

  & figcaption {
    margin-bottom: 10px;
    font-weight: 500;
    color: var(--gray01);
  }

  & figure .img {
    border: 1px solid var(--gray02);
    border-radius: 10px;
  }

  & .imgContainer {
    position: relative;

    & .source {
      position: absolute;
      top: 43px;
      right: 14px;
      cursor: pointer;

      & .icon {
        width: 24px;
        height: 24px;
      }

      & p {
        position: absolute;
        bottom: calc(100% + 4px);
        right: 0;
        display: none;
        width: max-content;
        padding: 15px;
        background-color: var(--blue04);
        border-radius: 4px;
      }

      &:hover {
        & p {
          display: block;
        }
      }
    }
  }

  & .img {
    height: 420px;
  }

  & .overviewSection .img {
    padding: 31px 172px;
  }

  & .senseSection .img {
    padding: 30px 353px;
  }

  & .planSection .img {
    padding: 29px 338px;
  }

  & .listButton {
    width: 260px;
    margin: 80px auto 0;
  }

  @media (max-width: 1480px) {
    padding-bottom: calc(100vw * (200 / 1480));

    & section:not(:last-of-type) {
      margin-bottom: calc(100vw * (80 / 1480));

      & .sectionDescription {
        margin-bottom: calc(100vw * (20 / 1480));
      }
    }

    & h3 {
      margin-bottom: calc(100vw * (50 / 1480));
      padding-bottom: calc(100vw * (30 / 1480));
      font-size: calc(100vw * (36 / 1480));
    }

    & .sectionDescription {
      font-size: calc(100vw * (18 / 1480));
    }

    & figcaption {
      margin-bottom: calc(100vw * (10 / 1480));
    }

    & figure .img {
      border-radius: calc(100vw * (10 / 1480));
    }

    & .imgContainer {
      & .source {
        top: calc(100vw * (43 / 1480));
        right: calc(100vw * (14 / 1480));

        & .icon {
          width: calc(100vw * (24 / 1480));
          height: calc(100vw * (24 / 1480));
        }

        & p {
          bottom: calc(100% + calc(100vw * (4 / 1480)));
          padding: calc(100vw * (15 / 1480));
          border-radius: calc(100vw * (4 / 1480));
        }
      }
    }

    & .img {
      height: calc(100vw * (420 / 1480));
    }

    & .overviewSection .img {
      padding: calc(100vw * (31 / 1480)) calc(100vw * (172 / 1480));
    }

    & .senseSection .img {
      padding: calc(100vw * (30 / 1480)) calc(100vw * (353 / 1480));
    }

    & .planSection .img {
      padding: calc(100vw * (29 / 1480)) calc(100vw * (338 / 1480));
    }

    & .listButton {
      width: calc(100vw * (260 / 1480));
      margin: calc(100vw * (80 / 1480)) auto 0;
    }
  }

  @media (max-width: 768px) {
    min-height: calc(100vh - calc(100vw * (309 / 375)));
    min-height: calc(100dvh - calc(100vw * (309 / 375)));
    padding: calc(100vw * (66 / 375)) 0 calc(100vw * (130 / 375));

    & section:not(:last-of-type) {
      margin-bottom: calc(100vw * (50 / 375));

      & .sectionDescription {
        margin-bottom: calc(100vw * (20 / 375));
      }
    }

    & h3 {
      margin-bottom: calc(100vw * (30 / 375));
      padding-bottom: calc(100vw * (20 / 375));
      font-size: calc(100vw * (24 / 375));
    }

    & .sectionDescription {
      font-size: calc(100vw * (14 / 375));
      line-height: 1.42;
    }

    & figcaption {
      margin-bottom: calc(100vw * (6 / 375));
    }

    & figure .img {
      border-radius: calc(100vw * (10 / 375));
    }

    & .imgContainer {
      & .source {
        top: calc(100vw * (29 / 375));
        right: calc(100vw * (6 / 375));

        & .icon {
          width: calc(100vw * (16 / 375));
          height: calc(100vw * (16 / 375));
        }

        & p {
          bottom: calc(100% + calc(100vw * (2 / 375)));
          padding: calc(100vw * (10 / 375));
          font-size: calc(100vw * (12 / 375));
          border-radius: calc(100vw * (4 / 375));
        }
      }
    }

    & .overviewSection .img {
      height: calc(100vw * (154 / 375));
      padding: calc(100vw * (20 / 375)) calc(100vw * (11 / 375));
    }

    & .senseSection .img {
      height: calc(100vw * (226 / 375));
      padding: calc(100vw * (24 / 375)) calc(100vw * (17 / 375));
    }

    & .planSection .img {
      height: calc(100vw * (218 / 375));
      padding: calc(100vw * (24 / 375)) calc(100vw * (16 / 375));
    }

    & .listButton {
      width: calc(100vw * (180 / 375));
      margin: calc(100vw * (50 / 375)) auto 0;
    }
  }
`;

export { Container };

export default {
  Container,
};
