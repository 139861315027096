import { api } from "service";
import { useQuery } from "react-query";
import { useModals } from "hooks";
import { useLocation, useNavigate } from "react-router-dom";

export default function useStudy({
  option,
  detailOption
}) {
  const modalOption = useModals();
  const location = useLocation();
  const navigate = useNavigate();

  const getStudyList = useQuery(["study", option], () => {
    return api.study.getStudyList(option);
  }, {
    refetchOnWindowFocus: false, 
    enabled: !!option
  });
  const getStudyDetail = useQuery(["studyDetail", detailOption], () => {
    return api.study.getStudyDetail(detailOption);
  }, {
    refetchOnWindowFocus: false, 
    enabled: !!detailOption
  });

  const postStudyTax = (data) => {
    modalOption.setDefaultModal((e) => {
      e.show = true;
      e.title = (
        <>
          세금계산서 발행 정보를 <br />
          입력하시겠습니까?
        </>
      );
      e.subText = (
        <ul>
          <li>
            입력한 정보를 바탕으로 <br className="mbOnly" />
            세금계산서가 발행되므로 <br className="pcOnly" />
            작성 시 <br className="mbOnly" />
            오기재하는 부분이 없도록 유의 <br className="mbOnly" />
            바랍니다.
          </li>
          <li>
            지정해주신 발행일자 이후부터 발행{" "}
            <br className="mbOnly" />
            가능하며, <br className="pcOnly" />
            발행일자 이전에 미리 <br className="mbOnly" />
            발행하는 것은 불가능합니다.
          </li>
        </ul>
      );
      e.closeText = "닫기";
      e.confirmText = "입력하기";
      e.confirmClick = async () => {
        const result = await api.study.postStudyTax(JSON.stringify(data));
        if (result.success) {
          modalOption.setDefaultModal((e) => {
            e.show = true;
            e.title = (
              <>
                세금계산서 발행 정보 입력이 <br />
                완료되었습니다.
              </>
            );
            e.subText = (
              <ul>
                <li>
                  세금계산서 최종 발행 시 담당자{" "}
                  <br className="mbOnly" />
                  이메일로 <br className="pcOnly" />
                  전송되며, 결제 완료 처리
                  <br className="mbOnly" />
                  됩니다.
                </li>
                <li>
                  관련 문의는 담당자 이메일 <br />
                  (ghyoo@cnbis.co.kr)로 연락 <br className="mbOnly" />
                  바랍니다.
                </li>
              </ul>
            );
            e.confirmText = "확인";
            e.confirmClick = () => { navigate(0, { replace: true }) };
            e.closeText = false;
            navigate(location.pathname.replace("before", "after"), { state: true, replace: true })
            return { ...e };
          });
        }
      };
      return { ...e };
    });
  }

  return {
    getStudyList,
    getStudyDetail,
    postStudyTax
   };
};