import Styles from "./styles";
import { common } from "components";
import { useStudy } from "hooks";
import { etc, date } from "utils";
import { useParams, Outlet, useLocation } from "react-router-dom";

function InquiryDetailPage() {
  const { id } = useParams();
  
  const studyInfo = useStudy({
    detailOption: {
      token: encodeURI(sessionStorage.getItem("token")),
      transaction_info_id: id,
    },
  });

  const data = studyInfo.getStudyDetail.data;

  const CancelType = {
    1: date.compareNowDate(date.dateCalculator(data?.study_start_date, -7)) === 1,
    2: date.compareNowDate(new Date(data?.application_end_date)) === 1,
    3: false,
  };
  const ableCancel = data?.cancel_yn !== "Y" && CancelType[data?.category_type];

  // data
  const educationList = [
    {
      title: "교육과정",
      text: data?.title,
    },
    {
      title: "교육기간",
      text: data?.study_date,
    },
    {
      title: "교육비용",
      text: <>{etc.numberWithCommas(data?.price)}원</>,
    },
  ];
  const userList = [
    {
      title: "이름(국문)",
      text: data?.name,
    },
    {
      title: "이름(영문)",
      text: data?.name_en,
    },
    {
      title: "생년월일",
      text: data?.birthday_format,
    },
    {
      title: "성별",
      text:
        data?.gender == "M" ? "남성" : data?.gender == "F" ? "여성" : "미선택",
    },
    {
      title: "소속(국문)",
      text: data?.department,
    },
    {
      title: "소속(영문)",
      text: data?.department_en,
    },
    {
      title: "연락처",
      text: data?.phone,
    },
    {
      title: "이메일",
      text: data?.email,
    },
    {
      title: "심사원 ID",
      text: data?.code,
    },
  ];

  const termsList = [
    "현장 결제 No-show 발생 시 씨엔비스에서 진행하는 모든 교육에 대해 1년간 수강이 불가합니다.",
    "신청 완료 후 교육 당일 취소 요청 시 6개월간 씨엔비스 전 교육 수강 불가하며, 교재 비용이 청구됩니다.",
  ];

  const renderPay = (type) => {
    switch (type) {
      case "CARD":
        return (
          <>
            카드 결제{" "}
            <a
              href="https://consumer.tosspayments.com/payment-history/card"
              target="_blank"
            >
              결제 영수증 조회
            </a>
          </>
        );

      case "VBANK":
        return "세금계산서(무통장 입금)";

      case "OFFLINE":
        return (
          <>
            현장 결제
            <div className="termsDetail">
              <ul>
                {termsList.map((item, index) => {
                  return (
                    <li
                      className="onsiteList termsList"
                      key={"onsiteList" + index}
                    >
                      {item}
                    </li>
                  );
                })}
              </ul>
            </div>
          </>
        );
    }
  };

  return (
    // location.state &&
    sessionStorage.getItem("token") && (
      <>
        <common.Header />
        <Styles.Container>
          <section>
            <div className="inner">
              <h2>교육신청 조회</h2>

              <div className="educationInfo wrap">
                <h3>
                  교육정보
                  {data?.cancel_yn === "Y" && (
                    <span className="cancel">신청 취소</span>
                  )}
                </h3>

                <ul>
                  {educationList.map((item, index) => {
                    return (
                      <li className="list" key={"educationInfoList" + index}>
                        <div className="listTitle">{item.title}</div>
                        <p>{item.text}</p>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className="userInfo wrap">
                <h3>신청자 정보</h3>
                <ul>
                  {userList.map((item, index) => {
                    return (
                      <li className="list" key={"userInfoList" + index}>
                        <div className="listTitle">{item.title}</div>
                        <p>{item.text}</p>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className="payInfo wrap">
                <h3>결제수단</h3>
                <div className="payDetail">{renderPay(data?.method)}</div>
              </div>

              <Outlet context={{ data: data, ableCancel }} />
            </div>
          </section>
        </Styles.Container>
        <common.Footer />
      </>
    )
  );
}
export default InquiryDetailPage;
