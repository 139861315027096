import { atom } from "jotai";

const modalFlagAtom = atom(false);
const defaultModalAtom = atom({ show: false, zIndex: 20 });
const mainModalAtom = atom({ show: false, zIndex: 20 });
const registerModalAtom = atom({ show: false, zIndex: 20 });
const schedulerModalAtom = atom({ show: false, zIndex: 20 });
const askModalAtom = atom({ show: false, zIndex: 20 });
const cancelModalAtom = atom({ show: false, zIndex: 20 });
const toastModalAtom = atom({ show: false, zIndex: 20 });

export {
  modalFlagAtom,
  defaultModalAtom,
  mainModalAtom,
  registerModalAtom,
  schedulerModalAtom,
  askModalAtom,
  cancelModalAtom,
  toastModalAtom,
};

export default {
  modalFlagAtom,
  defaultModalAtom,
  mainModalAtom,
  registerModalAtom,
  schedulerModalAtom,
  askModalAtom,
  cancelModalAtom,
  toastModalAtom,
};
