import utils from "utils";
import Styles from "./styles";

function ApplyRadio({ data, onChangeOption, detail }) {
  return (
    <Styles.Container>
      <input
        type="checkbox"
        id={data?.id}
        name={data?.name}
        disabled={data.confirm_yn === "N" || data.state_type != "2" || detail.price == 0}
        checked={data?.checked}
        onClick={()=>{
          onChangeOption(data,null);
        }}
      />

      <label htmlFor={data?.id}></label>

      <div className="applyWrap">
        <div className="checkbox"></div>

        <div className="applyInfo">
          <p className="period">{data.confirm_yn === "Y" ? `총 ${data?.day_diff}일` : "미정"}</p>

          {data.confirm_yn === "Y"
            ?
            <p className="date">
              {data.study_started_at === data?.study_ended_at
                ? utils.date.getDateFormat(data?.study_started_at, 'MM.DD(DATEKOR)')
                : `${utils.date.getDateFormat(data?.study_started_at, 'MM.DD(DATEKOR)')} ~ ${utils.date.getDateFormat(data?.study_ended_at, 'MM.DD(DATEKOR)')}`
              }
            </p>
            : <p className="date">일정 확정 시 공지 예정</p>
          }
        </div>

        {data.confirm_yn === "N" || data.state_type != "2" || detail.price == 0  ? (
          <div className="state unable">신청 불가</div>
        ) : (
          <div className="state able">신청 가능</div>
        )}
      </div>
    </Styles.Container>
  );
}

export default ApplyRadio;
