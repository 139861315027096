import product from "./product";
import transaction from "./transaction";
import category from "./category";
import productQuick from "./productQuick";
import dummy from "./dummy";
import support from "./support";
import sms from "./sms";
import study from "./study";
import upload from "./upload";
import seminar from "./seminar";
import history from "./history";
import fileIntroduce from "./fileIntroduce";
import banner from "./banner";
import term from "./term";

export default {
  product,
  transaction,
  category,
  dummy,
  productQuick,
  support,
  sms,
  study,
  upload,
  seminar,
  history,
  fileIntroduce,
  banner,
  term,
};

export {
  product,
  transaction,
  category,
  dummy,
  productQuick,
  support,
  sms,
  study,
  upload,
  seminar,
  history,
  fileIntroduce,
  banner,
  term,
};
