import { useAtom } from "jotai";
import { useState } from "react";
import { useQuery } from "react-query";
import { api } from "service";
import { modalAtom } from "store/atoms";
import { getDateType } from "utils/date";

export default function useTransaction({
  transactionList,
  transactionDetailOption,
}) {


  const detailQuery = useQuery(["transacitonDetail",transactionDetailOption], 
    ()=>{
      return api.transaction.getDetail(transactionDetailOption);
    }, {
    enabled:!!transactionDetailOption,
    refetchOnWindowFocus: false,
  });

  const getTotalPrice = () => {

    if(!transactionList)return 0;

    let totalPrice = 0;
    let totalProductPrice = 0;

    for(let i = 0;i<transactionList.length;i++){
      const { detail, optionList, amount } = transactionList[i];

      let productCount = 0;

      let price = 0;
      let optionPrice = 0;

      let productDetail = detail;
      if(!productDetail)return 0;

      price = parseInt(productDetail?.price);

      let isSelectOptionCount = 0;
      optionList.map((group)=>{
        let isOption = 0;

        group?.list.map((option)=>{
          if(option.checked){
            isOption++;
            optionPrice += parseInt(option.price);

            // if(detail?.type == "1"){
            //   productCount++;
            // }
          }

          option?.option_relate_list?.map((value)=>{
            if(value.checked){
              optionPrice += parseInt(value.price);
            }
          })
        })

        if(isOption)
          isSelectOptionCount++;
      });


      // if(isSelectOptionCount != optionList?.length){
      //   return 0;
      // }

      // if(detail?.type == "1" && productCount > 0){
      //   price = price * productCount;
      // }
      totalPrice += (price + optionPrice) * amount;
    }


    return totalPrice;
  }


  const isValidate = () =>{

    if (!transactionList) return false;

    for(let i = 0;i<transactionList.length;i++){

      if(transactionList[i].detail?.type != "4" && transactionList[i].optionList.length == 0){
        return false;
      }
      
      const { detail, optionList, amount } = transactionList[i];
      let isSelectOptionCount = 0;

      optionList.map((group)=>{
        let isOption = 0;
        group?.list.map((option)=>{
          if(option.checked){
            isOption++;
          }
        })
        if(isOption){
          isSelectOptionCount++;
        }
      });

      if(isSelectOptionCount == 0){
        alert("신청 가능한 교육 일정을 선택해주세요");
        return false;
      }

    }

    return true;
  }

  const getGenerateTransactionList = () =>{ 
    
    if(!transactionList)return [];

    let resultList = [];

    transactionList.map(value=>{
      const { detail, optionList, amount, date } = value;
      let resultOptionList = [];
      let resultOptionRelateList = [];
      
      optionList.map((group)=>{
        group?.list?.map((option)=>{
          if(option.checked){
            resultOptionList.push({
              amount : 1,
              id : option.id,
              relate_info_id : 0,
              title : option.title,
              product_title : detail?.title,
              price : parseInt(option.price),
              date : `${getDateType(option.study_started_at, ".")} ~ ${getDateType(option.study_ended_at, ".")}`
            });
            
            option?.option_relate_list?.map((value)=>{
              if(value.checked){
                resultOptionRelateList.push({
                  amount : 1,
                  id : value.id,
                  relate_info_id : option.id,
                  title : value.title,
                  product_title : value.product_title,
                  price : parseInt(value.price),
                  date : `${getDateType(value.study_started_at, ".")} ~ ${getDateType(value.study_ended_at, ".")}`
                })
              }
            })
          }

        })
      })

      
      resultList.push(
        {
          "type" : detail?.type,
          "date": date,
          "amount": amount > 0 ? amount : 1,
          "option_list": resultOptionList,
          "option_relate_list": resultOptionRelateList,
          "product": {
            "amount": 1,
            "id": detail?.id,
            "price": parseInt(detail?.price)
          }
        }
      )
    })
    return resultList;
  }

  const cancelTransaction = async (data) => {
    return await api.transaction.cancelTransaction(JSON.stringify(data))
  }

  return {
    detailQuery,
    transactionList,

    getTotalPrice,
    isValidate,

    getGenerateTransactionList,
    cancelTransaction,
  };
}
