import { useLanguage, useModals } from "hooks";
import { api } from "service";

export default function useSeminar() {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";
  const modalOption = useModals();

  const postSeminar = async (modalClose, data) => {
    const result = await api.seminar.postSeminar(JSON.stringify(data));
    if (result) {
      modalClose();
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.title = languageQuarter
          ? result.success
            ? "등록이 완료되었습니다"
            : "이미 등록된 수신자입니다."
          : result.success
            ? "Registration is complete."
            : "The recipient is already registered.";
        e.confirmText = languageQuarter
          ? "확인"
          : "Check";

        e.subText = false;
        e.closeText = false;
        e.confirmClick = false;

        return { ...e };
      });
    }
  }

  return {
    postSeminar,
  };
};