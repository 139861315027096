import styled from "styled-components";

const Container = styled.section`
  &.propertySection {
    & .img {
      height: 344px;
      padding: 34px 36px;
    }
  }

  &.backgroundSection,
  &.standardSection {
    & .imgContainer {
      width: 100%;
    }
  }

  &.backgroundSection {
    & .imgContainer {
      margin-top: 20px;

      &:not(:last-of-type) {
        margin-bottom: 50px;
      }

      & .img {
        height: 420px;
      }

      &:first-of-type .img {
        padding: 40px 297px;
      }

      &:last-of-type .img {
        padding: 40px 422px;
      }
    }
  }

  &.standardSection {
    & ul {
      display: flex;
      flex-wrap: wrap;
      gap: 20px 15px;
      margin: 30px 0 20px;

      & .standardList {
        width: 430px;
      }
    }

    & .img {
      height: 660px;
      padding: 39px 266px;
    }
  }

  &.processSection {
    & .sectionDescription {
      margin-bottom: 20px;
    }

    & .inner > figure {
      margin-bottom: 50px;

      &:first-of-type .img {
        height: 420px;
        padding: 41px 280px;
      }

      &:last-of-type .img {
        height: 686px;
        padding: 30px 255px;
      }
    }

    & .imgContainer .img {
      height: 348px;
      padding: 30px 388px;
    }
  }

  @media (max-width: 1480px) {
    &.propertySection {
      & .img {
        height: calc(100vw * (344 / 1480));
        padding: calc(100vw * (34 / 1480)) calc(100vw * (36 / 1480));
      }
    }

    &.backgroundSection {
      & .imgContainer {
        margin-top: calc(100vw * (20 / 1480));

        &:not(:last-of-type) {
          margin-bottom: calc(100vw * (50 / 1480));
        }

        & .img {
          height: calc(100vw * (420 / 1480));
        }

        &:first-of-type .img {
          padding: calc(100vw * (40 / 1480)) calc(100vw * (297 / 1480));
        }

        &:last-of-type .img {
          padding: calc(100vw * (40 / 1480)) calc(100vw * (422 / 1480));
        }
      }
    }

    &.standardSection {
      & ul {
        gap: calc(100vw * (20 / 1480)) calc(100vw * (15 / 1480));
        margin: calc(100vw * (30 / 1480)) 0 calc(100vw * (20 / 1480));

        & .standardList {
          width: calc(100vw * (430 / 1480));
        }
      }

      & .img {
        height: calc(100vw * (660 / 1480));
        padding: calc(100vw * (39 / 1480)) calc(100vw * (266 / 1480));
      }
    }

    &.processSection {
      & .sectionDescription {
        margin-bottom: calc(100vw * (20 / 1480));
      }

      & .inner > figure {
        margin-bottom: calc(100vw * (50 / 1480));

        &:first-of-type .img {
          height: calc(100vw * (420 / 1480));
          padding: calc(100vw * (41 / 1480)) calc(100vw * (280 / 1480));
        }

        &:last-of-type .img {
          height: calc(100vw * (686 / 1480));
          padding: calc(100vw * (30 / 1480)) calc(100vw * (255 / 1480));
        }
      }

      & .imgContainer .img {
        height: calc(100vw * (348 / 1480));
        padding: calc(100vw * (30 / 1480)) calc(100vw * (388 / 1480));
      }
    }
  }

  @media (max-width: 768px) {
    &.propertySection {
      & .img {
        height: calc(100vw * (197 / 375));
        padding: calc(100vw * (25 / 375)) calc(100vw * (24 / 375));
      }
    }

    &.backgroundSection {
      & .imgContainer {
        margin-top: calc(100vw * (20 / 375));

        &:not(:last-of-type) {
          margin-bottom: calc(100vw * (30 / 375));
        }

        & .img {
          height: calc(100vw * (189 / 375));
        }

        &:first-of-type .img {
          padding: calc(100vw * (16 / 375)) calc(100vw * (15 / 375));
        }

        &:last-of-type .img {
          padding: calc(100vw * (12 / 375)) calc(100vw * (52 / 375));
        }
      }
    }

    &.standardSection {
      & ul {
        gap: calc(100vw * (14 / 375));
        margin: calc(100vw * (20 / 375)) 0;

        & .standardList {
          width: 100%;
        }
      }

      & .img {
        height: calc(100vw * (260 / 375));
        padding: calc(100vw * (20 / 375)) calc(100vw * (19 / 375));
      }
    }

    &.processSection {
      & .sectionDescription {
        margin-bottom: calc(100vw * (20 / 375));
      }

      & .inner > figure {
        margin-bottom: calc(100vw * (30 / 375));

        &:first-of-type .img {
          height: calc(100vw * (168 / 375));
          padding: calc(100vw * (20 / 375)) calc(100vw * (24 / 375));
        }

        &:last-of-type .img {
          height: calc(100vw * (274 / 375));
          padding: calc(100vw * (20 / 375)) calc(100vw * (16 / 375));
        }
      }

      & .imgContainer .img {
        height: calc(100vw * (201 / 375));
        padding: calc(100vw * (20 / 375)) calc(100vw * (16 / 375));
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
