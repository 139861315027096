import { useAtom } from "jotai";
import { useState } from "react";
import { useQuery } from "react-query";
import { api } from "service";
import { modalAtom } from "store/atoms";

export default function useQuickProduct({
  listOption,
  relateListOption,
  groupDateListOption,
  groupListOption,
  monthListOption,
}) {

  const listQuery = useQuery(["product-quick",listOption], 
    ()=>{
      return api.productQuick.getList(listOption);
    }, {
    enabled:!!listOption,
    refetchOnWindowFocus: false,
  });

  const groupDateQuery = useQuery(["product-quick/date",groupDateListOption], 
    ()=>{
      return api.productQuick.getGroupDateList(groupDateListOption);
    }, {
    enabled:!!groupDateListOption,
    refetchOnWindowFocus: false,
  });

  const groupListQuery = useQuery(["product-quick/date/list",groupListOption], 
    ()=>{
      return api.productQuick.getGroupList(groupListOption);
    }, {
    enabled:!!groupListOption,
    refetchOnWindowFocus: false,
  });

  const relateListQuery = useQuery(["product-quick/date/list",relateListOption], 
    ()=>{
      return api.productQuick.getRelateList(relateListOption);
    }, {
    enabled:!!relateListOption,
    refetchOnWindowFocus: false,
  });

  const monthListQuery = useQuery(["product-quick/month/list",monthListOption], 
    ()=>{
      return api.productQuick.getMonthList(monthListOption);
    }, {
    enabled:!!monthListOption,
    refetchOnWindowFocus: false,
  });

  


  return {
    listQuery,
    relateListQuery,
    groupListQuery,
    groupDateQuery,
    monthListQuery,
  };
}
