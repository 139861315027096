import styled from "styled-components";

const Container = styled.main`
  min-height: calc(100vh - 287px);
  min-height: calc(100dvh - 287px);
  padding: 180px 0 200px;

  & .mainTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 80px;

    & h2 {
      font-size: 62px;
      font-weight: 800;
    }

    & .inquiryButton {
      width: 260px;
    }
  }

  & h3 {
    font-size: 36px;
    font-weight: bold;
  }

  & .cnbis {
    margin-bottom: 80px;

    & h3 {
      margin-bottom: 20px;
    }

    & .tab {
      margin-bottom: 30px;
    }
  }

  & .intacs h3 {
    margin-bottom: 50px;
  }

  @media (max-width: 1480px) {
    min-height: calc(100vh - calc(100vw * (287 / 1480)));
    min-height: calc(100dvh - calc(100vw * (287 / 1480)));
    padding: calc(100vw * (180 / 1480)) 0 calc(100vw * (200 / 1480));

    & .mainTitle {
      margin-bottom: calc(100vw * (80 / 1480));

      & h2 {
        font-size: calc(100vw * (62 / 1480));
      }

      & .inquiryButton {
        width: calc(100vw * (260 / 1480));
      }
    }

    & h3 {
      font-size: calc(100vw * (36 / 1480));
    }

    & .cnbis {
      margin-bottom: calc(100vw * (80 / 1480));

      & h3 {
        margin-bottom: calc(100vw * (20 / 1480));
      }

      & .tab {
        margin-bottom: calc(100vw * (30 / 1480));
      }
    }

    & .intacs h3 {
      margin-bottom: calc(100vw * (50 / 1480));
    }
  }

  @media (max-width: 768px) {
    min-height: calc(100vh - calc(100vw * (309 / 375)));
    min-height: calc(100dvh - calc(100vw * (309 / 375)));
    padding: calc(100vw * (114 / 375)) 0 calc(100vw * (130 / 375));

    & .mainTitle {
      margin-bottom: calc(100vw * (49 / 375));

      & h2 {
        font-size: calc(100vw * (32 / 375));
      }

      & .inquiryButton {
        width: calc(100vw * (180 / 375));
      }
    }

    & h3 {
      font-size: calc(100vw * (24 / 375));
    }

    & .cnbis {
      margin-bottom: calc(100vw * (50 / 375));

      & h3 {
        margin-bottom: calc(100vw * (14 / 375));
      }

      & .tab {
        margin-bottom: calc(100vw * (30 / 375));
      }
    }

    & .intacs h3 {
      margin-bottom: calc(100vw * (30 / 375));
    }
  }
`;

export { Container };

export default { Container };
