import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  padding-right: 24px;
  height: 80px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 16px;
  border: 1px solid var(--gray02);

  & .number,
  .pinText {
    width: 150px;
    margin-right: 50px;
    text-align: center;
  }

  & .pinText {
    color: var(--blue02);
  }

  & .titleWrap {
    display: flex;
    align-items: center;
    // margin: 0 88px 0 auto;
  }

  & .title {
    width: 770px;
    margin-right: 113px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  & .fileIcon {
    width: 24px;
    height: 24px;
    filter: var(--blackFilter);
  }

  & .date {
    margin-left: auto;
  }

  &.pin {
    background-color: var(--blue04);
    border-color: var(--blue04);
  }

  @media (max-width: 1480px) {
    padding-right: calc(100vw * (24 / 1480));
    height: calc(100vw * (80 / 1480));
    font-size: calc(100vw * (18 / 1480));
    border-radius: calc(100vw * (16 / 1480));

    & .number,
    .pinText {
      width: calc(100vw * (150 / 1480));
      margin-right: calc(100vw * (50 / 1480));
    }

    & .titleWrap {
      // margin: 0 calc(100vw * (88 / 1480)) 0 auto;
    }

    & .title {
      width: calc(100vw * (770 / 1480));
      margin-right: calc(100vw * (113 / 1480));
    }

    & .fileIcon {
      width: calc(100vw * (24 / 1480));
      height: calc(100vw * (24 / 1480));
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 calc(100vw * (14 / 375)) 0 calc(100vw * (16 / 375));
    height: calc(100vw * (111 / 375));
    font-size: calc(100vw * (14 / 375));
    border-radius: calc(100vw * (12 / 375));

    & .number,
    .pinText {
      width: 100%;
      margin: 0 0 calc(100vw * (8 / 375));
      text-align: left;
    }

    & .titleWrap {
      margin: 0;
    }

    & .title {
      width: calc(100vw * (271 / 375));
      margin-right: calc(100vw * (16 / 375));
    }

    & .fileIcon {
      width: calc(100vw * (18 / 375));
      height: calc(100vw * (18 / 375));
    }

    & .date {
      width: 100%;
      margin: calc(100vw * (20 / 375)) 0 0;
    }
  }

  @media (hover: hover) {
    &:hover {
      color: #fff;
      background-color: var(--blue02);
      border-color: var(--blue02);

      & .pinText {
        color: #fff;
      }

      & .fileIcon {
        filter: var(--whiteFilter);
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
