import Styles from "./styles";
import { layout, ui } from "components";
import { useLanguage } from "hooks";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

// img
import ImgSafety01 from "resources/image/business/service/img_safety01.jpg"; // 이미지 국문 변경 필요
import ImgSafety01En from "resources/image/business/service/img_safety01_en.jpg";
import ImgSafety02 from "resources/image/business/service/img_safety02.jpg"; // 이미지 국문 변경 필요
import ImgSafety02En from "resources/image/business/service/img_safety02_en.jpg";
import ImgSafety03 from "resources/image/business/service/img_safety03.jpg"; // 이미지 국문 변경 필요
import ImgSafety03En from "resources/image/business/service/img_safety03_en.jpg";
import ImgSafety04 from "resources/image/business/service/img_safety04.jpg";
import ImgSafety04En from "resources/image/business/service/img_safety04_en.jpg";
import ImgSafety05 from "resources/image/business/service/img_safety05.jpg";
import ImgSafety05En from "resources/image/business/service/img_safety05_en.jpg";

function Safety() {
  const languageInfo = useLanguage()
  const languageQuarter = languageInfo.suffix === ""
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });

  const navigate = useNavigate();

  return (
    <>
      <Styles.Container className="titleSection">
        <div className="inner" id="focus">
          <layout.DescriptionBox
            title={"Functional Safety"}
            left={languageQuarter ? "기능안전이란" : "Functional safety"}
            right={languageQuarter ?
              <>
                기능안전 basic standard IEC 61508 표준에서는 아래와 같이
                정의하고 있습니다. <br />
                “part of the overall safety relating to the EUC (Equipment Under
                Control) and the EUC control system which depends on the correct
                functioning of the E/E/PE safety-related systems, other
                technology safety-related systems and external risk reduction
                facilities”
                <br />
                <br />
                EUC(제어 장비)나 E/E/PE 안전 관련 시스템의 올바른 기능 작동에
                의존하는 EUC 제어 시스템, 기타 기술 안전 관련 시스템, 그리고
                외부 위험 감소 방안과 관련된 전반적인 안전을 다룬다는 의미로
                해석할 수 있습니다.
              </> : <>
                The functional safety basic standard IEC 61508 is defined as follows.
                “part of the overall safety relating to the EUC (Equipment Under Control)
                and the EUC control system which depends on the correct functioning of the E/E/PE safety-related systems,
                other technology safety-related systems and external risk reduction facilities”
                <br />
                <br />
                It can be interpreted to mean that functional safety covers the overall safety associated with EUC control systems,
                other technical safety-related systems, and external risk reduction measures that those depend on the correct functioning
                of the control equipment (EUC) or E/E/PE safety-related systems.
              </>
            }
          />
        </div>
      </Styles.Container>

      <Styles.Container className="propertySection">
        <div className="inner">
          <h3>{languageQuarter ? "ISO 26262 표준" : "ISO 26262 standard"}</h3>

          <p className="sectionDescription">
            {languageQuarter ?
              <>
                IEC 61508 표준을 기반으로 automotive 향 적용 표준인 ISO 26262 표준은
                아래와 같이 정의하고 있습니다. <br />
                “absence of unreasonable risk due to hazards caused by
                malfunctioning behaviour of E/E systems”
                <br />
                <br />
                E/E 시스템의 오작동이 야기하는 위험 요소에 대해 비합리적인 위험이
                없는 상태를 의미합니다. 두 표준의 용어 정의에 일부 차이는 있으나
                개발관점에서 개발 과정상에서 발생하는 Error로 인한 risk 관리(회피,
                공유, 전가, 감수 및 통제)를 요구하고 있으며, 무작위 하드웨어
                고장(Random Hardware Failure) 발생 시에 이를 안전한 상태로
                제어하도록 요구하는 것은 동일합니다.
              </> : <>
                ISO 26262 standard (the automated orientation application standard based on the IEC 61508 standard) is defined as follows.
                “absence of unreasonable risk due to hazards caused by malfunctioning behaviour of E/E systems”
                <br />
                <br />
                This means that there is no unreasonable risk to the hazards caused by the malfunction of the E/E system.
                Although there are some differences in the definition of the terms of the two standards, in the development point of view function safety
                requires risk management (avoidance, sharing, transfer, supervision and control) due to errors in the development process, and they require
                safe control in the event of a random hardware failure.
              </>}
          </p>
        </div>
      </Styles.Container>

      <Styles.Container className="backgroundSection">
        <div className="inner">
          <h3>{languageQuarter ? "기능안전 등장 배경" : "Functional safety appearance background"}</h3>

          <p className="sectionDescription">
            {languageQuarter ?
              <>
                기능안전의 등장 배경은 IEC 61508 또는 ISO 26262 표준을
                준비/검토/배포하는 과정으로 보는 경우가 있으나, 보다 근본적으로는
                첫째로 인명에 대한 사회의 인식 수준이 높아짐에 따라 오동작을 초래할
                수 있는 잠재적 요소에 대한 Risk 관리 요구가 증대되는 시점으로 볼 수
                있으며, 둘째로 안전과 관련된 공정/기능에 대해 전기/전자/소프트웨어를
                활용하여 이를 구현하게 된 시점이 후 새롭게 발생할 수 있는 오동작들에
                대해 적절한 Risk 관리가 필요해진 시점으로 볼 수 있습니다.
                <br />
                <br />
                이러한 안전을 위한 risk 관리의 요구에 맞춰 기능안전이 정의 되었습니다.
              </> : <>
                Functional safety is sometimes seen as a process of preparing/reviewing/distributing IEC 61508 or ISO 26262 standards,
                but more fundamentally, the increased need for risk management of potential malfunctions as society's awareness of human life increases,
                and secondly, the use of electrical/electronic/software for safety-related processes and functions requires proper risk management for possible malfunctions.
                <br />
                <br />
                Functional safety is defined to meet the needs of risk management for this safety.
              </>}
          </p>

          <div className="tableWrap">
            <div className="table">
              <layout.BusinessTable
                title={"IEC 61508"}
                description={
                  <ul>
                    {languageQuarter ?
                      <>
                        <li>
                          a. 1995년에 IEC 61508 초기 표준이 개발되었으며, 초기
                          표준은 위험 기반의 접근 방법(risk-based <br />
                          approach)을 사용하고 있습니다.
                        </li>

                        <li>
                          b. 1998년과 2000년 사이에 계속된 개정을 통해 현재 IEC
                          61508이 모든 산업 분야의 기본 표준이 <br />
                          되었습니다.
                        </li>
                      </> : <>
                        <li>
                          a. The IEC 61508 initial standard was developed in 1995, and the initial standard uses a risk-based approach.
                        </li>
                        <li>
                          b. Continued revisions between 1998 and 2000 have now made IEC 61508 the basic standard for all industries.
                        </li>
                      </>}
                  </ul>
                }
              />
            </div>

            <div className="table">
              <layout.BusinessTable
                title={"ISO 26262"}
                description={languageQuarter ?
                  <>
                    IEC 61508 을 기반으로 하여 2011년에 ISO 26262 초기 표준이
                    개발되었으며, <br className="pcOnly" />
                    2018년 2nd edition이 배포되어 자동차 산업에 적용되고
                    있습니다.
                  </> : <>
                    Based on IEC 61508, ISO 26262 initial standards were developed in 2011,
                    and 2nd edition was distributed in 2018 and applied to the automotive industry.
                  </>
                }
              />
            </div>
          </div>
        </div>
      </Styles.Container>

      <Styles.Container className="demandSection">
        <div className="inner">
          <h3>{languageQuarter ? "Failure design에 대한 요구" : "Request for Failure design"}</h3>

          <div className="wrap">
            <div className="imgContainer">
              <figure>
                <div className="img">
                  <img src={languageQuarter ? ImgSafety01 : ImgSafety01En} alt="ImgSafety01" />
                </div>
              </figure>
            </div>

            <div className="textContainer">
              <p className="sectionDescription">
                {languageQuarter ?
                  <>
                    기능안전을 달성한 시스템은 궁극적으로 잠재적인 H.Hazard (첫번째
                    그림) 에 대한 Risk management (식별된 Risk에 대한 회피, 공유,
                    전가 또는 수용) 를 필요로 하는데, 이를 위해 설계관점에서는
                    Intended function system의 잠재적 C.Faulty condition 를
                    식별하여야 하며, 이를 감지하여 제어할 수 있도록 추가적인
                    기능구현(FMU/SMU 등의 safety mechanism)이 필요합니다.
                    <br />
                    <br />
                    Safety-related system 은 faulty condition 발생 여부 상황에 대해
                    주기적으로 모니터링 하여야 하며, 시스템은 사전에 정의된 시나리오
                    (safe state) 로 faulty 상황을 제어할 수 있어야 합니다. 이러한
                    접근법에서 기능안전 달성을 위해서는 고장설계가 필요합니다.
                    <br />
                    <br />
                    기능안전 적용이 어려운 여러 이유 중, 현실적인 이유는 이러한
                    failure design 을 위해 critical hazard 를 야기할 수 있는 Faulty
                    condition 에 대해 사전에 모두 식별하여 고객이 인지할 수 있도록
                    하고 해결 방안을 모색해야 한다는 점이며, potential failure 에
                    대한 체계적인 접근 하에 safety concept (concept 에 따라 D.
                    Additional signals 이 상이함)을 수립해 본 경험이 국내 업체는
                    선진업체 대비 부족하다는 점입니다.
                    <br />
                    <br />
                    또한, 기능안전 달성을 위해서는 System development phase 동안
                    System operation phase 에서 발생할 수 있는 fault를 사전에
                    식별하여 제어할 수 있도록 제품 개발 과정에서 <br />
                    안전 수명주기를 적용하여 Systematic fault 에 대한 회피를
                    요구하고 있습니다.
                  </> : <>
                    Systems that have achieved functional safety ultimately require risk management
                    (avoidance, sharing, transfer, or acceptance of identified risks) for potential H. Hazard (first picture),
                    To this end, from a design perspective, potential C. Faulty conditions of the Intended function system must be identified,
                    and additional functionality (safety mechanisms such as FMU/SMU) are required to detect and control them.
                    <br />
                    <br />
                    The safety-related system shall monitor periodically the status whether the faulty condition is triggered,
                    and the system could control the faulty status into the predefined scenario (safe state). In this approach,
                    failure design is necessary to achieve the functional safety.
                    <br />
                    <br />
                    Among the many reasons why it is difficult to apply functional safety, the practical reason is that all Faulty conditions
                    that can cause critical hazards should be identified in advance so that customers can recognize them and seek solutions.
                    The domestic company lacks experience in establishing a safety concept (D. Additional signals vary depending on the safety concept)
                    under the consideration of systematic approach for potential failure.
                    <br />
                    <br />
                    In addition, in order to achieve functional safety, it is required to avoid systematic failures by applying a safety life cycle during
                    the product development process so that faults that may occur in the system operation phase can be identified and controlled in advance
                    during the system development phase.
                  </>}
              </p>
            </div>
          </div>
        </div>
      </Styles.Container>

      <Styles.Container className="cycleSection">
        <div className="inner">
          <h3>{languageQuarter ? "안전수명 주기" : "Safe life cycle"}</h3>

          <div className="wrap">
            <figure>
              <figcaption>ISO 26262 overall safety lifecycle</figcaption>

              <div className="img">
                <img src={languageQuarter ? ImgSafety02 : ImgSafety02En} alt="ImgSafety02" />
              </div>
            </figure>

            <figure>
              <figcaption>IEC 61508 overall safety lifecycle</figcaption>

              <div className="img">
                <img
                  src={languageQuarter ? ImgSafety03 : ImgSafety03En}
                  alt="ImgSafety03"
                />
              </div>
            </figure>
          </div>
        </div>
      </Styles.Container>

      <Styles.Container className="serviceSection">
        <div className="inner">
          <h3>{languageQuarter ? "안전수명 주기" : "Functional Safety Services at C&BIS"}</h3>

          <div className="wrap">
            <div>
              <p className="title">
                {languageQuarter ?
                  <>
                    1. 기능안전 달성을 위한 기능안전 표준 프로세스 구축 서비스를{" "}
                    <span className="mbOnly"></span>
                    제공하고 있습니다.
                  </> : <>
                    1. We provide a functional safety standard process<br /> construction service to achieve functional safety.
                  </>}
              </p>

              <p className="text">
                {languageQuarter ?
                  <>
                    a. 기능안전 표준 프로세스는 하기의 5단계로 진행됩니다.
                  </> : <>
                    a. The functional safety standard process <br />proceeds through the following five steps.
                  </>}
              </p>

              <figure>
                <div className="img">
                  <img src={languageQuarter ? ImgSafety04 : ImgSafety04En} alt="ImgSafety04" />
                </div>
              </figure>
            </div>

            <div>
              <p className="title">
                {languageQuarter ?
                  <>
                    2. 기능안전 달성을 위한 엔지니어링 컨설팅 및 교육 서비스를{" "}
                    <span className="mbOnly"></span>
                    제공하고 있습니다.
                  </> : <>
                    2. We provide engineering consulting and training services <br />to achieve functional safety.
                  </>}
              </p>

              <p className="text">
                {languageQuarter ?
                  <>
                    a. 기능안전 엔지니어링 수행 절차는 하기 5단계로{" "}
                    <br className="mbOnly" />
                    <span className="mbOnly"></span>수행됩니다.
                  </> : <>
                    a. The functional safety engineering performance procedure is carried<br /> out in the following five steps.
                  </>}
              </p>

              <figure>
                <div className="img">
                  <img src={languageQuarter ? ImgSafety05 : ImgSafety05En} alt="ImgSafety05" />
                </div>
              </figure>

              <p className="guideText">
                {languageQuarter ?
                  <>
                    ※ 안전분석 (FMEA, FMEDA, FTA 등) 의 경우 당사에서 용역 진행 가능{" "}
                    <br />
                    (상기 Documentation 과정의 Role, 엔지니어링 주관부서 ▸ 컨설턴트)
                  </> : <>
                    ※ In the case of safety analysis (FMEA, FMEDA, FTA, etc.), <br />
                    we can proceed with the service <br />(Role in the above documentation process,
                    engineering department -> Consultant)
                  </>}
              </p>
            </div>
          </div>
        </div>
      </Styles.Container>

      <div className="listButton">
        <ui.button.BasicButton
          buttonText={languageQuarter ? "목록으로" : "List"}
          buttonType={"blue"}
          onClick={() => {
            navigate("/business/depth/service?disableScrollTop=true&focus=bis_menu_0");
          }}
        />
      </div>
    </>
  );
}

export default Safety;
