import CalendarSample from "./CalendarSample";
import MainBanner from "./MainBanner";
import KakaoMap from "./KakaoMap";
import EducationSlider from "./EducationSlider";
import ScrollGuide from "./ScrollGuide";
import DescriptionBox from "./DescriptionBox";
import BusinessTable from "./BusinessTable";
import Calendar from "./Calendar";

export {
  CalendarSample,
  MainBanner,
  KakaoMap,
  EducationSlider,
  ScrollGuide,
  DescriptionBox,
  BusinessTable,
  Calendar,
};

export default {
  CalendarSample,
  MainBanner,
  KakaoMap,
  EducationSlider,
  ScrollGuide,
  DescriptionBox,
  BusinessTable,
  Calendar,
};
