import { useLanguage } from "hooks";
import Styles from "./styles";

// img
import IconArrowLeft from "resources/image/icon/icon_arrow_left_gray01.svg";
import IconArrowRight from "resources/image/icon/icon_arrow_right_gray01.svg";

function ScrollGuide() {
  const languageInfo = useLanguage()
  const languageQuarter = languageInfo.suffix === ""

  return (
    <Styles.Container>
      <div className="icon">
        <img src={IconArrowLeft} alt="IconArrowLeft" />
      </div>
      {languageQuarter ? "좌우 스크롤로 내용을 확인해보세요." : "Please scroll left and right"}
      <div className="icon">
        <img src={IconArrowRight} alt="IconArrowRight" />
      </div>
    </Styles.Container>
  );
}

export default ScrollGuide;
