import styled from "styled-components";

// img
import IconEssential from "resources/image/icon/icon_essential.svg";

const Container = styled.div`
  & .inputLabel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    & label {
      position: relative;
      display: inline-block;
      font-size: 20px;
      font-weight: bold;

      &.essential::after {
        position: absolute;
        top: 0;
        right: -10px;
        width: 7px;
        height: 7px;
        background: url(${IconEssential}) no-repeat center / cover;
        content: "";
      }
    }

    & .errorText,
    .confirmText {
      font-size: 18px;
      font-weight: 500;
      color: var(--red);
    }

    & .confirmText {
      color: #2771ff;
    }
  }

  & input {
    width: 100%;
    height: 60px;
    padding: 0 20px;
    font-size: 18px;
    border: 2px solid var(--gray02);
    border-radius: 6px;

    &::placeholder {
      color: var(--gray01);
    }

    &.error {
      border-color: var(--red);
    }
  }

  @media (max-width: 1480px) {
    & .inputLabel {
      margin-bottom: calc(100vw * (20 / 1480));

      & label {
        font-size: calc(100vw * (20 / 1480));

        &.essential::after {
          right: calc(100vw * (-10 / 1480));
          width: calc(100vw * (7 / 1480));
          height: calc(100vw * (7 / 1480));
        }
      }

      & .errorText,
      .confirmText {
        font-size: calc(100vw * (18 / 1480));
      }
    }

    & input {
      height: calc(100vw * (60 / 1480));
      padding: 0 calc(100vw * (20 / 1480));
      font-size: calc(100vw * (18 / 1480));
      border: calc(100vw * (2 / 1480)) solid var(--gray02);
      border-radius: calc(100vw * (6 / 1480));
    }
  }

  @media (max-width: 768px) {
    & .inputLabel {
      margin-bottom: calc(100vw * (14 / 375));

      & label {
        font-size: calc(100vw * (14 / 375));

        &.essential::after {
          right: calc(100vw * (-8 / 375));
          width: calc(100vw * (5 / 375));
          height: calc(100vw * (5 / 375));
        }
      }

      & .errorText,
      .confirmText {
        font-size: calc(100vw * (12 / 375));
      }
    }

    & input {
      height: calc(100vw * (41 / 375));
      padding: 0 calc(100vw * (14 / 375));
      font-size: calc(100vw * (14 / 375));
      border: calc(100vw * (2 / 375)) solid var(--gray02);
      border-radius: calc(100vw * (6 / 375));
    }
  }
`;

export { Container };

export default {
  Container,
};
