import styled from "styled-components";

const Container = styled.section`
  &.backgroundSection {
    & .tableWrap {
      display: flex;
      gap: 14px;
      margin-top: 30px;

      & .table {
        flex: 1 0;

        & > div {
          height: 100%;
        }

        & .bottom {
          height: max-content;
          text-align: start;
        }
      }
    }
  }

  &.demandSection {
    & .img {
      height: 366px;
      padding: 20px 19px;
    }
  }

  &.cycleSection {
    & .img {
      width: 630px;
      height: 624px;
    }

    & figure:first-child .img {
      padding: 30px 38px;
    }

    & figure:last-child .img {
      padding: 34px 19px;
    }
  }

  &.serviceSection {
    & .title {
      font-weight: bold;
    }

    & .text {
      margin: 20px 0 30px;
      padding-left: 20px;
    }

    & .img {
      width: 630px;
      height: 650px;
      padding: 30px 73px;
    }

    & .guideText {
      margin-top: 20px;
      font-size: 14px;
      line-height: 1.42;
    }
  }

  @media (max-width: 1480px) {
    &.backgroundSection {
      & .tableWrap {
        gap: calc(100vw * (14 / 1480));
        margin-top: calc(100vw * (30 / 1480));
      }
    }

    &.demandSection {
      & .img {
        height: calc(100vw * (366 / 1480));
        padding: calc(100vw * (20 / 1480)) calc(100vw * (19 / 1480));
      }
    }

    &.cycleSection {
      & .img {
        width: calc(100vw * (630 / 1480));
        height: calc(100vw * (624 / 1480));
      }

      & figure:first-child .img {
        padding: calc(100vw * (30 / 1480)) calc(100vw * (38 / 1480));
      }

      & figure:last-child .img {
        padding: calc(100vw * (34 / 1480)) calc(100vw * (19 / 1480));
      }
    }

    &.serviceSection {
      & .text {
        margin: calc(100vw * (20 / 1480)) 0 calc(100vw * (30 / 1480));
        padding-left: calc(100vw * (20 / 1480));
      }

      & .img {
        width: calc(100vw * (630 / 1480));
        height: calc(100vw * (650 / 1480));
        padding: calc(100vw * (30 / 1480)) calc(100vw * (73 / 1480));
      }

      & .guideText {
        margin-top: calc(100vw * (20 / 1480));
        font-size: calc(100vw * (14 / 1480));
      }
    }
  }

  @media (max-width: 768px) {
    &.backgroundSection {
      & .tableWrap {
        flex-direction: column;
        gap: calc(100vw * (14 / 375));
        margin-top: calc(100vw * (20 / 375));

        & .bottom {
          padding: calc(100vw * (11 / 375)) calc(100vw * (16 / 375));
        }
      }
    }

    &.demandSection {
      & .img {
        height: calc(100vw * (204 / 375));
        padding: calc(100vw * (14 / 375));
      }
    }

    &.cycleSection {
      & .img {
        width: 100%;
        height: calc(100vw * (345 / 375));
      }

      & figure:first-child .img {
        padding: calc(100vw * (20 / 375)) calc(100vw * (18 / 375));
      }

      & figure:last-child .img {
        height: calc(100vw * (327 / 375));
        padding: calc(100vw * (23 / 375)) calc(100vw * (15 / 375))
          calc(100vw * (20 / 375));
      }
    }

    &.serviceSection {
      & .title {
        line-height: 1.42;
      }

      & span.mbOnly {
        display: inline-block;
        margin-left: calc(100vw * (14 / 375));
      }
      & .text {
        margin: calc(100vw * (14 / 375)) 0;
        padding-left: calc(100vw * (20 / 375));
      }

      & .img {
        width: 100%;
        height: calc(100vw * (400 / 375));
        padding: calc(100vw * (18 / 375));
      }

      & .guideText {
        margin-top: calc(100vw * (14 / 375));
        font-size: calc(100vw * (12 / 375));
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
