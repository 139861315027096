import BasicInput from "./BasicInput";
import BasicCheckbox from "./BasicCheckbox";
import ApplyRadio from "./ApplyRadio";
import BasicRadio from "./BasicRadio";
import DateInput from "./DateInput";
import BasicTextarea from "./BasicTextarea";

export {
  BasicInput,
  BasicCheckbox,
  ApplyRadio,
  BasicRadio,
  DateInput,
  BasicTextarea,
};

export default {
  BasicInput,
  BasicCheckbox,
  ApplyRadio,
  BasicRadio,
  DateInput,
  BasicTextarea,
};
