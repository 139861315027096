import Styles from "./styles";
import utils, { etc } from "utils";
import { common, ui } from "components";
import { useParams, useNavigate, useLocation } from "react-router-dom";

// img
import { useTransaction } from "hooks";
import { useEffect } from "react";

function ApplyCompletePage() {
  const { id } = useParams();
  const location = useLocation();
  const transactionInfo = useTransaction({
    transactionDetailOption: { id: id },
  });

  
  const detail = transactionInfo?.detailQuery?.data?.data || {};
  const navigate = useNavigate();

  const renderPay = (type) => {
    switch (type) {
      case "CARD":
        return (
          <>
            카드 결제{" "}
            <a
              href="https://consumer.tosspayments.com/payment-history/card"
              target="_blank"
            >
              결제 영수증 조회
            </a>
          </>
        );

      case "VBANK":
        return "세금계산서(무통장 입금)";

      case "OFFLINE":
        return "현장 결제";
    }
  };

  // const dateSame = true;
  // // detail?.group_list[0]?.option_list[0]?.study_started_at ===
  // // detail?.group_list[0]?.option_list[0]?.study_ended_at;
  

  // data
  const educationList = [
    {
      title: "교육과정",
      text: detail?.group_list ? detail?.group_list[0]?.title : "",
    },
    {
      title: "교육기간",
      text: (
        <>
          {/* yyyy.mm.dd 형태로 넣어주세요 */}
          {detail?.group_list ? detail?.group_list[0]?.date_title : ""}
          {detail?.group_list &&
          detail?.group_list[0] &&
          detail?.group_list[0].option_list
            ? detail?.group_list[0]?.option_list?.[0]?.study_started_at === detail?.group_list?.[0]?.option_list[0]?.study_ended_at 
              ? utils.date.getDateType(detail?.group_list[0]?.option_list[0]?.study_started_at)
              : utils.date.getDateType(detail?.group_list[0]?.option_list[0]?.study_started_at) +
                " ~ " + utils.date.getDateType(detail?.group_list[0]?.option_list[0]?.study_ended_at)
            : ""}
          {/* {data.educationData.applyList[0].startDate} ~{" "}
          {data.educationData.applyList[0].endDate} */}
        </>
      ),
    },
    {
      title: "교육비용",
      text: <>{etc.numberWithCommas(detail?.total_price)}원</>,
    },
  ];
  
  
  const userList = [
    {
      title: "이름(국문)",
      text: detail?.name,
    },
    {
      title: "이름(영문)",
      text: detail?.name_en,
    },
    {
      title: "생년월일",
      text: utils.date.getDateType(detail?.birthday),
    },
    {
      title: "성별",
      text:
        detail?.gender == "M"
          ? "남성"
          : detail?.gender == "F"
          ? "여성"
          : "미선택",
    },
    {
      title: "소속(국문)",
      text: detail?.department,
    },
    {
      title: "소속(영문)",
      text: detail?.department_en,
    },
    {
      title: "연락처",
      text: detail?.phone,
    },
    {
      title: "이메일",
      text: detail?.email,
    },
    {
      title: "심사원 ID",
      text: detail?.code,
    },
  ];

  useEffect(() => {
    if (location.state) return;
    if (detail.merchant_uid) {
      if (id + detail.merchant_uid == sessionStorage.getItem("check")) {
        navigate(location.pathname, { state: true, replace: true });
        sessionStorage.removeItem("check");
      } else {
        navigate(-1);
      }
    }
  }, [detail.merchant_uid]);



  return (
    location.state && (
      <>
        <common.Header />
        <Styles.Container>
          <section>
            <div className="inner">
              <div className="title">
                <h2>교육 신청완료</h2>

                <p>
                  신청이 완료되었습니다. <br />
                  신청 내역은 [교육신청 조회]를 통해 확인 가능합니다.
                </p>
              </div>

              <div className="educationInfo wrap">
                <h3>교육정보</h3>

                <ul>
                  {educationList.map((item, index) => {
                    return (
                      <li className="list" key={"educationInfoList" + index}>
                        <div className="listTitle">{item.title}</div>

                        <p>{item.text}</p>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className="userInfo wrap">
                <h3>신청자 정보</h3>

                <ul>
                  {userList.map((item, index) => {
                    return (
                      <li className="list" key={"userInfoList" + index}>
                        <div className="listTitle">{item.title}</div>

                        <p>{item.text}</p>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className="payInfo wrap">
                <h3>결제수단</h3>

                <p className="payDetail">{renderPay(detail?.method)}</p>
              </div>

              <div className="payInfo wrap">
                <h3>환불 규정</h3>

                <div className="termsDetail">
                  <ul>
                    <li>
                      C&BIS 교육과정은 교육일 기준 일주일 전까지 취소 가능하며,
                      이후 취소 시 교재 비용을 제외한 금액만 환불됩니다.
                    </li>
                    <li>
                      intacs™ 교육과정은 신청 마감일까지 취소 가능하며, 이후
                      취소 시 라이선스 비용(100만원)을 제외한 금액만 환불됩니다.
                    </li>
                    <li>
                      카드 결제의 경우, 신청 취소 시 카드사 영업일 기준 3~5일
                      이내 환불됩니다.
                    </li>
                    <li>
                      세금계산서 발행의 경우, 신청 취소 시 C&BIS 담당자 확인 후
                      환불 관련 개별 연락드립니다.
                    </li>
                    <li>
                      현장 결제의 경우, 당일 취소 및 No-show 발생 시 C&BIS에서
                      진행하는 모든 교육에 대해 6개월~1년간 수강이 불가합니다.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="buttonWrap">
                <div className="prevButton">
                  <ui.button.BasicButton
                    buttonText={"메인으로 돌아가기"}
                    buttonType={"white"}
                    onClick={() => {
                      navigate("/education/apply");
                    }}
                  />
                </div>

                {/* pay 값 마다 완료 페이지가 다릅니다. */}
                <div className="nextButton">
                  <ui.button.BasicButton
                    buttonText={"교육신청 조회"}
                    buttonType={"blue"}
                    onClick={() => {
                      navigate("/inquiry");
                    }}
                  />
                </div>
              </div>
            </div>
          </section>
        </Styles.Container>
        <common.Footer />
      </>
    )
  );
}

export default ApplyCompletePage;
