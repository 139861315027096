import styled from "styled-components";

// img
import IconCheckOn from "resources/image/icon/icon_check_on.svg";

const Container = styled.div`
  position: relative;

  & input {
    display: none;

    &:checked ~ .applyWrap {
      color: #fff;
      background-color: var(--blue02);

      & .checkbox {
        background: url(${IconCheckOn}) no-repeat center / 12px #fff;
      }

      & .state {
        display: none;
      }
    }

    &:disabled ~ .applyWrap {
      color: var(--gray02);
      background-color: rgba(232, 232, 232, 0.4);
      border-color: var(--gray02);

      & .checkbox {
        border-color: var(--gray02);
      }
    }
  }

  & label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  & .applyWrap {
    display: flex;
    width: 100%;
    height: 63px;
    padding: 11px 20px 11px 12px;
    color: var(--blue02);
    border: 1px solid var(--blue02);
    border-radius: 5px;

    & .checkbox {
      width: 18px;
      height: 18px;
      margin-right: 10px;
      background-color: #fff;
      border: 1px solid var(--blue02);
      border-radius: 50%;
    }

    & .period {
      margin-bottom: 3px;
      font-size: 14px;
      font-weight: 600;
    }

    & .date {
      font-weight: bold;
    }

    & .state {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
      width: 95px;
      height: 27px;
      margin-left: auto;
      font-size: 14px;
      font-weight: 600;
      background-color: #fff;
      border: 1px solid var(--blue02);
      border-radius: 5px;

      &.unable {
        color: var(--gray01);
        border-color: var(--gray01);
      }
    }
  }

  @media (max-width: 1480px) {
    & input {
      &:checked ~ .applyWrap {
        & .checkbox {
          background: url(${IconCheckOn}) no-repeat center /
            calc(100vw * (12 / 1480)) #fff;
        }
      }
    }

    & .applyWrap {
      height: calc(100vw * (63 / 1480));
      padding: calc(100vw * (11 / 1480)) calc(100vw * (20 / 1480))
        calc(100vw * (11 / 1480)) calc(100vw * (12 / 1480));
      border-radius: calc(100vw * (5 / 1480));

      & .checkbox {
        width: calc(100vw * (18 / 1480));
        height: calc(100vw * (18 / 1480));
        margin-right: calc(100vw * (10 / 1480));
      }

      & .period {
        margin-bottom: calc(100vw * (3 / 1480));
        font-size: calc(100vw * (14 / 1480));
      }

      & .state {
        width: calc(100vw * (95 / 1480));
        height: calc(100vw * (27 / 1480));
        font-size: calc(100vw * (14 / 1480));
        border-radius: calc(100vw * (5 / 1480));
      }
    }
  }

  @media (max-width: 768px) {
    & input {
      &:checked ~ .applyWrap {
        & .checkbox {
          background: url(${IconCheckOn}) no-repeat center /
            calc(100vw * (12 / 375)) #fff;
        }
      }
    }

    & .applyWrap {
      height: calc(100vw * (63 / 375));
      padding: calc(100vw * (11 / 375)) calc(100vw * (12 / 375));
      border-radius: calc(100vw * (5 / 375));

      & .checkbox {
        width: calc(100vw * (18 / 375));
        height: calc(100vw * (18 / 375));
        margin-right: calc(100vw * (10 / 375));
      }

      & .period {
        margin-bottom: calc(100vw * (3 / 375));
        font-size: calc(100vw * (14 / 375));
      }

      & .state {
        width: calc(100vw * (95 / 375));
        height: calc(100vw * (27 / 375));
        font-size: calc(100vw * (14 / 375));
        border-radius: calc(100vw * (5 / 375));
      }
    }
  }
`;

export { Container };

export default { Container };
