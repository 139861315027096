import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 11;

  & .modalInner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 44px 30px 30px;
    background-color: var(--blue02);

    & .img {
      width: 1099px;
      height: 757px;
      background-color: #fff;
      overflow: auto;

      & img {
        width: auto;
        height: auto;
      }
    }

    & .closeButton,
    .todayCloseButton {
      position: absolute;
      top: 10px;
      right: 29px;
      width: 24px;
      height: 24px;
    }
  }

  & .overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(34, 34, 34, 0.7);
  }

  @media (max-width: 1480px) {
    & .modalInner {
      padding: calc(100vw * (44 / 1480)) calc(100vw * (30 / 1480))
        calc(100vw * (30 / 1480));

      & .img {
        width: calc(100vw * (1099 / 1480));
        height: calc(100vw * (757 / 1480));
      }

      & .closeButton,
      .todayCloseButton {
        top: calc(100vw * (10 / 1480));
        right: calc(100vw * (29 / 1480));
        width: calc(100vw * (24 / 1480));
        height: calc(100vw * (24 / 1480));
      }
    }
  }

  @media (max-width: 768px) {
    & .modalInner {
      padding: calc(100vw * (30 / 375)) calc(100vw * (12 / 375))
        calc(100vw * (12 / 375));

      & .img {
        width: calc(100vw * (296 / 375));
        height: calc(100vw * (590 / 375));
        max-height: 75vh;
        max-height: 75dvh;
      }

      & .closeButton,
      .todayCloseButton {
        top: calc(100vw * (8 / 375));
        right: calc(100vw * (10 / 375));
        width: calc(100vw * (14 / 375));
        height: calc(100vw * (14 / 375));
      }
    }
  }
`;

export { Container };

export default { Container };
