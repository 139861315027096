import Styles from "./styles";

// img
import IconArrowRight from "resources/image/icon/icon_arrow_right_circle.svg";
import IconArrowLeft from "resources/image/icon/icon_arrow_left_circle.svg";
import utils from "utils";

export default function Calendar({ calendar, activeAll }) {
  
  function getDayRender(day, i) {
    return (
      <div
        // 스케줄 있을 때 active 클래스 넣어주세요
        className={`date ${day.enable && !day.isBefore && !day.isAfter && "active"} 
          ${day.curDay === 0 ? " sunday" : ""} `}
        key={"day_info_" + "_" + i}
        onClick={() => {
          if((!day.isBefore && !day.isAfter) == false)return;
          if(activeAll)return;
          if (!day.isBefore && !day.isAfter) calendar.onClickDate(day.date);
        }}
      >
        <>
          <p className={!day.isBefore && !day.isAfter ? "" : "emptyDate"}>
            {day.day}
          </p>
        </>
      </div>
    );
  }

  function renderCalendar(dateObject) {
    let days = dateObject.days;
    let resultTag = [];
    let dayTags = [];

    resultTag.push(
      <div key={"calendar_tag_00"} className="dayContainer">
        <p className="day sunday">Sun</p>
        <p className="day">Mon</p>
        <p className="day">Tue</p>
        <p className="day">Wed</p>
        <p className="day">Thu</p>
        <p className="day">Fri</p>
        <p className="day">Sat</p>
      </div>
    );

    for (let i = 0; i < days.length; i++) {
      //개행
      if (i != 0 && i % 7 == 0) {
        resultTag.push(
          <div key={"calendar_tag_" + i} className="row">
            {dayTags}
          </div>
        );
        dayTags = [];
      }
      //element 요소
      dayTags.push(getDayRender(days[i], i));

      //개행
      if (days.length === i + 1) {
        resultTag.push(
          <div className="row" key={"calendar_tag_" + i}>
            {dayTags}
          </div>
        );
      }
    }

    return resultTag;
  }

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return (
    <Styles.Container>
      <div className="dateTopContainer">
        <div className="dateWrap">
          <span className="month">
            {monthNames[calendar.currentDate.getMonth()]}
          </span>

          <span className="year">{calendar.currentDate.getFullYear()}</span>
        </div>

        <div className="buttonContainer">
          <button
            type="button"
            onClick={() => {
              calendar.prevMonthEvent();
            }}
          >
            <img src={IconArrowLeft} alt="prev" />
          </button>
          <button
            type="button"
            onClick={() => {
              calendar.nextMonthEvent();
            }}
          >
            <img src={IconArrowRight} alt="next" />
          </button>
        </div>
      </div>

      <div className="dateBottomContainer">
        {renderCalendar(calendar.getDaysObject())}
      </div>
    </Styles.Container>
  );
}
