import styled from "styled-components";

// img
import BgOrganizeChart from "resources/image/introduction/bg_organize_chart.png";
import BgOrganizeChartMb from "resources/image/introduction/bg_organize_chart_mb.png";

const Container = styled.main`
  min-height: 100vh;
  min-height: 100dvh;

  & .chartSection {
    padding: 80px 0 200px;
    background: url(${BgOrganizeChart}) no-repeat center / cover;

    & .img {
      width: 1256px;
      height: 872px;
      margin: 0 auto;
    }
  }

  @media (max-width: 1480px) {
    & .chartSection {
      padding: calc(100vw * (80 / 1480)) 0 calc(100vw * (200 / 1480));

      & .img {
        width: calc(100vw * (1256 / 1480));
        height: calc(100vw * (872 / 1480));
      }
    }
  }

  @media (max-width: 768px) {
    min-height: calc(100vh - calc(100vw * (309 / 375)));
    min-height: calc(100dvh - calc(100vw * (309 / 375)));
    padding-top: calc(100vw * (66 / 375));

    & .chartSection {
      padding: calc(100vw * (50 / 375)) 0 calc(100vw * (130 / 375));
      background: url(${BgOrganizeChartMb}) no-repeat center bottom / 100%
        calc(100vw * (641 / 375));

      & .scrollContainer {
        margin-bottom: calc(100vw * (30 / 375));
      }

      & .img {
        width: calc(100vw * (821 / 375));
        height: calc(100vw * (656 / 375));
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
