import styled from "styled-components";

const Container = styled.main`
  min-height: 100vh;
  min-height: 100dvh;
  padding-bottom: 200px;

  & section:not(:last-of-type) {
    margin-bottom: 80px;
  }

  & h3 {
    margin-bottom: 50px;
    padding-bottom: 30px;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: -0.02em;
    border-bottom: 1px solid var(--gray02);
  }

  & .wrap {
    display: flex;
    justify-content: space-between;
  }

  & .sectionDescription {
    font-size: 18px;
    line-height: 1.44;
    word-break: keep-all;
  }

  & figcaption {
    margin-bottom: 10px;
    font-weight: 500;
    color: var(--gray01);
  }

  & figure .img {
    border: 1px solid var(--gray02);
    border-radius: 10px;
  }

  & .grayBox {
    & .title {
      margin-bottom: 10px;
      font-size: 22px;
      font-weight: bold;

      & span {
        color: var(--blue02);
      }
    }

    & .text {
      padding: 30px;
      line-height: 1.37;
      word-break: keep-all;
      background-color: #f6f6f6;
    }

    & figure .img {
      border-color: var(--gray01);
      border-radius: 20px;
    }
  }

  & .codebeamerTitleSection {
    & ul {
      display: flex;
      flex-wrap: wrap;
      gap: 14px;
      margin: 30px 0;

      & li {
        width: calc(calc(100% - 14px) / 2);
      }
    }

    & .img {
      width: 630px;
      height: 344px;
    }

    & figure:first-child .img {
      padding: 34px 46px;
    }

    & figure:last-child .img {
      padding: 34px 169px;
    }
  }

  & .codebeamerOverviewSection {
    & .textContainer {
      width: 653px;

      & li:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    & .imgContainer {
      width: 645px;

      & .img {
        height: 420px;
        padding: 50px 132px;
      }
    }
  }

  & .almSection {
    & .inner > .img {
      width: 1260px;
      height: 300px;
      margin-bottom: 20px;
    }

    & figure .img {
      height: 420px;
      padding: 20px 358px;
    }
  }

  & .codebeamerAlmSection {
    & .sectionDescription {
      margin-bottom: 20px;
    }

    & .img {
      height: 420px;
      padding: 59.5px 262.5px;
    }
  }

  & .codebeamerEffectSection,
  .stagesEffectSection,
  .mediniOverviewSection {
    & .wrap {
      justify-content: flex-start;
      align-items: center;
      gap: 40px;
    }
  }

  & .codebeamerEffectSection {
    & .wrap .img {
      width: 363px;
      height: 76px;
    }
  }

  & .stagesEffectSection {
    & .wrap .img {
      width: 206px;
      height: 76px;
    }
  }

  & .mediniOverviewSection {
    & ul {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      & li {
        width: calc(calc(100% - 20px) / 2);
      }
    }

    & .grayBox {
      & figure {
        margin-top: 30px;

        & .img {
          height: 400px;
        }
      }

      &:not(:last-child) {
        margin-bottom: 50px;
      }

      &:first-of-type {
        & .img {
          padding: 40px 393px;
        }
      }

      &:nth-of-type(2) {
        & .img {
          padding: 50px 481px;
        }
      }

      &:last-of-type {
        & figure .img {
          padding: 40px 473px;
        }

        & .wrap {
          margin-top: 20px;

          & .img {
            width: 233px;
            height: 76px;
          }
        }
      }
    }
  }

  & .listButton {
    width: 260px;
    margin: 80px auto 0;
  }

  @media (max-width: 1480px) {
    padding-bottom: calc(100vw * (200 / 1480));

    & section:not(:last-of-type) {
      margin-bottom: calc(100vw * (80 / 1480));
    }

    & h3 {
      margin-bottom: calc(100vw * (50 / 1480));
      padding-bottom: calc(100vw * (30 / 1480));
      font-size: calc(100vw * (36 / 1480));
    }

    & .sectionDescription {
      font-size: calc(100vw * (18 / 1480));
    }

    & figcaption {
      margin-bottom: calc(100vw * (10 / 1480));
    }

    & figure .img {
      border-radius: calc(100vw * (10 / 1480));
    }

    & .grayBox {
      & .title {
        margin-bottom: calc(100vw * (10 / 1480));
        font-size: calc(100vw * (22 / 1480));
      }

      & .text {
        padding: calc(100vw * (30 / 1480));
      }
    }

    & .codebeamerTitleSection {
      & ul {
        gap: calc(100vw * (14 / 1480));
        margin: calc(100vw * (30 / 1480)) 0;

        & li {
          width: calc(calc(100% - calc(100vw * (14 / 1480))) / 2);
        }
      }

      & .img {
        width: calc(100vw * (630 / 1480));
        height: calc(100vw * (344 / 1480));
      }

      & figure:first-child .img {
        padding: calc(100vw * (34 / 1480)) calc(100vw * (46 / 1480));
      }

      & figure:last-child .img {
        padding: calc(100vw * (34 / 1480)) calc(100vw * (169 / 1480));
      }
    }

    & .codebeamerOverviewSection {
      & .textContainer {
        width: calc(100vw * (653 / 1480));

        & li:not(:last-child) {
          margin-bottom: calc(100vw * (20 / 1480));
        }
      }

      & .imgContainer {
        width: calc(100vw * (645 / 1480));

        & .img {
          height: calc(100vw * (420 / 1480));
          padding: calc(100vw * (50 / 1480)) calc(100vw * (132 / 1480));
        }
      }
    }

    & .almSection {
      & .inner > .img {
        width: calc(100vw * (1260 / 1480));
        height: calc(100vw * (300 / 1480));
        margin-bottom: calc(100vw * (20 / 1480));
      }

      & figure .img {
        height: calc(100vw * (420 / 1480));
        padding: calc(100vw * (20 / 1480)) calc(100vw * (358 / 1480));
      }
    }

    & .codebeamerAlmSection {
      & .sectionDescription {
        margin-bottom: calc(100vw * (20 / 1480));
      }

      & .img {
        height: calc(100vw * (420 / 1480));
        padding: calc(100vw * (59.5 / 1480)) calc(100vw * (262.5 / 1480));
      }
    }

    & .codebeamerEffectSection,
    .stagesEffectSection,
    .mediniOverviewSection {
      & .wrap {
        gap: calc(100vw * (40 / 1480));
      }
    }

    & .codebeamerEffectSection {
      & .wrap .img {
        width: calc(100vw * (363 / 1480));
        height: calc(100vw * (76 / 1480));
      }
    }

    & .stagesEffectSection {
      & .wrap .img {
        width: calc(100vw * (206 / 1480));
        height: calc(100vw * (76 / 1480));
      }
    }

    & .mediniOverviewSection {
      & ul {
        gap: calc(100vw * (20 / 1480));

        & li {
          width: calc(calc(100% - calc(100vw * (20 / 1480))) / 2);
        }
      }

      & .grayBox {
        & figure {
          margin-top: calc(100vw * (30 / 1480));

          & .img {
            height: calc(100vw * (400 / 1480));
          }
        }

        &:not(:last-child) {
          margin-bottom: calc(100vw * (50 / 1480));
        }

        &:first-of-type {
          & .img {
            padding: calc(100vw * (40 / 1480)) calc(100vw * (393 / 1480));
          }
        }

        &:nth-of-type(2) {
          & .img {
            padding: calc(100vw * (50 / 1480)) calc(100vw * (481 / 1480));
          }
        }

        &:last-of-type {
          & figure .img {
            padding: calc(100vw * (40 / 1480)) calc(100vw * (473 / 1480));
          }

          & .wrap {
            margin-top: calc(100vw * (20 / 1480));

            & .img {
              width: calc(100vw * (233 / 1480));
              height: calc(100vw * (76 / 1480));
            }
          }
        }
      }
    }

    & .listButton {
      width: calc(100vw * (260 / 1480));
      margin: calc(100vw * (80 / 1480)) auto 0;
    }
  }

  @media (max-width: 768px) {
    min-height: calc(100vh - calc(100vw * (309 / 375)));
    min-height: calc(100dvh - calc(100vw * (309 / 375)));
    padding: calc(100vw * (66 / 375)) 0 calc(100vw * (130 / 375));

    & section:not(:last-of-type) {
      margin-bottom: calc(100vw * (50 / 375));
    }

    & h3 {
      margin-bottom: calc(100vw * (30 / 375));
      padding-bottom: calc(100vw * (20 / 375));
      font-size: calc(100vw * (24 / 375));
    }

    & .wrap {
      flex-direction: column;
      gap: calc(100vw * (20 / 375));
    }

    & .sectionDescription {
      font-size: calc(100vw * (14 / 375));
      line-height: 1.42;
    }

    & figcaption {
      margin-bottom: calc(100vw * (6 / 375));
    }

    & figure .img {
      border-radius: calc(100vw * (10 / 375));
    }

    & .grayBox {
      & .title {
        margin-bottom: calc(100vw * (6 / 375));
        font-size: calc(100vw * (16 / 375));
      }

      & .text {
        padding: calc(100vw * (14 / 375)) calc(100vw * (20 / 375));
      }
    }

    & .codebeamerTitleSection {
      & ul {
        gap: calc(100vw * (14 / 375));
        margin: calc(100vw * (30 / 375)) 0;

        & li {
          width: 100%;
        }
      }

      & .img {
        width: 100%;
      }

      & figure:first-child .img {
        height: calc(100vw * (226 / 375));
        padding: calc(100vw * (18 / 375)) calc(100vw * (11 / 375));
      }

      & figure:last-child .img {
        height: calc(100vw * (203 / 375));
        padding: calc(100vw * (20 / 375)) calc(100vw * (12 / 375));
      }
    }

    & .codebeamerOverviewSection {
      & .textContainer {
        width: 100%;

        & li:not(:last-child) {
          margin-bottom: calc(100vw * (14 / 375));
        }
      }

      & .imgContainer {
        width: 100%;

        & .img {
          height: calc(100vw * (287 / 375));
          padding: calc(100vw * (20 / 375));
        }
      }
    }

    & .almSection {
      & .inner > .img {
        width: 100%;
        height: calc(100vw * (240 / 375));
        margin-bottom: calc(100vw * (20 / 375));
      }

      & figure .img {
        height: calc(100vw * (206 / 375));
        padding: calc(100vw * (20 / 375));
      }
    }

    & .codebeamerAlmSection {
      & .sectionDescription {
        margin-bottom: calc(100vw * (20 / 375));
      }

      & .img {
        height: calc(100vw * (164 / 375));
        padding: calc(100vw * (20 / 375)) calc(100vw * (14 / 375));
      }
    }

    & .codebeamerEffectSection,
    .stagesEffectSection,
    .mediniOverviewSection {
      & .wrap {
        gap: calc(100vw * (14 / 375));
      }
    }

    & .codebeamerEffectSection {
      & .wrap .img {
        width: calc(100vw * (277 / 375));
        height: calc(100vw * (58 / 375));
      }
    }

    & .stagesEffectSection {
      & .wrap .img {
        width: calc(100vw * (207 / 375));
        height: calc(100vw * (76 / 375));
      }
    }

    & .mediniOverviewSection {
      & ul {
        gap: calc(100vw * (13 / 375));
        margin-bottom: calc(100vw * (30 / 375));

        & li {
          width: 100%;
        }
      }

      & .grayBox {
        & figure {
          margin-top: calc(100vw * (20 / 375));
        }

        &:not(:last-child) {
          margin-bottom: calc(100vw * (30 / 375));
        }

        &:first-of-type {
          & .img {
            height: calc(100vw * (226 / 375));
            padding: calc(100vw * (20 / 375)) calc(100vw * (10 / 375));
          }
        }

        &:nth-of-type(2) {
          & .img {
            height: calc(100vw * (318 / 375));
            padding: calc(100vw * (29 / 375)) calc(100vw * (18 / 375));
          }
        }

        &:last-of-type {
          & figure .img {
            height: calc(100vw * (332 / 375));
            padding: calc(100vw * (20 / 375)) calc(100vw * (5 / 375));
          }

          & .wrap {
            margin-top: calc(100vw * (30 / 375));

            & .img {
              width: calc(100vw * (295 / 375));
              height: calc(100vw * (96 / 375));
            }
          }
        }
      }
    }

    & .listButton {
      width: calc(100vw * (180 / 375));
      margin: calc(100vw * (50 / 375)) auto 0;
    }
  }
`;

export { Container };

export default {
  Container,
};
