import ModalPage from "./ModalPage";
import MainPage from "./MainPage";
import GreetingPage from "./GreetingPage";
import HistoryPage from "./HistoryPage";
import OrganizeChartPage from "./OrganizeChartPage";
import PerformancePage from "./PerformancePage";
import DirectionPage from "./DirectionPage";
import BusinessAreaPage from "./BusinessAreaPage";
import BusinessServicePage from "./BusinessServicePage";
import BusinessAssessmentPage from "./BusinessAssessmentPage";
import BusinessRndPage from "./BusinessRndPage";
import BusinessSolutionPage from "./BusinessSolutionPage";
import EducationCenterPage from "./EducationCenterPage";
import EducationCnbisCurriculmPage from "./EducationCnbisCurriculmPage";
import EducationIntacsCurriculmPage from "./EducationIntacsCurriculmPage";
import EducationCurriculmDetailPage from "./EducationCurriculmDetailPage";
import EducationApplyPage from "./EducationApplyPage";
import EducationApplyDetailPage from "./EducationApplyDetailPage";
import ApplyPage from "./ApplyPage";
import ApplyCompletePage from "./ApplyCompletePage";
import SchedulePage from "./SchedulePage";
import TermsPage from "./TermsPage";
import CustomerSupportPage from "./CustomerSupportPage";
import ClosePage from "./ClosePage";
import InquiryPage from "./InquiryPage";
import InquiryResultPage from "./InquiryResultPage";
import InquiryDetailPage from "./InquiryDetailPage";

export default {
  ModalPage,
  MainPage,
  GreetingPage,
  HistoryPage,
  OrganizeChartPage,
  PerformancePage,
  DirectionPage,
  BusinessAreaPage,
  BusinessServicePage,
  BusinessAssessmentPage,
  BusinessRndPage,
  BusinessSolutionPage,
  EducationCenterPage,
  EducationCnbisCurriculmPage,
  EducationIntacsCurriculmPage,
  EducationCurriculmDetailPage,
  EducationApplyPage,
  EducationApplyDetailPage,
  ApplyPage,
  ApplyCompletePage,
  SchedulePage,
  TermsPage,
  CustomerSupportPage,
  ClosePage,
  InquiryPage,
  InquiryResultPage,
  InquiryDetailPage,
};

export {
  ModalPage,
  MainPage,
  GreetingPage,
  HistoryPage,
  OrganizeChartPage,
  PerformancePage,
  DirectionPage,
  BusinessAreaPage,
  BusinessServicePage,
  BusinessAssessmentPage,
  BusinessRndPage,
  BusinessSolutionPage,
  EducationCenterPage,
  EducationCnbisCurriculmPage,
  EducationIntacsCurriculmPage,
  EducationCurriculmDetailPage,
  EducationApplyPage,
  EducationApplyDetailPage,
  ApplyPage,
  ApplyCompletePage,
  SchedulePage,
  TermsPage,
  CustomerSupportPage,
  ClosePage,
  InquiryPage,
  InquiryResultPage,
  InquiryDetailPage,
};
