import Styles from "./styles";
import ScrollContainer from "react-indiana-drag-scroll";
import { layout, ui } from "components";
import { useLanguage } from "hooks";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

// img
import ImgOta01 from "resources/image/business/service/img_ota01.jpg";
import ImgOta02 from "resources/image/business/service/img_ota02.jpg";
import ImgOta03 from "resources/image/business/service/img_ota03.jpg";
import ImgOta04 from "resources/image/business/service/img_ota04.jpg";
import ImgOta05 from "resources/image/business/service/img_ota05.jpg";
import ImgOta05En from "resources/image/business/service/img_ota05_en.jpg";
import ImgOta05Mb from "resources/image/business/service/img_ota05_mb.jpg";
import ImgOta05MbEn from "resources/image/business/service/img_ota05_mb_en.jpg";
import IconWarning from "resources/image/icon/icon_warning.svg";

function Ota() {
  const languageInfo = useLanguage()
  const languageQuarter = languageInfo.suffix === ""
  const navigate = useNavigate();

  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });

  return (
    <>
      <Styles.Container className="titleSection">
        <div className="inner" id="focus">
          <layout.DescriptionBox
            title={"ISO 24089 Road vehicles — Software update engineering"}
            left={languageQuarter ?
              <>
                자동차 소프트웨어 <br />
                업데이트란
              </> : <>
                Automotive Software Update
              </>
            }
            right={languageQuarter ?
              <>
                2023년 2월에 제정된 자동차 소프트웨어 업데이트는 자동차
                소프트웨어 업데이트에 따른 표준이며 자동차 E/E 제품 개발에
                필수적인 요소로 자리잡고 있습니다. 이에 표준에서는 도로 차량의
                안전과 사이버 보안을 다루고 있습니다.
              </> : <>
                The automotive software update, established in February 2023, is a standard for
                automotive software updates and has become an integral part of the development
                of automotive E/E products. The standard covers the safety and cybersecurity of road vehicles.
              </>

            }
          />
        </div>
      </Styles.Container>

      <Styles.Container className="updateSection">
        <div className="inner">
          <h3>{languageQuarter ? "업데이트 및 보안" : "Updates and security"}</h3>

          <p className="sectionDescription">
            {languageQuarter ?
              <>
                차량 내 소프트웨어는 숙련된 인력이 작업장에서 업데이트
                <br className="mbOnly" />
                하거나 자동으로 무선으로 업데이트 됩니다. 차량 사용자에 의해
                소프트웨어 업데이트 캠페인의 빈도가 증가함에 따라 각각의 차량 구성
                정보를 갖습니다. 따라서, 소프트웨어 업데이트 엔지니어링은 소프트웨어
                품질, 사이버 보안 및 안전을 <br className="mbOnly" />
                보장하는 데 중요하며, 소프트웨어 업데이트 엔지니어링 활동은 차량의
                수명주기 전반에 걸쳐서 발생하기에 이를 준수할 수 있도록 하고
                있습니다.
              </> : <>
                In-vehicle software is updated in the workplace by experienced personnel,
                or automatically and wirelessly. Each vehicle configuration information is
                available as the frequency of software update campaigns is increased by vehicle
                users. As a result, software update engineering is critical to ensuring software
                quality, cybersecurity and safety, and software update engineering activities
                take place throughout the vehicle's life cycle to ensure compliance.
              </>}
          </p>

          <div className="imgContainer">
            <div className="source">
              <div className="icon">
                <img src={IconWarning} alt="IconWarning" />
              </div>

              <p>
                [{languageQuarter ? "출처" : "source"}] 兼容功能安全和信息安全的车载网
                <br className="mbOnly" />
                络解决方案是否存在？ - 知乎 (zhihu.com)
              </p>
            </div>
            <figure>
              <figcaption>{languageQuarter ? "자동차 소프트웨어 업데이트 및 보안의 예" : "Automotive software updates and security"}</figcaption>

              <div className="img">
                <img src={ImgOta01} alt="ImgOta01" />
              </div>
            </figure>
          </div>
        </div>
      </Styles.Container>

      <Styles.Container className="conceptSection">
        <div className="inner">
          <h3>{languageQuarter ? "자동차 소프트웨어 업데이트의 개념" : "Concept of Automotive Software Update"}</h3>

          <div className="wrap">
            <div className="textContainer">
              <p className="sectionDescription">
                {languageQuarter ?
                  <>
                    Over The Air는 케이블이나 다른 로컬 연결 대신 Cellular
                    네트워크를 사용하여 무선으로 데이터를 전송하거나 업데이트 하는
                    방법이라고 할 수 있습니다. Software의 Upload나 Download의 용어로
                    활용되어지고 있으며, 현재 자동차 산업에서 활발히 사용하려
                    준비중에 있습니다. Connectivity Network를 활용한 기술 방법으로
                    차량 간 통신 방식과, 차량 인프라 간 통신 방식으로 사용하고
                    있습니다. Firmware Over The Air라고도 표현하고 있으며, 차량에서
                    무선으로 편하게 업데이트를 할 수 있는 방식이라고 할 수 있습니다.
                    새로운 소프트웨어 펌웨어, 설정, 암호화키 업데이트를 휴대전화,
                    셋톱박스 등의 장치에 무선으로 배포하기 위한 방식을 의미하고
                    있습니다. <br />
                    <br />
                    Firmware의 경우, ROM, EPROM, flash memory 등 비휘발성 메모리에
                    탑재되어 있어서 생산 이후에는 변경이 불가능 합니다. Firmware의
                    버그나 새로운 기능을 추가하기 위해 반드시 필요한 물리적 연결의
                    불편함을 해결하기 위해 무선으로 배포하는 기술이 필요하게
                    되었습니다. 중앙 제어소 혹은 서버에서 모든 사용자에게 전송
                    가능하며 사용자는 업데이트를 거부 및 변조가 불가능하도록
                    구성되어져 있으며, 업데이트는 채널을 통해 모든 사용자들에게 즉시
                    적용이 가능하도록 구성됩니다.
                  </> : <>
                    Over The Air is a method of transmitting or updating data wirelessly using a cellular
                    network instead of a cable or other local connection. It is used as a term for software
                    upload or download, and is currently preparing to be actively used in the automobile industry.
                    It is a technological method utilizing the Connectivity Network and is used as a communication
                    method between vehicles and vehicle infrastructure. It is also described as Firmware Over The Air,
                     and it can be said that it is a convenient way to update wirelessly from the vehicle. It refers
                     to a method for wirelessly distributing new software firmware, settings, and encryption key updates
                     to devices such as mobile phones and set-top boxes.
                    <br />
                    <br />
                    Firmware is installed in non-volatile memory such as ROM, EPROM, flash memory, etc., and cannot be
                    changed after production. Wireless deployment technology is needed to address the inconvenience of
                    physical connections that are essential to adding bugs or new features in Firmware. It can be sent
                    to all users at a central control station or server, and users are configured to deny or modulate
                    updates, and updates are configured to be immediately applicable to all users through channels.
                  </>}
              </p>
            </div>

            <div className="imgContainer">
              <div className="source">
                <div className="icon">
                  <img src={IconWarning} alt="IconWarning" />
                </div>

                <p>
                  [{languageQuarter ? "출처" : "source"}] https://medium.com/@karimhamdymo/
                  <br className="mbOnly" />
                  over-the-air-ota-
                  <br className="pcOnly" />
                  updates-what-is-it-and-
                  <br className="mbOnly" />
                  how-to-do-it-simply-efficiently-with-
                  <br className="pcOnly" />
                  zdm-
                  <br className="mbOnly" />
                  db613ea29678
                </p>
              </div>
              <figure>
                <figcaption>
                  {languageQuarter ? "Software Update(OTA)에 대한 개념 및 정의" : "Concepts and definitions for Software Update (OTA)"}
                </figcaption>

                <div className="img">
                  <img src={ImgOta02} alt="ImgOta02" />
                </div>
              </figure>
            </div>
          </div>
        </div>
      </Styles.Container>

      <Styles.Container className="backgroundSection">
        <div className="inner">
          <h3>
            {languageQuarter ?
              <>
                ISO 24089 Software <br />
                Update Engineering의 도입 배경
              </> : <>
                The introduction background of ISO 24089 Software Update Engineering
              </>}
          </h3>

          <div className="wrap">
            <div className="imgContainer">
              <div className="source">
                <div className="icon">
                  <img src={IconWarning} alt="IconWarning" />
                </div>

                <p>{languageQuarter ? "[출처] 윈드리버" : "[source] windriver"}</p>
              </div>
              <figure>
                <figcaption>
                  {languageQuarter ?
                    <>
                      Embedded System과 Connected Car의 진화과정(4단계)
                    </> : <>
                      Evolution of Embedded System and Connected Car (Step 4)
                    </>}
                </figcaption>

                <div className="img">
                  <img src={ImgOta03} alt="ImgOta03" />
                </div>
              </figure>
            </div>

            <div className="textContainer">
              <p className="sectionDescription">
                {languageQuarter ?
                  <>
                    자동차의 개발 Trend가 Hardware보다 Software의 비중이 더 커지고
                    있으며, 이런 움직임에 따라 Over the air(Software wireless
                    update)의 필요성이 대두되어 각 OEM에서는 경쟁적으로 개발,
                    상용화를 진행 중에 있습니다. <br />
                    Software상에서의 Source Code의 양과 복잡성이 커지고 계속적인
                    무선 업데이트로 인하여 사이버 보안과 같은 문제점들이
                    발생되어지고 있습니다.(공격 취약점 증가에 따른 Risk 발생 등){" "}
                    <br className="mbOnly" />
                    OTA 업데이트 차량의 보안 문제와 처리에 따른 비용 때문에{" "}
                    <br className="mbOnly" />각 OEM들이 어려움을 겪고 있으며,
                    신기술에 대한 도입 여부 및 추후 발생할 문제점들에 대한 내용들도
                    아직은 검토되어야할 부분들이 상당하다고 볼 수 있습니다.
                  </> : <>
                    The development trend of automobiles has a greater proportion of software than hardware,
                    and the need for Over The Air (Software wireless update) has emerged, and each OEM is competitively developing and commercializing it.
                    Increasing the volume and complexity of source codes on the software and continuous wireless
                    updates are creating problems such as cybersecurity (such as risk due to increased attack vulnerabilities)
                    Each OEM is experiencing difficulties due to security issues and processing costs of OTA updated vehicles,
                    and there are still many aspects that need to be reviewed regarding the introduction of new technology and future problems.
                  </>}
              </p>
            </div>
          </div>
        </div>
      </Styles.Container>

      <Styles.Container className="standardSection">
        <div className="inner">
          <h3>
            {languageQuarter ?
              <>
                ISO 24089 Software <br />
                Update Engineering의 표준 내용
              </> : <>
                ISO 24089 Software Update Engineering Standard Content
              </>}
          </h3>

          <p className="sectionDescription">
            {languageQuarter ?
              <>
                Software Update Engineering 표준 개발에 따른 내용은 총 9장으로
                구성되어져 있으며, 실제 개발과 관련된 프로세스는 4장 ~ 9장까지로
                구성되어져 있습니다. <br />
                다음은 표준에서 다루고 있는 구조도입니다.
              </> : <>
                The contents according to the development of the Software Update Engineering standard
                consists of a total of 9 chapters, and the process related to the actual development
                consists of 4 to 9 chapters. The following is a structural diagram covered by the standard.
              </>}
          </p>

          <figure>
            <div className="img">
              <img src={ImgOta04} alt="ImgOta04" />
            </div>
          </figure>

          <p className="sectionDescription">
            {languageQuarter ?
              <>
                표준에서 다루고 있는 내용은 다음과 같습니다.
              </> : <>
                The contents of the standard are as follows.
              </>}
          </p>

          <ScrollContainer className="imgWrap">
            <div className="img">
              <img src={
                languageQuarter
                  ? isMobile ? ImgOta05Mb : ImgOta05
                  : isMobile ? ImgOta05MbEn : ImgOta05En
              } alt="ImgOta05" />
            </div>
          </ScrollContainer>

          <layout.ScrollGuide />
        </div>
      </Styles.Container>

      <Styles.Container className="solutionSection">
        <div className="inner">
          <h3>
            {languageQuarter ?
              <>
                ISO 24089 소프트웨어 <br />
                업데이트 대응을 위한 솔루션 제공
              </> : <>
                C&BIS Services
              </>}
          </h3>

          <p className="sectionDescription">
            {languageQuarter ?
              <>
                저희 씨엔비스㈜에서는 ASPICE 및 ISO26262 기능안전 프로세스에 대한
                엔지니어링 컨설팅 능력을 바탕으로 자동차 소프트웨어 업데이트 표준을
                대응하기 위한 ISO24089/TC22/
                <br className="mbOnly" />
                SC32/
                <br className="pcOnly" />
                WG12 한국 대표로 활동하면서 표준 제정 활동을 수행
                <br className="mbOnly" />
                하고 있으며 빠르게 변화하고 있는 자동차 소프트웨어 업데이트에 대한
                첨단 기술들을 이해하고 있으며 이를 통해 다양한 컨설팅 솔루션을
                제공하고 있습니다. 이를 통해 제공되는 솔루션에는 ISO 24089
                소프트웨어 업데이트 표준 대응을 위해서 필요한 자동차 소프트웨어
                업데이트 프로세스 및 관련 프로세스 자산 구축, 자동차 소프트웨어
                업데이트 전문 인력 양성 및 교육 서비스 등을 제공하고 있습니다.
              </> : <>
                Based on its engineering consulting capabilities for ASPICE and ISO26262 functional
                safety processes, C&BIS is conducting standards establishment activities while representing
                Korea for ISO24089/TC22/SC32/WG12 to respond to automotive software update standards. We also
                understand the cutting-edge technologies for rapidly changing automotive software updates and
                provide various consulting solutions through them.
                The solutions provided through this include building the automotive software update process
                and related process assets necessary to respond to the ISO 24089 software update standard,
                as well as training and training services for automotive software update experts.
              </>}
          </p>
        </div>
      </Styles.Container>

      <div className="listButton">
        <ui.button.BasicButton
          buttonText={languageQuarter ? "목록으로" : "List"}
          buttonType={"blue"}
          onClick={() => {
            navigate("/business/depth/service?disableScrollTop=true&focus=bis_menu_0");
          }}
        />
      </div>
    </>
  );
}

export default Ota;
