import Styles from "./styles";
import { useLanguage } from "hooks";
import { common, layout } from "components";

// img
import BgBanner from "resources/image/introduction/bg_main.jpg";
import BgBannerMb from "resources/image/introduction/bg_main_mb.jpg";
import IconPlace from "resources/image/icon/icon_place.svg";
import IconCall from "resources/image/icon/icon_call.svg";
import IconFax from "resources/image/icon/icon_fax.svg";
import IconSend from "resources/image/icon/icon_send.svg";

function DirectionPage() {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === ""

  const mapList = [
    {
      title: languageQuarter ? "판교 본사" : "Pangyo Head Office",
      location: "headOffice",
      mapInfoList: [
        {
          icon: IconPlace,
          text: (languageQuarter ?
            <>
              경기도 성남시 수정구 창업로 43, <br />
              판교글로벌비즈센터 A동 907~910호
            </> : <>
              Rooms 907-910, Building A, <br className="mb_only" />Pangyo Global Biz Center, 43 <br />
              changeop-ro, Sujeong-gu, <br className="mb_only" />Seongnam-si, Gyeonggi-do
            </>
          ),
        },
        {
          icon: IconCall,
          text: "031-502-4900",
        },
        {
          icon: IconFax,
          text: "031-502-4902",
        },
        {
          icon: IconSend,
          text: "syjang@cnbis.co.kr",
        },
      ],
    },
    {
      title: languageQuarter ? "대구 지사" : "Branch office in Daegu",
      location: "branchOffice",
      mapInfoList: [
        {
          icon: IconPlace,
          text: (languageQuarter ?
            <>
              대구광역시 동구 동대구로 489 <br />
              대구무역회관 703호
            </> : <>
              Room 703, Daegu Trade Center, <br />
              489 Dongdaegu-ro, Dong-gu, Daegu
            </>
          ),
        },
        {
          icon: IconCall,
          text: "031-502-4900",
        },
        {
          icon: IconFax,
          text: "031-502-4902",
        },
        {
          icon: IconSend,
          text: "syjang@cnbis.co.kr",
        },
      ],
    },
  ];

  return (
    <>
      <common.Header />
      <Styles.Container>
        <section className="bannerSection">
          <layout.MainBanner
            img={BgBanner}
            mbImg={BgBannerMb}
            colorText={languageQuarter ? "회사소개" : "About C&BIS"}
            title={languageQuarter ? "오시는 길" : "Location"}
          />
        </section>

        <section className="mapSection">
          <div className="inner">
            {mapList.map((item, index) => {
              return (
                <div className="map" key={"map" + index}>
                  <div className="textWrap">
                    <p className="title">{item.title}</p>

                    <ul>
                      {item.mapInfoList.map((current, idx) => {
                        return (
                          <li className="mapInfoList" key={"mapInfoList" + idx}>
                            <div className="icon">
                              <img src={current.icon} alt="mapIcon" />
                            </div>

                            <p>{current.text}</p>
                          </li>
                        );
                      })}
                    </ul>
                  </div>

                  <div className="mapWrap">
                    <layout.KakaoMap location={item.location} />
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default DirectionPage;
