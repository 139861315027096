import Styles from "./styles";

function BusinessTable({ title, description }) {
  return (
    <Styles.Container>
      <div className="top">{title}</div>

      <div className="bottom">{description}</div>
    </Styles.Container>
  );
}

export default BusinessTable;
