import { useQuery } from "react-query";
import { api } from "service";

export default function useFileIntroduce({
  companyFile,
  studyFile,
}) {

  const getCompanyFile = useQuery(["companyFile"], () => {
    return api.fileIntroduce.getCompanyFile();
  }, {
    enabled: !!companyFile,
    refetchOnWindowFocus: false,
  });
  const getStudyFile = useQuery(["studyFile"], () => {
    return api.fileIntroduce.getStudyFile();
  }, {
    enabled: !!studyFile,
    refetchOnWindowFocus: false,
  });


	return {
		getCompanyFile,
    getStudyFile,
	};
}