import Styles from "./styles";
import AskList from "../AskList";
import { ui } from "components";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLanguage, useModals, useSupport } from "hooks";

//img
import IconArrowRight from "resources/image/icon/icon_arrow_right02.svg";
import utils from "utils";

function Ask() {
  const navigate = useNavigate();
  const location = useLocation();
  const modalOption = useModals();
  
  const [filter, setFilter] = useState({});
  const [isOpen, setIsOpen] = useState({});
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === ""
  const supportInfo = useSupport({ faqOption: filter?.recordSize && filter });
  const data = supportInfo.getFaqList.data || [];

  useEffect(() => {
    let param = utils.etc.getSearchParam();
    let f = {
      page: param.page ? param.page : 1,
      type: param.type ? param.type : 1,
      recordSize: 10,
    };
    setFilter({ ...f });
  }, [location]);

  const filterList = languageQuarter
    ? ["전체", "일반교육", "심사원교육", "기타"]
    : ["ALL", "CEC Education", "intacs™", "Etc."]

  return (
    <Styles.Container>
      <div className="top">
        <ul className="filter">
          {filterList.map((item, index) => {
            return (
              <li className="filterList" key={"filterList" + index}>
                <button
                  type="button"
                  className={filter.type == index + 1 ? "on" : ""}
                  onClick={() => {
                    navigate(utils.etc.getQueryParamUrl({ type: index + 1, page: 1 }));
                  }}
                >
                  {item}
                </button>
              </li>
            );
          })}
        </ul>

        <div className="askButton">
          <ui.button.BasicButton
            buttonText={languageQuarter ? "1:1 문의하기" : "1:1 inquiry"}
            buttonType={"whiteHover"}
            icon={IconArrowRight}
            onClick={() => {
              modalOption.setAskModal((e) => {
                e.show = true;

                return { ...e };
              });
            }}
          />
        </div>
      </div>

      <ul className="askWrap">
        {data.list?.map((item, index) => {
          return (
            <li className="askList" key={"askList" + index}>
              <AskList
                data={item}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                suffix={languageInfo.suffix}
              />
            </li>
          );
        })}
      </ul>

      <div className="pagination">
        <ui.Pagination
          page={data.pagination?.page}
          list={data.pagination?.page_list}
          maxPage={data.pagination?.totalPageCount}
        />
      </div>
    </Styles.Container>
  );
}

export default Ask;
