import styled from "styled-components";

// img
import IconCheckOff from "resources/image/icon/icon_check_off.svg";
import IconCheckOn from "resources/image/icon/icon_check_on.svg";

const Input = styled.input`
  display: none;

  &:checked + label {
    color: var(--blue02);
    border-color: var(--blue02);
    background-image: url(${IconCheckOn});
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 10px;
  width: 70px;
  height: 29px;
  font-size: 16px;
  color: var(--gray01);
  border: 2px solid var(--gray02);
  border-radius: 6px;
  background: url(${IconCheckOff}) no-repeat 10px center / 12px;
  cursor: pointer;

  @media (max-width: 1480px) {
    padding: 0 calc(100vw * (10 / 1480));
    width: calc(100vw * (70 / 1480));
    height: calc(100vw * (29 / 1480));
    font-size: calc(100vw * (16 / 1480));
    border: calc(100vw * (2 / 1480)) solid var(--gray02);
    border-radius: calc(100vw * (6 / 1480));
    background: url(${IconCheckOff}) no-repeat calc(100vw * (10 / 1480)) center /
      calc(100vw * (12 / 1480));
  }

  @media (max-width: 768px) {
    padding: 0 calc(100vw * (10 / 375));
    width: calc(100vw * (67 / 375));
    height: calc(100vw * (27 / 375));
    font-size: calc(100vw * (14 / 375));
    border: calc(100vw * (2 / 375)) solid var(--gray02);
    border-radius: calc(100vw * (6 / 375));
    background: url(${IconCheckOff}) no-repeat calc(100vw * (10 / 375)) center /
      calc(100vw * (13 / 375));
  }
`;

export { Input, Label };

export default { Input, Label };
