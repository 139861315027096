import styled from "styled-components";

const Container = styled.main`
  min-height: 100vh;
  min-height: 100dvh;
  padding: 180px 0 200px;

  & .titleWrap {
    display: flex;
    align-items: center;
    gap: 50px;
    margin-bottom: 80px;
    font-weight: 500;

    & h2 {
      font-size: 62px;
      font-weight: 800;
    }

    & p {
      line-height: 1.5;
    }
  }

  & .thead,
  .td a {
    display: flex;
    gap: 60px;
    padding: 0 29px 0 24px;
    text-align: center;
  }

  & .thead {
    margin-bottom: 18px;
    font-size: 20px;
    font-weight: bold;
  }

  & .td {
    &:not(:last-child) {
      margin-bottom: 10px;
    }

    & a {
      align-items: center;
      height: 80px;
      font-size: 18px;
      font-weight: 500;
      border: 1px solid var(--gray02);
      border-radius: 16px;

      &:hover {
        color: #fff;
        background-color: var(--blue02);
        border-color: var(--blue02);
      }
    }
  }

  & .number {
    width: 32px;
  }

  & .category,
  .title {
    width: 273px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  & .period {
    width: 215px;
  }

  & .method {
    width: 77px;
  }

  & .date {
    width: 101px;
  }

  & .pagination {
    margin-top: 80px;
  }

  & .emptyText {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 400px;
    background-color: #f6f6f6;
    border-radius: 20px;

    & .icon {
      width: 30px;
      height: 30px;
      margin-bottom: 20px;
    }

    & p {
      font-size: 18px;
      font-weight: 500;
    }
  }

  @media (max-width: 1480px) {
    padding: calc(100vw * (180 / 1480)) 0 calc(100vw * (200 / 1480));

    & .titleWrap {
      gap: calc(100vw * (50 / 1480));
      margin-bottom: calc(100vw * (80 / 1480));

      & h2 {
        font-size: calc(100vw * (62 / 1480));
      }
    }

    & .thead,
    .td a {
      gap: calc(100vw * (60 / 1480));
      padding: 0 calc(100vw * (29 / 1480)) 0 calc(100vw * (24 / 1480));
    }

    & .thead {
      margin-bottom: calc(100vw * (18 / 1480));
      font-size: calc(100vw * (20 / 1480));
    }

    & .td {
      &:not(:last-child) {
        margin-bottom: calc(100vw * (10 / 1480));
      }

      & a {
        height: calc(100vw * (80 / 1480));
        font-size: calc(100vw * (18 / 1480));
        border-radius: calc(100vw * (16 / 1480));
      }
    }

    & .number {
      width: calc(100vw * (32 / 1480));
    }

    & .category,
    .title {
      width: calc(100vw * (273 / 1480));
    }

    & .period {
      width: calc(100vw * (215 / 1480));
    }

    & .method {
      width: calc(100vw * (77 / 1480));
    }

    & .date {
      width: calc(100vw * (101 / 1480));
    }

    & .pagination {
      margin-top: calc(100vw * (80 / 1480));
    }

    & .emptyText {
      height: calc(100vw * (400 / 1480));
      border-radius: calc(100vw * (20 / 1480));

      & .icon {
        width: calc(100vw * (30 / 1480));
        height: calc(100vw * (30 / 1480));
        margin-bottom: calc(100vw * (20 / 1480));
      }

      & p {
        font-size: calc(100vw * (18 / 1480));
      }
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (116 / 375)) 0 calc(100vw * (130 / 375));

    & .titleWrap {
      flex-direction: column;
      align-items: flex-start;
      gap: calc(100vw * (20 / 375));
      margin-bottom: calc(100vw * (53 / 375));

      & h2 {
        font-size: calc(100vw * (32 / 375));
      }

      & p {
        line-height: 1.42;
      }
    }

    & .thead,
    .td a {
      gap: 0;
      padding: calc(100vw * (13 / 375)) calc(100vw * (13 / 375))
        calc(100vw * (16 / 375)) calc(100vw * (19 / 375));
    }

    & .thead {
      display: none;
    }

    & .td {
      &:not(:last-child) {
        margin-bottom: calc(100vw * (8 / 375));
      }

      & a {
        flex-wrap: wrap;
        align-content: flex-start;
        height: calc(100vw * (170 / 375));
        font-size: calc(100vw * (14 / 375));
        text-align: left;
        border-radius: calc(100vw * (12 / 375));
      }
    }

    & .number {
      width: calc(100vw * (18 / 375));
      margin-right: calc(100vw * (16 / 375));
    }

    & .category {
      width: calc(100vw * (266 / 375));
    }

    & .title,
    .period,
    .method,
    .date {
      width: 100%;
    }

    & .title {
      margin: calc(100vw * (20 / 375)) 0 calc(100vw * (8 / 375));
    }

    & .method {
      margin: calc(100vw * (8 / 375)) 0 calc(100vw * (20 / 375));
    }

    & .date {
      margin-top: auto;
    }

    & .pagination {
      margin-top: calc(100vw * (50 / 375));
    }

    & .emptyText {
      height: calc(100vw * (381 / 375));
      border-radius: calc(100vw * (12 / 375));

      & .icon {
        width: calc(100vw * (24 / 375));
        height: calc(100vw * (24 / 375));
        margin-bottom: calc(100vw * (16 / 375));
      }

      & p {
        font-size: calc(100vw * (14 / 375));
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
