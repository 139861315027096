import { useQuery } from "react-query";
import { api } from "service";

export default function useBanner({
  type
}) {

  const getBanner = useQuery(["banner", type], () => {
    return api.banner.getBanner(type);
  }, {
    enabled:!!type,
    refetchOnWindowFocus: false,
  });

  return { getBanner }
}