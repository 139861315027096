import Styles from "./styles";

// datepicker
import ReactDatePicker from "react-datepicker";
import { ko } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";

function DateInput({ data, value, setValue }) {
  const {
    id,
    essential,
    labelText,
    errorText,
    placeholder,
    startDate,
    setStartDate,
    maxDate,
    minDate,
  } = data;

  return (
    <Styles.Container>
      {labelText && (
        <div className="inputLabel">
          <label htmlFor={id} className={essential ? "essential" : ""}>
            {labelText}
          </label>

          {errorText && <p className="errorText">{errorText}</p>}
        </div>
      )}

      <ReactDatePicker
        selected={value ? value : startDate}
        onChange={(date) => {
          if (setValue) {
            setValue(date);
          } else setStartDate(date);
        }}
        placeholderText={placeholder}
        dateFormat="yyyy.MM.dd"
        locale={ko}
        className={`datepicker ${errorText ? "error" : ""}`}
        showYearDropdown
        maxDate={maxDate && maxDate}
        minDate={minDate && minDate}
      />
    </Styles.Container>
  );
}

export default DateInput;
