import Styles from "./styles";
import { useState } from "react";
import { common, ui } from "components";
import { useLanguage, useTerm } from "hooks";

function TermsPage() {
  const languageInfo = useLanguage()
  const [tabIndex, setTabIndex] = useState(0);
  const termInfo = useTerm({
    termPersonal: true,
    termService: true,
  });

  const tabList = [
    {
      text: "서비스 이용약관",
      text_en: "Terms and Conditions of Service",
      description: termInfo.getTermService?.data?.description,
      description_en: termInfo.getTermService?.data?.description_en,
    },
    {
      text: "개인정보처리방침",
      text_en: "Personal Information Processing Policy",
      description: termInfo.getTermPersonal?.data?.description,
      description_en: termInfo.getTermPersonal?.data?.description_en,
    },
  ];

  return (
    <>
      <common.Header />
      <Styles.Container>
        <section>
          <div className="inner">
            <h2 className="tabTitle">{tabList[tabIndex]["text" + languageInfo.suffix]}</h2>

            <div className="tab">
              <ui.button.Tab
                tabList={tabList}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
                language={languageInfo.suffix}
              />
            </div>

            <div
              className="editor ck-content"
              dangerouslySetInnerHTML={{__html:
                tabList[tabIndex]["description" + languageInfo.suffix]
              }}
            ></div>
          </div>
        </section>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default TermsPage;
