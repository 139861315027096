import Styles from "./styles";
import Marquee from "react-fast-marquee";
import { common, ui, layout } from "components";
import { useFileIntroduce, useLanguage } from "hooks";

// img
import BgBanner from "resources/image/introduction/bg_main.jpg";
import BgBannerMb from "resources/image/introduction/bg_main_mb.jpg";
import IconDownload from "resources/image/icon/icon_download.svg";

function GreetingPage() {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === ""
  const fileIntroduceInfo = useFileIntroduce({ companyFile: true });

  return (
    <>
      <common.Header />
      <Styles.Container>
        <section className="bannerSection">
          <layout.MainBanner
            img={BgBanner}
            mbImg={BgBannerMb}
            colorText={languageQuarter ? "회사소개" : "About C&BIS"}
            title={languageQuarter ? "CEO 인사말" : "CEO Message"}
          />
        </section>

        <section className="textSection">
          <div className="inner">
            <div className="left">
              <p>Together for</p>
              <p>a safer world</p>

              <a href={fileIntroduceInfo.getCompanyFile?.data?.file_url} download className="downloadButton">
                <ui.button.BasicButton
                  buttonText={languageQuarter ? "회사소개서 다운로드" : "Download Company Introduction"}
                  buttonType={"whiteHover"}
                  icon={IconDownload}
                />
              </a>
            </div>

            <div className="right">
              <p>
                {
                  languageQuarter ? <>
                    씨엔비스㈜는 프로세스 분야의 전문성을 바탕으로 2010년
                    설립되었습니다. <br className="pcOnly" />
                    당사는 SW 분야 프로세스 엔지니어링 역량을 인정받으며, 빠르게
                    변화하는 첨단 자동차 시장을 위한 비즈니스 솔루션을
                    제공해왔습니다.
                    <br />
                    <br />
                    씨엔비스㈜는 이러한 전문성과 글로벌 네트워크를 기반으로 고객과
                    함께 더 안전한 세상을 만들어나가는 기업으로 거듭나고자 합니다.
                    <br className="pcOnly" />
                    프로세스 문화 정착을 통해 더 안전하고, <br className="mbOnly" />
                    더 신뢰할 수 있는 자율주행 자동차 및 New Mobility 시장을
                    만들어나가는 씨엔비스가 되겠습니다.
                  </> : <>
                    C&BIS was founded in 2010 based on expertise in the process sector. <br className="pcOnly" />
                    We are recognized for our process engineering capabilities in the SW field and
                    have provided business solutions for the rapidly <br className="pcOnly" />
                    changing high-tech automotive market.
                    <br />
                    <br />
                    Based on this expertise and global network,
                    C&BIS wants to be reborn as a company that creates a safer world with customers.
                    <br className="pcOnly" />
                    Through the establishment of a process culture,
                    we will be a C&BIS that creates a safer,
                    <br className="pcOnly" />
                    more reliable self-driving car and New Mobility market.
                  </>
                }
              </p>
            </div>
          </div>
        </section>

        <section className="marqueeSection">
          <Marquee autoFill={true}>
            <p>Leading the Trend Automated Driving System</p>
          </Marquee>
        </section>

        <section className="youtubeSection">
          <div className="inner">
            {/* 스타일 지우고 사용 해 주세요 */}
            <div className="video">
              <iframe
                src="https://www.youtube.com/embed/_gDUKG2IMU4?si=C0j8zxUXHFZViNJG"
                title="YouTube video player"
                allowFullScreen
                referrerPolicy="strict-origin-when-cross-origin"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
            </div>
          </div>
        </section>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default GreetingPage;
