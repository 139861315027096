import Styles from "./styles";
import { useBanner, useLanguage } from "hooks";

// img
import IconClose from "resources/image/icon/icon_close02.svg";
import IconCheck from "resources/image/icon/icon_check.svg";

function MainModal({ modalClose }) {
  const bannerInfo = useBanner({ type: 2 });
  const bannerData = bannerInfo.getBanner?.data?.data[0];
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";

  const updateOneDayCookie = () => {
    var now = new Date();
    var time = now.getTime();
    var expireTime = time + 86400000; //1000*60*60*24*1
    now.setTime(expireTime);

    document.cookie =
      "mdeivce=true; expires=" + now.toUTCString() + "; path=/;";
  };

  if (!bannerData) return null;

  return (
    <Styles.Container>
      <div className="modalInner">
        <button type="button" className="closeButton" onClick={modalClose}>
          <img src={IconClose} alt="IconClose" />
        </button>

        <div className="img">
          <a href={bannerData?.url}>
            <img src={bannerData?.image} alt="ImgDummy" />
          </a>
        </div>

        <button
          type="button"
          className="todayCloseButton"
          onClick={() => {
            updateOneDayCookie();
            modalClose();
          }}
        >
          <span className="icon">
            <img src={IconCheck} alt="IconCheck" />
          </span>
          {languageQuarter ? "하루동안 보지않기" : "Do not open for a day"}
        </button>
      </div>

      <div className="overlay"></div>
    </Styles.Container>
  );
}

export { MainModal };

export default MainModal;
