import Styles from "./styles";
import { useLanguage } from "hooks";
import { useNavigate } from "react-router-dom";
import { common, layout, ui } from "components";

// img
import IconWarning from "resources/image/icon/icon_warning.svg";
import BgBanner from "resources/image/business/bg_main.jpg";
import BgBannerMb from "resources/image/business/bg_main_mb.jpg";
import ImgRnd01 from "resources/image/business/rnd/img_rnd01.jpg";
import ImgRnd02 from "resources/image/business/rnd/img_rnd02.jpg";
import ImgRnd03 from "resources/image/business/rnd/img_rnd03.jpg";

function BusinessRndPage() {
  const languageInfo = useLanguage()
  const languageQuarter = languageInfo.suffix === ""
  const navigate = useNavigate();

  return (
    <>
      <common.Header />
      <Styles.Container>
        <section className="bannerSection">
          <layout.MainBanner
            img={BgBanner}
            mbImg={BgBannerMb}
            colorText={languageQuarter ? "사업영역" : "Technical Service"}
            title={
              <>
                Automated <br />
                Driving R&D
              </>
            }
          />
        </section>

        <section className="titleSection">
          <div className="inner">
            <layout.DescriptionBox
              title={"AI Solution for Automated Driving"}
            />
          </div>
          <div id="focus"></div>
        </section>

        <section className="overviewSection">
          <div className="inner">
            <h3>{languageQuarter ? "개요 및 개발 분야" : "Overview and areas of development"}</h3>

            <p className="sectionDescription">
              {languageQuarter ?
                <>
                  국제 표준 ISO 5083의 ‘자율주행시스템 안전 V 모델(ADS Safety
                  lifecycle V-model)’을 기반으로 V2V 통신 정보와 센서 융합을 통해
                  주변 객체를 인지하고, 객체의 경로를 예측하여 충돌이 발생하지 않는
                  주행 경로를 생성하는 Sense/
                  <br className="mbOnly" />
                  Plan 시스템을 개발합니다.
                </> : <>
                  Based on the 'ADS Safety Lifecycle V-model' of the international standard ISO 5083,
                  we develop an Sense/Plan system that recognizes surrounding objects through V2V communication
                  information and sensor fusion, predicts the path of the objects, and generates a collision-free driving path.
                </>}
            </p>

            <div className="imgContainer">
              <div className="source">
                <div className="icon">
                  <img src={IconWarning} alt="IconWarning" />
                </div>

                <p>[{languageQuarter ? "출처" : "source"}] ISO 5083 Standard</p>
              </div>
              <figure>
                <figcaption>Architecture in ISO 5083</figcaption>

                <div className="img">
                  <img src={ImgRnd01} alt="ImgRnd01" />
                </div>
              </figure>
            </div>
          </div>
        </section>

        <section className="senseSection">
          <div className="inner">
            <h3>Sense</h3>

            <p className="sectionDescription">
              {languageQuarter ?
                <>
                  주변 객체 인지를 위해 V2X (차량 통신) 기술과 카메라, 라이다와 같은
                  다양한 센서 융합기술을 활용합니다. 이를 통해 비가시거리에 있는
                  객체까지 정확하게 인지할 수 있는 능력을 갖추고 있습니다. <br />
                  씨엔비스 시스템은 주변 환경을 실시간으로 감지하고 분석할 수
                  있으며, 운전자와 도로 사용자의 안전을 향상시키는데 기여합니다.
                </> : <>
                  Uses various sensor fusion technologies such as V2X(vehicle communication) technology
                  and camera and lidar for recognizing surrounding objects. As a result, it has the ability
                  to accurately recognize objects at non-visual distances. C&BIS systems can detect and analyze
                  the surrounding environment in real time, contributing to improving the safety of drivers and road users.
                </>}
            </p>

            <div className="imgContainer">
              <div className="source">
                <div className="icon">
                  <img src={IconWarning} alt="IconWarning" />
                </div>

                <p>[{languageQuarter ? "출처" : "source"}] https://www.rsipvision.com</p>
              </div>
              <figure>
                <figcaption>Concept of Sense</figcaption>

                <div className="img">
                  <img src={ImgRnd02} alt="ImgRnd02" />
                </div>
              </figure>
            </div>
          </div>
        </section>

        <section className="planSection">
          <div className="inner">
            <h3>Plan</h3>

            <p className="sectionDescription">
              {languageQuarter ?
                <>
                  판단 시스템은 AI 및 도로 인프라 정보를 활용하여 주변 객체의
                  움직임을 예측하고 더 안정적인 주행 결정을 내리는 역할을 합니다.{" "}
                  <br />
                  씨엔비스 자율주행 시스템은 다양한 교통 상황에서도 효과적인 의사
                  결정을 내릴 수 있으며, 운전자 및 도로 사용자의 안전을 지키고 교통
                  흐름을 최적화합니다.
                </> : <>
                  The judgment system uses AI and road infrastructure information to predict the movement
                  of surrounding objects and make more stable driving decisions. C&BIS autonomous driving
                  systems can make effective decisions in a variety of traffic situations, keeping drivers
                  and road users safe and optimizing traffic flow.
                </>}
            </p>

            <div className="imgContainer">
              <div className="source">
                <div className="icon">
                  <img src={IconWarning} alt="IconWarning" />
                </div>

                <p>[{languageQuarter ? "출처" : "source"}] www.developer.nvidia.com/drive</p>
              </div>
              <figure>
                <figcaption>Concept of Plan</figcaption>

                <div className="img">
                  <img src={ImgRnd03} alt="ImgRnd03" />
                </div>
              </figure>
            </div>
          </div>
        </section>

        <section className="expectationSection">
          <div className="inner">
            <h3>{languageQuarter ? "기대효과" : "Expected Effect"}</h3>

            <p className="sectionDescription">
              {languageQuarter ?
                <>
                  씨엔비스의 자율주행 솔루션은 국제 표준 ISO 5083을 준수하여
                  개발됩니다. <br />
                  씨엔비스의 기술과 제품은 전세계 어디에서나 사용될 수 있으며,
                  국경을 넘어 스마트 교통 시스템을 혁신하고 더 안전하고 스마트한
                  교통 시스템을 세계적으로 보급하는 것입니다.
                </> : <>
                  C&BIS autonomous driving solutions are developed in compliance with the international standard ISO 5083.
                  C&BIS technologies and products can be used anywhere in the world, and the goal is to innovate smart transportation
                  systems across borders and globally distribute safer and smarter transportation systems.
                </>}
            </p>
          </div>
        </section>

        <div className="listButton">
          <ui.button.BasicButton
            buttonText={languageQuarter ? "목록으로" : "List"}
            buttonType={"blue"}
            onClick={() => {
              navigate("/business/depth/r&d?disableScrollTop=true&focus=bis_menu_2");
            }}
          />
        </div>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default BusinessRndPage;
