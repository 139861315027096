import Styles from "./styles";

export default function BasicButton({ buttonText, icon, buttonType, onClick }) {
  return (
    <Styles.Button
      onClick={onClick}
      type="button"
      buttonType={buttonType}
      className={buttonType === "whiteHover" ? " whiteHover " : ""}
    >
      {buttonText}

      {icon && (
        <span className="icon">
          <img src={icon} alt="buttonIcon" />
        </span>
      )}
    </Styles.Button>
  );
}
