import utils from "utils";
import Styles from "./styles";
import { api } from "service";
import { Link } from "react-router-dom";
import { common } from "components";
import { useMediaQuery } from "react-responsive";
import { useEffect, useMemo } from "react";
import {
  useModals,
  useMainAnimation,
  useLanguage,
  useBanner,
  useToss,
} from "hooks";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper";

// AOS
import Aos from "aos";
import "aos/dist/aos.css";

// img
import IconArrowDouble from "resources/image/icon/icon_arrow_double.svg";
import IconArrowRight from "resources/image/icon/icon_arrow_right.svg";
import ImgBusiness01 from "resources/image/main/img_business01.png";
import ImgBusiness02 from "resources/image/main/img_business02.png";
import ImgBusiness03 from "resources/image/main/img_business03.png";
import ImgBusiness04 from "resources/image/main/img_business04.png";
import ImgCar from "resources/image/main/img_car.png";
import ImgCar01 from "resources/image/main/img_car01.png";
import ImgCar02 from "resources/image/main/img_car02.png";
import ImgCar03 from "resources/image/main/img_car03.png";
import ImgCar04 from "resources/image/main/img_car04.png";
import CarEffect from "resources/image/main/effect_car.gif";

function MainPage() {
  const animationInfo = useMainAnimation();
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";
  const modalOption = useModals();
  const bannerInfo = useBanner({ type: 1 });

  const bannerData = bannerInfo.getBanner?.data?.data;
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });

  useEffect(() => {
    Aos.init();
    if (!utils.etc.getCookie("mdeivce")) {
      modalOption.setMainModal((e) => {
        e.show = true;

        return { ...e };
      });
    }
  }, []);

  const businessList = [
    {
      img: ImgBusiness01,
      title: "Best Quality Service",
      description: languageQuarter ? (
        <>
          씨엔비스㈜는 Process & Engineering 분야를 아우르는 Total Service를
          제공 합니다. 고객의 요구에 부합하는 최고의 서비스 제공을 통해 귀사의
          비즈니스 경쟁력을 높여 나가겠습니다.
        </>
      ) : (
        <>
          C&BIS offers a total service that encompasses the Process &
          Engineering field. We will increase your business competitiveness by
          providing the best service that meets your needs.
        </>
      ),
    },
    {
      img: ImgBusiness02,
      title: "Progressive Assessment",
      description: languageQuarter ? (
        <>
          씨엔비스㈜는 자동차에 요구되는 국제표준을 기반으로 객관적이고 전문적인
          심사를 제공합니다. 귀사의 프로세스 개선을 통해 혁신적인 비즈니스
          환경을 제공해 드리겠습니다.
        </>
      ) : (
        <>
          C&BIS provides objective and professional screening based on
          international standards required for automobiles. We provide an
          innovative business environment by improving your processes.
        </>
      ),
    },
    {
      img: ImgBusiness03,
      title: "Professional Training",
      description: languageQuarter ? (
        <>
          씨엔비스㈜는 풍부한 경험과 전문지식을 바탕으로 ASPICE 국제 표준 심사원
          및 프로세스 실무 전문가 양성에 초점을 맞춘 교육 서비스를 제공합니다.
          귀사 인적자원의 경쟁력을 높여 나가는 파트너로 함께 하겠습니다.
        </>
      ) : (
        <>
          With extensive experience and expertise, C&BIS provides training
          servicesfocused on training ASPICE International Standard Reviewers
          and process practice experts. We will be partners to enhance the
          competitiveness of your human resources.
        </>
      ),
    },
    {
      img: ImgBusiness04,
      title: (
        <>
          High-Tech <br className="mbOnly" />
          AI Solution
        </>
      ),
      description: languageQuarter ? (
        <>
          씨엔비스㈜는 AI를 활용한 자율주행 기술을 제공합니다. 최신 국제표준에
          기반한 AI 기술을 제공하여 개발자와 사용자 모두가 안전한, Together for
          a safer world를 만들고자 합니다.
        </>
      ) : (
        <>
          C&BIS provides autonomous driving technology using AI. By providing AI
          technology based on the latest international standards, we want to
          create a safe Together for a safer world for both developers and
          users.
        </>
      ),
    },
  ];
  const carList = [
    {
      img: ImgCar01,
      title: (
        <>
          The more advance, <br />
          the more benefits
        </>
      ),
      description: languageQuarter ? (
        <>
          씨엔비스㈜는 Process & Engineering 분야를 아우르는{" "}
          <br className="mbOnly" />
          Total Service를 제공합니다. <br />
          고객의 요구에 부합하는 최고의 서비스 제공을 통해{" "}
          <br className="mbOnly" />
          귀사의 비즈니스 경쟁력을 높여 나가겠습니다.
        </>
      ) : (
        <>
          C&BIS offers a total service that encompasses the Process &
          Engineering field. We will increase your business competitiveness by
          providing the best service that meets your needs.
        </>
      ),
      link: "/business/depth/service",
    },
    {
      img: ImgCar02,
      title: (
        <>
          Innovate <br />
          your processes
        </>
      ),
      description: languageQuarter ? (
        <>
          씨엔비스㈜는 자동차에 요구되는 국제표준을 기반으로 객관적이고 <br />
          전문적인 심사를 제공합니다. 귀사의 프로세스 개선을 통해 <br />
          혁신적인 비즈니스 환경을 제공해 드리겠습니다.
        </>
      ) : (
        <>
          C&BIS provides objective and professional screening based on
          international standards required for automobiles. We will provide an
          innovative business environment by improving your process.
        </>
      ),
      link: "/business/depth/assessment",
    },
    {
      img: ImgCar03,
      title: (
        <>
          Enhance <br />
          your competitiveness
        </>
      ),
      description: languageQuarter ? (
        <>
          씨엔비스㈜는 풍부한 경험과 전문지식을 바탕으로 ASPICE 국제 표준 심사원
          및 <br className="pcOnly" />
          프로세스 실무 전문가 양성에 초점을 맞춘 교육 서비스를 제공합니다.{" "}
          <br className="pcOnly" />
          귀사 인적자원의 경쟁력을 높여 나가는 파트너로 함께 하겠습니다.
        </>
      ) : (
        <>
          With extensive experience and expertise, C&BIS provides training
          services focused on training ASPICE International Standard Reviewers
          and process practice experts. We will be partners to enhance the
          competitiveness of your human resources.
        </>
      ),
      link: "/business/depth/r&d",
    },
    {
      img: ImgCar04,
      title: (
        <>
          Beyond <br />
          automated driving, <br />
          toward artificial <br />
          intelligence
        </>
      ),
      description: languageQuarter ? (
        <>
          씨엔비스㈜는 AI를 활용한 자율주행 기술을 제공합니다. 최신 국제표준에{" "}
          <br />
          기반한 AI 기술을 제공하여 개발자와 사용자 모두가 안전한,{" "}
          <br className="pcOnly" />
          Together <br className="mbOnly" />
          for a safer world를 만들고자 합니다.
        </>
      ) : (
        <>
          C&BIS provides autonomous driving technology using AI. By providing AI
          technology based on the latest international standards, we want to
          create a Together for a safer world that is safe for both developers
          and users.
        </>
      ),
      link: "/business/depth/solution",
    },
  ];
  const businessRender = useMemo(() => {
    return businessList.map((item, index) => {
      return (
        <li
          className="businessList"
          data-aos="fade-up"
          data-aos-duration="700"
          key={"businessList" + index}
        >
          <div className="img">
            <img src={item.img} alt="businessImg" />
          </div>
          <p className="title">{item.title}</p>
          <p className="description">{item.description}</p>
        </li>
      );
    });
  }, []);
  const carRender = useMemo(() => {
    return carList.map((item, index) => {
      return (
        <li className="carList" key={"carList" + index}>
          <div className="img">
            <img src={item.img} alt="carImg" />
          </div>

          <div className="textWrap">
            <p className="title">{item.title}</p>

            <p className="description">{item.description}</p>

            <Link to={item.link}>
              View more{" "}
              <div className="icon">
                <img src={IconArrowRight} alt="IconArrowRight" />
              </div>
            </Link>
          </div>
        </li>
      );
    });
  }, []);

  return (
    bannerData && (
      <>
        <common.Header main />
        <Styles.Container>
          <section className="bannerSection">
            <Swiper
              speed={1000}
              autoplay={{
                delay: 3000,
                // disableOnInteraction: false,
              }}
              loop={"ture"}
              modules={[Autoplay]}
              className="mainBanner"
            >
              {bannerData.map((item, index) => {
                return (
                  <SwiperSlide
                    className="bannerSlide"
                    key={"bannerSlide" + index}
                  >
                    <img
                      src={isMobile ? item.thumbnail : item.image}
                      alt="ImgDummy"
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>

            <div className="scrollDown" onClick={() => {}}>
              <div className="icon">
                <img src={IconArrowDouble} alt="IconArrowDouble" />
              </div>
              SCROLL DOWN
            </div>
          </section>

          <section className="introductionSection">
            <div className="inner">
              <div className="title">
                <p>Technical Service</p>
                <p>for New Mobility</p>
              </div>

              <p className={"description"}>
                {languageQuarter ? (
                  <>
                    자율주행 자동차 기술의 트렌드를 선도하는 기업 C&BIS는 <br />
                    첨단 모빌리티 시장의 선두 주자로서 미래 산업을 일구어가는
                    여러분의 든든한 파트너가 될 것입니다.
                  </>
                ) : (
                  <>
                    C&BIS, a company leading the trend in self-driving car
                    technology <br />
                    As a leader in the advanced mobility market, we will be your
                    strong partner in building the future industry.
                  </>
                )}
              </p>
            </div>
          </section>

          <section className="businessSection">
            <div className="inner">
              <ul>{businessRender}</ul>
            </div>
          </section>

          <section className="videoSection">
            <div className="video">
              <video autoPlay={true} muted={true} loop playsInline>
                <source
                  src={"https://cnbis.co.kr/video_cnbis.mp4"}
                  type="video/mp4"
                />
              </video>
            </div>
          </section>

          <section className="carSection">
            <div className="inner">
              <div ref={animationInfo.targetRef} className="car">
                <img src={ImgCar} alt="ImgCar" />
                <img
                  style={{ position: "absolute", top: "22%", left: "-46%" }}
                  src={CarEffect}
                  alt="ImgCar"
                />
              </div>
              <div ref={animationInfo.targetTwoRef} className="car-two">
                <img src={ImgCar} alt="ImgCar" />
                <img
                  style={{ position: "absolute", top: "22%", left: "-46%" }}
                  src={CarEffect}
                  alt="ImgCar"
                />
              </div>
              <div ref={animationInfo.targetThreeRef} className="car-three">
                <img src={ImgCar} alt="ImgCar" />
                <img
                  style={{ position: "absolute", top: "22%", left: "-46%" }}
                  src={CarEffect}
                  alt="ImgCar"
                />
              </div>

              <ul>
                {carRender}
                {/* {carList.map((item, index) => {
                return (
                  <li className="carList" key={"carList" + index}>
                    <div className="img">
                      <img src={item.img} alt="carImg" />
                    </div>

                    <div className="textWrap">
                      <p className="title">{item.title}</p>

                      <p className="description">{item.description}</p>

                      <Link to={item.url}>
                        View more{" "}
                        <div className="icon">
                          <img src={IconArrowRight} alt="IconArrowRight" />
                        </div>
                      </Link>
                    </div>
                  </li>
                );
              })} */}
              </ul>
            </div>
          </section>
        </Styles.Container>
        <common.Footer />
      </>
    )
  );
}

export default MainPage;
