import Styles from "./styles";
import { useLanguage } from "hooks";
import { useMediaQuery } from "react-responsive";
import { common, layout } from "components";
import ScrollContainer from "react-indiana-drag-scroll";

// img
import BgBanner from "resources/image/introduction/bg_main.jpg";
import BgBannerMb from "resources/image/introduction/bg_main_mb.jpg";
import ImgChart from "resources/image/introduction/img_organize_chart.png";
import ImgChartMb from "resources/image/introduction/img_organize_chart_mb.png";

function OrganizeChartPage() {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === ""

  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });

  return (
    <>
      <common.Header />
      <Styles.Container>
        <section className="bannerSection">
          <layout.MainBanner
            img={BgBanner}
            mbImg={BgBannerMb}
            colorText={languageQuarter ? "회사소개" : "About C&BIS"}
            title={languageQuarter ? "조직도" : "Organization"}
          />
        </section>

        <section className="chartSection">
          <div className="inner">
            <ScrollContainer className="scrollContainer">
              <div className="img">
                <img src={isMobile ? ImgChartMb : ImgChart} alt="ImgChart" />
              </div>
            </ScrollContainer>

            <layout.ScrollGuide />
          </div>
        </section>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default OrganizeChartPage;
