import connector from "service/connector";
import utils from "utils";


const getList = async (data) => {
  return await connector.authConnector(`product-quick?${utils.etc.getFilterStr(data)}`,'GET');
}

const getGroupDateList = async (data) => {
  return await connector.authConnector(`product-quick/date?${utils.etc.getFilterStr(data)}`,'GET');
}

const getGroupList = async (data) => {
  return await connector.authConnector(`product-quick/date/group?${utils.etc.getFilterStr(data)}`,'GET');
}

const getRelateList = async (data) => {
  return await connector.authConnector(`product-quick/relate?${utils.etc.getFilterStr(data)}`,'GET');
}

const getMonthList = async (data) => {
  return await connector.connectFetchController(`product-quick/month/list?${utils.etc.getFilterStr(data)}`,'GET');
}


export {
  getGroupDateList,
  getGroupList,
  getList,
  getRelateList,
  getMonthList,
};


export default {
  getGroupDateList,
  getGroupList,
  getList,
  getRelateList,
  getMonthList,
}