import Styles from "./styles";
import ui from "components/ui";

// img
import IconMessage from "resources/image/icon/icon_message.svg";

function DefaultModal({ modalOption, children, modalClose }) {
  return (
    <Styles.Container>
      <div className="modalInner">
        <div className="icon">
          <img src={IconMessage} alt="IconMessage" />
        </div>

        <p className="title">{modalOption.title}</p>

        {modalOption.subText && (
          <div className="subText">{modalOption.subText}</div>
        )}

        {children}

        <div className="buttonContainer">
          {modalOption.closeText && (
            <ui.button.BasicButton
              onClick={() => {
                modalClose();
              }}
              buttonText={modalOption.closeText}
              buttonType={"white"}
            />
          )}

          <ui.button.BasicButton
            onClick={() => {
              modalClose();
              {
                modalOption.confirmClick && modalOption.confirmClick();
              }
            }}
            buttonText={modalOption.confirmText}
            buttonType={"blue"}
          />
        </div>
      </div>

      <div className="overlay"></div>
    </Styles.Container>
  );
}

export { DefaultModal };

export default DefaultModal;
