import { useCalendar } from "hooks";


export default function CalendarSample(){
  const calendar = useCalendar(); 

  function getDayRender(day,i){

    return (
      <div 
        style={{float:"left", width:30}}
        key={"day_info_" + "_" + i} 
        onClick={()=>{calendar.onClickDate(day)}}>
          <div>
              {
                !day.isBefore && !day.isAfter ?
                <p style={{color:day.weekend ? "red" : "black"}}>{day.day ? day.day : " 무 "}</p>
                :
                <p style={{color:day.weekend ? "red" : "black"}}>{day.day ? day.day : " 무 "}</p>
              }
          </div>
      </div>
    )
  }

  function renderCalendar(dateObject){

    
      let days = dateObject.days;
      let resultTag = [];
      let dayTags = [];

      resultTag.push(
        <div key={"calendar_tag_00"} style={{width:"100vw", height:0}}>
          <p style={{float:"left", width:30}}>일</p>
          <p style={{float:"left", width:30}}>월</p>
          <p style={{float:"left", width:30}}>화</p>
          <p style={{float:"left", width:30}}>수</p>
          <p style={{float:"left", width:30}}>목</p>
          <p style={{float:"left", width:30}}>금</p>
          <p style={{float:"left", width:30}}>토</p>
        </div>
      )


      for(let i = 0;i<days.length;i++){
          //개행
          if(i == 0 || i % 7 == 0){
              resultTag.push(<div key={"calendar_tag_" + i} style={{ width: "100%", float: "left",height:50}}>{dayTags}</div>);
              dayTags = [];
          }
          //element 요소
          dayTags.push(getDayRender(days[i],i));

          //개행
          if(days.length === i + 1){
            resultTag.push(<div key={"calendar_tag_" + i} style={{height:50}}>{dayTags}</div>);
          }
      }

      return resultTag;
  }

  return (
    <>
      <button onClick={()=>{  let date = new Date(); date.setMonth(date.getMonth() + 100);  calendar.moveToDate(date); }}>testDate</button>
      <button onClick={calendar.prevMonthEvent}>prevMonth</button>
      <button onClick={calendar.nextMonthEvent}>nextMonth</button>
      <h1>Current Years : {calendar.currentDate.getFullYear()}</h1>
      <h1>Current Month : {calendar.currentDate.getMonth()}</h1>
      {
        renderCalendar(calendar.getDaysObject())
      }
    </>
  )
}


