import Styles from "./styles";
import { NavLink } from "react-router-dom";

function Tab({ tabList, tabIndex, setTabIndex, language }) {
  return (
    <Styles.Container>
      {tabList?.map((item, index) => {
        return (
          <li
            className="tabList"
            key={"tabList" + index}
            onClick={() => {
              {
                !item.url && setTabIndex(index);
              }
            }}
          >
            {item.url ? (
              <NavLink to={item.url}>{item["text" + language]}</NavLink>
            ) : (
              <div className={tabIndex === index ? " active" : ""}>
                {item["text" + language]}
              </div>
            )}
          </li>
        );
      })}
    </Styles.Container>
  );
}

export default Tab;
