import styled from "styled-components";

const Container = styled.div`
  & .title {
    position: relative;
    margin-bottom: 40px;
    width: fit-content;
    padding: 13px 40px 13px 0;
    font-size: 36px;
    font-weight: bold;
    color: #fff;

    &::after {
      right: 0;
      background-color: var(--blue02);
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  & .textWrap {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 58px 0 58px 40px;
    background-color: var(--blue04);

    &::after {
      left: 0;
      background-color: var(--blue04);
    }

    & .left {
      font-size: 52px;
      font-weight: bold;
      color: var(--blue02);
    }

    & .right {
      flex-shrink: 0;
      width: 754px;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.5;
      word-break: keep-all;
    }
  }

  & .title::after,
  & .textWrap::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: calc(100% + 300px);
    height: 100%;
    content: "";
    z-index: -1;
  }

  @media (max-width: 1480px) {
    & .title {
      margin-bottom: calc(100vw * (40 / 1480));
      padding: calc(100vw * (13 / 1480)) calc(100vw * (40 / 1480))
        calc(100vw * (13 / 1480)) 0;
      font-size: calc(100vw * (36 / 1480));

      &::after {
        border-top-right-radius: calc(100vw * (6 / 1480));
        border-bottom-right-radius: calc(100vw * (6 / 1480));
      }
    }

    & .textWrap {
      padding: calc(100vw * (58 / 1480)) 0 calc(100vw * (58 / 1480))
        calc(100vw * (40 / 1480));

      & .left {
        font-size: calc(100vw * (52 / 1480));
      }

      & .right {
        width: calc(100vw * (754 / 1480));
        font-size: calc(100vw * (20 / 1480));
      }
    }

    & .title::after,
    & .textWrap::after {
      position: absolute;
      width: calc(100% + calc(100vw * (300 / 1480)));
    }
  }

  @media (max-width: 768px) {
    & .title {
      margin-bottom: calc(100vw * (20 / 375));
      padding: calc(100vw * (14 / 375)) calc(100vw * (20 / 375))
        calc(100vw * (14 / 375)) 0;
      font-size: calc(100vw * (24 / 375));

      &::after {
        border-top-right-radius: calc(100vw * (6 / 375));
        border-bottom-right-radius: calc(100vw * (6 / 375));
      }
    }

    & .textWrap {
      flex-direction: column;
      gap: calc(100vw * (50 / 375));
      padding: calc(100vw * (30 / 375)) 0 calc(100vw * (30 / 375))
        calc(100vw * (20 / 375));

      & .left {
        font-size: calc(100vw * (32 / 375));
      }

      & .right {
        width: 100%;
        font-size: calc(100vw * (14 / 375));
      }
    }

    & .title::after,
    & .textWrap::after {
      position: absolute;
      width: calc(100% + calc(100vw * (20 / 375)));
    }
  }
`;

export { Container };

export default { Container };
