import { api } from "service";

export default function useUpload() {

	const uploadFile = async (file) => {
    const postData = new FormData();
    postData.append("file", file)
    return await api.upload.uploadFile(postData);
  }
	const uploadImage = async (image) => {
    const postData = new FormData();
    postData.append("image", image)
    return await api.upload.uploadImage(postData);
  }


	return {
		uploadFile,
		uploadImage
	};
}