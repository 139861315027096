import Styles from "./styles";
import { common, ui } from "components";
import { useLanguage } from "hooks";
import { useLocation, Outlet } from "react-router-dom";

function CustomerSupportPage() {
  const languageInfo = useLanguage()
  const location = useLocation();

  const tabList = [
    {
      url: "/customer-support/notice",
      text: "공지사항",
      text_en: "Notice",
    },
    {
      url: "/customer-support/ask",
      text: "문의사항",
      text_en: "Contact Us",
    },
    {
      url: "/customer-support/recruitment",
      text: "채용정보",
      text_en: "Recruitment",
    },
  ];

  const nowPath = location.pathname.split("/")[2];
  const nowTab = tabList.findIndex((v) => v.url.split("/")[2] === nowPath);
  const nowPage = tabList[nowTab]["text" + languageInfo.suffix];

  return (
    <>
      <common.Header />
      <Styles.Container>
        <section>
          <div className="inner">
            <h2>{nowPage}</h2>

            <div className="tab">
              <ui.button.Tab
                tabList={tabList}
                language={languageInfo.suffix}
              />
            </div>

            <Outlet />
          </div>
        </section>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default CustomerSupportPage;
