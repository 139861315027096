import styled from "styled-components";

const Container = styled.div`
  color: #fff;
  background: linear-gradient(
    125deg,
    #abcd03 -46.61%,
    #0178c0 44.67%,
    #1b1c80 89.45%
  );

  & .inner {
    padding: 60px 0;

    & .top,
    .bottom {
      display: flex;
    }

    & .top {
      justify-content: space-between;
      margin-bottom: 80px;
      font-size: 22px;
      font-weight: bold;

      & .termsButton {
        display: flex;
        align-items: center;
        gap: 5px;

        & .icon {
          width: 24px;
          height: 24px;
        }
      }
    }

    & .bottom {
      gap: 60px;

      & .logo {
        width: 120px;
        height: 35px;
      }

      & .info {
        font-size: 14px;

        & ul {
          display: flex;
          flex-wrap: wrap;
          gap: 5px 20px;
          width: 850px;
          margin-bottom: 5px;
        }
      }
    }
  }

  & .quickMenu {
    position: fixed;
    right: 30px;
    bottom: 30px;
    z-index: 9;

    & .quickMenuList {
      display: flex;
      flex-direction: column;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      & .applyButton {
        background-color: var(--blue02);
      }

      & .registerButton {
        color: var(--blue02);
        background-color: #fff;
      }

      & .topButton {
        background-color: var(--black);
      }
    }

    & button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 7px;
      width: 90px;
      height: 90px;
      font-weight: 600;
      color: #fff;
      box-shadow: 0px 10px 20px 0px rgba(4, 50, 105, 0.2);

      & .icon {
        display: inline-block;
        width: 28px;
        height: 28px;
      }
    }
  }

  @media (max-width: 1480px) {
    & .inner {
      padding: calc(100vw * (60 / 1480)) 0;

      & .top {
        margin-bottom: calc(100vw * (80 / 1480));
        font-size: calc(100vw * (22 / 1480));

        & .termsButton {
          gap: calc(100vw * (5 / 1480));

          & .icon {
            width: calc(100vw * (24 / 1480));
            height: calc(100vw * (24 / 1480));
          }
        }
      }

      & .bottom {
        gap: calc(100vw * (60 / 1480));

        & .logo {
          width: calc(100vw * (120 / 1480));
          height: calc(100vw * (35 / 1480));
        }

        & .info {
          font-size: calc(100vw * (14 / 1480));

          & ul {
            gap: calc(100vw * (5 / 1480)) calc(100vw * (20 / 1480));
            width: calc(100vw * (850 / 1480));
            margin-bottom: calc(100vw * (5 / 1480));
          }
        }
      }
    }

    & .quickMenu {
      right: calc(100vw * (30 / 1480));
      bottom: calc(100vw * (30 / 1480));

      & .quickMenuList {
        &:not(:last-child) {
          margin-bottom: calc(100vw * (10 / 1480));
        }
      }

      & button {
        gap: calc(100vw * (7 / 1480));
        width: calc(100vw * (90 / 1480));
        height: calc(100vw * (90 / 1480));
        box-shadow: 0 calc(100vw * (10 / 1480)) calc(100vw * (20 / 1480)) 0
          rgba(4, 50, 105, 0.2);

        & .icon {
          width: calc(100vw * (28 / 1480));
          height: calc(100vw * (28 / 1480));
        }
      }
    }
  }

  @media (max-width: 768px) {
    & .inner {
      padding: calc(100vw * (30 / 375)) 0;

      & .top {
        margin-bottom: calc(100vw * (50 / 375));
        font-size: calc(100vw * (14 / 375));

        & .termsButton {
          gap: calc(100vw * (5 / 375));

          & .icon {
            width: calc(100vw * (15 / 375));
            height: calc(100vw * (15 / 375));
          }
        }
      }

      & .bottom {
        flex-direction: column;
        gap: calc(100vw * (30 / 375));

        & .logo {
          width: calc(100vw * (96 / 375));
          height: calc(100vw * (28 / 375));
        }

        & .info {
          font-size: calc(100vw * (12 / 375));

          & ul {
            gap: calc(100vw * (10 / 375)) calc(100vw * (14 / 375));
            width: calc(100vw * (302 / 375));
            margin-bottom: calc(100vw * (10 / 375));

            & .infoList span {
              margin-right: calc(100vw * (30 / 375));
            }
          }
        }
      }
    }

    & .quickMenu {
      right: calc(100vw * (20 / 375));
      bottom: calc(100vw * (20 / 375));

      & .quickMenuList {
        &:not(:last-child) {
          margin-bottom: calc(100vw * (10 / 375));
        }
      }

      & button {
        gap: calc(100vw * (6 / 375));
        width: calc(100vw * (62 / 375));
        height: calc(100vw * (62 / 375));
        font-size: calc(100vw * (12 / 375));
        box-shadow: 0 calc(100vw * (10 / 375)) calc(100vw * (20 / 375)) 0
          rgba(4, 50, 105, 0.2);

        & .icon {
          width: calc(100vw * (24 / 375));
          height: calc(100vw * (24 / 375));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
