import styled from "styled-components";

// img
import BgCar from "resources/image/main/bg_car.png";
import BgCarMb from "resources/image/main/bg_car_mb.png";

const Container = styled.main`
  padding-bottom: 200px;

  & section:not(:last-child) {
    margin-bottom: 80px;
  }

  & .bannerSection {
    position: relative;

    & .bannerSlide {
      height: 980px;
    }

    & .scrollDown {
      position: absolute;
      bottom: 60px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 12px;
      font-weight: 500;
      color: #fff;
      z-index: 2;

      & .icon {
        width: 20px;
        height: 30px;
        margin: 0 auto 18px;
      }
    }
  }

  & .introductionSection {
    & .title {
      margin-bottom: 30px;
      font-size: 130px;
      font-weight: 500;
      color: var(--blue02);

      & p:last-child {
        width: fit-content;
        margin: -22px 0 0 auto;
      }
    }

    & .description {
      font-size: 28px;
      color: var(--gray01);
      line-height: 1.28;
      text-align: center;
    }
  }

  & .businessSection {
    & ul {
      display: flex;
      justify-content: space-between;

      & .businessList {
        width: 260px;
        word-break: keep-all;

        &:nth-child(2) {
          transition-delay: 0.2s;
        }
        &:nth-child(3) {
          transition-delay: 0.4s;
        }
        &:last-child {
          transition-delay: 0.6s;
        }

        & .img {
          width: 200px;
          height: 200px;
          margin-bottom: 50px;
        }

        & .title {
          margin-bottom: 20px;
          font-size: 42px;
          font-weight: 600;
        }

        & .description {
          font-weight: 500;
          color: var(--gray01);
          line-height: 1.5;
        }
      }
    }
  }

  & .videoSection {
    padding: 0 50px;

    & .video {
      height: 491px;
      border-radius: 500px;
      overflow: hidden;
    }
  }

  & .carSection {
    background: url(${BgCar}) no-repeat center / cover;

    & .inner {
      position: relative;
    }

    & .car {
      visibility: hidden;
      position: absolute;
      top: 240px;
      left: calc(75% - 367px);
      transform: translateX(-50%);
      width: 391px;
      height: 337px;
      transition: opacity 0.5s linear;
    }

    & .car-two {
      visibility: hidden;
      position: absolute;
      top: 789px;
      left: calc(3% - 367px);
      transform: translateX(-50%);
      width: 391px;
      height: 337px;
      transition: opacity 0.5s linear;
    }

    & .car-three {
      visibility: hidden;
      position: absolute;
      top: 1411px;
      left: calc(128% - 367px);
      transform: translateX(-50%);
      width: 391px;
      height: 337px;
      transition: opacity 0.5s linear;
    }

    & .carList {
      display: flex;
      align-items: center;
      word-break: keep-all;

      & .img {
        flex-shrink: 0;
        width: 683px;
        height: 659px;
      }

      & .title {
        margin-bottom: 30px;
        font-size: 52px;
        font-weight: 600;
        text-transform: capitalize;
      }

      & .description {
        margin-bottom: 35px;
        font-weight: 500;
        color: var(--gray01);
        line-height: 1.5;
      }

      & a {
        position: relative;
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 18px;

        &::before {
          position: absolute;
          top: -5px;
          left: -10px;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: var(--blue03);
          content: "";
          z-index: -1;
        }

        & .icon {
          width: 24px;
          height: 24px;
        }
      }

      &:nth-child(odd) {
        flex-direction: row-reverse;
      }

      &:first-child {
        gap: 52px;
        margin-bottom: 61px;
      }

      &:nth-child(2) {
        gap: 236px;
        margin-bottom: 61px;
      }

      &:nth-child(3) {
        gap: 106px;
        margin-bottom: 55px;
      }

      &:last-child {
        gap: 133px;
        align-items: flex-start;

        & .img {
          width: 725px;
          height: 893px;
        }

        & .textWrap {
          padding-top: 184px;
        }
      }
    }
  }

  @media (max-width: 1480px) {
    padding-bottom: calc(100vw * (200 / 1480));

    & section:not(:last-child) {
      margin-bottom: calc(100vw * (80 / 1480));
    }

    & .bannerSection {
      & .bannerSlide {
        height: calc(100vw * (980 / 1480));
      }

      & .scrollDown {
        bottom: calc(100vw * (60 / 1480));
        font-size: calc(100vw * (12 / 1480));

        & .icon {
          width: calc(100vw * (20 / 1480));
          height: calc(100vw * (30 / 1480));
          margin: 0 auto calc(100vw * (18 / 1480));
        }
      }
    }

    & .introductionSection {
      & .title {
        margin-bottom: calc(100vw * (30 / 1480));
        font-size: calc(100vw * (130 / 1480));

        & p:last-child {
          margin: calc(100vw * (-22 / 1480)) 0 0 auto;
        }
      }

      & .description {
        font-size: calc(100vw * (28 / 1480));
      }
    }

    & .businessSection {
      & ul {
        & .businessList {
          width: calc(100vw * (260 / 1480));

          & .img {
            width: calc(100vw * (200 / 1480));
            height: calc(100vw * (200 / 1480));
            margin-bottom: calc(100vw * (50 / 1480));
          }

          & .title {
            margin-bottom: calc(100vw * (20 / 1480));
            font-size: calc(100vw * (42 / 1480));
          }
        }
      }
    }

    & .videoSection {
      padding: 0 calc(100vw * (20 / 1480));

      & .video {
        height: calc(100vw * (491 / 1480));
        border-radius: calc(100vw * (500 / 1480));
      }
    }

    & .carSection {
      & .car {
        top: calc(48vw * (467 / 1480));
        left: calc(75% - calc(100vw * (367 / 1480)));
        width: calc(100vw * (391 / 1480));
        height: calc(100vw * (337 / 1480));
      }

      & .car-two {
        top: calc(176vw * (467 / 1480));
        left: calc(9% - calc(100vw * (367 / 1480)));
        width: calc(100vw * (391 / 1480));
        height: calc(100vw * (337 / 1480));
      }

      & .car-three {
        top: calc(300vw * (467 / 1480));
        left: calc(126% - calc(100vw * (367 / 1480)));
        // top: calc(100vw * (467 / 1480));
        // left: calc(50% - calc(100vw * (367 / 1480)));
        width: calc(100vw * (391 / 1480));
        height: calc(100vw * (337 / 1480));
      }

      & .carList {
        & .img {
          width: calc(100vw * (683 / 1480));
          height: calc(100vw * (659 / 1480));
        }

        & .title {
          margin-bottom: calc(100vw * (30 / 1480));
          font-size: calc(100vw * (52 / 1480));
        }

        & .description {
          margin-bottom: calc(100vw * (35 / 1480));
        }

        & a {
          gap: calc(100vw * (8 / 1480));
          font-size: calc(100vw * (18 / 1480));

          &::before {
            top: calc(100vw * (-5 / 1480));
            left: calc(100vw * (-10 / 1480));
            width: calc(100vw * (20 / 1480));
            height: calc(100vw * (20 / 1480));
          }

          & .icon {
            width: calc(100vw * (24 / 1480));
            height: calc(100vw * (24 / 1480));
          }
        }

        &:first-child {
          gap: calc(100vw * (52 / 1480));
          margin-bottom: calc(100vw * (61 / 1480));
        }

        &:nth-child(2) {
          gap: calc(100vw * (236 / 1480));
          margin-bottom: calc(100vw * (61 / 1480));
        }

        &:nth-child(3) {
          gap: calc(100vw * (106 / 1480));
          margin-bottom: calc(100vw * (55 / 1480));
        }

        &:last-child {
          gap: calc(100vw * (133 / 1480));

          & .img {
            width: calc(100vw * (725 / 1480));
            height: calc(100vw * (893 / 1480));
          }

          & .textWrap {
            padding-top: calc(100vw * (184 / 1480));
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (66 / 375)) 0 calc(100vw * (130 / 375));

    & section:not(:last-child) {
      margin-bottom: calc(100vw * (50 / 375));
    }

    & .bannerSection {
      & .bannerSlide {
        height: calc(100vw * (484 / 375));
      }

      & .scrollDown {
        display: none;
      }
    }

    & .introductionSection {
      & .title {
        margin-bottom: calc(100vw * (20 / 375));
        font-size: calc(100vw * (36 / 375));

        & p:last-child {
          margin: calc(100vw * (-5 / 375)) 0 0 auto;
        }
      }

      & .description {
        font-size: calc(100vw * (14 / 375));
        word-break: keep-all;
        line-height: 1.42;
      }
    }

    & .businessSection {
      & ul {
        flex-wrap: wrap;
        gap: calc(100vw * (30 / 375)) calc(100vw * (33 / 375));

        & .businessList {
          width: calc(100vw * (151 / 375));
          font-size: calc(100vw * (12 / 375));

          & .img {
            width: calc(100vw * (120 / 375));
            height: calc(100vw * (120 / 375));
            margin-bottom: calc(100vw * (30 / 375));
          }

          & .title {
            margin-bottom: calc(100vw * (12 / 375));
            font-size: calc(100vw * (26 / 375));
          }
        }
      }
    }

    & .videoSection {
      padding: 0 calc(100vw * (10 / 375));

      & .video {
        height: calc(100vw * (149 / 375));
        border-radius: calc(100vw * (500 / 375));
      }
    }

    & .carSection {
      background-image: url(${BgCarMb});

      & .car {
        top: calc(100vw * (355 / 375));
        left: calc(100vw * (-30 / 375));
        width: calc(100vw * (228 / 375));
        height: calc(100vw * (197 / 375));
      }

      & .car-two {
        top: calc(100vw * (355 / 375));
        left: calc(100vw * (-30 / 375));
        width: calc(100vw * (228 / 375));
        height: calc(100vw * (197 / 375));
      }

      & .car-three {
        top: calc(100vw * (355 / 375));
        left: calc(100vw * (-30 / 375));
        width: calc(100vw * (228 / 375));
        height: calc(100vw * (197 / 375));
      }

      & .carList {
        flex-direction: column-reverse;

        & .img {
          width: calc(100vw * (259 / 375));
          height: calc(100vw * (250 / 375));
        }

        & .textWrap {
          width: 100%;
        }

        & .title {
          margin-bottom: calc(100vw * (15 / 375));
          font-size: calc(100vw * (32 / 375));
        }

        & .description {
          margin-bottom: calc(100vw * (18 / 375));
          font-size: calc(100vw * (12 / 375));
          line-height: 1.33;
        }

        & a {
          gap: calc(100vw * (5 / 375));
          font-size: calc(100vw * (14 / 375));

          &::before {
            top: calc(100vw * (-3 / 375));
            left: calc(100vw * (-6 / 375));
            width: calc(100vw * (11 / 375));
            height: calc(100vw * (11 / 375));
          }

          & .icon {
            width: calc(100vw * (14 / 375));
            height: calc(100vw * (14 / 375));
          }
        }

        &:nth-child(odd) {
          flex-direction: column-reverse;
        }

        &:first-child {
          gap: calc(100vw * (30 / 375));
          margin-bottom: calc(100vw * (30 / 375));
        }

        &:nth-child(2) {
          gap: calc(100vw * (30 / 375));
          margin-bottom: calc(100vw * (30 / 375));
        }

        &:nth-child(3) {
          gap: calc(100vw * (30 / 375));
          margin-bottom: calc(100vw * (30 / 375));
        }

        &:last-child {
          align-items: center;
          gap: calc(100vw * (30 / 375));

          & .img {
            width: calc(100vw * (255 / 375));
            height: calc(100vw * (310 / 375));
          }

          & .textWrap {
            padding-top: 0;
          }
        }
      }
    }
  }
`;

export { Container };

export default { Container };
