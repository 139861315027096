import styled from "styled-components";

const Container = styled.ul`
  display: flex;
  align-items: center;
  gap: 50px;
  border-bottom: 3px solid var(--blue04);

  & .tabList {
    position: relative;
    font-size: 20px;
    font-weight: bold;
    color: var(--blue03);
    cursor: pointer;

    & div,
    a {
      padding: 20px;

      &.active {
        color: var(--blue02);

        &::after {
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          height: 3px;
          background-color: var(--blue02);
          content: "";
        }
      }
    }
  }

  @media (max-width: 1480px) {
    gap: calc(100vw * (50 / 1480));
    border-bottom: calc(100vw * (3 / 1480)) solid var(--blue04);

    & .tabList {
      font-size: calc(100vw * (20 / 1480));

      & div,
      a {
        padding: calc(100vw * (20 / 1480));

        &.active {
          &::after {
            height: calc(100vw * (3 / 1480));
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    gap: calc(100vw * (20 / 375));
    justify-content: center;
    border-bottom: calc(100vw * (3 / 375)) solid var(--blue04);

    & .tabList {
      font-size: calc(100vw * (16 / 375));

      & div,
      a {
        padding: calc(100vw * (20 / 375));

        &.active {
          &::after {
            height: calc(100vw * (3 / 375));
          }
        }
      }
    }
  }
`;

export { Container };

export default { Container };
