import Styles from "./styles";
import ScrollContainer from "react-indiana-drag-scroll";
import { layout, ui } from "components";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "hooks";
import { useMediaQuery } from "react-responsive";

// img
import ImgAssessmentDetail01 from "resources/image/business/assessment/img_assessment_detail01.jpg";
import ImgAssessmentDetail02 from "resources/image/business/assessment/img_assessment_detail02.jpg";
import ImgAssessmentDetail03 from "resources/image/business/assessment/img_assessment_detail03.png";
import ImgAssessmentDetail03En from "resources/image/business/assessment/img_assessment_detail03_en.png";
import ImgAssessmentDetail03Mb from "resources/image/business/assessment/img_assessment_detail03_mb.png";
import ImgAssessmentDetail03MbEn from "resources/image/business/assessment/img_assessment_detail03_mb_en.png";
import ImgAssessmentDetail04 from "resources/image/business/assessment/img_assessment_detail04.png";
import ImgAssessmentDetail04En from "resources/image/business/assessment/img_assessment_detail04_en.png";
import ImgAssessmentDetail04Mb from "resources/image/business/assessment/img_assessment_detail04_mb.png";
import ImgAssessmentDetail04MbEn from "resources/image/business/assessment/img_assessment_detail04_mb_en.png";

function AssessmentDetail() {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";
  const navigate = useNavigate();

  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });

  const intacsList = languageQuarter
    ? [
        "독립적이고 법적으로 등록된 비영리 기관",
        "명예 조직으로 개방적이고 투명한 운영",
        <>
          국제적 단체(다국어 지원) <br />
          a. 심사원 등급 및 교육 강사 수준에 대한 기준을 수립
        </>,
      ]
    : [
        "an independent, legally registered non-profit organization",
        "Open and transparent operations as an honorary organization",
        <>
          International Organizations (Multilingual Support) <br />
          a. Establish standards for assessor ratings and training instructor
          levels
        </>,
      ];

  const certificationList = [
    languageQuarter
      ? "심사원 교육기관 승인"
      : "Approved by the assessor's educational institution",
    languageQuarter
      ? "심사원 인증 시험 주관"
      : "Subject to the examination certified by the assessor",
    <>
      {languageQuarter ? (
        <>
          심사원 및 교육 강사 재 인증관리 <br />
          a. Automotive SPICE®에 대한 인증기관 : VDA QMC
          <br />
          b. Other SPICE에 대한 인증기관 : ECQA
        </>
      ) : (
        <>
          Managing re-certification of assessors & instructors{" "}
          <br />
          a. Certification authority for ASPICE® : VDA QMC <br />
          b. Certification authority for Other SPICE : ECQA
        </>
      )}
    </>,
    <ScrollContainer className="imgWrap">
      <div className="img">
        <img
          src={
            languageQuarter
              ? isMobile ? ImgAssessmentDetail03Mb : ImgAssessmentDetail03
              : isMobile ? ImgAssessmentDetail03MbEn : ImgAssessmentDetail03En
          }
          alt="ImgAssessmentDetail03"
        />
      </div>
    </ScrollContainer>,
    <ScrollContainer className="imgWrap">
      <div className="img">
        <img
          src={
            languageQuarter
              ? isMobile ? ImgAssessmentDetail04Mb : ImgAssessmentDetail04
              : isMobile ? ImgAssessmentDetail04MbEn : ImgAssessmentDetail04En}
          alt="ImgAssessmentDetail04"
        />
      </div>
    </ScrollContainer>,
  ];

  const providerList = {
    first: [
      "Business Cube & Partners, Inc.",
      "C&BIS",
      "Compita Japan Compony Limited",
      "DNV Japan",
      "Expleogroup Technology Germany GmbH",
    ],
    second: [
      "IQI Consulting Services Pvt. Ltd.",
      "ISCN",
      "Knüvener Mackert GmbH",
      "Korea Advanced Automotive Technology Association",
      "KUGLER MAAG CIE by UL",
    ],
    third: [
      "Mercedes-Benz AG",
      "Method Park by UL",
      "NTT Data Spain S.L.U.",
      "Process Fellows GmbH",
      "Synspace",
    ],
  };

  return (
    <>
      <Styles.Container className="titleSection">
        <div className="inner">
          <layout.DescriptionBox title={"Automotive SPICE"} />
        </div>
        <div id="focus"></div>
      </Styles.Container>

      <Styles.Container className="systemSection">
        <div className="inner">
          <h3>
            {languageQuarter
              ? "Automotive SPICE® 심사체계"
              : "Automotive SPICE® assessment system"}
          </h3>

          <p className="sectionDescription">
            {languageQuarter ? (
              <>
                Automotive SPICE®는 독립적이고 객관적인 심사(Assessment)를
                보장하기 위해 다음과 같이 intacs™, 인증기관, 교육기관이 각각
                심사원의 기준, 수준 / 인증, <br className="mbOnly" /> 유지보수 /
                교육을 담당하고 있습니다. 이러한 체계를 통해 능력과 자질을 갖춘
                Automotive SPICE® 심사원들을 육성 및 유지하도록 하고,
                제품개발업체(Product Development Companies)에게 독립적이고
                객관적인 Automotive <br className="mbOnly" />
                SPICE® 심사를 제공합니다.
              </>
            ) : (
              <>
                To ensure independent and objective assessment, Automotive
                SPICE® is responsible for the standards, level/certification,
                maintenance/training of the assessors by intacs™, certification
                bodies, and educational institutions, respectively, to ensure
                independent and objective assessment. The system fosters and
                maintains capable and qualified Automotive SPICE® assessors and
                provides product development companies with independent and
                objective Automotive SPICE® assessment
              </>
            )}
          </p>

          <figure>
            <div className="img">
              <img src={ImgAssessmentDetail01} alt="ImgAssessmentDetail01" />
            </div>
          </figure>
        </div>
      </Styles.Container>

      <Styles.Container className="intacsSection">
        <div className="inner">
          <h3>
            <>
              intacs<sup>™</sup> <br />
              <span>(international Assessor Certification Scheme)</span>
            </>
          </h3>

          <ul>
            {intacsList.map((item, index) => {
              return (
                <li className="intacsList" key={"intacsList" + index}>
                  <layout.BusinessTable
                    title={`0${index + 1}`}
                    description={item}
                  />
                </li>
              );
            })}
          </ul>

          <figure>
            <div className="img">
              <img src={ImgAssessmentDetail02} alt="ImgAssessmentDetail02" />
            </div>
          </figure>
        </div>
      </Styles.Container>

      <Styles.Container className="certificationSection">
        <div className="inner">
          <h3>
            {languageQuarter ? (
              <>
                인증기관 <br />
                <span>(Certification Bodies)</span>
              </>
            ) : (
              <>Certification Bodies</>
            )}
          </h3>

          <ul>
            {certificationList.map((item, index) => {
              return (
                <li
                  className="certificationList"
                  key={"certificationList" + index}
                >
                  <layout.BusinessTable
                    title={`0${index + 1}`}
                    description={item}
                  />

                  {index > 2 && isMobile && (
                    <div className="scrollGuide">
                      <layout.ScrollGuide />
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </Styles.Container>

      <Styles.Container className="providerSection">
        <div className="inner">
          <h3>
            {languageQuarter ? (
              <>
                교육기관 <br />
                <span>(Training Providers)</span>
              </>
            ) : (
              <>Training Providers</>
            )}
          </h3>

          <ul className="listWrap">
            <li className="list">
              <b>
                {languageQuarter
                  ? "1. 초급 심사원 / 선임 심사원 양성 교육 주관"
                  : "1. Supervised by training beginner/senior assessor"}
              </b>
            </li>

            <li className="list">
              <b>
                {languageQuarter ? (
                  <>
                    2. 전 세계적으로 17개의 승인된 교육기관이 존재함
                    <br className="mbOnly" />
                    (2023.12 기준)
                  </>
                ) : (
                  <>
                    2. There are 17 approved educational institutions worldwide
                    (as of December 2023)
                  </>
                )}
              </b>

              <p>
                {languageQuarter ? (
                  <>
                    <span>a. </span>
                    대한민국에서는 씨엔비스(주)가 2016년 9월 국내 최초로
                    교육기관으로 승인되었음
                  </>
                ) : (
                  <>
                    <span>a. </span> In Korea, C&BIS was approved as the first
                    educational institution in Korea in September 2016
                  </>
                )}
              </p>

              <div className="providerListWrap">
                <ol>
                  {providerList.first.map((item, index) => {
                    return (
                      <li className="providerList" key={"providerList" + index}>
                        {item}
                      </li>
                    );
                  })}
                </ol>

                <ol start={6}>
                  {providerList.second.map((item, index) => {
                    return (
                      <li className="providerList" key={"providerList" + index}>
                        {item}
                      </li>
                    );
                  })}
                </ol>

                <ol start={11}>
                  {providerList.third.map((item, index) => {
                    return (
                      <li className="providerList" key={"providerList" + index}>
                        {item}
                      </li>
                    );
                  })}
                </ol>
              </div>
            </li>
          </ul>
        </div>
      </Styles.Container>

      <div className="listButton">
        <ui.button.BasicButton
          buttonText={languageQuarter ? "이전으로" : "Back"}
          buttonType={"blue"}
          onClick={() => {
            navigate("/business/assessment/assessment?disableScrollTop=true&focus=focus");
          }}
        />
      </div>
    </>
  );
}

export default AssessmentDetail;
