import Styles from "./styles";
import { api } from "service";
import { common, layout } from "components";
import { useState, useRef } from "react";
import { useHistory, useLanguage } from "hooks";

// img
import BgBanner from "resources/image/introduction/bg_main.jpg";
import BgBannerMb from "resources/image/introduction/bg_main_mb.jpg";
import ImgCar from "resources/image/introduction/img_car.png";
import IconArrowTop from "resources/image/icon/icon_arrow_top_box.svg";
import IconArrowBottom from "resources/image/icon/icon_arrow_bottom_box.svg";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/thumbs";
import { EffectFade, Navigation, Pagination, Thumbs } from "swiper";

function HistoryPage() {
  const historyInfo = useHistory();
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";

  const data = historyInfo.getHistory.data;

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const carRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [percentResult, setPercentResult] = useState(0);

  return (
    data && (
      <>
        <common.Header />
        <Styles.Container>
          <section className="bannerSection">
            <layout.MainBanner
              img={BgBanner}
              mbImg={BgBannerMb}
              colorText={languageQuarter ? "회사소개" : "About C&BIS"}
              title={languageQuarter ? "연혁" : "History"}
            />
          </section>

          <section className="historySection">
            <div className="inner">
              <div className="yearSliderContainer">
                <button type="button" className="prevButton" ref={prevRef}>
                  <img src={IconArrowTop} alt="IconArrowTop" />
                </button>

                <Swiper
                  navigation={{
                    prevEl: prevRef.current,
                    nextEl: nextRef.current,
                  }}
                  direction={"vertical"}
                  ref={carRef}
                  onSwiper={setThumbsSwiper}
                  onSlideChange={(thumbsSwiper) => {
                    setCurrentIndex(thumbsSwiper.activeIndex);
                    const percent =
                      (thumbsSwiper.activeIndex / data.length) * 100;

                    setPercentResult(percent);
                  }}
                  slidesPerView={"auto"}
                  centeredSlides={true}
                  allowTouchMove={false}
                  modules={[Navigation, Pagination, Thumbs]}
                  className="yearSlider"
                >
                  {data.map((item, index) => {
                    return (
                      <SwiperSlide
                        onClick={() => {
                          thumbsSwiper?.slideTo(index, 300);
                        }}
                        key={"yearSlideList" + index}
                      >
                        {item.date}
                      </SwiperSlide>
                    );
                  })}
                </Swiper>

                <button type="button" className="nextButton" ref={nextRef}>
                  <img src={IconArrowBottom} alt="IconArrowBottom" />
                </button>
              </div>

              <div className="progressBar">
                <div
                  className="guage"
                  style={{
                    height: `${
                      data.length > currentIndex + 1 ? percentResult : 100
                    }%`,
                  }}
                >
                  <div
                    className="car"
                    style={{
                      transform: `${
                        data.length > currentIndex + 1
                          ? `translate(-50%, 100%)`
                          : `translate(-50%, 0)`
                      }`,
                    }}
                  >
                    <img src={ImgCar} alt="ImgCar" />
                  </div>
                </div>
              </div>

              <div className="historySliderContainer">
                <Swiper
                  effect={"fade"}
                  navigation={{
                    prevEl: prevRef.current,
                    nextEl: nextRef.current,
                  }}
                  thumbs={{
                    swiper:
                      thumbsSwiper && !thumbsSwiper.destroyed
                        ? thumbsSwiper
                        : null,
                  }}
                  modules={[Navigation, Thumbs, EffectFade]}
                  allowTouchMove={false}
                  className="historySlider"
                >
                  {data.map((item, index) => {
                    return (
                      <SwiperSlide
                        onClick={() => {
                          thumbsSwiper?.slideTo(index, 300);
                        }}
                        key={"historySlideList" + index}
                      >
                        <div className="img">
                          <img src={item.image} alt="historyImg" />
                        </div>

                        <p className="text">
                          {item["description" + languageInfo.suffix]}
                        </p>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          </section>
        </Styles.Container>
        <common.Footer />
      </>
    )
  );
}

export default HistoryPage;
