import Styles from "./styles";
import ui from "components/ui";
import { useState } from "react";
import { useLanguage, useModals, useSeminar } from "hooks";

function RegisterModal({ modalClose }) {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";
  const seminarInfo = useSeminar();

  const [errorText, setErrorText] = useState({ text: "", state: 0 });
  const [check, setCheck] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [inputs, setInputs] = useState({
    title: "",
    name: "",
    email: "",
    phone: "",
  });

  const inputValidate = (item, e) => {
    let text = e.target?.value;

    if(item === "email"){
      text = text.replace(/[^A-Za-z\-.@0-9]/ig, '');
    }
    if (item === "phone") {
      text = text.replace(/[^0-9]/gi, "");
      text = text.replace(/-/gi, "");
      text =
        text?.substr(0, 3) +
        (text?.length > 3
          ? "-" +
            text?.substr(3, 4) +
            (text?.length > 7 ? "-" + text?.substr(7, 4) : "")
          : "");
    }

    setInputs((ins) => {
      return { ...ins, [item]: text };
    });
  }

  const inputList = [
    {
      name: "title",
      value: inputs.title,
      placeholder: languageQuarter
        ? "소속을 입력하세요."
        : "Please enter your affiliation.",
      errorText: errorText.state === 1
    },
    {
      name: "name",
      value: inputs.name,
      placeholder: languageQuarter
        ? "이름을 입력하세요."
        : "Please enter your name.",
      errorText: errorText.state === 2
    },
    {
      name: "email",
      value: inputs.email,
      placeholder: languageQuarter
        ? "이메일을 입력하세요."
        : "Please enter your email.",
      errorText: errorText.state === 3
    },
    {
      value: inputs.phone,
      name: "phone",
      type: "tel",
      placeholder: languageQuarter
        ? "연락처를 입력하세요."
        : "Please enter your contact information.",
      errorText: errorText.state === 4
    },
  ];

  const validate = () => {
    const emailRegEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (inputs.title === "") {
      setErrorText({
        state: 1,
        text: languageQuarter
          ? "소속을 입력해주세요."
          : "Please enter your affiliation.",
      })
      return false;
    }
    if (inputs.name === "") {
      setErrorText({
        state: 2,
        text: languageQuarter
          ? "이름을 입력해주세요."
          : "Please enter your name.",
      })
      return false;
    }
    if (inputs.email === "") {
      setErrorText({
        state: 3,
        text: languageQuarter
          ? "이메일을 입력해주세요."
          : "Please enter your email.",
      })
      return false;
    }
    if (emailRegEx.test(inputs.email) === false) {
      setErrorText({
        state: 3,
        text: languageQuarter
          ? "이메일 형식을 확인해주세요."
          : "Please check the email format."
      })
      return false;
    }
    if (inputs.phone === "") {
      setErrorText({
        state: 4,
        text: languageQuarter
          ? "연락처를 입력해주세요."
          : "Please enter your contact information."
      })
      return false;
    }
    if (!check) {
      setErrorText({
        text: languageQuarter
          ? "개인정보 수집에 동의해주세요."
          : "Please Ok to collect personal information."
      })
      return false;
    }
    return true;
  }
  
  const onNext = () => {
    if (validate()) {
      setIsBlocked(true);
      seminarInfo.postSeminar(
        modalClose,
        { ...inputs, personal_policy_yn: check ? "Y" : "N"}
      )
    }
  }

  return (
    <Styles.Container>
      <div className="modalInner">
        <div className="modalTitle">
          {languageQuarter ? (
            <>
              교육 안내문 <br />
              수신자 등록
            </>
          ) : (
            <>
              Registration of recipients <br />
              of training notices
            </>
          )}
        </div>

        <div className="wrap">
          <p className="mainGuide">
            {languageQuarter ? (
              <>
                <span>씨엔비스 교육센터</span>에서 개설하는 교육 관련 안내 메일 수신을 희망하는 경우,{" "}
                <br />
                아래 내용을 입력해주세요.
              </>
            ) : (
              <>
                If the training is confirmed, CNBIS Education Center will send a
                training notice four to five weeks before the training date. If
                you want to receive the notice, please enter the information
                below.
              </>
            )}
          </p>

          <ul className="subGuide">
            <li>
              {"* " +
                (languageQuarter
                  ? "본 메일은 education@cnbis.co.kr로부터 발송됩니다."
                  : "This email is sent from education@cnbis.co.kr")}
            </li>
            <li>
              {"* " +
                (languageQuarter
                  ? "스팸메일로 분류될 수 있으니 유의 바랍니다."
                  : "Please note that it may be classified as spam.")}
            </li>
          </ul>

          <form>
            <p className="formTitle">
              {languageQuarter ? "수신자 정보" : "Recipient Information"}
            </p>

            <ul className="inputWrap">
              {inputList.map((item, index) => {
                return (
                  <li className="input" key={"input" + index}>
                    <ui.input.BasicInput
                      data={item}
                      setInputs={setInputs}
                      onChange={(e) => {
                        inputValidate(item.name, e);
                      }}
                    />
                  </li>
                );
              })}
            </ul>

            <div className="terms">
              <div className="check">
                {languageQuarter
                  ? "개인정보 수집 및 이용에 동의합니다."
                  : "I agree to collect and use personal information."}
                <div className="checkbox">
                  <ui.input.BasicCheckbox
                    checked={check}
                    id={"privacyTerms"}
                    checkText={languageQuarter ? "동의" : "Ok"}
                    onChange={(e) => {
                      setCheck(e.target.checked);
                    }}
                  />
                </div>
              </div>

              <div className="termsDetail">
                {languageQuarter ? (
                  <>
                    교육 안내문 수신을 위해서는 아래와 같이 개인정보를
                    수집・이용 합니다.
                    <ul>
                      <li>1. 개인정보 수집 목적 : 교육 안내문 수신 목적</li>
                      <li>
                        2. 개인정보 수집 항목 : 이름, 소속, 이메일, 연락처
                      </li>
                      <li>3. 보유 및 이용기간 : 수집일부터 3년까지</li>
                    </ul>
                  </>
                ) : (
                  <>
                    To receive educational notices, personal information is
                    collected and used as follows.
                    <ul>
                      <li>
                        1. Purpose of collecting personal information: Receiving
                        educational notices
                      </li>
                      <li>
                        2. Item of collecting personal information: Name,
                        affiliation, e-mail, contact information
                      </li>
                      <li>
                        3. Retention and use period: From the date of collection
                        to 3 years
                      </li>
                    </ul>
                  </>
                )}
              </div>
            </div>

            <div className="buttonWrap">
              <div className="errorText">
                {errorText.text}
              </div>

              <ui.button.BasicButton
                buttonText={languageQuarter ? "취소" : "Cancellation"}
                buttonType={"white"}
                onClick={() => {
                  modalClose();
                }}
              />
              <ui.button.BasicButton
                buttonText={
                  isBlocked
                    ? languageQuarter ? "처리 중" : "Processing"
                    : languageQuarter ? "등록하기" : "Register"
                }
                buttonType={"blue"}
                onClick={!isBlocked ? onNext : ()=>{}}
              />
            </div>
          </form>
        </div>
      </div>

      <div className="overlay"></div>
    </Styles.Container>
  );
}

export { RegisterModal };

export default RegisterModal;
