import Styles from "./styles";
import { Link } from "react-router-dom";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";

// img
import IconPrev from "resources/image/icon/icon_prev02.svg";
import IconNext from "resources/image/icon/icon_next02.svg";

  
function EducationSlider({ id, data, link, intacs, isIntro }) {
  const renderTag = (type) => {
    switch (type) {
      case "1":
        return <div className="state progress">개설예정</div>;
      case "2":
        return <div className="state scheduled">신청중</div>;
      case "3":
        return <div className="state closed">교육신청 마감</div>;
    }
  };

  return (
    <Styles.Container>
      <Swiper
        spaceBetween={21}
        slidesPerView={"auto"}
        navigation={{
          prevEl: intacs ? ".secondPrev" : ".firstPrev",
          nextEl: intacs ? ".secondNext" : ".firstNext",
        }}
        modules={[Navigation]}
        className="slider"
      >
        {data.map((item, index) => {
          return (
            <SwiperSlide className="slideList" key={"slideList" + index}>
              <Link
                to={(link ? link : isIntro
                  ? "/education/curriculum-detail/" + id + "?id=" + item.id
                  : "/education/apply/detail/" + id + "?id=" + item.id
                )}
                onClick={(e) => {
                  item.type === 2 && e.preventDefault();
                }}
              >
                <div className="imgWrap">
                  <div className="img">
                    <img src={item?.thumbnail} alt="slideImg" />
                  </div>

                  {renderTag(item.state_type)}
                </div>

                <div className="title">
                  <p>{item.title}</p>
                </div>
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>

      <button
        type="button"
        className={"prevButton " + (intacs ? "secondPrev" : "firstPrev")}
      >
        <img src={IconPrev} alt="IconPrev" />
      </button>

      <button
        type="button"
        className={"nextButton " + (intacs ? "secondNext" : "firstNext")}
      >
        <img src={IconNext} alt="IconNext" />
      </button>
    </Styles.Container>
  );
}

export default EducationSlider;
