import Styles from "./styles";

function BasicRadio({ data, name, check, setCheck }) {
  const { id, labelText } = data;

  return (
    <>
      <Styles.Input
        type="radio"
        id={id}
        name={name}
        checked={check === id ? true : false}
        onChange={(e) => {
          setCheck(e.target.id);
        }}
      />

      <Styles.Label htmlFor={id}>
        <span className="checkbox"></span>

        <span className="labelText">{labelText}</span>
      </Styles.Label>
    </>
  );
}

export default BasicRadio;
