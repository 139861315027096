import styled from "styled-components";

const Container = styled.main`
  min-height: 100vh;
  min-height: 100dvh;
  padding: 180px 0 200px;

  & h2 {
    margin-bottom: 80px;
    font-size: 62px;
    font-weight: 800;
  }

  & .tab {
    margin-bottom: 50px;
  }

  @media (max-width: 1480px) {
    padding: calc(100vw * (180 / 1480)) 0 calc(100vw * (200 / 1480));

    & h2 {
      margin-bottom: calc(100vw * (80 / 1480));
      font-size: calc(100vw * (62 / 1480));
    }

    & .tab {
      margin-bottom: calc(100vw * (50 / 1480));
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (116 / 375)) 0 calc(100vw * (130 / 375));

    & h2 {
      margin-bottom: calc(100vw * (40 / 375));
      font-size: calc(100vw * (32 / 375));
    }

    & .tab {
      margin-bottom: calc(100vw * (30 / 375));
    }
  }
`;

export { Container };

export default {
  Container,
};
