import Styles from "./styles";
import { ui } from "components";
import { useLanguage, useSupport } from "hooks";
import { useLocation, useNavigate } from "react-router-dom";

//img
import IconFile from "resources/image/icon/icon_file.svg";

function NoticeDetail() {
  const navigate = useNavigate();
  const supportInfo = useSupport({ noticeDetail: true });
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === ""
  const data = supportInfo.getNoticeDetail.data || [];

  return (
    <Styles.Container>
      <div className="titleContainer">
        <p className="title">{data?.["title" + languageInfo.suffix]}</p>
        <p className="date">{data?.created_format}</p>
      </div>

      <div className="editor ck-content"
        dangerouslySetInnerHTML={{__html: data?.["description" + languageInfo.suffix]}}
      >
      </div>

      {data.file_list?.length > 0 && (
        <ul className="fileWrap">
          {data.file_list?.map((item, index) => {
            return (
              <li className="fileList" key={"fileList" + index}>
                <a href={window.location.origin + item.file_url} download>
                  <p className="fileName">{item.file_name}</p>
                  <div className="icon">
                    <img src={IconFile} alt="IconFile" />
                  </div>
                </a>
              </li>
            );
          })}
        </ul>
      )}

      <div className="listButton">
        <ui.button.BasicButton
          buttonText={languageQuarter ? "목록으로" : "List"}
          buttonType={"blue"}
          onClick={() => {
            navigate(-1);
          }}
        />
      </div>
    </Styles.Container>
  );
}

export default NoticeDetail;
