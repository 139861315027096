import utils from "utils";
import Styles from "./styles";
import { common, ui } from "components";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

// img
import IconEmpty from "resources/image/icon/icon_empty.svg";
import { useStudy } from "hooks";

function InquiryResultPage() {
  const location = useLocation();
  const [filter, setFilter] = useState();
  const studyInfo = useStudy({ option: filter });
  const data = studyInfo.getStudyList.data;

  const renderPay = (type) => {
    switch (type) {
      case "CARD":
        return "카드 결제";

      case "VBANK":
        return "세금계산서";

      case "OFFLINE":
        return "현장 결제";
    }
  };

  const table = {
    thead: [
      {
        class: "number",
        text: "No.",
      },
      {
        class: "category",
        text: "교육과정",
      },
      {
        class: "title",
        text: "과정명",
      },
      {
        class: "period",
        text: "교육기간",
      },
      {
        class: "method",
        text: "결제수단",
      },
      {
        class: "date",
        text: "신청일",
      },
    ],
    tbody: data?.list
  };

  useEffect(() => {
    let param = utils.etc.getSearchParam();
    let f = {
      page: param.page ? param.page : 1,
      recordSize: 10,
      token: encodeURI(sessionStorage.getItem("token")),
    }
    setFilter({ ...f });
  }, [location]);

  return (
    sessionStorage.getItem("token") &&
    <>
      <common.Header />
      <Styles.Container>
        <section>
          <div className="inner">
            <div className="titleWrap">
              <h2>교육신청 조회</h2>
              <p>
                교육 기간이 종료된 교육은 <br />
                조회되지 않습니다.
              </p>
            </div>

            {table.tbody?.length > 0 ? (
              <>
                <div className="table">
                  <ul className="thead">
                    {table.thead.map((item, index) => {
                      return (
                        <li className={`th ${item.class}`} key={"th" + index}>
                          {item.text}
                        </li>
                      );
                    })}
                  </ul>

                  <ul className="tbody">
                    {table.tbody.map((item, index) => {
                      return (
                        <li className="td" key={"td" + index}>
                          <Link
                            to={`/inquiry/detail/${item.method}${
                              item.method === "VBANK" ? item.tax_yn && (item.tax_yn === "N" ? "/before" : "/after") : ""
                            }/${item.id}`}
                            state={true}
                          >
                            <div className="number">{(data.pagination?.totalRecordCountAll - index) - (data.pagination?.page - 1) * 10}</div>
                            <div className="category">{item.category_title}</div>
                            <div className="title">{item.title}</div>
                            <div className="period">
                              {item.study_date}
                            </div>
                            <div className="method">
                              {item.cancel_yn === "Y" ? "신청 취소" : renderPay(item.method)}
                            </div>
                            <div className="date">{item.created_format}</div>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div className="pagination">
                  <ui.Pagination
                    page={data.pagination?.page}
                    list={data.pagination?.page_list}
                    maxPage={data.pagination?.totalPageCount}
                  />
                </div>
              </>
            ) : (
              <div className="emptyText">
                <div className="icon">
                  <img src={IconEmpty} alt="IconEmpty" />
                </div>

                <p>신청한 교육이 없습니다.</p>
              </div>
            )}
          </div>
        </section>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default InquiryResultPage;
