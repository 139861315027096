import Styles from "./styles";
import { layout, ui } from "components";
import { useLanguage } from "hooks";
import { useNavigate } from "react-router-dom";

// img
import ImgAds01 from "resources/image/business/service/img_ads01.jpg";
import ImgAds01En from "resources/image/business/service/img_ads01_en.jpg";
import ImgAds02 from "resources/image/business/service/img_ads02.jpg";
import ImgAds03 from "resources/image/business/service/img_ads03.jpg";
import ImgAds04 from "resources/image/business/service/img_ads04.jpg";
import ImgAds04En from "resources/image/business/service/img_ads04_en.jpg";
import ImgAds05 from "resources/image/business/service/img_ads05.jpg";
import ImgAds05En from "resources/image/business/service/img_ads05_en.jpg";
import ImgAds06 from "resources/image/business/service/img_ads06.jpg";
import ImgAds07 from "resources/image/business/service/img_ads07.jpg";
import ImgAds08 from "resources/image/business/service/img_ads08.jpg";

function Ads() {
  const languageInfo = useLanguage()
  const languageQuarter = languageInfo.suffix === ""
  const navigate = useNavigate();

  const strategyList = languageQuarter ? [
    <>
      고려 중인 ADS 피처에 대해 운행설계범위(ODD)가 <br className="mbOnly" />
      정의되어야 합니다.
    </>,
    <>
      ODD는 DDT 및 DDT 폴백의(레벨 4의 ADS의 경우) <br className="mbOnly" />
      지속적인 실행이 <br className="pcOnly" />
      ODD 내부에서 시작되도록 정의해야 합니다.
    </>,
    <>
      고려 중인 ADS 피처의 유스 케이스는 ADS 피처와 사용자 및{" "}
      <br className="mbOnly" />
      환경 간의 <br className="pcOnly" />
      상호작용을 포함하여 명시되어야 합니다.
    </>,
    <>
      ADS는 작동시 모든 주행 작업을 안전하게 <br className="mbOnly" />
      수행하는 역할을 합니다.
    </>,
    <>
      ADS는 적절한 수의 위험최소화상태(MRC)이 <br className="mbOnly" />
      정의되어야 합니다.
    </>,
    <>
      MRC의 정의는 안전한 이유에 대한 논의로 <br className="mbOnly" />
      보완될 필요가 있습니다.
    </>,
  ] : [
    <>
      An operating design envelope (ODD) must be defined for the ADS feature under consideration.
    </>,
    <>
      The ODD must define continued execution of the DDT and DDT fallback (for ADS at level 4) to be initiated inside the ODD.
    </>,
    <>
      The use case of the ADS feature under consideration must be specified,
      including the interaction between the ADS feature and the user and the environment.
    </>,
    <>
      ADS is responsible for safely carrying out all driving operations when operating.
    </>,
    <>
      ADS must have an appropriate number of Minimum Risk Conditions(MRCs) defined.
    </>,
    <>
      The definition of the MRC needs to be supplemented by discussions on why it is safe.
    </>,
  ];

  const definitionList = languageQuarter ? [
    "ADS 피처에 대한 안전 요구사항을 정의해야 합니다.",
    <>
      ADS 기능이 안전하게 작동하는 데 필요한 가정을 <br />
      정의해야 합니다.
    </>,
    <>
      ADS 안전 요구사항은 해당 가정과 함께 <br className="mbOnly" />
      6.2(안전 기준 정의)의
      <br className="pcOnly" />
      위험 수락 기준을 충족해야 합니다.
    </>,
  ] : [
    "Safety requirements must be defined for ADS features.",
    <>
      The assumptions required for the ADS function to operate safely must be defined.
    </>,
    <>
      ADS safety requirements must meet the risk acceptance criteria
      in 6.2(Defining safety criteria) with their assumptions.
    </>,
  ];

  const validationList = languageQuarter ? [
    <>
      ADS의 구현이 ADS 설계의 요건을 <br />
      충족한다는 증거를 제공합니다.
    </>,
    <>
      ADS에 대한 안전 요건이 의도된 용도와 ODD에 따라 <br />
      ADS에 의해 달성된다는 증거를 제공합니다.
    </>,
    "안전 타당성 확인 및 평가에 사용되는 증거를 생성합니다.",
  ] : [
    <>
      Provide evidence that the implementation of ADS meets the requirements of ADS design.
    </>,
    <>
      Provide evidence that safety requirements for ADS are achieved by ADS in accordance with the intended use and ODD.
    </>,
    "Produce evidence used to validate and evaluate safety.",
  ];

  return (
    <>
      <Styles.Container className="titleSection">
        <div className="inner" id="focus">
          <layout.DescriptionBox
            title={
              <>
                ISO AWI TS 5083 Road vehicles — SAFETY FOR AUTOMATED DRIVING
                SYSTEMS
              </>
            }
            left={"ISO 5083" + (languageQuarter ? "이란" : "")}
            right={languageQuarter ?
              <>
                자동화 주행차량의 안전한 운용은 가장 중요한 요인 중 하나
                <br className="mbOnly" />
                입니다. ISO5083은 기존의 ISO26262 <br className="pcOnly" />
                기능안전성, ISO21448(SOTIF) 의도된 기능안전성, 자율주행 테스트
                시나리오에 대한 각종 표준, <br className="pcOnly" />
                ISO21434 사이버보안까지 관련 모든 안전 표준을 포함하는 새로운
                ‘자율주행 안전성 및 검증 <br className="pcOnly" />
                가이드라인’입니다.
              </> : <>
                Safe operation of automated driving vehicles is one of the most important factors.
                ISO5083 is a new 'Self-Driving Safety and Verification Guidelines' that includes all
                relevant safety standards, including the existing ISO26262 functional safety,
                ISO21448 (SOTIF) intended functional safety, various standards for self-driving test scenarios,
                and ISO21434 cybersecurity.
              </>
            }
          />
        </div>
      </Styles.Container>

      <Styles.Container className="overviewSection">
        <div className="inner">
          <h3>{languageQuarter ? "표준의 개요" : "Overview"}</h3>

          <p className="sectionDescription">
            {languageQuarter ?
              <>
                이 표준은 도로 차량에 통합된 자동화 주행 시스템(ADS)의 안전을
                달성하고 입증하기 위한 지침을 제공합니다. 이 접근법은 전 세계적으로
                적용 가능한 간행물과 최상위 안전 목표에서 도출된 안전 원칙에
                기초합니다. ISO/SAE PAS 22736에 따라 정의된 레벨 3 및 레벨 4 ADS
                기능에 대한 설계, 검증 및 타당성 확인 및 배포 후 활동에 의한 안전을
                고려합니다.
                <br />
                <br />
                사이버 보안 고려사항에 대해 설명하며, 적용은 트럭과 버스를 포함하고
                오토바이를 제외한 도로 차량을 대상으로 합니다.
              </> : <>
                This standard provides an overview and guidance of the steps for developing and validating
                a vehicle equipped with a safe automated driving system. The approach is based on top level
                safety goals and basic principles derived from worldwide applicable publications.
                It considers safety by design, verification and validation methods for automated driving
                focused on SAE level 3 and level 4 vehicles according to ISO/SAE PAS 22736.
                <br />
                <br />
                In addition, it outlines cybersecurity considerations throughout all described steps.
                The standard is intended to be applied to road vehicles
                (incl. trucks and busses, i.e. road vehicles > 3,5to) and excluding motorcycles.
              </>}
          </p>
        </div>
      </Styles.Container>

      <Styles.Container className="structureSection">
        <div className="inner">
          <h3>{languageQuarter ? "표준의 구조" : "Structure of standards"}</h3>

          <p className="sectionDescription">
            {languageQuarter ?
              <>
                ADS 및 구성 컴포넌트의 개발 및 배포는 일반적으로 제품 설계 및 배포를
                위한 일반적인 프로세스 흐름을 따릅니다. 프로세스 흐름은 V-모델 안전
                수명 주기로 표현되며, 좌측은 제품 설계 개발을 포함하고 우측은 평가
                및 배포를 포함합니다. 실제로는 다양한 모델을 사용하고 보다 반복적인
                프로세스를 따르는 시스템 및 구성 요소에 대한 여러 수명 주기가 있을
                수 있습니다.
              </> : <>
                The development and deployment of an ADS and constituent components will typically
                follow a regular process flow for product design and deployment. This process flow
                is represented as a V-model safety lifecycle, where the left-side leg encompasses
                the product design development, and the right-side leg evaluation and deployment.
                In practice, there can be multiple life cycles for systems and components that use
                different models and follow more iterative processes.
              </>}
          </p>

          <figure>
            <div className="img">
              <img src={languageQuarter ? ImgAds01 : ImgAds01En} alt="ImgAds01" />
            </div>
          </figure>
        </div>
      </Styles.Container>

      <Styles.Container className="strategySection">
        <div className="inner">
          <h3>Defined Safety Strategy</h3>

          <ul>
            {strategyList.map((item, index) => {
              return (
                <li className="strategyList" key={"strategyList" + index}>
                  <layout.BusinessTable
                    title={`0${index + 1}`}
                    description={item}
                  />
                </li>
              );
            })}
          </ul>

          <figure>
            <div className="img">
              <img src={ImgAds02} alt="ImgAds02" />
            </div>
          </figure>
        </div>
      </Styles.Container>

      <Styles.Container className="definitionSection">
        <div className="inner">
          <h3>{languageQuarter
            ? "차량 수준의 안전 요구사항 정의"
            : "Define vehicle level safety requirements"
          }</h3>

          <ul>
            {definitionList.map((item, index) => {
              return (
                <li className="definitionList" key={"definitionList" + index}>
                  <layout.BusinessTable
                    title={`0${index + 1}`}
                    description={item}
                  />
                </li>
              );
            })}
          </ul>

          <figure>
            <figcaption>Categorization of Event</figcaption>
            <div className="img">
              <img src={ImgAds03} alt="ImgAds03" />
            </div>
          </figure>

          <div className="wrap">
            <figure>
              <figcaption>Severity Calculation</figcaption>
              <div className="img">
                <img src={languageQuarter ? ImgAds04 : ImgAds04En} alt="ImgAds04" />
              </div>
            </figure>

            <figure>
              <figcaption>Safety margin and Residual Risk</figcaption>
              <div className="img">
                <img src={languageQuarter ? ImgAds05 : ImgAds05En} alt="ImgAds05" />
              </div>
            </figure>
          </div>
        </div>
      </Styles.Container>

      <Styles.Container className="designSection">
        <div className="inner">
          <h3>Safety by Design</h3>

          <p className="sectionDescription">
            {languageQuarter ?
              <>
                안전 설계를 공식화하는 방법을 보여드리겠습니다. 기능적 설계를
                사용하는 중요한 결정은 성능, 무결성 및 안전성의 분리
                <br className="mbOnly" />
                입니다. 안전은 정교한 안전 요구 사항을 충족함으로써 달성되며 <br />
                성능은 이러한 제약 조건 하에서 달성할 수 있는것입니다.
                실시간으로 전송되는 메타 데이터는 예를 들어 World
                Model에서 일부 데이터의 잘 정의된 사용이 올바른 정도
                <br />
                (예: "보고된 무결성에서 지정된 좌표에 보행자가 없음")를 표현
                <br className="mbOnly" />할 수 있습니다. World Model은 적어도 주어진
                무결성을 충족
                <br className="mbOnly" />
                해야 합니다.
              </> : <>
                How to formulate a safety design - A important decision using functional
                design is the separation of performance, integrity, and safety. Safety is
                achieved by fulfilling the contracts and performance is what
                can be achieved under those constraints.  The meta data transmitted in real
                time can for example express to which degree a well-defined usage of some data
                in World Model is correct (e.g., “pedestrians are absent at a given coordinate
                with the reported integrity”), or where data in World Model fulfils at least a
                given integrity (e.g., “with the given integrity pedestrians are absent in the reported area”).
              </>}
          </p>

          <figure>
            <div className="img">
              <img src={ImgAds06} alt="ImgAds06" />
            </div>
          </figure>
        </div>
      </Styles.Container>

      <Styles.Container className="validationSection">
        <div className="inner">
          <h3>Verification & Validation</h3>

          <p className="sectionDescription">
            {languageQuarter ?
              <>
                자율주행 시스템에 대한 검증 단계에는 다음과 같은 속성을 따라야
                합니다.
              </> : <>
                The verification phase for the autonomous driving system must follow the following attributes.
              </>}
          </p>

          <ul>
            {validationList.map((item, index) => {
              return (
                <li className="validationList" key={"validationList" + index}>
                  <layout.BusinessTable
                    title={`0${index + 1}`}
                    description={item}
                  />
                </li>
              );
            })}
          </ul>

          <figure>
            <figcaption>Validation Strategy</figcaption>
            <div className="img">
              <img src={ImgAds07} alt="ImgAds07" />
            </div>
          </figure>

          <p className="sectionDescription">
            {languageQuarter ?
              <>
                위의 검증전략을 기반으로 시험 환경은 다음과 같은 요구사항을 만족해야
                합니다.
              </> : <>
                Based on the verification strategy above, the test
                environment must meet the following requirements.
              </>}
          </p>

          <figure>
            <figcaption>Test Platform</figcaption>
            <div className="img">
              <img src={ImgAds08} alt="ImgAds08" />
            </div>
          </figure>
        </div>
      </Styles.Container>

      <Styles.Container className="solutionSection">
        <div className="inner">
          <h3>{languageQuarter ? "자율주행 대응을 위한 솔루션 제공" : "C&BIS Services"}</h3>

          <p className="sectionDescription">
            {languageQuarter ?
              <>
                저희 씨엔비스㈜ 에서는 ASPICE 및 ISO26262 기능안전 프로세스에 대한
                엔지니어링 컨설팅 능력을 바탕으로 자율주행 시스템에서 요구하는
                5083의 요건사항에 대한 적용 방안 및 가이드 제공 및 엔지니어링
                서비스를 통해 수준 높은 컨설팅 서비스를 제공하고 있습니다.
              </> : <>
                Based on its engineering consulting capabilities for ASPICE
                and ISO26262 functional safety processes, C&BIS provides
                high-quality consulting services through providing guidance
                and engineering services to the requirements of 5083 required
                by autonomous driving systems.
              </>}
          </p>
        </div>
      </Styles.Container>

      <div className="listButton">
        <ui.button.BasicButton
          buttonText={languageQuarter ? "목록으로" : "List"}
          buttonType={"blue"}
          onClick={() => {
            navigate("/business/depth/service?disableScrollTop=true&focus=bis_menu_0");
          }}
        />
      </div>
    </>
  );
}

export default Ads;
