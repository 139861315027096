import { api } from "service";
import { useQuery } from "react-query";
import { useState } from "react";

export default function useKG(isInit) {
  const [price, setPrice] = useState(null);

  const transactionForm = useQuery("transactionForm", () => { return api.transaction.getTransactionForm(JSON.stringify({ price: price })); }, {
    refetchOnWindowFocus: false,
    enabled: !!isInit && (price >= 0 && price != null)
  });

  function callSa() {
    let window = popupCenter();
    if (window != undefined && window != null) {
      document.saForm.setAttribute("target", "SendPayForm_id");
      document.saForm.setAttribute("post", "post");
      document.saForm.setAttribute("action", "https://sa.inicis.com/auth");
      document.saForm.submit();
    }
  }

  function callMobile() {
    let myform = document.mobileweb;
    myform.action = "https://mobile.inicis.com/smart/payment/";
    myform.target = "_self";
    myform.submit();
  }

  function popupCenter() {
    let _width = 400;
    let _height = 620;
    var xPos = (document.body.offsetWidth / 2) - (_width / 2); // 가운데 정렬
    xPos += window.screenLeft; // 듀얼 모니터일 때

    return window.open("", "sa_popup", "width=" + _width + ", height=" + _height + ", left=" + xPos + ", menubar=yes, status=yes, titlebar=yes, resizable=yes");
  }

  function refreshTransaction() {
    // transactionForm.refetch();
  }

  function detectMobileDevice(agent) {
    const mobileRegex = [
      /Android/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ]

    return mobileRegex.some(mobile => agent.match(mobile))
  }


  function doTransaction() {

    if ((transactionForm.data.verification && transactionForm.data.signature) == false) return;

    const agt = navigator.userAgent.toLowerCase();
    const script = document.createElement("script");
    const isMobile = detectMobileDevice(window.navigator.userAgent);

    if (isMobile) {
      script.src = "https://mobile.inicis.com/smart/payment/";
    } else {
      script.src = "https://stgstdpay.inicis.com/stdjs/INIStdPay.js";
    }

    document.head.appendChild(script);
    script.onload = e => {
      console.log(e);

      if (isMobile) callMobile();
      if ((navigator.appName === 'Netscape' && agt.indexOf('trident') !== -1) || (agt.indexOf("msie") !== -1) || agt.indexOf('edge')) {
        e.srcElement.ownerDocument.defaultView.INIStdPay.pay('SendPayForm_id');
      } else {
        e.path[3].defaultView.INIStdPay.pay('SendPayForm_id');
      }
    }
    // document.getElementById("kg_pay_tag").click();
    // callSa();
  }


  const updatePrice = (_price) => {
    setPrice(_price);
  }

  return {

    data: transactionForm.data,
    refreshTransaction,
    doTransaction,
    callSa,
    popupCenter,
    updatePrice,
  }
};