import Styles from "./styles";
import { common, layout, ui } from "components";
import { useEffect, useState } from "react";
import { useCategory, useProduct } from "hooks";
import { useLocation, useNavigate } from "react-router-dom";

// img
import IconArrowRight from "resources/image/icon/icon_arrow_right02.svg";
import utils from "utils";

function EducationApplyPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [filter, setFilter] = useState();

  const categoryInfo = useCategory({
    listOption: { type: 1, parent_info_id: 2 },
  });

  const productInfo = useProduct({
    listOption: filter,
  });

  const productIntacsInfo = useProduct({
    listOption: {
      page: 1,
      pageSize: 20,
      recordSize: 999,
      category_info_id: 3,
    },
  });

  useEffect(()=>{
    let param = utils.etc.getSearchParam();
    let f = {
      page: 1,
      pageSize: 20,
      recordSize: 999,
    };
    if(param.category_info_id){
      f.category_info_id = param.category_info_id;
    }else{
      f.category_info_id = 2;
    }
    setFilter({ ...f });
  },[location])

  return (
    <>
      <common.Header />
      <Styles.Container>
        <section className="curriculmSection">
          <div className="inner">
            <div className="mainTitle">
              <h2>교육신청</h2>

              <div className="inquiryButton">
                <ui.button.BasicButton
                  buttonText={"교육신청 조회"}
                  buttonType={"whiteHover"}
                  icon={IconArrowRight}
                  onClick={() => {
                    navigate("/inquiry");
                  }}
                />
              </div>
            </div>

            <div className="cnbis">
              <h3>C&BIS 교육과정</h3>

              <div className="tab">
                <ui.button.EducationTab
                  list={[{ title: "All", id: 2 }].concat(categoryInfo.list)}
                  tabIndex={filter?.category_info_id}
                  setTabIndex={v => { setFilter(e => { return { ...e, category_info_id: v } }) }}
                  onClick={(e) => {
                    navigate(utils.etc.getQueryParamUrl({ category_info_id: e.id, disableScrollTop: true }));
                  }}
                />
              </div>

              <layout.EducationSlider
                // link={"/education/apply/detail/cnbis"}
                id={"cnbis"}
                data={productInfo?.listQuery?.data?.list || []}
              />
            </div>

            <div className="intacs">
              <h3>intacs™ 공식 교육과정</h3>

              <layout.EducationSlider
                intacs
                // link={"/education/apply/detail/intacs"}
                id={"intacs"}
                data={productIntacsInfo?.listQuery?.data?.list || []}
              />
            </div>
          </div>
        </section>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default EducationApplyPage;
