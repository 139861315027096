import Styles from "./styles";
import { useProduct } from "hooks";

// img
import IconClose from "resources/image/icon/icon_close02.svg";
import Dummy from "resources/image/dummy/img_dummy_schedule_modal.png";

function ScheduleModal({ modalClose }) {
  const productInfo = useProduct({ schedule: true });

  const imageData = productInfo.productSchedule?.data?.data;

  return (
    <Styles.Container>
      <div className="modalInner">
        <button
          type="button"
          className="closeButton"
          onClick={() => {
            modalClose();
          }}
        >
          <img src={IconClose} alt="IconClose" />
        </button>

        <div
          className="img editor ck-content"
          dangerouslySetInnerHTML={{ __html: imageData }}
        ></div>
      </div>

      <div className="overlay"></div>
    </Styles.Container>
  );
}

export { ScheduleModal };

export default ScheduleModal;
