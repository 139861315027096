import Styles from "./styles";
import utils from "utils";
import { ui } from "components";
import { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useModals, useStudy, useUpload } from "hooks";

// img
import IconDelete from "resources/image/icon/icon_delete.svg";
import IconDownload from "resources/image/icon/icon_download02.svg";
import IconWarning from "resources/image/icon/icon_warning.svg";

function BeforeForming() {
  const navigate = useNavigate();
  const modalOption = useModals();
  const studyInfo = useStudy({});
  const uploadInfo = useUpload();
  const { data, ableCancel } = useOutletContext();

  const [file, setFile] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [inputs, setInputs] = useState({
    manager_email: "",
    publish_site: "",
    request_memo: "",
  });

  const [date, setDate] = useState(null);
  const [depositDate, setDepositDate] = useState(null);
  const [errorText, setErrorText] = useState({
    errorFile: "",
    errorEmail: "",
    errorSite: "",
    errorDate: "",
    errorDeposit: ""
  });

  const fileUpload = async (e) => {
    setFile(e.target.files[0]);
    const fileData = await uploadInfo.uploadFile(e.target.files[0]);
    setFileData(fileData)
  };

  // data
  const termsList = {
    vbank: [
      "세금계산서 발행을 위해 필수 정보 작성 바라며, 최종 발행되어야 결제가 완료됩니다.",
      "세금계산서 발행 정보는 교육 신청 마감일까지 작성 가능합니다.",
    ],
    deposit: [
      "씨엔비스 '입금계좌/예금주명’을 확인해주세요. (통장사본이 필요할 경우 ghyoo@cnbis.co.kr로 요청해주세요.)",
    ],
    refund: [
      "C&BIS 교육과정은 교육일 기준 일주일 전까지 취소 가능하며, 이후 취소 시 교재 비용을 제외한 금액만 환불됩니다.",
      "intacs™ 교육과정은 신청 마감일까지 취소 가능하며, 이후 취소시 라이선스 비용(100만원)을 제외한 금액만 환불됩니다.",
      "카드 결제의 경우, 신청 취소 시 카드사 영업일 기준 3~5일 이내 환불됩니다.",
      "세금계산서 발행의 경우, 신청 취소 시 C&BIS 담당자 확인 후 환불 관련 개별 연락드립니다.",
      "현장 결제의 경우, 당일 취소 및 No-show 발생 시 C&BIS에서 진행하는 모든 교육에 대해 6개월~1년간 수강이 불가합니다.",
    ],
  };
  const taxInvoiceList = [
    {
      labelText: "담당자 이메일",
      value: inputs.manager_email,
      name: "manager_email",
      placeholder: "이메일을 입력하세요.",
      essential: true,
      errorText: errorText.errorEmail
    },
    {
      labelText: "발행 사이트",
      value: inputs.publish_site,
      name: "publish_site",
      placeholder: "발행 사이트를 입력하세요.",
      essential: true,
      errorText: errorText.errorSite,
      guide: ["지정 사이트가 없을 경우 '홈택스'에서 발행합니다. ('없음' 입력)"],
    },
    {
      labelText: "발행 일자",
      startDate: date,
      setStartDate: setDate,
      placeholder: "발행 일자를 입력하세요.",
      essential: true,
      errorText: errorText.errorDate,
      guide: [
        "지정 일자가 없을 경우 '교육 신청 일자'로 발행합니다. ('1900.01.01' 입력)",
        "C&BIS 교육은 교육 시작일, intacs 교육은 신청 마감일까지 발행 가능합니다.",
      ],
    },
    {
      labelText: "입금 예정일",
      startDate: depositDate,
      setStartDate: setDepositDate,
      placeholder: "입금 예정일을 입력하세요.",
      essential: true,
      errorText: errorText.errorDeposit,
      guide: ["세금계산서 발행일로부터 30일 이내 입금되어야 합니다."],
      // minDate: new Date(data?.study_date.split("~")[1]),
      // maxDate: utils.date.dateCalculator(data?.study_date.split("~")[1], 30),
    },
    {
      labelText: "메모(요청사항)",
      value: inputs.request_memo,
      name: "request_memo",
      placeholder: "내용을 입력하세요.",
      guide: [
        <>
          한 회사에서 2인 이상의 세금계산서 발행이 필요한 경우, <br />
          발행 방식을 선택하여 작성 부탁드립니다. (일괄 발행 or 개별 발행)
        </>,
      ],
    },
  ];
  const depositList = [
    {
      title: "입금계좌",
      text: "신한은행 140-009-880504",
    },
    {
      title: "예금주명",
      text: "씨엔비스(주)",
    },
  ];

  // console.log({ w: window.innerWidth, h: window.innerHeight });
  // console.log((window.innerWidth + window.innerHeight) / window.innerWidth);
  // const a = (window.innerWidth + window.innerHeight) / window.innerWidth;
  // console.log((window.innerWidth + window.innerHeight) / window.innerWidth);

  const validate = () => {
    setErrorText({});
    const emailRegEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!file) {
      setErrorText({ errorFile: "사업자등록증을 첨부해주세요." })
      window.scrollTo(0, 1200);
      return false;
    }
    if (inputs.manager_email === "") {
      setErrorText({ errorEmail: "담당자 이메일을 입력하세요." })
      window.scrollTo(0, 1600);
      return false;
    }
    if (emailRegEx.test(inputs.manager_email) === false) {
      setErrorText({ errorEmail: "이메일 형식을 확인하세요." })
      window.scrollTo(0, 1600);
      return false;
    }
    if (inputs.publish_site === "") {
      setErrorText({ errorSite: "발행 사이트를 입력하세요." })
      window.scrollTo(0, 1600);
      return false;
    }
    if (date === null) {
      setErrorText({ errorDate: "발행 일자를 입력하세요." })
      window.scrollTo(0, 1700);
      return false;
    }
    if (depositDate === null) {
      setErrorText({ errorDeposit: "입금 예정일을 입력하세요." })
      window.scrollTo(0, 1700);
      return false;
    }

    return true;
  }
  
  const onNext = () => {
    if (validate()) {
      studyInfo.postStudyTax({
        ...inputs,
        publish_date: utils.date.getDateType(date),
        deposit_date: utils.date.getDateType(depositDate),
        transaction_info_id: data?.id,
        token: sessionStorage.getItem("token"),
        file_name: fileData.data.original_file_name,
        file_uri: fileData.data.file_url
      })
    }
  }
  
  return (
    <Styles.Container>
      <div className="wrap">
        <h3>세금계산서 발행 안내</h3>
        <div className="termsDetail">
          <ul>
            {termsList.vbank.map((item, index) => {
              return (
                <li className="vbankList termsList" key={"vbankList" + index}>
                  {item}
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      <div className="licenseInfo wrap">
        <h3>
          사업자등록증 정보{" "}
          <span className="errorText">{errorText.errorFile}</span>
        </h3>

        <div className="licenseUpload">
          <p className="title">사업자등록증 첨부</p>

          <input
            type="file"
            id="license"
            onChange={(e) => {
              fileUpload(e)
            }}
          />

          <label htmlFor="license">
            파일 등록하기{" "}
            <span className="icon">
              <img src={IconDownload} alt="IconDownload" />
            </span>
          </label>
        </div>

        {file && (
          <div className="licenseFile">
            {file.name}
            <button
              type="button"
              className="deleteButton"
              onClick={() => {
                setFile(false);
              }}
            >
              <img src={IconDelete} alt="IconDelete" />
            </button>
          </div>
        )}
      </div>

      <div className="wrap">
        <h3>세금계산서 업무 담당자 및 발행 정보</h3>

        <ul className="inputListWrap">
          <li className="inputList">
            <ui.input.BasicInput
              data={taxInvoiceList[0]}
              value={inputs[taxInvoiceList[0].name]}
              setInputs={setInputs}
            />
          </li>

          <li className="inputList">
            <ui.input.BasicInput
              data={taxInvoiceList[1]}
              value={inputs[taxInvoiceList[1].name]}
              setInputs={setInputs}
            />
            <div className="guide">
              <div className="icon">
                <img src={IconWarning} alt="IconWarning" />
              </div>

              <ul className="guideListWrap">
                {taxInvoiceList[1].guide.map((current, idx) => {
                  return (
                    <li className="guideList" key={"guideList" + idx}>
                      {current}
                    </li>
                  );
                })}
              </ul>
            </div>
          </li>

          <li className="inputList">
            <ui.input.DateInput data={taxInvoiceList[2]} />
            <div className="guide">
              <div className="icon">
                <img src={IconWarning} alt="IconWarning" />
              </div>

              <ul className="guideListWrap">
                {taxInvoiceList[2].guide.map((current, idx) => {
                  return (
                    <li className="guideList" key={"guideList" + idx}>
                      {current}
                    </li>
                  );
                })}
              </ul>
            </div>
          </li>

          <li className="inputList">
            <ui.input.DateInput data={taxInvoiceList[3]} />
            <div className="guide">
              <div className="icon">
                <img src={IconWarning} alt="IconWarning" />
              </div>

              <ul className="guideListWrap">
                {taxInvoiceList[3].guide.map((current, idx) => {
                  return (
                    <li className="guideList" key={"guideList" + idx}>
                      {current}
                    </li>
                  );
                })}
              </ul>
            </div>
          </li>

          <li className="inputList">
            <ui.input.BasicTextarea
              data={taxInvoiceList[4]}
              value={inputs[taxInvoiceList[4].memo]}
              setInputs={setInputs}
            />
            <div className="guide">
              <div className="icon">
                <img src={IconWarning} alt="IconWarning" />
              </div>

              <ul className="guideListWrap">
                {taxInvoiceList[4].guide.map((current, idx) => {
                  return (
                    <li className="guideList" key={"guideList" + idx}>
                      {current}
                    </li>
                  );
                })}
              </ul>
            </div>
          </li>
        </ul>
      </div>

      <div className="depositInfo wrap">
        <h3>씨엔비스 입금 정보</h3>
        <div className="termsDetail">
          <ul>
            {termsList.deposit.map((item, index) => {
              return (
                <li
                  className="depositTermsList termsList"
                  key={"depositTermsList" + index}
                >
                  {item}
                </li>
              );
            })}
          </ul>

          <ul className="depositListWrap">
            {depositList.map((item, index) => {
              return (
                <li className="list" key={"depositList" + index}>
                  <div className="listTitle">{item.title}</div>
                  <p>{item.text}</p>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      <div className="wrap">
        <h3>환불 규정</h3>
        <div className="termsDetail">
          <ul>
            {termsList.refund.map((item, index) => {
              return (
                <li className="refundList termsList" key={"refundList" + index}>
                  {item}
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      <div className="buttonWrap">
        <div className="prevButton">
          <ui.button.BasicButton
            buttonText={"목록으로"}
            buttonType={"white"}
            onClick={() => {
              navigate("/inquiry/result");
            }}
          />
        </div>

        {ableCancel &&
          <div className="cancelButton">
            <ui.button.BasicButton
              buttonText={"신청 취소"}
              buttonType={"white"}
              onClick={() => {
                modalOption.setCancelModal((e) => {
                  e.show = true;
                  e.id = data?.id

                  return { ...e };
                });
              }}
            />
          </div>
        }

        <div className="saveButton">
          <ui.button.BasicButton
            buttonText={"저장하기"}
            buttonType={"blue"}
            onClick={onNext}
          />
        </div>
      </div>
    </Styles.Container>
  );
}
export default BeforeForming;
