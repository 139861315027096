import styled from "styled-components";

const Container = styled.div`
  margin-top: -12px;

  & .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;

    & .filter {
      display: flex;
      gap: 30px;

      & button {
        font-size: 20px;
        font-weight: 500;
        color: var(--gray01);

        &.on {
          font-weight: bold;
          color: var(--black);
        }
      }
    }

    & .askButton {
      width: 260px;
    }
  }

  & .askList:not(:last-child) {
    margin-bottom: 10px;
  }

  & .pagination {
    margin-top: 80px;
  }

  @media (max-width: 1480px) {
    margin-top: calc(100vw * (-12 / 1480));

    & .top {
      margin-bottom: calc(100vw * (32 / 1480));

      & .filter {
        gap: calc(100vw * (30 / 1480));

        & button {
          font-size: calc(100vw * (20 / 1480));
        }
      }

      & .askButton {
        width: calc(100vw * (260 / 1480));
      }
    }

    & .askList:not(:last-child) {
      margin-bottom: calc(100vw * (10 / 1480));
    }

    & .pagination {
      margin-top: calc(100vw * (80 / 1480));
    }
  }

  @media (max-width: 768px) {
    margin-top: 0;

    & .top {
      flex-direction: column;
      align-items: flex-start;
      gap: calc(100vw * (30 / 375));
      margin-bottom: calc(100vw * (35 / 375));

      & .filter {
        gap: calc(100vw * (18 / 375));

        & button {
          font-size: calc(100vw * (14 / 375));
        }
      }

      & .askButton {
        width: calc(100vw * (180 / 375));
      }
    }

    & .askList:not(:last-child) {
      margin-bottom: calc(100vw * (8 / 375));
    }

    & .pagination {
      margin-top: calc(100vw * (50 / 375));
    }
  }
`;

export { Container };

export default {
  Container,
};
