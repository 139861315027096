import styled from "styled-components";

const Container = styled.section`
  &.introductionSection {
    & ul {
      display: flex;
      gap: 15px;
      margin: 20px 0 30px;

      & li {
        flex: 1 0;
      }
    }
  }

  &.overviewSection {
    & .grayBox {
      &:not(:last-of-type) {
        margin-bottom: 50px;
      }

      &:first-of-type {
        & figure {
          margin: 20px 0 30px;

          & .img {
            height: 316px;
            padding: 26px 38px;
          }
        }

        & ul {
          padding-left: 20px;

          & li {
            line-height: 1.87;
            list-style: lower-alpha;
          }
        }
      }

      &:nth-of-type(2) {
        & .wrap {
          margin: 20px 0 30px;

          & .img {
            height: 313px;
          }

          & figure .img {
            width: 606px;
            padding: 15px 66px;
          }

          & .imgWrap .img {
            width: 594px;
          }
        }

        & ul {
          display: flex;
          gap: 15px;
          margin: 20px 0 30px;

          & li {
            flex: 1 0;

            & > div {
              height: 100%;
            }
          }
        }

        & .text > figure .img {
          height: 400px;
          margin-top: 20px;
          padding: 37px 48px 52px;
        }
      }

      &:nth-of-type(3) {
        & .img {
          height: 400px;
          margin-top: 20px;
          padding: 89px 148px;
        }
      }

      &:last-of-type {
        & b {
          display: inline-block;
          margin: 30px 0 20px;
        }

        & .imgWrap .img {
          height: 880px;
        }

        & figure .img {
          height: 924px;
          padding: 59px 47px;
        }
      }
    }
  }

  &.coverageSection {
    & .sectionDescription {
      margin-bottom: 20px;
    }

    & ul {
      display: flex;
      gap: 20px 15px;

      & li > div {
        height: 100%;
      }

      &:not(:last-of-type) {
        margin-bottom: 50px;

        & li {
          flex: 1 0;
        }
      }

      &:last-of-type {
        justify-content: center;
        flex-wrap: wrap;

        & li {
          width: 430px;
        }
      }
    }
  }

  @media (max-width: 1480px) {
    &.introductionSection {
      & ul {
        gap: calc(100vw * (15 / 1480));
        margin: calc(100vw * (20 / 1480)) 0 calc(100vw * (30 / 1480));
      }
    }

    &.overviewSection {
      & .grayBox {
        &:not(:last-of-type) {
          margin-bottom: calc(100vw * (50 / 1480));
        }

        &:first-of-type {
          & figure {
            margin: calc(100vw * (20 / 1480)) 0 calc(100vw * (30 / 1480));

            & .img {
              height: calc(100vw * (316 / 1480));
              padding: calc(100vw * (26 / 1480)) calc(100vw * (38 / 1480));
            }
          }

          & ul {
            padding-left: calc(100vw * (20 / 1480));
          }
        }

        &:nth-of-type(2) {
          & .wrap {
            margin: calc(100vw * (20 / 1480)) 0 calc(100vw * (30 / 1480));

            & .img {
              height: calc(100vw * (313 / 1480));
            }

            & figure .img {
              width: calc(100vw * (606 / 1480));
              padding: calc(100vw * (15 / 1480)) calc(100vw * (66 / 1480));
            }

            & .imgWrap .img {
              width: calc(100vw * (594 / 1480));
            }
          }

          & ul {
            gap: calc(100vw * (15 / 1480));
            margin: calc(100vw * (20 / 1480)) 0 calc(100vw * (30 / 1480));
          }

          & .text > figure .img {
            height: calc(100vw * (400 / 1480));
            margin-top: calc(100vw * (20 / 1480));
            padding: calc(100vw * (37 / 1480)) calc(100vw * (48 / 1480))
              calc(100vw * (52 / 1480));
          }
        }

        &:nth-of-type(3) {
          & .img {
            height: calc(100vw * (400 / 1480));
            margin-top: calc(100vw * (20 / 1480));
            padding: calc(100vw * (89 / 1480)) calc(100vw * (148 / 1480));
          }
        }

        &:last-of-type {
          & b {
            margin: calc(100vw * (30 / 1480)) 0 calc(100vw * (20 / 1480));
          }

          & .imgWrap .img {
            height: calc(100vw * (880 / 1480));
          }

          & figure .img {
            height: calc(100vw * (924 / 1480));
            padding: calc(100vw * (59 / 1480)) calc(100vw * (47 / 1480));
          }
        }
      }
    }

    &.coverageSection {
      & .sectionDescription {
        margin-bottom: calc(100vw * (20 / 1480));
      }

      & ul {
        gap: calc(100vw * (20 / 1480)) calc(100vw * (15 / 1480));

        &:not(:last-of-type) {
          margin-bottom: calc(100vw * (50 / 1480));
        }

        &:last-of-type {
          & li {
            width: calc(100vw * (430 / 1480));
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    &.introductionSection {
      & ul {
        flex-wrap: wrap;
        gap: calc(100vw * (14 / 375));
        margin: calc(100vw * (20 / 375)) 0 calc(100vw * (30 / 375));

        & li {
          flex: auto;
        }
      }
    }

    &.overviewSection {
      & .grayBox {
        &:not(:last-of-type) {
          margin-bottom: calc(100vw * (30 / 375));
        }

        &:first-of-type {
          & figure {
            margin: calc(100vw * (20 / 375)) 0;

            & .img {
              height: calc(100vw * (103 / 375));
              padding: calc(100vw * (21 / 375)) calc(100vw * (20 / 375));
            }
          }

          & ul {
            padding-left: calc(100vw * (12 / 375));

            & li {
              line-height: 1.42;
            }
          }
        }

        &:nth-of-type(2) {
          & .wrap {
            gap: calc(100vw * (30 / 375));
            margin: calc(100vw * (20 / 375)) 0 calc(100vw * (30 / 375));

            & figure .img {
              width: 100%;
              height: calc(100vw * (214 / 375));
              padding: calc(100vw * (25 / 375)) calc(100vw * (10 / 375));
            }

            & .imgWrap {
              margin-bottom: calc(100vw * (-10 / 375));

              & .img {
                width: calc(100vw * (543 / 375));
                height: calc(100vw * (317 / 375));
              }
            }
          }

          & ul {
            flex-wrap: wrap;
            gap: calc(100vw * (14 / 375));
            margin: calc(100vw * (20 / 375)) 0 calc(100vw * (30 / 375));

            & li {
              flex: auto;
            }
          }

          & .text > figure .img {
            height: calc(100vw * (469 / 375));
            margin-top: calc(100vw * (20 / 375));
            padding: calc(100vw * (20 / 375)) calc(100vw * (10 / 375));
          }
        }

        &:nth-of-type(3) {
          & .img {
            height: calc(100vw * (106 / 375));
            margin-top: calc(100vw * (20 / 375));
            padding: calc(100vw * (21 / 375)) calc(100vw * (10 / 375));
          }
        }

        &:last-of-type {
          & b {
            margin: calc(100vw * (30 / 375)) 0 calc(100vw * (20 / 375));
          }

          & .imgWrap {
            margin-bottom: calc(100vw * (20 / 375));

            & .img {
              width: calc(100vw * (865 / 375));
              height: calc(100vw * (640 / 375));
            }
          }

          & figure .img {
            height: calc(100vw * (231 / 375));
            padding: calc(100vw * (20 / 375)) calc(100vw * (10 / 375));
          }
        }
      }
    }

    &.coverageSection {
      & .sectionDescription {
        margin-bottom: calc(100vw * (20 / 375));
      }

      & ul {
        flex-wrap: wrap;
        gap: calc(100vw * (14 / 375));

        &:not(:last-of-type) {
          margin-bottom: calc(100vw * (30 / 375));

          & li {
            flex: auto;
          }
        }

        &:last-of-type {
          & li {
            width: 100%;
          }
        }
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
