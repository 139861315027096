import styled from "styled-components";

const Container = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: calc(100vw * (10 / 375));
    width: 100%;
    height: calc(100vw * (37 / 375));
    font-weight: 500;
    color: var(--gray01);
    background-color: var(--blue04);

    & .icon {
      width: calc(100vw * (16 / 375));
      height: calc(100vw * (16 / 375));
    }
  }
`;

export { Container };

export default { Container };
