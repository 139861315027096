import styled from "styled-components";

const Container = styled.ul`
  display: flex;
  align-items: center;
  gap: 30px;

  & .tabList {
    position: relative;
    font-size: 20px;
    font-weight: 500;
    color: var(--gray01);
    cursor: pointer;

    &.on {
      font-weight: bold;
      color: var(--black);
    }
  }

  @media (max-width: 1480px) {
    gap: calc(100vw * (30 / 1480));

    & .tabList {
      font-size: calc(100vw * (20 / 1480));
    }
  }

  @media (max-width: 768px) {
    gap: calc(100vw * (14 / 375));

    & .tabList {
      flex-shrink: 0;
      font-size: calc(100vw * (14 / 375));
    }
  }
`;

export { Container };

export default { Container };
