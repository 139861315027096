import Styles from "./styles";

// img
import IconFile from "resources/image/icon/icon_file.svg";

function NoticeList({ data, language }) {
  return (
    <Styles.Container className={data.hold_yn === "Y" ? "pin" : ""}>
      {data.hold_yn === "Y" ? (
        <div className="pinText">{language === "" ? "고정" : "Notice"}</div>
      ) : (
        <div className="number">{data.index}</div>
      )}

      <div className="titleWrap">
        <p className="title">{data["title" + language]}</p>

        {data.file_yn === "Y" && (
          <div className="fileIcon">
            <img src={IconFile} alt="IconFile" />
          </div>
        )}
      </div>

      <p className="date">{data.created_format}</p>
    </Styles.Container>
  );
}

export default NoticeList;
