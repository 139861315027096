import styled from "styled-components";

// img
import ImgMenu from "resources/image/common/img_menu.jpg";

const Container = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 9;

  & .headerWrap {
    position: relative;
    z-index: 1;
    box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.1);

    & .inner {
      display: flex;
      align-items: center;
      height: 100px;

      & h1 {
        margin-right: 70px;

        & a {
          width: 102px;
          height: 30px;
        }
      }

      & .gnb {
        display: flex;
        gap: 40px;
        font-size: 18px;
        font-weight: 600;

        & .gnbList a {
          position: relative;
          padding: 15px 10px;
          cursor: pointer;
        }
      }

      & .util {
        display: flex;
        align-items: center;
        gap: 50px;
        margin-left: auto;

        & .downloadButton {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 14px 22px;
          font-size: 18px;
          font-weight: 600;
          color: #fff;
          background-color: var(--blue02);
          border-radius: 50px;

          & .icon {
            width: 18px;
            height: 18px;
            filter: var(--whiteFilter);
          }
        }

        & .utilList {
          position: relative;

          & > button {
            width: 34px;
            height: 34px;
          }
        }

        & .global {
          position: absolute;
          top: calc(100% + 31px);
          left: 50%;
          transform: translateX(-50%);
          padding: 6px 0;
          background-color: #fff;

          & button {
            padding: 6px 22px;
            font-weight: 500;

            &.on {
              color: var(--blue02);
            }
          }
        }
      }
    }
  }

  & nav {
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    height: 292px;
    background-color: #fff;

    & .inner {
      display: flex;

      & .navTitle {
        flex-shrink: 0;
        width: 305px;
        padding-top: 40px;
        font-size: 40px;
        font-weight: bold;
      }

      & .lnb {
        padding-top: 40px;
        font-size: 20px;
        font-weight: 600;

        & .lnbList {
          &:not(:last-child) {
            margin-bottom: 23px;
          }
        }
      }

      & .img {
        flex-shrink: 0;
        width: 515px;
        height: 100%;
        margin-left: auto;
      }
    }
  }

  &.main {
    background-color: transparent;
    box-shadow: none;

    & .headerWrap .inner {
      & .gnb {
        color: #fff;
      }

      & .util {
        & .downloadButton {
          color: var(--blue02);
          background-color: #fff;

          & .icon {
            filter: var(--blue02Filter);
          }
        }

        & .utilList > button {
          filter: var(--whiteFilter);
        }

        & .global {
          top: calc(100% + 13px);
        }
      }
    }
  }

  @media (max-width: 1480px) {
    & .headerWrap {
      box-shadow: 0 calc(100vw * (6 / 1480)) calc(100vw * (14 / 1480)) 0
        rgba(0, 0, 0, 0.1);

      & .inner {
        height: calc(100vw * (100 / 1480));

        & h1 {
          margin-right: calc(100vw * (70 / 1480));

          & a {
            width: calc(100vw * (102 / 1480));
            height: calc(100vw * (30 / 1480));
          }
        }

        & .gnb {
          gap: calc(100vw * (40 / 1480));
          font-size: calc(100vw * (18 / 1480));

          & .gnbList a {
            padding: calc(100vw * (15 / 1480)) calc(100vw * (10 / 1480));
          }
        }

        & .util {
          gap: calc(100vw * (50 / 1480));

          & .downloadButton {
            gap: calc(100vw * (8 / 1480));
            padding: calc(100vw * (14 / 1480)) calc(100vw * (22 / 1480));
            font-size: calc(100vw * (18 / 1480));
            border-radius: calc(100vw * (50 / 1480));

            & .icon {
              width: calc(100vw * (18 / 1480));
              height: calc(100vw * (18 / 1480));
            }
          }

          & .utilList {
            & > button {
              width: calc(100vw * (34 / 1480));
              height: calc(100vw * (34 / 1480));
            }
          }

          & .global {
            top: calc(100% + calc(100vw * (31 / 1480)));
            padding: calc(100vw * (6 / 1480)) 0;

            & button {
              padding: calc(100vw * (6 / 1480)) calc(100vw * (22 / 1480));
            }
          }
        }
      }
    }

    & nav {
      top: calc(100vw * (100 / 1480));
      height: calc(100vw * (292 / 1480));

      & .inner {
        & .navTitle {
          width: calc(100vw * (305 / 1480));
          padding-top: calc(100vw * (40 / 1480));
          font-size: calc(100vw * (40 / 1480));
        }

        & .lnb {
          padding-top: calc(100vw * (40 / 1480));
          font-size: calc(100vw * (20 / 1480));

          & .lnbList {
            &:not(:last-child) {
              margin-bottom: calc(100vw * (23 / 1480));
            }
          }
        }

        & .img {
          width: calc(100vw * (515 / 1480));
        }
      }
    }

    &.main {
      & .headerWrap .inner {
        & .util {
          & .global {
            top: calc(100% + calc(100vw * (13 / 1480)));
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    & .headerWrap {
      box-shadow: none;

      & .inner {
        height: calc(100vw * (66 / 375));

        & h1 {
          margin-right: 0;

          & a {
            width: calc(100vw * (82 / 375));
            height: calc(100vw * (24 / 375));
          }
        }

        & .gnb {
          display: none;
        }

        & .util {
          gap: calc(100vw * (20 / 375));

          & .downloadButton {
            display: none;
          }

          & .utilList {
            & > button {
              width: calc(100vw * (26 / 375));
              height: calc(100vw * (26 / 375));
            }
          }

          & .global {
            top: calc(100% + calc(100vw * (18 / 375)));
            padding: calc(100vw * (4 / 375)) 0;

            & button {
              padding: calc(100vw * (6 / 375)) calc(100vw * (11 / 375));
            }
          }
        }
      }
    }

    & nav {
      display: none;
    }

    &.main {
      & .headerWrap .inner {
        & .util {
          & .global {
            top: calc(100% + calc(100vw * (13 / 1480)));
          }
        }
      }
    }
  }

  @media (hover: hover) {
    & .headerWrap .gnb a:hover {
      color: var(--blue02);

      &::after {
        position: absolute;
        bottom: -24px;
        left: 50%;
        transform: translateX(-50%);
        width: 69px;
        height: 8px;
        background-color: var(--blue02);
        content: "";
      }
    }

    & nav .inner .lnb .lnbList:hover {
      color: var(--blue02);
    }

    @media (max-width: 1480px) {
      & .headerWrap .gnb a:hover {
        &::after {
          bottom: calc(100vw * (-24 / 1480));
          width: calc(100vw * (69 / 1480));
          height: calc(100vw * (8 / 1480));
        }
      }
    }
  }
`;

const Menu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(${ImgMenu}) no-repeat center / cover;
  z-index: 10;

  & .inner {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    color: #fff;

    & .downloadButton {
      display: none;
    }

    & .closeButton {
      position: absolute;
      top: 34px;
      right: 0;
      width: 34px;
      height: 34px;
    }

    & .menuList {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 100px;
      }
    }

    & .depth01 {
      width: 273px;
      font-size: 42px;
      font-weight: 800;

      &.on {
        color: var(--blue02);
      }
    }

    & .depth02 {
      display: flex;
      gap: 50px;
      font-size: 20px;
      font-weight: 600;
    }
  }

  & .depth02List {
    & a {
      position: relative;

      & span:last-child {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        width: max-content;
        font-weight: 800;
        color: var(--blue02);
        border-bottom: 2px solid var(--blue02);
        opacity: 0;
      }
    }
  }

  @media (max-width: 1480px) {
    & .inner {
      & .closeButton {
        top: calc(100vw * (34 / 1480));
        width: calc(100vw * (34 / 1480));
        height: calc(100vw * (34 / 1480));
      }

      & .menuList {
        &:not(:last-child) {
          margin-bottom: calc(100vw * (100 / 1480));
        }
      }

      & .depth01 {
        width: calc(100vw * (273 / 1480));
        font-size: calc(100vw * (42 / 1480));
      }

      & .depth02 {
        gap: calc(100vw * (50 / 1480));
        font-size: calc(100vw * (20 / 1480));
      }
    }
  }

  @media (max-width: 767px) {
    & .inner {
      & .downloadButton {
        position: absolute;
        top: calc(100vw * (18 / 375));
        left: 0;
        display: flex;
        align-items: center;
        gap: calc(100vw * (8 / 375));
        height: calc(100vw * (30 / 375));
        padding: 0 calc(100vw * (12 / 375));
        font-size: calc(100vw * (14 / 375));
        font-weight: 600;
        color: var(--blue02);
        background-color: #fff;
        border-radius: calc(100vw * (50 / 375));

        & .icon {
          width: calc(100vw * (14 / 375));
          height: calc(100vw * (14 / 375));
          filter: var(--blue02Filter);
        }
      }

      & .closeButton {
        top: calc(100vw * (20 / 375));
        width: calc(100vw * (26 / 375));
        height: calc(100vw * (26 / 375));
      }

      & .menuList {
        flex-direction: column;
        align-items: flex-start;

        &:not(:last-child) {
          margin-bottom: calc(100vw * (60 / 375));
        }
      }

      & .depth01 {
        width: 100%;
        margin-bottom: calc(100vw * (20 / 375));
        font-size: calc(100vw * (26 / 375));
      }

      & .depth02 {
        flex-wrap: wrap;
        gap: calc(100vw * (16 / 375)) calc(100vw * (20 / 375));
        font-size: calc(100vw * (14 / 375));
      }
    }
  }

  @media (hover: hover) {
    & .depth02List a:hover span {
      &:first-child {
        opacity: 0;
      }
      &:last-child {
        opacity: 1;
      }
    }
    @media (max-width: 1480px) {
      & .depth02List a span:last-child {
        border-bottom: calc(100vw * (2 / 1480)) solid var(--blue02);
      }
    }
  }
`;

export { Container, Menu };

export default { Container, Menu };
