import { Route, Routes, useLocation } from "react-router-dom";
import Pages from "pages";
import { useEffect } from "react";
// 사업영역
import {
  Spice,
  Safety,
  Cybersecurity,
  Ota,
  Sotif,
  Ads,
} from "pages/BusinessServicePage/components";
import {
  Assessment,
  AssessmentDetail,
} from "pages/BusinessAssessmentPage/components";
// 고객지원
import {
  Notice,
  NoticeDetail,
  Ask,
  Recruitment,
} from "pages/CustomerSupportPage/components";
// 교육신청취소
import {
  AfterForming,
  BeforeForming,
} from "pages/InquiryDetailPage/components";
import utils from "utils";
import AuthRoute from "routes/AuthRoute";
import LanguageRoute from "routes/LanguageRoute";

export default function CustomRoute() {
  let location = useLocation();

  useEffect(() => {
    if (window.location.hostname.indexOf("www") == 0) {
      window.location = window.location.href.replace("www.", "");
    }

    if (utils.etc.getSearchParam().disableScrollTop != "true") {
      window.scrollTo(0, 0);
      document.body.scrollTo(0, 0);
    } else if (utils.etc.getSearchParam().focus) {
      try {
        setTimeout(() => {
          document
            .getElementById(utils.etc.getSearchParam().focus)
            .scrollIntoView({ block: "center", behavior: "smooth" });
        }, 300);
      } catch (e) {}
    }
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Pages.MainPage />} />
      {/* 회사소개 */}
      <Route
        path="/introduction/ceo-greeting"
        element={<Pages.GreetingPage />}
      />
      <Route path="/introduction/history" element={<Pages.HistoryPage />} />
      <Route
        path="/introduction/organize-chart"
        element={<Pages.OrganizeChartPage />}
      />
      <Route
        path="/introduction/performance"
        element={<Pages.PerformancePage />}
      />
      <Route path="/introduction/direction" element={<Pages.DirectionPage />} />
      {/* 사업영역 */}
      <Route path="/business/depth/:id" element={<Pages.BusinessAreaPage />} />
      <Route path="/business/service" element={<Pages.BusinessServicePage />}>
        <Route path="spice" element={<Spice />} />
        <Route path="safety" element={<Safety />} />
        <Route path="cybersecurity" element={<Cybersecurity />} />
        <Route path="ota" element={<Ota />} />
        <Route path="sotif" element={<Sotif />} />
        <Route path="ads" element={<Ads />} />
      </Route>
      <Route
        path="/business/assessment"
        element={<Pages.BusinessAssessmentPage />}
      >
        <Route path="assessment" element={<Assessment />} />
        <Route path="assessment/detail" element={<AssessmentDetail />} />
      </Route>
      <Route path="/business/r&d" element={<Pages.BusinessRndPage />} />
      <Route
        path="/business/solution"
        element={<Pages.BusinessSolutionPage />}
      />
      {/* 교육센터 */}
      <Route
        path="/education/center-introduction"
        element={<Pages.EducationCenterPage />}
      />
      <Route
        path="/education/cnbis-curriculum"
        element={<Pages.EducationCnbisCurriculmPage />}
      />
      <Route
        path="/education/intacs-curriculum"
        element={<Pages.EducationIntacsCurriculmPage />}
      />
      <Route
        path="/education/curriculum-detail/:id"
        element={
          <LanguageRoute>
            <Pages.EducationCurriculmDetailPage />
          </LanguageRoute>
        }
      />
      <Route
        path="/education/apply"
        element={
          <LanguageRoute>
            <Pages.EducationApplyPage />
          </LanguageRoute>
        }
      />
      <Route
        path="/education/apply/detail/:id"
        element={
          <LanguageRoute>
            <Pages.EducationApplyDetailPage />
          </LanguageRoute>
        }
      />
      <Route
        path="/apply/:id"
        element={
          <LanguageRoute>
            <Pages.ApplyPage />
          </LanguageRoute>
        }
      />
      <Route
        path="/apply/complete/:id"
        element={
          <LanguageRoute>
            <Pages.ApplyCompletePage />
          </LanguageRoute>
        }
      />
      <Route
        path="/schedule"
        element={
          <LanguageRoute>
            <Pages.SchedulePage />
          </LanguageRoute>
        }
      />
      {/* 교육신청 조회 */}
      <Route
        path="/inquiry"
        element={
          <LanguageRoute>
            <Pages.InquiryPage />
          </LanguageRoute>
        }
      />
      <Route
        path="/inquiry/result"
        element={
          <LanguageRoute>
            <AuthRoute>
              <Pages.InquiryResultPage />
            </AuthRoute>
          </LanguageRoute>
        }
      />

      <Route
        path="/inquiry/detail/:id"
        element={
          <LanguageRoute>
            <AuthRoute>
              <Pages.InquiryDetailPage />
            </AuthRoute>
          </LanguageRoute>
        }
      >
        <Route
          path="/inquiry/detail/:id/after/:id"
          element={<AfterForming />}
        />
        <Route
          path="/inquiry/detail/:id/before/:id"
          element={<BeforeForming />}
        />
        <Route path="/inquiry/detail/:id/:id" element={<AfterForming />} />
      </Route>
      {/* 고객지원 */}
      <Route path="/customer-support" element={<Pages.CustomerSupportPage />}>
        <Route path="notice" element={<Notice />} />
        <Route path="notice/detail/:id" element={<NoticeDetail />} />
        <Route path="ask" element={<Ask />} />
        <Route path="recruitment" element={<Recruitment />} />
      </Route>

      {/* 이용약관 */}
      <Route path="/terms" element={<Pages.TermsPage />} />
      <Route path="/close" element={<Pages.ClosePage />} />
    </Routes>
  );
}
