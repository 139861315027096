import { useAtom } from "jotai";
import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { api } from "service";
import { modalAtom } from "store/atoms";
import utils from "utils";

export default function useMainAnimation() {
  const targetRef = useRef();
  const targetTwoRef = useRef();
  const targetThreeRef = useRef();
  const positionRef = useRef([
      {
        currentPosition: 0,
        targetPosition: 700,
        startY: 2500,
        endY: 3200,
        lastScrollPosition: 0,
        targetArriveXPosition: -1000,
        targetArriveYPosition: 430,
      },
      {
        currentPosition: 0,
        targetPosition: 1000,
        startY: 2800,
        endY: 3600,
        lastScrollPosition: 0,
        targetArriveXPosition: 1600,
        targetArriveYPosition: 730,
      },
      {
        currentPosition: 0,
        targetPosition: 1000,
        startY: 3500,
        endY: 4280,
        lastScrollPosition: 0,
        targetArriveXPosition: -1250,
        targetArriveYPosition: 820,
      },
  ]);

  useEffect(() => {
    const scrollEvent = handleScroll; //utils.etc.throttle(handleScroll,100);
    window.addEventListener("scroll", scrollEvent);
    return () => {
      // return a cleanup function to unregister our function since it will run multiple times
      window.removeEventListener("scroll", scrollEvent);
    };
  }, []);

  function calculateYFromX(x) {
    // x의 범위를 0에서 100으로 정규화
    const normalizedX = x / 100;
    const y = Math.sin((normalizedX * Math.PI) / 2) * 100;

    return y;
  }

  function calculateYFromXToCos(x) {
    // x의 범위를 0에서 100으로 정규화
    const normalizedX = x / 100;
    const y = Math.cos((normalizedX * Math.PI) / 2) * 100;

    return y;
  }

  const handleScroll = (e) => {

    if(!targetRef.current || !targetTwoRef.current || !targetThreeRef.current)return;

    if(window.innerWidth < 600){
      targetRef.current.style.display = 'none';
      targetTwoRef.current.style.display = 'none';
      targetThreeRef.current.style.display = 'none';
      return;
    }else{
      targetRef.current.style.display = 'block';
      targetTwoRef.current.style.display = 'block';
      targetThreeRef.current.style.display = 'block';
    }
    firstAnimation(0);
    secondAnimation(1);
    threeAnimation(2);
  };

  const calculateWidthSize = (width, index) => {
    let cal = 0;
    if(window.innerWidth > 1480){
      cal = 0;
    }else if(window.innerWidth < 768){
    }else{
      if(window.innerWidth > 1400)
        cal = (index == 2 ? 150 : (index == 1 ? 0 : 200)) * ((1480 / window.innerWidth));
      else if(window.innerWidth > 1000){
        cal = (index == 2 ? 400 : index == 1 ? 200 : 200) * ((1480 / window.innerWidth));
      }else{
        cal = (index == 2 ? 380 : index == 1 ? 200 : 200) * ((1480 / window.innerWidth));
      }
    }

    return width - cal;
  }

  const calculateHeightSize = (width, index) => {
    let cal = 0;
    if(window.innerWidth > 1480){
      cal = 0;
    }else if(window.innerWidth < 768){
    }else{
      if(window.innerWidth > 1400)
        cal = (index == 2 ? 200 : (index == 1 ? 0 : 100)) * ((1480 / window.innerWidth));
      else if(window.innerWidth > 1000){
        cal = (index == 2 ? 300 : index == 1 ? -100 : 100) * ((1480 / window.innerWidth));
      }else{
        cal = (index == 2 ? 250 : index == 1 ? -130 : 100) * ((1480 / window.innerWidth));// * (index == 1 ? 1 : 0.7));
      }
    }

    return width + cal;
  }

  const calculateStartHeightSize = (width, index) => {

    let cal = 0;
    if(window.innerWidth > 1480){
      cal = 0;
    }else if(window.innerWidth < 768){
    }else{
      if(window.innerWidth > 1400)
        cal = (index == 2 ? 100 : (index == 1 ? 200 : 400)) * ((1480 / window.innerWidth));
      else if(window.innerWidth > 1000){
        cal = (index == 2 ? 500 : index == 1 ? 600 : 600) * ((1480 / window.innerWidth));
      }else{
        cal = (index == 2 ? 800 : index == 1 ? 900 : 900) * ((1480 / window.innerWidth));// * (index == 1 ? 1 : 0.7));
      }
    }
    return width - cal;
  }

  const calculateEndHeightSize = (width, index) => {
    let cal = 0;
    if(window.innerWidth > 1480){
      cal = 0;
    }else if(window.innerWidth < 768){

    }else{
      if(window.innerWidth > 1400){
        cal = (index == 2 ? 200 : (index == 1 ? 400 : 400)) * ((1480 / window.innerWidth));
      }else if(window.innerWidth > 1000){
        cal = (index == 2 ? 1000 : index == 1 ? 500 : 0) * ((1480 / window.innerWidth));
      }else{
        cal = (index == 2 ? 1500 : index == 1 ? 900 : 0) * ((1480 / window.innerWidth));// * (index == 1 ? 1 : 0.7));
      }
    }
    return width - cal;
  }

  const calculateYIndex = (yPer, index) => {
    let cal = 3
    if(window.innerWidth > 1400){

    }else{
      cal = 1;
    }
    return cal
  }

  const calculateRotateIndex = (yPer, index) => {
    let cal = 30
    if(window.innerWidth > 1400){

    }else{
      cal = 50
    }
    return cal
  }

  const firstAnimation = (index) => {
    
    if (
      calculateStartHeightSize(positionRef.current[index].startY) > window.scrollY ||
      window.scrollY > calculateEndHeightSize(positionRef.current[index].endY)
    ) {
      targetRef.current.style.visibility = "hidden";
      targetRef.current.style.opacity = "0";
      return;
    }
    targetRef.current.style.visibility = "visible";
    targetRef.current.style.opacity = "1";

    const scrollY = window.scrollY - positionRef.current[index].startY; // 현재 스크롤 위치

    const acceleration =
      scrollY - calculateWidthSize(positionRef.current[index].lastScrollPosition); // 가속도 계산
    positionRef.current[index].currentPosition += acceleration; // 현재 위치에 가속도 추가
    positionRef.current[index].currentPosition = Math.min(
      calculateWidthSize(positionRef.current[index].targetPosition),
      Math.max(0, calculateWidthSize(positionRef.current[index].currentPosition))
    ); // 목표 위치를 벗어나지 않도록 보정

    const xPer = (positionRef.current[index].currentPosition / positionRef.current[index].targetPosition) * 100;
    const yPer = (positionRef.current[index].currentPosition /  positionRef.current[index].targetPosition) * 100;

    const sinY = calculateYFromX(xPer);
    const resultXPosition = calculateWidthSize(positionRef.current[index].targetArriveXPosition) * (xPer / 100);
    const resultYPosition = calculateHeightSize(positionRef.current[index].targetArriveYPosition) * ((yPer - Math.min(sinY - yPer, 1)) / 100);
    // const resultYPosition = calculateHeightSize(positionRef.current[index].targetArriveYPosition) * ((yPer - (Math.max(sinY - yPer, 0.1))) / 100);

    targetRef.current.style.transform = `translate(${resultXPosition}px, ${resultYPosition}px) rotate(${
      yPer < 25 ? -3 : yPer < 50 ? -1 : (yPer / 70) * 1.212
    }deg)`;
    targetRef.current.style.transition = " all 0.4s";
    // targetRef.current.style.transition = " all 0.4s";
    positionRef.current[index].lastScrollPosition = scrollY;
  };

  const secondAnimation = (index) => {
    if (
      calculateStartHeightSize(positionRef.current[index].startY,1) > window.scrollY ||
      window.scrollY > calculateEndHeightSize(positionRef.current[index].endY,1)
    ) {
      targetTwoRef.current.style.visibility = "hidden";
      targetTwoRef.current.style.opacity = "0";
      return;
    }
    targetTwoRef.current.style.visibility = "visible";
    targetTwoRef.current.style.opacity = "1";

    const scrollY = window.scrollY - positionRef.current[index].startY; // 현재 스크롤 위치

    const acceleration =
      scrollY - calculateWidthSize(positionRef.current[index].lastScrollPosition,1); // 가속도 계산
    positionRef.current[index].currentPosition += acceleration; // 현재 위치에 가속도 추가
    positionRef.current[index].currentPosition = Math.min(
      calculateWidthSize(positionRef.current[index].targetPosition,1),
      Math.max(0, calculateWidthSize(positionRef.current[index].currentPosition,1))
    ); // 목표 위치를 벗어나지 않도록 보정

    const xPer = (positionRef.current[index].currentPosition / positionRef.current[index].targetPosition) * 100;
    const yPer = (positionRef.current[index].currentPosition / positionRef.current[index].targetPosition) * 100;

    const sinY = calculateYFromXToCos(xPer);
    const resultXPosition = calculateWidthSize(positionRef.current[index].targetArriveXPosition,1) * (xPer / 100);
    const resultYPosition = calculateHeightSize(positionRef.current[index].targetArriveYPosition,1) * ((yPer - (100 - sinY) * 0.3) / 100); 
    targetTwoRef.current.style.transform = `translate(${resultXPosition}px, ${resultYPosition}px) rotate(${
      -136 - (yPer / 100) * 14
    }deg)`;
    targetTwoRef.current.style.transition = " all 0.4s";
    positionRef.current[index].lastScrollPosition = scrollY;
  };

  const threeAnimation = (index) => {
    if (
      calculateStartHeightSize(positionRef.current[index].startY,2) > window.scrollY ||
      window.scrollY > calculateStartHeightSize(positionRef.current[index].endY,2)
    ) {
      targetThreeRef.current.style.visibility = "hidden";
      targetThreeRef.current.style.opacity = "0";
      return;
    }
    targetThreeRef.current.style.visibility = "visible";
    targetThreeRef.current.style.opacity = "1";

    const scrollY = window.scrollY - positionRef.current[index].startY; // 현재 스크롤 위치

    const acceleration =
      scrollY - calculateWidthSize(positionRef.current[index].lastScrollPosition,2); // 가속도 계산
    positionRef.current[index].currentPosition += acceleration; // 현재 위치에 가속도 추가
    positionRef.current[index].currentPosition = Math.min(
      calculateWidthSize(positionRef.current[index].targetPosition,2),
      Math.max(0, calculateWidthSize(positionRef.current[index].currentPosition,2))
    ); // 목표 위치를 벗어나지 않도록 보정

    const xPer = (positionRef.current[index].currentPosition / positionRef.current[index].targetPosition) * 100;
    const yPer = (positionRef.current[index].currentPosition /  positionRef.current[index].targetPosition) * 100;

    const sinY = calculateYFromX(xPer);
    const resultXPosition = calculateWidthSize(positionRef.current[index].targetArriveXPosition,2) * (xPer / 100);
    const resultYPosition = calculateHeightSize(positionRef.current[index].targetArriveYPosition,2) * ((yPer - (Math.min(sinY - yPer, 1) * calculateYIndex(yPer,2))) / 100);

    targetThreeRef.current.style.transform = `translate(${resultXPosition}px, ${resultYPosition}px) rotate(${-(
      (yPer / 100) * calculateRotateIndex(yPer,2)
    )}deg)`;
    targetThreeRef.current.style.transition = " all 0.4s";
    positionRef.current[index].lastScrollPosition = scrollY;
  };

  return {
    targetRef,
    targetTwoRef,
    targetThreeRef,
  };
}
