import styled from "styled-components";

// img
import IconArrowBottom from "resources/image/icon/icon_arrow_bottom.svg";
import IconArrowTop from "resources/image/icon/icon_arrow_top_blue02.svg";

const Container = styled.div`
  padding: 0 90px 0 60px;
  font-size: 18px;
  font-weight: 500;
  border: 1px solid var(--gray02);
  border-radius: 16px;
  cursor: pointer;

  & .icon {
    font-size: 28px;
    font-weight: bold;
    color: var(--gray01);
  }

  & .questionWrap {
    position: relative;
    display: flex;
    padding: 24px 0;

    & .icon {
      margin-right: 131px;
    }

    & .question {
      width: 1040px;
      padding-top: 6px;

      &::after {
        position: absolute;
        top: 25px;
        left: calc(100% + 30px);
        width: 30px;
        height: 30px;
        background: url(${IconArrowBottom}) no-repeat center / cover;
        content: "";
      }

      & p {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  & .askWrap {
    display: flex;
    gap: 132px;
    padding: 20px 0 40px;
    line-height: 1.44;

    & .ask {
      white-space: pre-wrap;
    }
  }

  &.on {
    color: var(--blue02);
    background-color: var(--blue04);
    border-color: var(--blue04);

    & .icon {
      color: var(--blue02);
    }

    & .questionWrap {
      border-bottom: 1px solid var(--blue02);

      & .question {
        &::after {
          background-image: url(${IconArrowTop});
        }

        & p {
          white-space: unset;
          overflow: auto;
        }
      }
    }
  }

  @media (max-width: 1480px) {
    padding: 0 calc(100vw * (90 / 1480)) 0 calc(100vw * (60 / 1480));
    font-size: calc(100vw * (18 / 1480));
    border-radius: calc(100vw * (16 / 1480));

    & .icon {
      font-size: calc(100vw * (28 / 1480));
    }

    & .questionWrap {
      padding: calc(100vw * (24 / 1480)) 0;

      & .icon {
        margin-right: calc(100vw * (131 / 1480));
      }

      & .question {
        width: calc(100vw * (1040 / 1480));
        padding-top: calc(100vw * (6 / 1480));

        &::after {
          top: calc(100vw * (25 / 1480));
          left: calc(100% + calc(100vw * (30 / 1480)));
          width: calc(100vw * (30 / 1480));
          height: calc(100vw * (30 / 1480));
        }
      }
    }

    & .askWrap {
      gap: calc(100vw * (132 / 1480));
      padding: calc(100vw * (20 / 1480)) 0 calc(100vw * (40 / 1480));
    }
  }

  @media (max-width: 768px) {
    padding: 0 calc(100vw * (16 / 375));
    font-size: calc(100vw * (14 / 375));
    border-radius: calc(100vw * (12 / 375));

    & .icon {
      font-size: calc(100vw * (20 / 375));
    }

    & .questionWrap {
      flex-direction: column;
      align-items: flex-start;
      gap: calc(100vw * (8 / 375));
      padding: calc(100vw * (16 / 375)) 0;

      & .icon {
        margin-right: 0;
      }

      & .question {
        position: relative;
        width: calc(100vw * (271 / 375));
        padding-top: 0;

        &::after {
          top: 0;
          left: calc(100% + calc(100vw * (14 / 375)));
          width: calc(100vw * (18 / 375));
          height: calc(100vw * (18 / 375));
        }
      }
    }

    & .askWrap {
      flex-direction: column;
      gap: calc(100vw * (8 / 375));
      padding: calc(100vw * (20 / 375)) 0 calc(100vw * (45 / 375));
      line-height: normal;
    }
  }
`;

export { Container };

export default {
  Container,
};
