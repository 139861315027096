import styled from "styled-components";

const Container = styled.div`
  & .licenseInfo {
    & .title {
      margin-bottom: 30px;
      font-size: 20px;
      font-weight: bold;
    }

    & .licenseFile {
      display: flex;
      align-items: center;
      height: 64px;
      padding: 0 20px;
      font-size: 18px;
      font-weight: 500;
      background-color: #fafafa;
    }
  }

  & .depositInfo {
    & .depositListWrap {
      margin-top: 30px;
      color: var(--black);

      & .list {
        &:not(:last-child) {
          margin-bottom: 14px;
        }

        & .listTitle {
          width: 128px;
        }
      }
    }
  }

  & .buttonWrap {
    display: flex;
    justify-content: center;
    gap: 30px;

    & .prevButton,
    .nextButton {
      width: 260px;
    }
  }

  @media (max-width: 1480px) {
    & .licenseInfo {
      & .title {
        margin-bottom: calc(100vw * (30 / 1480));
        font-size: calc(100vw * (20 / 1480));
      }

      & .licenseFile {
        height: calc(100vw * (64 / 1480));
        padding: 0 calc(100vw * (20 / 1480));
        font-size: calc(100vw * (18 / 1480));
      }
    }

    & .depositInfo {
      & .depositListWrap {
        margin-top: calc(100vw * (30 / 1480));

        & .list {
          &:not(:last-child) {
            margin-bottom: calc(100vw * (14 / 1480));
          }

          & .listTitle {
            width: calc(100vw * (128 / 1480));
          }
        }
      }
    }

    & .buttonWrap {
      gap: calc(100vw * (30 / 1480));

      & .prevButton,
      .nextButton {
        width: calc(100vw * (260 / 1480));
      }
    }
  }

  @media (max-width: 768px) {
    & .licenseInfo {
      & .title {
        margin-bottom: calc(100vw * (20 / 375));
        font-size: calc(100vw * (14 / 375));
      }

      & .licenseFile {
        height: calc(100vw * (45 / 375));
        padding: 0 calc(100vw * (14 / 375));
        font-size: calc(100vw * (14 / 375));
      }
    }

    & .depositInfo {
      & .depositListWrap {
        margin-top: calc(100vw * (20 / 375));

        & .list {
          &:not(:last-child) {
            margin-bottom: calc(100vw * (10 / 375));
          }

          & .listTitle {
            width: calc(100vw * (84 / 375));
          }
        }
      }
    }

    & .buttonWrap {
      flex-direction: column;
      align-items: center;
      gap: calc(100vw * (15 / 375));

      & .prevButton,
      .nextButton {
        width: calc(100vw * (180 / 375));
      }
    }
  }
`;

export { Container };

export default { Container };
