import styled from "styled-components";

// img
import ImgRecruitment01 from "resources/image/customer/img_recruitment01.jpg";
import ImgRecruitment02 from "resources/image/customer/img_recruitment02.jpg";
import ImgRecruitment03 from "resources/image/customer/img_recruitment03.jpg";
import ImgRecruitment04 from "resources/image/customer/img_recruitment04.jpg";
import ImgRecruitment05 from "resources/image/customer/img_recruitment05.jpg";
import ImgRecruitment06 from "resources/image/customer/img_recruitment06.jpg";

const Container = styled.div`
  & h3 {
    font-size: 36px;
    font-weight: bold;
  }

  & .step {
    margin-bottom: 80px;

    & h3 {
      margin-bottom: 30px;
    }

    & .stepWrap {
      display: flex;
      gap: 10px;

      & .stepList {
        flex: 1 0;
        height: 277px;
        padding: 30px;
        background-color: var(--blue04);
        border-radius: 10px;

        & .top {
          display: flex;
          justify-content: space-between;
          margin-bottom: 80px;

          & .textWrap {
            font-weight: bold;

            & .title {
              margin-top: 8px;
              font-size: 28px;
            }
          }

          & .icon {
            flex-shrink: 0;
            width: 80px;
            height: 80px;
          }
        }

        & .bottom {
          & .list {
            position: relative;
            padding-left: 10px;
            word-break: keep-all;

            &:not(:last-child) {
              margin-bottom: 8px;
            }

            &::before {
              position: absolute;
              top: -1px;
              left: 0;
              content: "•";
            }
          }
        }
      }
    }
  }

  & .welfare {
    & .subText {
      margin: 10px 0 30px;
      font-weight: 500;
      color: var(--gray01);
    }

    & ul {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      & li {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: calc(calc(100% - 20px) / 2);
        height: 214px;
        padding: 30px;
        color: #fff;
        background: no-repeat center / cover;
        border-radius: 10px;

        &:first-child {
          background-image: url(${ImgRecruitment01});
        }

        &:nth-child(2) {
          background-image: url(${ImgRecruitment02});
        }

        &:nth-child(3) {
          background-image: url(${ImgRecruitment03});
        }

        &:nth-child(4) {
          background-image: url(${ImgRecruitment04});
        }

        &:nth-child(5) {
          background-image: url(${ImgRecruitment05});
        }

        &:last-child {
          background-image: url(${ImgRecruitment06});
        }

        & .title {
          font-size: 28px;
          font-weight: bold;
        }
      }
    }
  }

  @media (max-width: 1480px) {
    & h3 {
      font-size: calc(100vw * (36 / 1480));
    }

    & .step {
      margin-bottom: calc(100vw * (80 / 1480));

      & h3 {
        margin-bottom: calc(100vw * (30 / 1480));
      }

      & .stepWrap {
        gap: calc(100vw * (10 / 1480));

        & .stepList {
          height: calc(100vw * (277 / 1480));
          padding: calc(100vw * (30 / 1480));
          border-radius: calc(100vw * (10 / 1480));

          & .top {
            margin-bottom: calc(100vw * (80 / 1480));

            & .textWrap {
              & .title {
                margin-top: calc(100vw * (8 / 1480));
                font-size: calc(100vw * (28 / 1480));
              }
            }

            & .icon {
              width: calc(100vw * (80 / 1480));
              height: calc(100vw * (80 / 1480));
            }
          }

          & .bottom {
            & .list {
              padding-left: calc(100vw * (10 / 1480));

              &:not(:last-child) {
                margin-bottom: calc(100vw * (8 / 1480));
              }
            }
          }
        }
      }
    }

    & .welfare {
      & .subText {
        margin: calc(100vw * (10 / 1480)) 0 calc(100vw * (30 / 1480));
      }

      & ul {
        gap: calc(100vw * (20 / 1480));

        & li {
          width: calc(calc(100% - calc(100vw * (20 / 1480))) / 2);
          height: calc(100vw * (214 / 1480));
          padding: calc(100vw * (30 / 1480));
          border-radius: calc(100vw * (10 / 1480));

          & .title {
            font-size: calc(100vw * (28 / 1480));
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    & h3 {
      font-size: calc(100vw * (28 / 375));
    }

    & .step {
      margin-bottom: calc(100vw * (60 / 375));

      & h3 {
        margin-bottom: calc(100vw * (30 / 375));
      }

      & .stepWrap {
        flex-wrap: wrap;
        gap: calc(100vw * (16 / 375));
        width: calc(100vw * (262 / 375));
        margin: 0 auto;

        & .stepList {
          height: calc(100vw * (227 / 375));
          padding: calc(100vw * (30 / 375)) calc(100vw * (18 / 375)) 0;
          border-radius: calc(100vw * (10 / 375));

          & .top {
            margin-bottom: calc(100vw * (50 / 375));

            & .textWrap {
              font-size: calc(100vw * (16 / 375));

              & .title {
                margin-top: calc(100vw * (8 / 375));
                font-size: calc(100vw * (28 / 375));
              }
            }

            & .icon {
              width: calc(100vw * (80 / 375));
              height: calc(100vw * (80 / 375));
            }
          }

          & .bottom {
            & .list {
              padding-left: calc(100vw * (10 / 375));

              &:not(:last-child) {
                margin-bottom: calc(100vw * (10 / 375));
              }
            }
          }
        }
      }
    }

    & .welfare {
      & .subText {
        margin: calc(100vw * (8 / 375)) 0 calc(100vw * (30 / 375));
      }

      & ul {
        flex-wrap: wrap;
        gap: calc(100vw * (16 / 375));

        & li {
          width: 100%;
          height: calc(100vw * (214 / 375));
          padding: calc(100vw * (24 / 375));
          border-radius: calc(100vw * (10 / 375));

          & .title {
            font-size: calc(100vw * (28 / 375));
          }
        }
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
