import { api } from "service";
import { useQuery } from "react-query";
import { useEffect, useRef, useState } from "react";
import { loadTossPayments } from "@tosspayments/payment-sdk";
const clientKey = "live_ck_Gv6LjeKD8aaZ19xDbyXM8wYxAdXy";

export default function useToss(isInit) {
  const [price, setPrice] = useState(2000);
  const transactionForm = useQuery(
    "transactionForm",
    () => {
      return api.transaction.getTransactionForm(
        JSON.stringify({ price: price })
      );
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!isInit && price >= 0 && price != null,
    }
  );

  async function doTransaction({ name, title, orderId }) {
    transactionForm.refetch();
    // ------ 클라이언트 키로 객체 초기화 ------
    loadTossPayments(clientKey).then((tossPayments) => {
      // ------ 결제창 띄우기 ------
      tossPayments
        .requestPayment("카드", {
          // 결제수단 파라미터
          // 결제정보 파라미터
          // 더 많은 결제 정보 파라미터는 결제창 Javascript SDK에서 확인하세요.
          // https://docs.tosspayments.com/reference/js-sdk
          amount: price, // 결제 금액
          orderId: orderId, // 'Bl_vcMqW85b08mIDM7Ij4', // 주문번호
          orderName: title, //'테스트 결제', // 구매상품
          customerName: name, //'김토스', // 구매자 이름
          successUrl: "https://cnbis.co.kr/api/pg/toss/success", // 결제 성공 시 이동할 페이지(이 주소는 예시입니다. 상점에서 직접 만들어주세요.)
          failUrl: "https://cnbis.co.kr/api/pg/toss/fail", // 결제 실패 시 이동할 페이지(이 주소는 예시입니다. 상점에서 직접 만들어주세요.)
          // successUrl: 'https://cnbis.co.kr:8080/api/pg/toss/success', // 결제 성공 시 이동할 페이지(이 주소는 예시입니다. 상점에서 직접 만들어주세요.)
          // failUrl: 'https://cnbis.co.kr:8080/api/pg/toss/fail', // 결제 실패 시 이동할 페이지(이 주소는 예시입니다. 상점에서 직접 만들어주세요.)
        })
        // ------ 결제창을 띄울 수 없는 에러 처리 ------
        // 메서드 실행에 실패해서 reject 된 에러를 처리하는 블록입니다.
        // 결제창에서 발생할 수 있는 에러를 확인하세요.
        // https://docs.tosspayments.com/reference/error-codes#결제창공통-sdk-에러
        .catch(function (error) {
          if (error.code === "USER_CANCEL") {
            // 결제 고객이 결제창을 닫았을 때 에러 처리
          } else if (error.code === "INVALID_CARD_COMPANY") {
            // 유효하지 않은 카드 코드에 대한 에러 처리
          }
        });
    });
  }

  const updatePrice = (_price) => {
    setPrice(_price);
  };

  return {
    data: transactionForm.data,
    doTransaction,
    updatePrice,
  };
}
