import { api } from "service";
import { useQuery } from "react-query";

export default function useTerm({
  termPersonal,
  termService,
}) {

  const getTermPersonal = useQuery(["termPersonal"], () => {
    return api.term.getTermPersonal();
  }, {
    refetchOnWindowFocus: false, 
    enabled: !!termPersonal
  });
  const getTermService = useQuery(["termService"], () => {
    return api.term.getTermService();
  }, {
    refetchOnWindowFocus: false, 
    enabled: !!termService
  });

  return {
    getTermPersonal,
    getTermService,
  }
}