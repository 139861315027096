import Styles from "./styles";
import ScrollContainer from "react-indiana-drag-scroll";
import { common, layout, ui } from "components";
import { useCalendar, useModals, useQuickProduct } from "hooks";

// img
import IconArrowRight from "resources/image/icon/icon_arrow_right02.svg";
import IconEmpty from "resources/image/icon/icon_empty.svg";
import utils from "utils";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function SchedulePage() {
  const navigate = useNavigate();
  const calendar = useCalendar();
  const modalOption = useModals();

  // 달력에 맞춰서 월이 바뀝니다.
  const date = new Date();
  const month = calendar.currentDate?.getMonth() + 1;

  const quickProductInfo = useQuickProduct({
    groupDateListOption: {
      date: utils.date.getDateType(calendar.currentDate, "-"),
    },
    groupListOption: {
      date: utils.date.getDateType(calendar.value, "-"),
    },
    monthListOption: {
      date: utils.date.getDateType(calendar.currentDate, "-"),
    }
  });

  useEffect(() => {
    calendar.updateValidMap(quickProductInfo.groupDateQuery?.data?.data);
    calendar.onClickDate(null);
  }, [quickProductInfo.groupDateQuery?.data]);

  const renderState = (type) => {
    switch (type) {
      case "1":
        return <div className="state progress">개설예정</div>;
      case "2":
        return <div className="state scheduled">신청중</div>;
      case "3":
        return <div className="state closed">신청마감</div>;
      default : 
        return <div className="state progress">개설예정</div>;
    }
  };

  return (
    <>
      <common.Header />

      <Styles.Container>
        <div className="section">
          <div className="inner">
            <h2>교육일정</h2>

            <div className="scheduleWrap">
              <div className="calendar">
                <layout.Calendar 
                  calendar={calendar} 

                />
              </div>

              <div className="detail">
                <div className="detailTitle">
                  {month}월 상세일정{" "}
                  <div className="scheduleButton">
                    <ui.button.BasicButton
                      icon={IconArrowRight}
                      buttonText={"연간 교육일정 확인하기"}
                      buttonType={"whiteHover"}
                      onClick={() => {
                        modalOption.setScheduleModal((e) => {
                          e.show = true;

                          return { ...e };
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="listContainer">
                  <ScrollContainer className="scroll-container">
                    {quickProductInfo?.groupListQuery?.data?.data?.length > 0 ? (
                      <>
                        <ul className="thead">
                          <li className="th state">상태</li>
                          <li className="th period">교육 기간</li>
                          <li className="th title">과정명</li>
                        </ul>

                        <ul className="tbody">
                          {quickProductInfo?.groupListQuery?.data?.data?.map((item, index) => {
                            return (
                              <li className="tr" key={"scheduleList" + index} onClick={()=>{
                                navigate("/education/apply/detail/intacs?id=" + item.id);
                                // navigate(`${item.option_count == 1 ? "/education/curriculum-detail/schedule?id=" + item.id : "/education/apply/detail/intacs?id=" + item.id}`);
                              }}>
                                <div className="td state">
                                  {renderState(item.state_type)}
                                </div>
                                <div className="td period">
                                  {item.study_started_at === item.study_ended_at
                                    ? utils.date.getDateType(item.study_started_at, '.')
                                    : `${utils.date.getDateType(item.study_started_at, '.')} ~ ${utils.date.getDateType(item.study_ended_at, '.')}`
                                  }
                                </div>
                                <div className="td title">{item.title}</div>
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    ) : calendar?.value?.getMonth() !== calendar?.currentDate?.getMonth() && 
                      quickProductInfo?.monthListQuery?.data?.data?.length > 0 ? (
                        <>
                          <ul className="thead">
                            <li className="th state">상태</li>
                            <li className="th period">교육 기간</li>
                            <li className="th title">과정명</li>
                          </ul>

                          <ul className="tbody">
                            {quickProductInfo?.monthListQuery?.data?.data?.map((item, index) => {
                              return (
                                <li className="tr" key={"scheduleList" + index} onClick={() => {
                                  navigate("/education/apply/detail/intacs?id=" + item.id);
                                  // navigate(`${item.option_count == 1 ? "/education/curriculum-detail/schedule?id=" + item.id : "/education/apply/detail/intacs?id=" + item.id}`);
                                }}>
                                  <div className="td state">
                                    {renderState(item.state_type)}
                                  </div>
                                  <div className="td period">
                                    {item.study_started_at === item.study_ended_at
                                      ? utils.date.getDateType(item.study_started_at, '.')
                                      : `${utils.date.getDateType(item.study_started_at, '.')} ~ ${utils.date.getDateType(item.study_ended_at, '.')}`
                                    }
                                  </div>
                                  <div className="td title">{item.title}</div>
                                </li>
                              );
                            })}
                          </ul>
                        </>
                    ) : (
                      <div className="empty">

                        <div className="icon">
                          <img src={IconEmpty} alt="IconEmpty" />
                        </div>

                        <p>등록된 일정이 없습니다.</p>
                      </div>
                    )}
                  </ScrollContainer>
                </div>

                <layout.ScrollGuide />
              </div>
            </div>
          </div>
        </div>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default SchedulePage;
