import { useLanguage } from "hooks";

const LanguageRoute = ({ children }) => {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";

  if (languageQuarter) {
    return children;
  } else {
    window.location.replace("/education/center-introduction");
    return <></>;
  }
}

export default LanguageRoute