import styled from "styled-components";

// img
import BgPerformance from "resources/image/introduction/bg_performance.png";
import BgPerformanceMb from "resources/image/introduction/bg_performance_mb.png";

const Container = styled.main`
  min-height: 100vh;
  min-height: 100dvh;
  padding-bottom: 200px;

  & section:not(:last-child) {
    margin-bottom: 80px;
  }

  & h3 {
    margin-bottom: 10px;
    font-size: 36px;
    font-weight: bold;
  }

  & .performanceSection {
    padding: 0 50px;

    & .wrap {
      padding: 80px 0;
      background: url(${BgPerformance}) no-repeat center / cover;
      border-radius: 500px;

      & .inner {
        display: flex;
        justify-content: space-between;
        font-size: 18px;
        font-weight: 500;
        color: #fff;

        & .listWrap {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          flex-wrap: wrap;
          gap: 50px 0;
          width: 518px;

          & .list {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: calc(100% / 3);
            height: 126px;
          }

          & .number {
            font-size: 36px;
            font-weight: bold;

            & span {
              font-size: 62px;
            }
          }
        }
      }
    }
  }

  & .clientSection {
    & .inner {
      display: flex;
      justify-content: space-between;
      font-weight: 500;

      & .textWrap {
        font-size: 18px;
      }

      & .listWrap {
        display: flex;
        flex-wrap: wrap;
        gap: 17px 19px;
        width: 873px;
        font-size: 14px;
        text-align: center;

        & .img {
          height: 82px;
          margin-bottom: 10px;
          border-radius: 10px;
          box-shadow: 3px 5px 15px 0px rgba(63, 93, 111, 0.14);
          overflow: hidden;
        }
      }
    }
  }

  @media (max-width: 1480px) {
    padding-bottom: calc(100vw * (200 / 1480));

    & section:not(:last-child) {
      margin-bottom: calc(100vw * (80 / 1480));
    }

    & h3 {
      margin-bottom: calc(100vw * (10 / 1480));
      font-size: calc(100vw * (36 / 1480));
    }

    & .performanceSection {
      padding: 0 calc(100vw * (20 / 1480));

      & .wrap {
        padding: calc(100vw * (80 / 1480)) 0;
        border-radius: calc(100vw * (500 / 1480));

        & .inner {
          font-size: calc(100vw * (18 / 1480));

          & .listWrap {
            gap: calc(100vw * (50 / 1480)) 0;
            width: calc(100vw * (518 / 1480));

            & .list {
              height: calc(100vw * (126 / 1480));
            }

            & .number {
              font-size: calc(100vw * (36 / 1480));

              & span {
                font-size: calc(100vw * (62 / 1480));
              }
            }
          }
        }
      }
    }

    & .clientSection {
      & .inner {
        & .textWrap {
          font-size: calc(100vw * (18 / 1480));
        }

        & .listWrap {
          gap: calc(100vw * (17 / 1480)) calc(100vw * (19 / 1480));
          width: calc(100vw * (873 / 1480));
          font-size: calc(100vw * (14 / 1480));

          & .img {
            height: calc(100vw * (82 / 1480));
            margin-bottom: calc(100vw * (10 / 1480));
            border-radius: calc(100vw * (10 / 1480));
            box-shadow: calc(100vw * (3 / 1480)) calc(100vw * (5 / 1480))
              calc(100vw * (15 / 1480)) 0 rgba(63, 93, 111, 0.14);
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    min-height: calc(100vh - calc(100vw * (309 / 375)));
    min-height: calc(100dvh - calc(100vw * (309 / 375)));
    padding: calc(100vw * (66 / 375)) 0 calc(100vw * (130 / 375));

    & section:not(:last-child) {
      margin-bottom: calc(100vw * (50 / 375));
    }

    & h3 {
      margin-bottom: calc(100vw * (10 / 375));
      font-size: calc(100vw * (24 / 375));
    }

    & .performanceSection {
      padding: 0;

      & .wrap {
        margin: 0 calc(100vw * (20 / 375));
        padding: calc(100vw * (100 / 375)) 0 calc(100vw * (209 / 375));
        border-radius: calc(100vw * (500 / 375));
        background-image: url(${BgPerformanceMb});

        & .inner {
          flex-direction: column;
          gap: calc(100vw * (50 / 375));
          padding: 0 calc(100vw * (40 / 375));
          font-size: calc(100vw * (14 / 375));

          & .listWrap {
            gap: calc(100vw * (32 / 375)) 0;
            justify-content: space-between;
            width: 100%;

            & .list {
              width: fit-content;
              max-width: calc(100vw * (123 / 375));
              min-width: calc(100vw * (89 / 375));
              height: calc(100vw * (82 / 375));

              &:last-child .text {
                width: min-content;
              }
            }

            & .number {
              font-size: calc(100vw * (24 / 375));

              & span {
                font-size: calc(100vw * (32 / 375));
              }
            }
          }
        }
      }
    }

    & .clientSection {
      & .inner {
        flex-direction: column;
        gap: calc(100vw * (30 / 375));

        & .textWrap {
          font-size: calc(100vw * (14 / 375));
        }

        & .listWrap {
          gap: calc(100vw * (14 / 375)) calc(100vw * (6 / 375));
          width: 100%;
          font-size: calc(100vw * (14 / 375));

          & .img {
            height: calc(100vw * (66 / 375));
            margin-bottom: calc(100vw * (14 / 375));
            border-radius: calc(100vw * (10 / 375));
            box-shadow: calc(100vw * (3 / 375)) calc(100vw * (5 / 375))
              calc(100vw * (15 / 375)) 0 rgba(63, 93, 111, 0.14);
          }
        }
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
