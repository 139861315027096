import Styles from "./styles";
import { useLanguage } from "hooks";
import { common, layout } from "components";
import { Link, useLocation } from "react-router-dom";

// img
import BgBanner from "resources/image/business/bg_main.jpg";
import BgBannerMb from "resources/image/business/bg_main_mb.jpg";

function BusinessAreaPage() {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === ""

  const location = useLocation();
  const params = location.pathname.split("/")[3];

  const menuList = [
    {
      param: "service",
      title: (
        <>
          Process & Engineering <br className="mbOnly" />
          Service
        </>
      ),
      menuList: [
        { menuText: "Automotive SPICE", url: "/spice?disableScrollTop=true&focus=focus" },
        { menuText: "Functional Safety", url: "/safety?disableScrollTop=true&focus=focus" },
        { menuText: "Cybersecurity", url: "/cybersecurity?disableScrollTop=true&focus=focus" },
        { menuText: "OTA", url: "/ota?disableScrollTop=true&focus=focus" },
        { menuText: "SOTIF", url: "/sotif?disableScrollTop=true&focus=focus" },
        { menuText: "Safety for ADS", url: "/ads?disableScrollTop=true&focus=focus" },
      ],
    },
    {
      param: "assessment",
      title: "Assessment",
      menuList: [
        { menuText: "Assessment", url: "/assessment?disableScrollTop=true&focus=focus" }
      ],
    },
    {
      param: "r&d",
      title: "Automated Driving R&D",
      menuList: [
        {
          menuText: (
            <>
              AI Solution for <br />
              Automated Driving
            </>
          ),
          url: "?disableScrollTop=true&focus=focus"
        },
      ],
    },
    {
      param: "solution",
      title: "Solution",
      menuList: [
        { menuText: "Solution", url: "?disableScrollTop=true&focus=focus" }
      ],
    },
  ];

  return (
    <>
      <common.Header />
      <Styles.Container>
        <section className="bannerSection">
          <layout.MainBanner
            img={BgBanner}
            mbImg={BgBannerMb}
            colorText={"C&BIS"}
            title={languageQuarter ? "사업영역" : "Technical Service"}
          />
        </section>

        <section className="textSection">
          <div className="textWrap">
            <p>
              Technical Service For <br className="mbOnly" />
              New Mobility
            </p>

            <p>
              {languageQuarter ? <>
                씨엔비스(주)는 <br className="mbOnly" />
                고객의 요구에 부합하는 <br className="mbOnly" />
                최고의 서비스 제공을 통해 <br />
                귀사의 경쟁력을 높여나가는 <br className="mbOnly" />
                비즈니스 파트너로 <br className="mbOnly" />
                함께 하겠습니다.
              </> : <>
                C&BIS offers the best service to meet the needs of our customers <br className="pcOnly" />
                We will work together as a business partner to increase your competitiveness.
              </>}
            </p>
          </div>
        </section>

        <section className="menuSection">
          <div className="inner">
            <ul className="menu" >
              {menuList.map((item, index) => {
                console.log(params, item.param);
                return (
                  <li
                    className={"meuList" + (params === item.param ? " on" : "")}
                    key={"meuList" + index}
                  >
                    <input id={"bis_menu_" + index} type="text" style={{opacity:0}}/>
                    <div className="title">{item.title} </div>
                    <ul>
                      {item.menuList?.map((current, idx) => {
                        return (
                          <li className="depthList" key={"depthList" + idx}>
                            <Link
                              to={
                                `/business/${item.param}` +
                                (current.url || "")
                              }
                            >
                              {current.menuText}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                    <div id="bis_menu"></div>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default BusinessAreaPage;
