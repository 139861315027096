import styled from "styled-components";

const Input = styled.input`
  display: none;

  &:checked + label {
    & .checkbox {
      position: relative;
      border-color: var(--blue02);

      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 10px;
        height: 10px;
        background-color: var(--blue02);
        border-radius: 50%;
        content: "";
      }
    }

    & .labelText {
      color: var(--blue02);
    }
  }

  @media (max-width: 1480px) {
    &:checked + label {
      & .checkbox {
        &::after {
          width: calc(100vw * (10 / 1480));
          height: calc(100vw * (10 / 1480));
        }
      }
    }
  }

  @media (max-width: 768px) {
    &:checked + label {
      & .checkbox {
        &::after {
          width: calc(100vw * (8 / 375));
          height: calc(100vw * (8 / 375));
        }
      }
    }
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  & .checkbox {
    width: 20px;
    height: 20px;
    border: 2px solid var(--gray02);
    border-radius: 50%;
  }

  & .labelText {
    font-size: 18px;
    font-weight: 500;
    color: var(--gray01);
  }

  @media (max-width: 1480px) {
    gap: calc(100vw * (10 / 1480));

    & .checkbox {
      width: calc(100vw * (20 / 1480));
      height: calc(100vw * (20 / 1480));
      border: calc(100vw * (2 / 1480)) solid var(--gray02);
    }

    & .labelText {
      font-size: calc(100vw * (18 / 1480));
    }
  }

  @media (max-width: 768px) {
    gap: calc(100vw * (6 / 375));

    & .checkbox {
      width: calc(100vw * (16 / 375));
      height: calc(100vw * (16 / 375));
      border: 1px solid var(--gray02);
    }

    & .labelText {
      font-size: calc(100vw * (14 / 375));
    }
  }
`;

export { Input, Label };

export default { Input, Label };
