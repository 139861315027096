export default function useLanguage() {
  const type = {
    KOR: "",
    ENG: "_en",
  }
  
  const setLanguage = (item) => {
    return sessionStorage.setItem("language", item);
  }

  const suffix = type[sessionStorage.getItem("language")] || "";

  return {
    setLanguage,
    suffix,
  };
}