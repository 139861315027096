import connector from "service/connector";
import utils from "utils";

const getList = async (data) => {
  return await connector.authConnector(`product?${utils.etc.getFilterStr(data)}`,'GET');
}

const getGroupList = async (data) => {
  return await connector.authConnector(`product/group_category_list?${utils.etc.getFilterStr(data)}`,'GET');
}

const getDetail = async (id) => {
  return await connector.authConnector(`product/${id}`,'GET');
}

const getTransactionDetail = async (id) => {
  return await connector.authConnector(`product/transaction/${id}`,'GET');
}

const getOptionList = async (data) => {
  return await connector.authConnector(`product/option?${utils.etc.getFilterStr(data)}`,'GET');
}

const productSchedule = async () => {
  return await connector.connectFetchController(`product/year`, 'GET');
}



export {
  getDetail,
  getGroupList,
  getList,
  getTransactionDetail,
  getOptionList,
  productSchedule,
};


export default {
  getDetail,
  getGroupList,
  getList,
  getTransactionDetail,
  getOptionList,
  productSchedule,
}