import Styles from "./styles";
import { Outlet } from "react-router-dom";
import { useLanguage } from "hooks";
import { common, layout } from "components";

// img
import BgBanner from "resources/image/business/bg_main.jpg";
import BgBannerMb from "resources/image/business/bg_main_mb.jpg";

function BusinessAssessmentPage() {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === ""

  return (
    <>
      <common.Header />
      <Styles.Container>
        <section className="bannerSection">
          <layout.MainBanner
            img={BgBanner}
            mbImg={BgBannerMb}
            colorText={languageQuarter ? "사업영역" : "Technical Service"}
            title={"Assessment"}
          />
        </section>

        <Outlet />
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default BusinessAssessmentPage;
