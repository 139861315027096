import Styles from "./styles";
import { useMediaQuery } from "react-responsive";

function MainBanner({ img, mbImg, colorText, title }) {
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });

  return (
    <Styles.Container
      style={{
        backgroundImage: `url(${isMobile ? mbImg : img})`,
      }}
    >
      <div className="inner">
        <h2>
          <span>{colorText}</span> <br />
          {title}
        </h2>
      </div>
    </Styles.Container>
  );
}

export default MainBanner;
