import {
  DefaultModal,
  MainModal,
  RegisterModal,
  ScheduleModal,
  AskModal,
  CancelModal,
} from "components/modal";
import { useModals } from "hooks";
import { useEffect } from "react";

// img
export default function ModalPage() {
  const modalOption = useModals();

  useEffect(() => {
    const body = document.body;
    const html = document.querySelector("html");
    if (
      modalOption.defaultModal.show ||
      modalOption.mainModal.show ||
      modalOption.registerModal.show ||
      modalOption.scheduleModal.show ||
      modalOption.askModal.show ||
      modalOption.cancelModal.show
    ) {
      body.className = "hidden";
      html.className = "hidden";
    } else {
      body.classList.remove("hidden");
      html.classList.remove("hidden");
    }
  }, [modalOption]);

  return (
    <>
      {
        //기본 모달
        modalOption.defaultModal.show ? (
          <DefaultModal
            modalOption={modalOption.defaultModal}
            modalClose={() => {
              modalOption.setDefaultModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        //메인 모달
        modalOption.mainModal.show ? (
          <MainModal
            modalOption={modalOption.mainModal}
            modalClose={() => {
              modalOption.setMainModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        //수신자 등록 모달
        modalOption.registerModal.show ? (
          <RegisterModal
            modalOption={modalOption.registerModal}
            modalClose={() => {
              modalOption.setRegisterModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        //연간 교육 일정 모달
        modalOption.scheduleModal.show ? (
          <ScheduleModal
            modalOption={modalOption.scheduleModal}
            modalClose={() => {
              modalOption.setScheduleModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        // 1:1 문의 모달
        modalOption.askModal.show ? (
          <AskModal
            modalOption={modalOption.askModal}
            modalClose={() => {
              modalOption.setAskModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        // 교육 신청 취소 모달
        modalOption.cancelModal.show ? (
          <CancelModal
            modalOption={modalOption.cancelModal}
            modalClose={() => {
              modalOption.setCancelModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
    </>
  );
}
