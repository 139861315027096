import Styles from "./styles";

function DescriptionBox({ title, left, right }) {
  return (
    <Styles.Container>
      <p className="title">{title}</p>

      {left && (
        <div className="textWrap">
          <p className="left">{left}</p>

          <p className="right">{right}</p>
        </div>
      )}
    </Styles.Container>
  );
}

export default DescriptionBox;
