import Styles from "./styles";
import { useLanguage } from "hooks";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { common, layout, ui } from "components";

// img
import BgBanner from "resources/image/business/bg_main.jpg";
import BgBannerMb from "resources/image/business/bg_main_mb.jpg";
import ImgSolution01 from "resources/image/business/solution/img_solution01.jpg";
import ImgSolution02 from "resources/image/business/solution/img_solution02.jpg";
import ImgSolution03 from "resources/image/business/solution/img_solution03.jpg";
import ImgSolution04 from "resources/image/business/solution/img_solution04.jpg";
import ImgSolution04En from "resources/image/business/solution/img_solution04_en.jpg";
import ImgSolution04Mb from "resources/image/business/solution/img_solution04_mb.jpg";
import ImgSolution04MbEn from "resources/image/business/solution/img_solution04_mb_en.jpg";
import ImgSolution05 from "resources/image/business/solution/img_solution05.jpg";
import ImgSolution06 from "resources/image/business/solution/img_solution06.jpg";
import ImgSolution07 from "resources/image/business/solution/img_solution07.jpg";
import ImgSolution08 from "resources/image/business/solution/img_solution08.jpg";
import ImgSolution09 from "resources/image/business/solution/img_solution09.png";
import ImgSolution10 from "resources/image/business/solution/img_solution10.png";
import ImgSolution11 from "resources/image/business/solution/img_solution11.png";
import ImgSolution12 from "resources/image/business/solution/img_solution12.png";

function BusinessSolutionPage() {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === ""
  const navigate = useNavigate();

  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });

  const codebeamerList = [
    "Software Development" + (languageQuarter ? "(소프트웨어 개발 프로세스)" : ""),
    "Requirement Management" + (languageQuarter ? "(요구사항 정의 및 관리)" : ""),
    "Test Management" + (languageQuarter ? "(테스트 계획 및 수행 관리)" : ""),
    "Document Management" + (languageQuarter ? "(산출물 관리 모듈)" : ""),
    <>
      IT Operation & SLM Solution <br className="mbOnly" />
      {languageQuarter && "(SLA 기반 티켓 관리 및 서비스 관리)"}
    </>,
    "Demand Management" + (languageQuarter ? "(커뮤니티 및 CS 센터 관리)" : ""),
  ];
  const codebeamerOverviewList = [
    {
      title: languageQuarter ? "프로세스 차원 (process dimension)" : "Process dimension",
      description: (languageQuarter ?
        <>
          “형상/변경/이력 관리” 전체 소프트웨어 생명주기 내에서{" "}
          <br className="mbOnly" />
          개발 산출물에 대한 <br className="pcOnly" />
          형상 이력을 관리하고 워크플로우 <br className="mbOnly" />
          기반으로 변경내역에 대한 추적을 가능하게 하며 <br />
          시스템을 통해 표준 프로세스를 수행합니다.
        </> : <>
          "Configuration/change/history management" Manage the shape history of the development
          output within the entire software life cycle, enable workflow-based tracking of changes,
          and perform standard processes through the system.
        </>
      ),
    },
    {
      title: languageQuarter ? "능력 차원 (capability dimension)" : "Capability dimension",
      description: (languageQuarter ?
        <>
          “일원화된 통합 관리 환경” ALM 도구를 통해 <br className="mbOnly" />
          단계별로 분산된 업무환경에서도 <br />
          손쉽게 협력하여 내용을 공유할 수 있는 환경을 제공합니다.
        </> : <>
          "Unified, integrated management environment" ALM tool provides an easy-to-cooperate,
          shared environment even in a step-by-step, distributed work environment.
        </>
      ),
    },
    {
      title: languageQuarter ? "능력 차원 (capability dimension)" : "Capability dimension",
      description: (languageQuarter ?
        <>
          “산출물의 추적성 관리” 요구사항부터 테스트 배포에{" "}
          <br className="mbOnly" />
          이르기까지 소프트웨어 개발의 각 단계에서 <br />
          프로세스에 맞도록 산출물이 관리될 수 있도록 도와주며{" "}
          <br className="mbOnly" />각 산출물들을 추적관리 하며 <br />
          국제 표준들의 컴플라이언스를 도와줄 수 있습니다.
        </> : <>
          "Traceability management of output" requirements to test distribution,
          we can help you manage outputs to fit your processes at each stage of software development,
          track and manage each output, and help you comply with international standards.
        </>
      ),
    },
  ];
  const mediniOverviewList = [
    {
      number: "01",
      description: (languageQuarter ?
        <>
          위험원 분석, 위험원 평가, 위험원 및 운용성 연구(HAZOP), 결함 트리
          분석(FTA), 고장 모드 <br className="pcOnly" />및 영향 분석(FMEA,
          FMEDA, FMECA), 신뢰성 블록 다이어그램(RBD), 신뢰성 분석을{" "}
          <br className="pcOnly" />
          지원하는 모델 <br className="mbOnly" />
          기반의 통합 도구 세트
        </> : <>
          A model-based integrated tool set that supports risk-source analysis,
          risk-source assessment, risk-source and operability studies (HAZOP),
          fault tree analysis (FTA), failure mode and impact analysis (FMEA, FMEDA, FMECA),
          reliability block diagram (RBD), and reliability analysis
        </>
      ),
    },
    {
      number: "02",
      description: (languageQuarter ?
        <>
          안전 관련 기능을 위한 ISO 26262, IEC 61508, ARP4761, MIL-STD-882 등을
          따르는 <br />
          안전 분석 및 설계
        </> : <>
          Safety analysis and design following ISO 26262, IEC 61508, ARP4761, MIL-STD-882, etc. for safety related functions
        </>
      ),
    },
    {
      number: "03",
      description: (languageQuarter ?
        <>
          SAE J1739, VDA 품질 핸드북, AIAG 등을 따르는 제품 설계 및{" "}
          <br className="pcOnly" />
          관련 프로세스에 대한 품질 분석
        </> : <>
          Quality analysis of product design and related processes following SAE J1739, VDA Quality Handbook, AIAG, etc
        </>
      ),
    },
    {
      number: "04",
      description: (languageQuarter ?
        <>
          아키텍처/기능 설계 모델과 품질, 신뢰성, 기능 안전{" "}
          <br className="mbOnly" />
          분석 방법의 통합
        </> : <>
          Integration of architecture/function design models and quality, reliability, and functional safety analysis methods
        </>
      ),
    },
    {
      number: "05",
      description: (languageQuarter ?
        <>
          기능 및 기술 안전 요구 사항의 정의 및 관리
        </> : <>
          Define and management of function and technical safety requirements
        </>
      ),
    },
    {
      number: "06",
      description: (languageQuarter ?
        <>
          시작에서 끝까지 완전한 추적성을 지원
        </> : <>
          Supports complete traceability from start to finish
        </>
      ),
    },
    {
      number: "07",
      description: (languageQuarter ?
        <>
          사용자 정의 가능한 작업 산출물/문서 생성
        </> : <>
          Create customizable job deliverables/documents
        </>
      ),
    },
    {
      number: "08",
      description: (languageQuarter ?
        <>
          상세 비교 및 병합을 통한 팀워크
        </> : <>
          Teamwork with detailed comparison and merging
        </>
      ),
    },
    {
      number: "09",
      description: (languageQuarter ?
        <>
          임베디드 시스템 개발, 분석 및 시뮬레이션을 위한 도구와 완전히 통합
        </> : <>
          Fully integrated with tools for embedded system development, analysis and simulation
        </>
      ),
    },
    {
      number: "10",
      description: (languageQuarter ?
        <>
          IBM Rational DOORS, PTC Codebeamer, Jama, Dassault Cameo Systems Modeler, <br />
          Siemens Polarion 등과 연동이 가능합니다.
        </> : <>
          It can be integrated with IBM Rational DOORS, PTC Codebeamer, Jama, Dassault Cameo Systems Modeler, Siemens Polarion, etc.
        </>
      ),
    },
  ];

  return (
    <>
      <common.Header />
      <Styles.Container>
        <section className="bannerSection">
          <layout.MainBanner
            img={BgBanner}
            mbImg={BgBannerMb}
            colorText={languageQuarter ? "사업영역" : "Technical Service"}
            title={"Solution"}
          />
        </section>

        <section className="codebeamerTitleSection">
          <div className="inner">
            <layout.DescriptionBox
              title={"Codebeamer"}
              left={"Codebeamer" + (languageQuarter ? "란" : "")}
              right={languageQuarter
                ? "요구사항, 형상/변경, 이슈, 품질 등의 SW개발 전 과정을 통합적으로 관리/지원하는 ALM 솔루션 입니다."
                : "It is an ALM solution that integrates/supports the entire SW development process such as requirements, configuration/change, issues, and quality."
              }
            />
            <div id="focus"></div>
            
            <ul>
              {codebeamerList.map((item, index) => {
                return (
                  <li className="codebeamerList" key={"codebeamerList" + index}>
                    <layout.BusinessTable
                      title={`0${index + 1}`}
                      description={item}
                    />
                  </li>
                );
              })}
            </ul>

            <div className="wrap">
              <figure>
                <div className="img">
                  <img src={ImgSolution01} alt="ImgSolution01" />
                </div>
              </figure>

              <figure>
                <div className="img">
                  <img src={ImgSolution02} alt="ImgSolution02" />
                </div>
              </figure>
            </div>
          </div>
        </section>

        <section className="codebeamerOverviewSection">
          <div className="inner">
            <h3>{languageQuarter ? "솔루션 개요" : "Solution Overview"}</h3>

            <div className="wrap">
              <div className="textContainer">
                <ul>
                  {codebeamerOverviewList.map((item, index) => {
                    return (
                      <li
                        className="codebeamerOverviewList"
                        key={"codebeamerOverviewList" + index}
                      >
                        <layout.BusinessTable
                          title={item.title}
                          description={item.description}
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className="imgContainer">
                <figure>
                  <div className="img">
                    <img src={ImgSolution03} alt="ImgSolution03" />
                  </div>
                </figure>
              </div>
            </div>
          </div>
        </section>

        <section className="almSection">
          <div className="inner">
            <h3>
              {languageQuarter ?
                <>
                  성공적인 프로젝트 <br className="mbOnly" />
                  관리를 위한 ALM
                </> : <>
                  ALM for successful project management
                </>}
            </h3>

            <div className="img">
              <img
                src={languageQuarter
                      ? isMobile ? ImgSolution04Mb : ImgSolution04
                      : isMobile ? ImgSolution04MbEn : ImgSolution04En
                    }
                alt="ImgSolution04"
              />
            </div>

            <figure>
              <div className="img">
                <img src={ImgSolution05} alt="ImgSolution05" />
              </div>
            </figure>
          </div>
        </section>

        <section className="codebeamerAlmSection">
          <div className="inner">
            <h3>Codebeamer ALM</h3>

            <p className="sectionDescription">
              {languageQuarter ?
                <>
                  SW 개발 프로젝트 혁신을 위한 통합 관리 솔루션으로 Lifecycle(요구사항, 설계, 개발, 릴리즈, 유지보수)의 업무 프로세스를
                  시스템을 통해 개선 및 협업체계를 구축하는 Single Platform
                </> : <>
                  Single Platform to improve Lifecycle (requirements, design, development, release, maintenance)
                  work processes and build collaboration systems as an integrated management solution for SW development project innovation
                </>}
            </p>

            <figure>
              <div className="img">
                <img src={ImgSolution06} alt="ImgSolution06" />
              </div>
            </figure>
          </div>
        </section>

        <section className="codebeamerEffectSection">
          <div className="inner">
            <h3>Codebeamer Effect</h3>

            <div className="wrap">
              <div className="img">
                <img src={ImgSolution07} alt="ImgSolution07" />
              </div>

              <p className="sectionDescription">
                {languageQuarter ?
                  <>
                    Automotive / Medical / High Tech & Embedded / 반도체 등 다양한 분야에서 사용되며, <br className="pcOnly"/>
                    특히 미래 자동차(전기차, 자율주행차 등) SW 개발 토털서비스를 제공하는데 특화되어 있습니다.
                  </> : <>
                    Use in various fields such as Automotive/Medical/High Tech & Embedded/Semiconductors,
                    In particular, it is specialized in providing total services for future automobiles (electric vehicles, automated-driving, etc.) SW development.
                  </>}
              </p>
            </div>
          </div>
        </section>

        <section className="stagesTitleSection">
          <div className="inner">
            <layout.DescriptionBox
              title={"stages"}
              left={"stages" + (languageQuarter ? "란" : "")}
              right={languageQuarter ?
                <>
                  자율주행, AI 기반의 제품 등의 SW의 중요도가 높아짐에 따라,
                  개발 요구사항의 복잡도가 높아지고 있습니다. Stages는 연구/개발 부문에서 프로세스의 개선과 혁신을 위한 솔루션입니다.
                </> : <>
                  As the importance of SW such as automated-driving and AI-based products increases, the complexity of development requirements is increasing.
                  Stages is a solution for process improvement and innovation in research and development.
                </>
              }
            />
          </div>
        </section>

        <section className="stagesOverviewSection">
          <div className="inner">
            <h3>{languageQuarter ? "솔루션 개요" : "Solution overview"}</h3>
            <p className="sectionDescription">
              {languageQuarter ?
                <>
                  CMMI, IATF 16949, ISO 26262, Cybersecurity 등 조직 프로세스에
                  반영하고 프로젝트 별로 맞춤형 프로세스를 생성함
                  <br className="mbOnly" />
                  으로써 일관된 개발 및 품질 경영 관리 체계(QMS)를 구축할 수 있도록
                  합니다. 따라서 기존 문서 형태의 관리가 아닌 시스템적으로
                  프로세스를 조직에 내재화시키고, 조직과 유관부서원 모두가 한 방향의
                  품질 목표를 지속적으로 달성하도록 지원합니다.
                  <br />
                  <br />
                  개발 단계별 누가(Role), 무엇을(Work Products), 어떻게(Tasks)활동을
                  해야 하는가 등의 명확한 업무 정의로 생산성을 향상하고, 고객사의
                  심사 및 평가에 대응하는데 소요되는 시간과 비용을 획기적으로
                  절감하는 솔루션입니다.
                </> : <>
                  By reflecting it in organizational processes such as CMMI, IATF 16949, ISO 26262, and Cybersecurity,
                  and creating customized processes for each project, we can build a consistent development and quality
                  management system (QMS). Therefore, it systematically internalizes processes in the organization rather
                  than managing them in the form of existing documents, and helps both the organization and related department
                  members achieve quality goals in one direction continuously.
                  <br />
                  <br />
                  It is a solution that improves productivity with a clear definition of work such as who (Role),
                  what (Work Products), and how (Tasks) activities in each development stage, and dramatically
                  reduces the time and cost spent responding to customer review and evaluation.
                </>}
            </p>
          </div>
        </section>

        <section className="stagesEffectSection">
          <div className="inner">
            <h3>stages Effect</h3>

            <div className="wrap">
              <div className="img">
                <img src={ImgSolution08} alt="ImgSolution08" />
              </div>

              <p className="sectionDescription">
                {languageQuarter ?
                  <>
                    신뢰할 수 있는 프로세스는 안전하고 보안이 유지되며 지속 가능한
                    제품과 민첩하고 탄력적인 비즈니스의 핵심입니다. <br />
                    고객의 프로세스 통합 관리 및 업무 효율화를 위한 프로세스 통합 솔루션과 서비스를 제공해 드립니다.
                  </> : <>
                    Trusted processes are the key to safe, secure, and sustainable products and agile, resilient businesses. <br />
                    We provide process integration solutions and services for your integrated process management and work efficiency.
                  </>}
              </p>
            </div>
          </div>
        </section>

        <section className="mediniTitleSection">
          <div className="inner">
            <layout.DescriptionBox
              title={"medini analyze"}
              left={"medini analyze" + (languageQuarter ? "란" : "")}
              right={languageQuarter ?
                <>
                  medini analyze는 ISO 26262, ISO/SAE 21434 등 국제표준 준수를
                  위한 통합 기능 안전/사이버보안 분석 솔루션입니다. <br />
                  <br />
                  국제표준에서 요구하는 기능 안전 및 사이버보안 분석 활동 -
                  시스템의 위험도 분석과 리스크 평가(HARA)부터 정성적/정량적
                  FME(D)A, FTA 등을 하나의 통합된 환경에서 수행
                  <br className="mbOnly" />할 수 있도록 지원하며, 외부 보안
                  공격의 위험으로부터 자동차를 구성하는 전기/전자 시스템을
                  보호하기 위해 위협 분석 및 리스크 평가(TARA) 등을 수행할 수
                  있는 환경을 지원합니다. 또한, 매번 변경되는 안전 요구사항부터
                  설계까지의 지속적인 추적성 및 일관성을 손쉽게 확보하여
                  국제표준에서 요구하는 기능 안전분석 활동을 효율적으로 대응하는
                  솔루션입니다.
                </> : <>
                  Medini analyze is an integrated functional safety/cybersecurity analysis solution for
                  compliance with international standards such as ISO 26262 and ISO/SAE 21434. <br />
                  <br />
                  Functional safety and cybersecurity analysis activities required by international standards - It
                  supports system risk analysis and risk assessment (HARA), qualitative and quantitative FME(D)A,
                  FTA, etc. in one unified environment, and environmental support for threat analysis and risk assessment
                  (TARA) to protect the electrical/electronic systems that make up the vehicle from the risk of external
                  security attacks. In addition, it is a solution that efficiently responds to functional safety analysis
                  activities required by international standards by ensuring continuous traceability and consistency from
                  safety requirements to design that change every time.
                </>
              }
            />
          </div>
        </section>

        <section className="mediniOverviewSection">
          <div className="inner">
            <h3>{languageQuarter ? "솔루션 개요 및 효과" : "Solution overview & Effect"}</h3>

            <div className="grayBox">
              <div className="title">
                {languageQuarter ?
                  <>
                    반도체 안전 보장을 위한 <span>ISO26262 전문 솔루션</span>
                  </> : <>
                    ISO26262 Specialized Solution for Semiconductor Security
                  </>}
              </div>

              <div className="text">
                <p>
                  {languageQuarter ?
                    <>
                      ISO 26262에 특화된 지원을 통해 자동차 부문에서 반도체 안전을
                      보장합니다. ISO 26262의 적용에 따라 자동차 기능 안전을 위해
                      하드웨어 설계를 분석해야 합니다. 기능 안전 분석에 필요한
                      작업이 최대 55% 감소하고 출시 기간도 이와 비슷한 수준으로
                      단축되었다고 말합니다. 또한 기능 안전 분석 작업 산출물들
                      사이의 불일치가 제거되고 확인 검토 및 평가 속도가
                      향상되었습니다.
                    </> : <>
                      Support specific to ISO 26262 ensures semiconductor safety in the automotive sector.
                      Hardware design should be analyzed for automotive functional safety in accordance with
                      the application of ISO 26262. It says that the task required for functional safety
                      analysis has been reduced by up to 55% and the launch period has been reduced to a
                      similar level. In addition, inconsistencies between functional safety analysis task outputs
                      have been eliminated and confirmation review and evaluation speed has been improved.
                    </>}
                </p>

                <figure>
                  <div className="img">
                    <img src={ImgSolution09} alt="ImgSolution09" />
                  </div>
                </figure>
              </div>
            </div>

            <div className="grayBox">
              <div className="title">
                {languageQuarter ?
                  <>
                    <span>사이버 보안 위협</span> 분석 및 위험 평가
                  </> : <>
                    <span>Cybersecurity threat</span> analysis and risk assessment
                  </>}
              </div>

              <div className="text">
                <p>
                  {languageQuarter ?
                    <>
                      분석 범위(context)설정, 자산 및 위협 식별, 공격 트리, 위협
                      평가 및 처리를 지원하는 모델 기반의 통합 툴입니다. <br />
                      <br />
                      SAE J3061, HEAVENS, ISO/SAE 21434와 같은 표준을 따르는 사이버
                      보안 분석, 사이버 보안 관련 기능 및 시스템의 분석과
                      아키텍처/기능 설계 모델을 사이버 보안 분석 방법과 통합가능
                      합니다.
                      <br />
                      <br />
                      사이버 보안 요구사항의 정의 및 관리할 수 있으며, 처음부터
                      끝까지 완전한 추적성을 지원하고 작업 <br className="mbOnly" />
                      산출물/문서 생성에 대한 사용자를 정의합니다. <br />
                      IBM Rational DOORS, PTC Codebeamer, Jama, MS Office, SVN 등과 연동이 가능합니다.
                    </> : <>
                      A model-based integrated tool that supports context setting, asset and
                      threat identification,attack trees, threat assessment, and handling. <br />
                      <br />
                      It can integrate cybersecurity analysis, analysis of cybersecurity-related
                      functions and systems, and architecture/function design models with cybersecurity
                      analysis methods that comply with standards such as SAE J3061, HEAVENS, and ISO/SAE 21434.
                      <br />
                      <br />
                      Define and manage cybersecurity requirements, support complete traceability from start to finish,
                      and customize your work product/document creation. Integration with IBM Rational DOORS,
                      PTC Codebeamer, Jama, MS Office, SVN, etc,
                    </>}
                </p>

                <figure>
                  <div className="img">
                    <img src={ImgSolution10} alt="ImgSolution10" />
                  </div>
                </figure>
              </div>
            </div>

            <div className="grayBox">
              <div className="title">
                {languageQuarter ?
                  <>
                    <span>품질, 안전 및 신뢰성</span> 엔지니어링
                  </> : <>
                    <span>Quality, Safety and Reliability</span> Engineering
                  </>}
              </div>

              <div className="text">
                <ul>
                  {mediniOverviewList.map((item, index) => {
                    return (
                      <li
                        className="mediniOverviewList"
                        key={"mediniOverviewList" + index}
                      >
                        <layout.BusinessTable
                          title={item.number}
                          description={item.description}
                        />
                      </li>
                    );
                  })}
                </ul>

                <figure>
                  <div className="img">
                    <img src={ImgSolution11} alt="ImgSolution11" />
                  </div>
                </figure>

                <div className="wrap">
                  <div className="img">
                    <img src={ImgSolution12} alt="ImgSolution12" />
                  </div>

                  <p className="sectionDescription">
                    {languageQuarter ?
                      <>
                        고객의 제품개발 단계에서 각종 위험(사고, 사이버보안 등)과 영향을 분석하여{" "}
                        <br className="pcOnly" />
                        품질, 안전 및 신뢰성을 높이는데 필요한 솔루션과 서비스를 제공합니다.
                      </> : <>
                        We provide solutions and services necessary to increase quality, safety, and reliability by analyzing various risks{" "}
                        <br className="pcOnly" />(accidents, cybersecurity, etc.) and impacts during the customer's product development phase.
                      </>}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="listButton">
          <ui.button.BasicButton
            buttonText={languageQuarter ? "목록으로" : "List"}
            buttonType={"blue"}
            onClick={() => {
              navigate("/business/depth/solution?disableScrollTop=true&focus=bis_menu_3");
            }}
          />
        </div>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default BusinessSolutionPage;
