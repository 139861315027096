import styled from "styled-components";

const Container = styled.section`
  && .imgContainer .source {
    top: 43px;
  }

  &.updateSection {
    & .sectionDescription {
      margin-bottom: 40px;
    }

    & .img {
      height: 420px;
      padding: 30px 464px;
    }
  }

  &.conceptSection {
    & .img {
      height: 344px;
      padding: 34px 61px;
    }
  }

  &.backgroundSection {
    & .img {
      height: 344px;
      padding: 88px 35px;
    }
  }

  &.standardSection {
    & .sectionDescription {
      margin-bottom: 20px;
    }

    & figure {
      margin-bottom: 50px;

      & .img {
        height: 366px;
        padding: 30px 476px;
      }
    }

    & .imgWrap .img {
      height: 1272px;
    }
  }

  @media (max-width: 1480px) {
    && .imgContainer .source {
      top: calc(100vw * (43 / 1480));
    }

    &.updateSection {
      & .sectionDescription {
        margin-bottom: calc(100vw * (40 / 1480));
      }

      & .img {
        height: calc(100vw * (420 / 1480));
        padding: calc(100vw * (30 / 1480)) calc(100vw * (464 / 1480));
      }
    }

    &.conceptSection {
      & .img {
        height: calc(100vw * (344 / 1480));
        padding: calc(100vw * (34 / 1480)) calc(100vw * (61 / 1480));
      }
    }

    &.backgroundSection {
      & .img {
        height: calc(100vw * (344 / 1480));
        padding: calc(100vw * (88 / 1480)) calc(100vw * (35 / 1480));
      }
    }

    &.standardSection {
      & .sectionDescription {
        margin-bottom: calc(100vw * (20 / 1480));
      }

      & figure {
        margin-bottom: calc(100vw * (50 / 1480));

        & .img {
          height: calc(100vw * (366 / 1480));
          padding: calc(100vw * (30 / 1480)) calc(100vw * (476 / 1480));
        }
      }

      & .imgWrap .img {
        height: calc(100vw * (1272 / 1480));
      }
    }
  }

  @media (max-width: 768px) {
    && .imgContainer .source {
      top: calc(100vw * (29 / 375));
    }

    &.updateSection {
      & .sectionDescription {
        margin-bottom: calc(100vw * (20 / 375));
      }

      & .img {
        height: calc(100vw * (282 / 375));
        padding: calc(100vw * (20 / 375)) calc(100vw * (35 / 375));
      }
    }

    &.conceptSection {
      & .img {
        height: calc(100vw * (208 / 375));
        padding: calc(100vw * (20 / 375));
      }
    }

    &.backgroundSection {
      & .img {
        height: calc(100vw * (134 / 375));
        padding: calc(100vw * (20 / 375)) calc(100vw * (19 / 375));
      }
    }

    &.standardSection {
      & .sectionDescription {
        margin-bottom: calc(100vw * (20 / 375));
      }

      & figure {
        margin-bottom: calc(100vw * (20 / 375));

        & .img {
          height: calc(100vw * (284 / 375));
          padding: calc(100vw * (20 / 375));
        }
      }

      & .imgWrap {
        width: calc(100vw * (295 / 375));
        margin: 0 auto calc(100vw * (20 / 375));

        & .img {
          width: calc(100vw * (781 / 375));
          height: calc(100vw * (1092 / 375));
        }
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
