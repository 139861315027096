import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 1080px;
  padding-top: 100px;
  background: no-repeat right center / cover;

  & h2 {
    font-size: 62px;
    font-weight: 800;

    & span {
      display: inline-block;
      margin-bottom: 6px;
      font-size: 32px;
      color: var(--blue02);
    }
  }

  @media (max-width: 1480px) {
    height: calc(100vw * (1080 / 1480));
    padding-top: calc(100vw * (100 / 1480));

    & h2 {
      font-size: calc(100vw * (62 / 1480));

      & span {
        margin-bottom: calc(100vw * (6 / 1480));
        font-size: calc(100vw * (32 / 1480));
      }
    }
  }

  @media (max-width: 768px) {
    height: calc(100vw * (484 / 375));
    padding-top: 0;
    background: no-repeat center / cover;

    & h2 {
      font-size: calc(100vw * (32 / 375));

      & span {
        margin-bottom: calc(100vw * (6 / 375));
        font-size: calc(100vw * (20 / 375));
      }
    }
  }
`;

export { Container };

export default { Container };
