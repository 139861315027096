import Styles from "./styles";
import { Link, useNavigate } from "react-router-dom";
import { useLanguage, useModals } from "hooks";

// img
import IconArrowDiagonal from "resources/image/icon/icon_arrow_diagonal.svg";
import LogoWhite from "resources/image/icon/logo_white02.svg";
import IconListPlus from "resources/image/icon/icon_list_plus.svg";
import IconUserPlus from "resources/image/icon/icon_user_plus.svg";
import IconTop from "resources/image/icon/icon_top.svg";

function Footer({ noQuickMenu }) {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";

  const navigate = useNavigate();
  const modalOption = useModals();

  const infoList = languageQuarter
    ? [
        "회사명 : 씨엔비스 (주)",
        "사업자등록번호 : 134-86-71286",
        "대표명 : 백재원",
        <>
          주소 : 경기도 성남시 수정구 창업로 43, 판교글로벌비즈센터 A동{" "}
          <br className="mbOnly" />
          <span></span> 907~910호
        </>,
        "TEL : 031-502-4900",
        "FAX : 031-502-4902",
      ]
    : [
        "C&BIS",
        "Korean EID(Employeer ID Numer)",
        "CEO : Jaewon BAIK",
        <>
          Address : (13449) A-907~910, Pangyo Global Biz Center, 43,
          Changeop-ro, Sujeong-gu, Seongnam-si, Gyeonggi-do, Korea
        </>,
        "TEL : 031-502-4900",
        "FAX : 031-502-4902",
      ];

  const quickMenuData = [
    {
      text: languageQuarter ? "수신자 등록" : "Register",
      icon: IconUserPlus,
      class: "registerButton",
      onClick: () => {
        modalOption.setRegisterModal((e) => {
          e.show = true;

          return { ...e };
        });
      },
    },
    {
      text: "TOP",
      icon: IconTop,
      class: "topButton",
      onClick: () => {
        window.scrollTo(0, 0);
      },
    },
  ];

  const quickMenuList = languageQuarter
    ? [
        {
          text: languageQuarter ? "교육 신청" : "Apply",
          icon: IconListPlus,
          class: "applyButton",
          onClick: () => {
            navigate("/education/apply");
          },
        },
        ...quickMenuData,
      ]
    : [...quickMenuData];

  return (
    <Styles.Container>
      <div className="inner">
        <div className="top">
          <Link to={"/terms"} className="termsButton">
            {languageQuarter ? "이용약관" : "Tems of Service"}{" "}
            <div className="icon">
              <img src={IconArrowDiagonal} alt="IconArrowDiagonal" />
            </div>
          </Link>
          {languageQuarter ? "씨엔비스 (주)" : "C&BIS"}
        </div>

        <div className="bottom">
          <div className="logo">
            <img src={LogoWhite} alt="logo" />
          </div>

          <div className="info">
            <ul>
              {infoList.map((item, index) => {
                return (
                  <li className="infoList" key={"infoList" + index}>
                    {item}
                  </li>
                );
              })}
            </ul>

            <p className="copyright">© C&BIS. all rights reserved.</p>
          </div>
        </div>
      </div>

      {!noQuickMenu && (
        <ul className="quickMenu">
          {quickMenuList.map((item, index) => {
            return (
              <li className="quickMenuList" key={"quickMenuList" + index}>
                <button
                  type="button"
                  className={item.class}
                  onClick={item.onClick}
                >
                  <span className="icon">
                    <img src={item.icon} alt="quickMenuIcon" />
                  </span>
                  {item.text}
                </button>
              </li>
            );
          })}
        </ul>
      )}
    </Styles.Container>
  );
}

export default Footer;
